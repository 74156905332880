import axios from 'axios'
import React, { useEffect, useState } from 'react'

import { getConfig } from '../AppConfig'

const HttpInterceptor = () => {
  const [authReqInterceptor, setAuthReqInterceptor] = useState(undefined)
  const [jsonResponseInterceptor, setJsonResponseInterceptor] =
    useState(undefined)
  const [urlInterceptor, setUrlInterceptor] = useState(undefined)
  // eslint-disable-next-line no-undef
  const { apiUrl } = getConfig()

  const addAuthInterceptor = () => {
    const interceptor = axios.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!config.headers.hasOwnProperty('Authorization')) {
          const token = localStorage.getItem('tokenadmin')
          if (token) {
            config.headers.Authorization = 'Bearer ' + token
          }
        } else if (!config.headers.Authorization) {
          delete config.headers.Authorization
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    setAuthReqInterceptor(interceptor)
  }

  const addParseResponseInterceptor = () => {
    const interceptor = axios.interceptors.response.use(
      (res: any) => {
        // eslint-disable-next-line no-prototype-builtins
        return res.data?.jsonInfo ? JSON.parse(res.data.jsonInfo) : res
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    setJsonResponseInterceptor(interceptor)
  }

  const addBaseUrlInterceptor = () => {
    const interceptor = axios.interceptors.request.use(
      (config) => {
        if (!config.url.includes('http')) {
          config.baseURL = apiUrl
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    setUrlInterceptor(interceptor)
  }

  const removeAuthInterceptor = () => {
    axios.interceptors.request.eject(authReqInterceptor)
    setAuthReqInterceptor(undefined)
  }

  const removeBaseUrlInterceptor = () => {
    axios.interceptors.request.eject(urlInterceptor)
    setAuthReqInterceptor(undefined)
  }

  const removeJsonResponseInterceptor = () => {
    axios.interceptors.request.eject(jsonResponseInterceptor)
    setJsonResponseInterceptor(undefined)
  }

  useEffect(() => {
    addAuthInterceptor()
    addBaseUrlInterceptor()
    addParseResponseInterceptor()
    return () => {
      removeAuthInterceptor()
      removeBaseUrlInterceptor()
      removeJsonResponseInterceptor()
    }
  }, [])

  return <React.Fragment />
}

export default HttpInterceptor
