import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface AuctionInformation {
    type: string;
    status: string;
    message: string;
    biddingopens: string;
    biddingcloses: string;
    authorizedbidders: number;
    pendingregistrations: number;
    totalnumberofbids: number;
    openbidsamount: number;
    pendingcheckoutamount: number;
    collectedamount: number;
    totalnumberofbuyitnowpurchases: number;
    totaldollarsofbuyitnowpurchases: number;
    numberitemswithbids: number;
    numberitemswithoutbids: number;
    eventlink: string;
    auctionlink:string;
    resetmessage: string;
    showresetbutton: boolean;
}
const defaultAuctionInformation: AuctionInformation = {
    type: "",
    status: "",
    message: "",
    biddingopens: "",
    biddingcloses: "",
    authorizedbidders: 0,
    pendingregistrations: 0,
    totalnumberofbids: 0,
    openbidsamount: 0,
    pendingcheckoutamount: 0,
    collectedamount: 0,
    totalnumberofbuyitnowpurchases: 0,
    totaldollarsofbuyitnowpurchases: 0,
    numberitemswithbids: 0,
    numberitemswithoutbids: 0,
    eventlink: "",
    auctionlink:"",
    resetmessage: "",
    showresetbutton: false
};

export const useAuctionInfo = (eventIdvalue) => {
    return useQuery<AuctionInformation>(
        ['auctioninformation', eventIdvalue],
        () => globalAuctionInfoFetcher(eventIdvalue),
        {
            staleTime: 120000,
            placeholderData: defaultAuctionInformation,
            keepPreviousData: true
        }
    )
}


export const globalAuctionInfoFetcher = async (eventIdvalue) => {
    const { data } = await axios.post('/auctioninformation', { eventid: eventIdvalue })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as AuctionInformation
}

export const useEventInfo = (eventIdvalue) => {
    return useQuery<AuctionInformation>(
        ['eventinformation', eventIdvalue],
        () => globalEventInfoFetcher(eventIdvalue),
        {
            staleTime: 120000,
            placeholderData: defaultAuctionInformation,
            keepPreviousData: true
        }
    )
}


export const globalEventInfoFetcher = async (eventIdvalue) => {
    const { data } = await axios.post('/evtinformation', { eventid: eventIdvalue })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as AuctionInformation
}
