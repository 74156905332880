import axios from 'axios'
import { useQuery } from 'react-query'

export interface GEM {
    transactionid: string
    gatewayid: string
    ecommerceid: string
    retailid: string
    lastupdated: string
    validationdate: string
    valid: boolean
}

export const orgSettingsGEMFetcher = async () => {
    const response = await axios.get(`/orgsettingsgem`, {
        params: {}
    })
    const parsedResponse = JSON.parse(response?.data?.jsonString)
    const goemerchant = JSON.parse(parsedResponse.goemerchant)
    return goemerchant
}

export const useFetchOrgSettingsGEM = ({
                                        onSuccess = () => null,
                                        onError = () => null
                                    }: {
    onSuccess?: any
    onError?: any
} = {}) => {
    return useQuery<GEM>(
        ['orgsettingsgem'],
        () => orgSettingsGEMFetcher(),
        {
            staleTime: 120000,
            onSuccess,
            onError,
            retry: false
        }
    )
}
