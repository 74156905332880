import * as React from 'react';
import { EditorUtils } from '@progress/kendo-react-editor';
import { plusIcon } from '@progress/kendo-svg-icons';
import { DropDownButton } from '@progress/kendo-react-buttons';

interface Shortcode {
  text: string;
  code: string;
  id: number;
}

const shortcodes: Shortcode[] = [
  { text: 'Name', code: '«name»', id: 1 },
  { text: 'Initials', code: '«initials»', id: 2 },
  { text: 'Address', code: '«address_street»', id: 3 },
  { text: 'Email', code: '«email»', id: 4 },
  { text: 'Phone', code: '«phone»', id: 5 },
];

const defaultItem: Shortcode = { text: 'Insert Field', code: '', id: 0 };

interface InsertShortcodeToolProps {
  view: any;  // This should be more specific, but without knowing the exact type, I'll use 'any' for now.
}

export class InsertShortcodeTool extends React.Component<InsertShortcodeToolProps> {
  handleChange = (event: any) => {  // The type of 'event' should be more specific, but I'll use 'any' for now.
    if (!event.item.code) {
      return;
    }
    const { view } = this.props;
    const schema = view.state.schema;

    // get the new node from the schema
    const nodeType = schema.nodes.nonEditable;

    // create a new node with the selected text
    const node = nodeType.createAndFill(
      { class: 'shortcode' },
      schema.text(event.item.code)
    );

    // Insert the new node
    EditorUtils.insertNode(view, node);
    view.focus();
  };

  render() {
    const { view } = this.props;
    const nodeType = view && view.state.schema.nodes.text;
    const canInsert = view && EditorUtils.canInsert(view.state, nodeType);

    return (
      <DropDownButton
        text="Field Codes"
        items={shortcodes}
        iconClass='k-icon k-i-plus'
//svgIcon={plusIcon}
        // textField="text"
        // dataItemKey="id"
        //defaultItem={defaultItem}
        //value={defaultItem}
        // disabled={!canInsert}
        onItemClick={this.handleChange}
        style={{ userSelect: 'none' }}
      />
    );
  }
}
