import React, { useState, useMemo } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { DataGridPremium, DataGridPremiumProps,  GridToolbarContainer, GridToolbarExport, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import {useFetchparticipentsListing, usePutParticipantUNLink, usePutParticipantAutoLink} from "../../queries/useParticipantLinking";
import {useAutoEvent} from "../../queries/useAutoEvent";
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import {EventState, setEvent, useEventState} from '../../slices/eventSlice'
import dayjs from "dayjs";
import {formatMoney} from "../../util/formatHelpers";
import { makeStyles, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import styles from './ParticipantsListingPage.module.css'

interface Params {
  eventid: string
}



const useStyle = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "visible",
      lineHeight: "1.43rem",
      whiteSpace: "normal"
    }
  }
});


  const ParticipantsListingPage = () => {
    
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const unLink = usePutParticipantUNLink()
    const autoLink = usePutParticipantAutoLink()
    const history = useHistory()
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const event = useEventState()
    //console.log(event)
    var rows = []

    const { data , isLoading } = useFetchparticipentsListing(event?.eventId);
    if(data){
      rows = data;
    }
    const columns = [
      {
        field: 'button',
        headerName: 'Link',
        width: 130,
        disableExport: true,
        sortable: false,
        renderCell: (params) => {
          if(params.row.contactid > 0){
            return <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                unLink.mutate({
                  eventid: event.eventId,
                  participantid: params.row.participantid,
                },{
                  onSuccess: (data) => {
                    console.log(data)
                   
                  }
                })
              }}>
              UnLink
            </Button>;
          }
            return  <Button
          variant="contained"
          color="primary"
          onClick={() => {
            console.log(params)
            history.push({ 
              pathname: '/auction/' + event.eventId + '/participantlinking',
             state:{
               data: params.row,
             }}
            )
          }}>
          Link
        </Button>;
                  
         }
      },
      //{ field: 'participantid', headerName: 'participantid' } ,
      {
        field: 'bidnumber',
        type: 'string',
        headerName: 'Bidder #',
        width: 75,
        editable: false
      },
      {
        field: 'authorizedbidder',
        type: 'boolean',
        headerName: 'Authorized',
        width: 90,
        editable: true
      },
     
      {
        field: 'first',
        type: 'string',
        headerName: 'First Name',
        width: 150,
        editable: true
      },
      {
        field: 'last',
        type: 'string',
        headerName: 'Last Name',
        width: 150,
        editable: true
      },
      {
        field: 'email',
        type: 'string',
        headerName: 'Email',
        width: 200,
        editable: false
      },
      {
        field: 'phone',
        type: 'string',
        headerName: 'Phone',
        valueFormatter: (params) => {
          var cleaned = ('' + params.value).replace(/\D/g, '')
          var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
          return match
            ? '(' + match[1] + ') ' + match[2] + '-' + match[3]
            : params.value
        },
        width: 150,
        editable: true
      },
      {
        field: 'source',
        type: 'string',
        headerName: 'Source',
        width: 100,
        editable: true
      },
      {
        field: 'lastlogindate',
        headerName: 'Last Login',
        width: 175,
        valueFormatter: (params: GridValueFormatterParams) => {
          if (params.value) {
            return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
          }
          else {
            return 'Never'
          }
        },
      },
      {
        field: 'contactid',
        headerName: 'Linked Contact',
        width: 100,
        
      }
      ,
    {
      field: 'paddlenumbertext',
      type: 'string',
      headerName: 'Paddle Number',
      width: 200,
      editable: true
    }
     
    ];
  const classes = useStyle();

    return (
      <div className={styles.root}>
        <h2>Participant Linking</h2>
    
        <Box
  m={1}
 //margin
  display="flex"
  justifyContent="flex-start"
  alignItems="flex-start"
>
      <Button 
              variant="contained"
              color="secondary"
              onClick={() => {
                autoLink.mutate({
                  eventid: event.eventId,
                },{
                  onSuccess: (response) => {
                    const parsedData = JSON.parse(response.data.jsonString);
                    if (parsedData.status === "success") {
                      setDialogOpen(true);
                      setDialogMessage(parsedData.message);
                    } else {
                      setDialogOpen(true);
                      setDialogMessage(parsedData.message);
                    }
                  },
                  onError: () => {
                    setDialogOpen(true);
                    setDialogMessage('Auto link operation failed. Please try again.');
                  }
                })
              }}>
              Auto Link
            </Button>
            <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Auto Link Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
            </Box>
        <DataGridPremium
        getRowId={(row) => row.participantid}
        className={classes.root}
        autoHeight
          columns={columns}
          rows={rows}
          loading={isLoading}
          rowThreshold={0}
        
          columnVisibilityModel={{
            // Hide column rownumber the other columns will remain visible
            participantid: false,
          }}
         

        
        />
        </div>
    );
  }
  export default ParticipantsListingPage
