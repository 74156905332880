import axios from 'axios'
import { useQuery } from 'react-query'

export type Sponsor = {
  sponsorid: number
  name: string
  logo: string
  logourl: string
  active: boolean
}

export const globalSponsorFetcher = async (eventIdvalue) => {
  const { data } = await axios.post('/sponsor', { eventid: eventIdvalue })
  const parsedData = JSON.parse(data.jsonString)
  return parsedData.sponsors as Sponsor[]
}

export const useFetchSponsors = (eventid) => {
  return useQuery<Sponsor[]>(
    ['sponsors', eventid],
    () => globalSponsorFetcher(eventid),
    {
      staleTime: 120000
    }
  )
}
