import { useForm } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import SelectInput from '../../../components/common/inputs/SelectInput'
import TextInput from '../../../components/common/inputs/TextInput'
import { useEventState } from '../../../slices/eventSlice'
import styles from './TagsTable.module.css'
import { useCreateTag } from './useCreateTag'
import React, {useEffect} from "react";

type FormData = {
  active: string
  id: number
  description: string
}
export const TagsForm = ({ isOpen, setIsOpen, gridParams }) => {
  const formMethods = useForm<FormData>()
  const { reset } = formMethods
  const eventState = useEventState()
  const createMutation = useCreateTag()
  const title = gridParams ? "Edit Tag" : "Create Tag"
  const [dialogMsg, setDialogMsg] = React.useState("")

  useEffect(() => {
    if (gridParams) {
      reset({
        id: gridParams.id,
        description: gridParams.description,
        active: (gridParams ? (gridParams.active ? 'Active' : 'Inactive') : 'Active'),
      })
    }
    else {
      reset({ active: 'Active' })
    }
  }, [gridParams, isOpen])

  const onSubmit = async (formData: FormData) => {
    const id = gridParams ? { id: formData.id } : { }

    try {
      await createMutation.mutateAsync({
        description: formData.description,
        active: (formData.active === 'Active'),
        type: "tag",
        eventid: Number(eventState.eventId),
        ...id
      },{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          if (json.status === "failure") {
            setDialogMsg(json.message)
          }
          else {
            setIsOpen(false)
          }
        }
      })
    } catch {}
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={onSubmit}
          className={styles.tagsForm}
          formProps={{ id: 'tags-form' }}>
          <TextInput name="description" label="Tag" required />
          <SelectInput
            name="active"
            label="Status"
            required
            options={[
              { label: 'Active', value: 'Active' },
              { label: 'Inactive', value: 'Inactive' }
            ]}
            includeNone={false}
          />
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="tags-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
