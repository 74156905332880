import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {CheckoutObject, globalCheckoutFetcher} from "../../queries/useFetchCheckout";

export const useBroadcastMessage = () => {
  const queryClient = useQueryClient()

  return useMutation((body: any) => axios.post(`/broadcastmessage`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['broadcastmessage'])
    }
  })
}

export const useBroadcastTextMessage = (body) => {
  return useQuery(
      ['broadcasttextmessage', body.eventid],
      () => globalBroadcastTextMessageFetcher(body),
      {
        staleTime: 0,
        keepPreviousData: false
      }
  )
}

export const globalBroadcastTextMessageFetcher = async (body) => {
  const { data } = await axios.post('/broadcasttextmessage', body)
  const parsedData = JSON.parse(data.jsonString)
  return parsedData as CheckoutObject
}


export const useCartEmailPresend = () => {
  const queryClient = useQueryClient()

  return useMutation((body: any) => axios.post(`/cartemailpresend`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['cartemailpresend'])
    }
  })
}


export const useCartEmailSend = () => {
  const queryClient = useQueryClient()

  return useMutation((body: any) => axios.post(`/cartemailsend`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['cartemailsend'])
    }
  })
}
