import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export type AuctionItems = {
    itemid: number
    itemnumber: string
    name: string
    shortdesc: string
    longdesc: string
    locationid: number
    taglistids: number
    liveauctiononly: boolean
    onsitesaleitem: boolean
    allowautobid: boolean
    donatedby: string
    donatedbyemail: string
    donationtypeid: number
    fairmarketvalue: number
    startingbid: number
    minimumraise: number
    buyitnowprice: number
    buyitnowonly: boolean
    quantityunlimited: boolean
    quantity: number
    imageloaded: boolean
    logo: string
    logourl: string
    pickupinstructions: string
    active: boolean
    itemtype: number
    itemorder: number
}

const auctionItemsDefaults = {
    itemid: 0,
    itemnumber: "",
    name: "",
    shortdesc: "",
    longdesc: "",
    locationid: 0,
    taglistids: 0,
    liveauctiononly: false,
    onsitesaleitem: false,
    allowautobid:  false,
    donatedby: "",
    donatedbyemail: "",
    donationtypeid: 0,
    fairmarketvalue: 0,
    startingbid: 0,
    minimumraise: 1,
    buyitnowprice: 0,
    buyitnowonly:  false,
    quantityunlimited:  false,
    quantity: 1,
    imageloaded:  false,
    logo: "",
    logourl: "",
    pickupinstructions: "",
    active:  true,
    itemtype: 1,
    itemorder: 0
}
export type LiveAuctionItems = {
    itemid: number
    itemnumber: string
    name: string
    startingbid: number
    bidderparticipantid: number
    lastbid: number
    biddername: string
    biddernumber: number
    biddersource: string
    historyid: number
    livebidemailsent: string
    livebidcheckedout: string
    livebidconfirmed: string
}

const defaultLiveAuctionItem = {
    itemid: 0,
    itemnumber: "",
    name: "",
    startingbid: 0,
    lastbid: 0,
    biddername: "",
    biddernumber: -1,
    biddersource: "",
    historyid: 0,
    livebidemailsent: "",
    livebidcheckedout: "",
    livebidconfirmed: "",
    bidderparticipantid : -1
};

export type liveBidder = {
    rownumber: number
    bidderparticipantid: number
    biddername: string
}
const liveBidderdefault = {
    rownumber: 0,
    bidderparticipantid: 0,
    biddername : ""
}

export type liveAuctionLists = {
    LiveAuctionitemlist : LiveAuctionItems[]
    bidderlist : liveBidder[]
}
const liveauctionDefault : liveAuctionLists ={
    LiveAuctionitemlist:[defaultLiveAuctionItem],
    bidderlist:[liveBidderdefault]
}
export const globalAuctionItemsFetcher = async (body) => {
    const { data } = await axios.post('/eventitems', body)
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.eventitems as AuctionItems[]
}

export const useFetchAuctionItems = (eventiDvalue) => {
    console.log("use fetch auction items")
    console.log(eventiDvalue)
    return useQuery<AuctionItems[]>(
        ['eventitems', eventiDvalue],
        () => globalAuctionItemsFetcher({ "eventid": eventiDvalue }),
        {
            staleTime: 120000,
            placeholderData: [auctionItemsDefaults],
            //keepPreviousData: true
        }
    )
}

export const globalLiveAuctionItemsFetcher = async (body) => {
    const { data } = await axios.post('/liveauction', body)
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as liveAuctionLists
}
export const useUpdateLiveBid = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`liveauction`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['liveeventitems'])
        }
    })
}
export const useClearLiveBid = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`liveauctionclear`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['liveeventitems'])
        }
    })
}
export const useFetchLiveAuctionItems = (eventiDvalue) => {
    return useQuery<liveAuctionLists>(
        ['liveeventitems', eventiDvalue],
        () => globalLiveAuctionItemsFetcher({ "eventid": eventiDvalue }),
        {
            staleTime: 120000,
            placeholderData: liveauctionDefault
        }
    )
}



export const useUpdateItemOrder = (eventiDvalue) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`eventitemsorder`, body), {
        onSuccess: () => {
            //  const parsedData = JSON.parse(newData.data.jsonString)
             console.log("updatesuccess")
            // console.log(parsedData.eventitems as AuctionItems[])
            // let newDataFields = parsedData.eventitems as AuctionItems[]
            //  queryClient.setQueryData(['eventitems', eventid], newDataFields)
            console.log(eventiDvalue)
            queryClient.invalidateQueries(['eventitems', eventiDvalue])
        }
    })
}

export const useConfirmLiveAuction = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
      axios.post(`liveauctionconfirmsend`, body), {
      onSuccess: () => {
        queryClient.invalidateQueries(['liveeventitems'])
      },
    });
  }