import React, {useEffect, useMemo, useState} from 'react'
import Dropzone from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, ButtonProps } from '@material-ui/core'
import { styled } from '@mui/material/styles';
import Alerts from '../../components/common/Alerts'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import CheckboxGroup from '../../components/common/inputs/CheckboxGroup'
import DateTimeInput from '../../components/common/inputs/DateTimeInput'
import SelectInput from '../../components/common/inputs/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
import { useFetchEventSettings } from '../../queries/events/useFetchEventSettings'
import {EventState, setEvent, useEventState} from '../../slices/eventSlice'
import styles from './AuctionSettingsForm.module.css'
import { useEditEventSettings } from './useEditEventSettings'
import { useFetchTimezones } from "../../queries/useFetchTimezones";
import {useFetchReceiptTemplates} from "../../queries/useFetchReceiptTemplates";
import {Lock, LockOpen, Close } from '@material-ui/icons'
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {checkBidEndDate, checkBidStartDate, checkEndDate, checkEventEndDate} from "../../util/formValidation";
import {useAuthToken} from "../../slices/authSlice";
import {useFetchOrgSettings} from "../OrgSettingsPage/useFetchOrgSettings";
import {OrgState, setOrg, useOrgState} from "../../slices/orgSlice";
import {Editor, EditorTools} from "@progress/kendo-react-editor";
import imageCompression from "browser-image-compression";
import {useQueryClient} from "react-query";

type FormData = {
  rownumber: number
  id: number
  name: string
  code: string
  active: boolean
  start: string
  end: string
  bidstart: string
  bidend: string
  timezone: string
  checkboxbiddingsettings: {
    allowbuyitnow: boolean
    allowbidanonymously: boolean
    allowbillme: boolean
    allowguestentry: boolean
    allowpaymentoffees: boolean
  }
  checkboxothersettings: {
    allowautobid: boolean
    allowautobidincrement: boolean
    allowcreditcards: boolean
    approvalofbidders: boolean
    allowcustomlocations: boolean
    allowtexting: boolean
    eventshowbidderpaddlenumber: boolean,
    eventusepaddlenumber: boolean
  }
  ccfee: number
  startbiddernum: number
  receipttemplateid: number
  eventbiddingopen: string
  eventbiddingclosed: string
  logourl: string
  eventprivacy: string
  logo: string
  textprefix: string
  logoremoved: boolean
}

interface Params {
  id: string
}

const AuctionSettingsForm = ({ isEdit = false }) => {
  const richTextTools = [
    [EditorTools.Bold, EditorTools.Italic, EditorTools.Underline, EditorTools.Strikethrough],
    // [EditorTools.Subscript, EditorTools.Superscript],
    EditorTools.ForeColor,
    EditorTools.BackColor,
    [EditorTools.CleanFormatting],
    [EditorTools.AlignLeft, EditorTools.AlignCenter, EditorTools.AlignRight, EditorTools.AlignJustify],
    [EditorTools.Indent, EditorTools.Outdent],
    [EditorTools.OrderedList, EditorTools.UnorderedList],
    // [EditorTools.NumberedList, EditorTools.BulletedList],
    EditorTools.FontSize,
    EditorTools.FontName,
    EditorTools.FormatBlock,
    [EditorTools.SelectAll],
    [EditorTools.Undo, EditorTools.Redo],
    [EditorTools.Link, EditorTools.Unlink, EditorTools.InsertImage, EditorTools.ViewHtml],
    [EditorTools.InsertTable, EditorTools.InsertFile],
    // [EditorTools.Pdf, EditorTools.Print],
    [EditorTools.AddRowBefore, EditorTools.AddRowAfter, EditorTools.AddColumnBefore, EditorTools.AddColumnAfter],
    [EditorTools.DeleteRow, EditorTools.DeleteColumn, EditorTools.DeleteTable],
    [EditorTools.MergeCells, EditorTools.SplitCell],
  ]
  const queryClient = useQueryClient()
  const [privacyValue, setPrivacyValue] = useState('')
  const warningMsg = "WARNING: Changing the Event Code will change your event URL."
  const { id } = useParams<Params>()
  useAutoEvent(id)
  const event = useEventState()
  const orgState = useOrgState()

  const formMethods = useForm<FormData>()
  const { reset, watch, setValue } = formMethods
  const watchLogo = watch('logourl')
  const watchLogoData = watch('logo')
  const history = useHistory()
  const [dialogObj, setDialogObj] = React.useState({ msg: "", show: false })
  const allowTextingWatch = watch('checkboxothersettings.allowtexting')
  const approvalofbidders = watch('checkboxothersettings.approvalofbidders')
  const allowcustomlocations = watch('checkboxothersettings.allowcustomlocations')
  const allowpaymentoffeesWatch = watch('checkboxbiddingsettings.allowpaymentoffees')
  const bidEndWatch = watch('bidend')


  const dispatch = useDispatch()
  const authToken = useAuthToken()
  const { data: settings } = useFetchOrgSettings({ userid: authToken?.userid })

  useEffect(() => {
    if (settings) {
      const orgState: OrgState = {
        name: settings?.name, message: settings?.message, isdonorsnapcustomer: settings?.isdonorsnapcustomer, twilio: settings?.twilio
      }
      dispatch(setOrg(orgState))
    }
  }, [settings])

  const [errorMsg, setErrorMsg] = useState({
    show: false,
    msg: ""
  })


  const [successMsg, setSuccessMsg] = useState<boolean>(false)

  const [lockCode, setLockCode] = useState<boolean>(true)
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [openFailure, setFailureOpen] = React.useState(false);
  const [failureMessage, setFailureMessage] = React.useState<string>("");

  var { data: eventsettings, isFetching } = useFetchEventSettings(
    Number(event.eventId),
    isEdit
  )

  const { data: recieptTemplates } = useFetchReceiptTemplates()
  const templates = recieptTemplates.map(x => {
    return { label: x.name, value: x.receipttemplateid }
  })
  const { data : timeZoneData } = useFetchTimezones()
  const timezones = timeZoneData?.map(timeZone => {
    return { value: timeZone.timezone, label: timeZone.timezone }
  })
  const editMutation = useEditEventSettings()
  const eventCodeRegex = /^[-_A-Za-z0-9]+$/

  const onEditSubmit = (formData: FormData) => {
    console.log(formData)
    const {
      checkboxbiddingsettings,
      checkboxothersettings,
      start,
      end,
      bidstart,
      bidend,
      ...rest
    } = formData

    editMutation.mutate({
      eventid: Number(event.eventId),
      start: dayjs(start).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(end).format('YYYY-MM-DDTHH:mm:ss'),
      bidstart: dayjs(bidstart).format('YYYY-MM-DDTHH:mm:ss'),
      bidend: dayjs(bidend).format('YYYY-MM-DDTHH:mm:ss'),
      ...rest,
      ccfee: Number(formData.ccfee),
      eventprivacy: privacyValue,
      startbiddernum: Number(formData.startbiddernum),
      ...checkboxbiddingsettings,
      ...checkboxothersettings
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "failure") {
          setFailureMessage(json.message)
          setFailureOpen(true)
        }
        else {
          console.log(json)
          const eventState: EventState = {
            eventId: json.eventid,
            eventName: json.name,
            eventCode: json.code,
            eventStart: json.start,
            eventEnd: json.end,
            bidStart: json.bidstart,
            bidEnd: json.bidend,
            allowpaymentoffees: json.allowpaymentoffees,
            ccfee: json.ccfee
          }
          dispatch(setEvent(eventState))
          queryClient.invalidateQueries(['auctioninformation', json.eventid])
          queryClient.invalidateQueries(['eventinformation', json.eventid])
          setDialogMsg("Event was updated successfully.")
        }
      }
    })
  }

  const handleClickContinue = () => {
    const isLock = (warningMsg === dialogMsg);
    setDialogMsg("")
    setLockCode(!lockCode)

    if (!isLock && !isEdit) {
      history.goBack()
    }
  };

  const handleClose = () => {
    setDialogMsg("")
  };

  const closeFailure = () => {
    setFailureOpen(false);
  };

  const showDialog = () => {
    return (
        <>
          <Dialog
              open={dialogMsg.length > 0}
              // onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {((warningMsg === dialogMsg)) && (
                  <Button onClick={handleClose}>Cancel</Button>
              )}
              <Button onClick={handleClickContinue} autoFocus>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </>
    )
  }

  const showFailureDialog = () => {
    return (
        <>
          <Dialog
              open={openFailure}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <b>Failure:</b> {failureMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeFailure}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
    )
  }

const FloatingButton = styled(Button)<ButtonProps>(({ theme }) => ({
  margin:'auto',
  top: 'auto',
  //  right: '40%',
  bottom: 20,
   left: '25%',
  position: 'fixed',
  width: '50%'
}))

  const onCreateSubmit = (formData: FormData) => {

    const {
      checkboxbiddingsettings,
      start,
      end,
      bidstart,
      bidend,
      checkboxothersettings,
      receipttemplateid,
      ...rest
    } = formData

    editMutation.mutate({
      ...rest,
      start: dayjs(start).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(end).format('YYYY-MM-DDTHH:mm:ss'),
      bidstart: dayjs(bidstart).format('YYYY-MM-DDTHH:mm:ss'),
      bidend: dayjs(bidend).format('YYYY-MM-DDTHH:mm:ss'),
      ccfee: Number(formData.ccfee),
      startbiddernum: Number(formData.startbiddernum),
      ...checkboxbiddingsettings,
      ...checkboxothersettings,
      active: true,
      eventprivacy: privacyValue
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "failure") {
          setFailureMessage(json.message)
          setFailureOpen(true)
        }
        else {
          // setDialogMsg("Event was created successfully.")
          history.push('/auction/' + json.eventid)
        }
      }
    })
  }

  const onLogoDrop = async (files: File[]) => {
    const options = {
      maxSize: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    const compressedFile = await imageCompression(files[0], options)

    const fileReader = new FileReader()
    fileReader.onload = function (event) {
      const data = event.target.result as string
      setValue('logo', data.split(';base64,')[1])
    }
    fileReader.readAsDataURL(compressedFile)
  }

  const clickLockOpen = () => {
    setDialogMsg(warningMsg);
  }

  const clickLockClose = () => {
    setDialogMsg("");
    setLockCode(!lockCode)
  }

  const clickRemoveLogo = () => {
    setValue("logourl", "")
    setValue('logoremoved', true)
  }

  useEffect(() => {
    // if (eventsettings && isEdit) {
      const startDate = eventsettings.start ? eventsettings?.start?.split(' ').join('T') : new Date().toISOString()
      const endDate = eventsettings.end ? eventsettings?.end?.split(' ').join('T') : new Date().toISOString()
      const bidStartDate = eventsettings.bidstart ? eventsettings?.bidstart?.split(' ').join('T') : new Date().toISOString()
      const bidEndDate = eventsettings.bidend ? eventsettings?.bidend?.split(' ').join('T') : new Date().toISOString()
      const timeZoneForm = eventsettings.timezone ? eventsettings.timezone : 'Central Standard Time'

      const twilio = orgState?.twilio

      var allowtexting = eventsettings.allowtexting
      if (twilio.valid === false) {
        allowtexting = false
      }

      reset({
        rownumber: eventsettings.rownumber,
        id: eventsettings.eventid,
        name: eventsettings.name,
        code: eventsettings.code,
        active: eventsettings.active,
        start: startDate,
        end: endDate,
        bidstart: bidStartDate,
        bidend: bidEndDate,
        timezone: timeZoneForm,
        checkboxbiddingsettings: {
          allowbuyitnow: eventsettings.allowbuyitnow,
          allowbidanonymously: eventsettings.allowbidanonymously,
          allowbillme: eventsettings.allowbillme,
          allowguestentry: eventsettings.allowguestentry,
          allowpaymentoffees: eventsettings.allowpaymentoffees,
        },
        checkboxothersettings: {
          allowcustomlocations: eventsettings.allowcustomlocations,
          allowcreditcards: eventsettings.allowcreditcards,
          allowautobid: eventsettings.allowautobid,
          approvalofbidders: eventsettings.approvalofbidders,
          allowautobidincrement: eventsettings.allowautobidincrement,
          allowtexting: allowtexting,
          eventshowbidderpaddlenumber: eventsettings.eventshowbidderpaddlenumber,
          eventusepaddlenumber: eventsettings.eventusepaddlenumber
        },
        ccfee: eventsettings.ccfee,
        startbiddernum: eventsettings.startbiddernum,
        receipttemplateid: eventsettings.receipttemplateid,
        eventbiddingopen: eventsettings.eventbiddingopen,
        eventbiddingclosed: eventsettings.eventbiddingclosed,
        textprefix: eventsettings.textprefix,
        eventprivacy: eventsettings.eventprivacy,
        logourl: eventsettings.logourl,
        logoremoved: false
      })
      setPrivacyValue(eventsettings.eventprivacy)
  }, [eventsettings, reset, isFetching, isEdit])

  const handleChange = (e, field) => {
      if (field.name === "checkboxothersettings.approvalofbidders" && field.value === false) {
        setDialogObj({msg: "Caution: Checking this box means that you will need to regularly check the Participants page to manually approve any newly registered participants. Participants that are not approved will not be able to bid on any items. To automatically approve all newly registered participants, leave this setting unchecked.", show: true})
      }
      else if (field.name === "checkboxothersettings.allowcustomlocations" && field.value === false) {
        setDialogObj({msg: "Caution: Checking this box means that all bidding open/close times are controlled by the Location settings. Changing the bidding open/close times on this page will not affect any existing location close times. Only use this setting if you plan to close tables at different times.", show: true})
      }
  }

  const handleCloseDate = () => {
    if (eventsettings?.bidend) {
      if (new Date(eventsettings?.bidend) <= new Date()) {
        if (dialogObj.show === false) {
          setDialogObj({msg: "Warning: This auction has already closed. Reopening bidding may cause unintended consequences and confusion for participants, especially in checkout. Winning bid and closing soon notifications will not be sent again for this auction. If this was a test, please create a new auction or clone this auction to set up your live event.", show: true})
        }
      }
    }
  }

  const disableTexting = () => {
    const twilio = orgState?.twilio

    return twilio.valid === false
  }

  return (
    <div className={styles.root}>
      {showDialog()}
      {showFailureDialog()}

      <>
        <Dialog
            open={dialogObj.show}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogObj.msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setDialogObj({msg: '', show: false})}}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <h1>Auction Settings</h1>
      <HookForm
        methods={formMethods}
        onSubmit={isEdit ? onEditSubmit : onCreateSubmit}
        className={styles.form}
        formProps={{ id: 'EventSettings-form' }}>
        <LoadingBackdrop open={isFetching} />
          <TextInput name="name" label="Event/Auction Name" required tooltipText={'The name that will be displayed to your guests'}/>
        <Grid container spacing={1}>
          <Grid item xs={11} className={styles.selectcontrol}>
            <TextInput
                name="code"
                label="Event/Auction Code"
                required
                disabled={isEdit && lockCode}
                rules={{
                  pattern: {
                    value: eventCodeRegex,
                    message: 'Event/Auction code can only contain letters, numbers, hyphens, underscores, and no spaces.'
                  }
                }}
                tooltipText={'The part of the URL to specify your event. No spaces or special characters allowed. Keep it short and easy to remember.'}
            />
          </Grid>
          {isEdit && (
              <Grid item xs={1} className={styles.selectcontrol}>
                {!lockCode && <Lock fontSize="large" onClick={clickLockClose} />}
                {lockCode && <LockOpen fontSize="large" onClick={clickLockOpen} />}
              </Grid>
          )}
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={3} className={styles.selectcontrol}>
            <DateTimeInput
                name="start"
                label="Auction Open"
                defaultValue={null}
                required={true}
                tooltipText={'The time when a user can log in to your event and see the auction items'}
            />
          </Grid>

          <Grid item xs={3} className={styles.selectcontrol}>
{!allowcustomlocations &&  <DateTimeInput
                name="bidstart"
                label="Bidding Open"
                rules={{
                  validate: (val) => checkBidStartDate(val, watch),
                }}
                defaultValue={new Date().toISOString()}
                required={!allowcustomlocations}
                disabled={allowcustomlocations}
                tooltipText={'The time when a user can begin bidding on auction items'}
            />}
           {allowcustomlocations && 
              <TextInput
                name="bidstartBlank"
                label="Bidding Open"
                defaultValue='Custom by Location'
                disabled={true}
                tooltipText={ 'To enable the auction-wide bidding open/close times, disable the "Allow Custom Bidding Open/Close by Location setting below”'}
            />}
          </Grid>


          <Grid item xs={3} className={styles.selectcontrol}>
          {!allowcustomlocations && <DateTimeInput
                name="bidend"
                label="Bidding Close"
                rules={{
                  validate: (val) => checkBidEndDate(val, watch),
                }}
                defaultValue={new Date().toISOString()}
                onClose={handleCloseDate}
                required={!allowcustomlocations}
                disabled={allowcustomlocations}
                //tooltipText={"The time when no more bids will be accepted on auction items"}
            />}
             {allowcustomlocations && 
              <TextInput
                name="bidendBlank"
                label="Bidding Close"
                defaultValue='Custom by Location'
                disabled={true}
                tooltipText={ 'To enable the auction-wide bidding open/close times, disable the "Allow Custom Bidding Open/Close by Location setting below”'}
            />}
          </Grid>

          <Grid item xs={3} className={styles.selectcontrol}>
            <DateTimeInput
                name="end"
                label="Auction Close"
                rules={{
                  validate: (val) => checkEventEndDate(val, watch),
                }}
                defaultValue={new Date().toISOString()}
                required={true}
                tooltipText={"The time when the auction website and checkout pages will no longer be accessible. This is usually multiple days after bidding closes to allow collecting payment at a later date."}
            />
          </Grid>
        </Grid>

        <SelectInput
          name="timezone"
          label={'Event/Auction Timezones'}
          options={timezones}
          defaultValue={(timezones && timezones.length > 0 ? timezones[0].label : "")}
          includeNone={false}
          tooltipText={"The timezone where your event will be held. Dates/times will automatically adjust for each user’s location on their device."}
        />

        <Grid container spacing={1}>
          <Grid item xs={4} className={styles.selectcontrol}>
            <CheckboxGroup
              name="checkboxbiddingsettings"
              onChange={(e, field) => {handleChange(e, field)}}
              options={[
                // { value: 'allowbillme', label: 'Allow Bill Me', tooltipText: "Allow checkout without immediate payment in order to send an invoice at a later date." },
                {
                  value: 'allowbidanonymously',
                  label: 'Allow Bid Anonymously',
                  tooltipText: "Give users the option to hide their name in the Bid History section."
                },
                // { value: 'allowbuyitnow', label: 'Allow Buy it Now', tooltipText: "Allow setting a price for an auction item to be purchased, bypassing all bids."},
                {
                  value: 'allowguestentry',
                  label: 'Allow Guest Entry (View Only)',
                  tooltipText: "Allow users to browse your auction items without logging in. All users must log in to place a bid."
                },
                {
                  value: 'allowpaymentoffees',
                  label: 'Allow Payment of CC Fees',
                  tooltipText: "Display a checkbox during checkout for the user to add a set percentage to their payment to offset payment processing fees."
                },
              ]}
              label=""
            />
          </Grid>
          <Grid item xs={4} className={styles.selectcontrol}>
            <CheckboxGroup
              name="checkboxothersettings"
              onChange={(e, field) => {handleChange(e, field)}}
              options={[
                // { value: 'allowautobid', label: 'Allow Auto Bid', tooltipText: "Give users the ability to set a maximum bid amount when placing a bid." },
                // {
                //   value: 'allowautobidincrement',
                //   label: 'Allow Auto Bid Increment',
                //   tooltipText: "Give users the option to specify an increment amount when using Auto Bid."
                // },
                {
                  value: 'approvalofbidders',
                  label: 'Require Approval of Bidders',
                  tooltipText: "Do not allow bidding by users that have not been manually approved in the Participants page."
                },
                {
                  value: 'allowcustomlocations',
                  label: 'Allow custom bidding open/close by location',
                  tooltipText: "Each location will have independent open and close times for bidding. Set up these times on the Locations page."
                },
                { value: 'eventusepaddlenumber', label: 'Use manually assigned paddle number instead of auto-assigned bidder number', tooltipText: "" },

              ]}
              label=""
            />
          </Grid>
          <Grid item xs={4} className={styles.selectcontrol}>
            <CheckboxGroup
                name="checkboxothersettings"
                checkboxProps={{disabled: disableTexting()}}
                options={[
                  { value: 'eventshowbidderpaddlenumber', label: 'Show Bidder/Paddle Number to Participant', tooltipText: "" },
                  {
                    value: 'allowtexting',
                    label: 'Allow Texting',
                    tooltipText: "Send text message notifications to the users that have enabled them.",
                    textoptions: {
                      name: "textprefix",
                      label: "Text Prefix",
                      defaultValue: "",
                      disabled: (!allowTextingWatch || disableTexting()),
                      maxLength: 50,
                      tooltipText: 'Add an optional prefix to all text message notifications to help identify your organization. Keep this prefix as short as possible. Up to 50 characters are allowed. A colon and space are added automatically.\n\nFor example: “Malaika Auction”\n“Malaika Auction: You have been outbid on the Franklin X-40 Pickleballs (100 Pack). The current bid is now $50.”'
                    }
                  },
                ]}
                label=""
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={6} className={styles.selectcontrol}>
              <TextInput disabled={!allowpaymentoffeesWatch} name="ccfee" label="Event/Auction CC Fee % to Add" defaultValue="2.5" endAdornment="%" required={allowpaymentoffeesWatch} tooltipText={"If the user opts to cover the payment processing fees, this percentage will be added to their payment total."} />
            </Grid>
          <Grid item xs={6} className={styles.selectcontrol}>
            <TextInput
              name="startbiddernum"
              label="Starting Bidder Number"
              defaultValue="1"
              required
              tooltipText={"The first bidder number that will be assigned to a participant followed by sequential order."}
            />
          </Grid>
        </Grid>

        <SelectInput
          name={'receipttemplateid'}
          label={'Reciept Template'}
          defaultValue={(templates && templates.length > 0 ? templates[0].label : "")}
          options={templates}
          tooltipText={"Select the PDF template that should be used for all printed/PDF generated receipts."}
        />

        <Grid container spacing={1}>
          {watchLogo && (
              <Grid item xs={6} className={styles.selectcontrol}>
            <div> <Close fontSize="small" onClick={clickRemoveLogo} /></div>
            <div className={styles.logoWrapper}>
              <img src={watchLogo} alt="event logo" />
            </div>
              </Grid>
          )}

          <Grid item xs={6} className={styles.selectcontrol}>
          {watchLogoData && (
              <>
              <div>Event/Auction Logo</div>
              <div className={styles.logoWrapper}>
                <img src={`data:image/png;base64,${watchLogoData}`} alt="event logo" />
              </div>
              </>
          )}
          </Grid>
        </Grid>
        <h3>Event Logo</h3>
        <Dropzone onDrop={onLogoDrop}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} className={styles.dropzone}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a file here, or click to select a file.
                  Event logo will be displayed with a max height of 54px. For best mobile experience, your logo should not exceed 215px by 54px. Only .jpg, .jpeg, .png, .gif, and .bmp images under 2MB are allowed.</p>
              </div>
            </section>
          )}
        </Dropzone>

        <div className={styles.inputsContainer}>
          <h3>Event/Auction Privacy/Refund Policy</h3>
          <Editor
              tools={richTextTools}
              contentStyle={{ height: 230 }}
              value={privacyValue}
              onChange={(eventState) => {
                setPrivacyValue(eventState.html)
              }}
          />
        </div>

        <FloatingButton type="submit" variant="contained" className={styles.button}>
          Save
        </FloatingButton>

        <Alerts
          errorMsg={errorMsg.show ? errorMsg.msg : null}
          successMsg={successMsg ? 'Submitted successfully' : null}
        />
      </HookForm>
    </div>
  )
}

export default AuctionSettingsForm
