import React, { useState, useEffect } from 'react';
import {
  DataGridPremium,
  GridColDef,
  GridValueFormatterParams,
  GridSelectionModel,
  GridRowParams,
  GridCallbackDetails, MuiEvent
} from '@mui/x-data-grid-premium';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  Select,
  IconButton,
  Grid,
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  AppBar, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EmailEditModal from './EmailReviewForm'; // Import your Email Edit Modal component
import { Edit, Delete } from '@material-ui/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import styles from './EventMassEmailMaintenanceTable.module.css';
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {InsertShortcodeTool} from "../../components/common/inputs/RTEInsertTool";
import {
  Editor,
  EditorTools,
  EditorUtils,
  ProseMirror
} from "@progress/kendo-react-editor";
import EmailRecipients from "./EmailRecipients";
import EmailBody from "./EmailBody";
interface Params {
  eventid: string
}

const { Bold, Italic, Underline, ViewHtml } = EditorTools;
const { Schema, EditorView, EditorState } = ProseMirror;

const nonEditable = {
  name: "nonEditable",
  inline: true,
  group: "inline",
  content: "inline+",
  marks: "",
  attrs: {
    contenteditable: { default: null },
    class: { default: null },
    style: { default: null }
  },
  atom: true,
  parseDOM: [{ tag: "span.uneditable", priority: 51 }],
  // The styles can be added via the class as well
  toDOM: () => [
    "span",
    {
      contenteditable: false,
      class: "uneditable",
      style: "user-select: none; opacity: 0.5;"
    },
    0
  ]
};
const onMount = event => {
  const { viewProps } = event;
  const { plugins, schema } = viewProps.state;

  // Append a new node.
  let nodes = schema.spec.nodes.addToEnd('nonEditable', nonEditable);

  // Create the new schema.
  const mySchema = new Schema({ nodes: nodes, marks: schema.spec.marks });

  // Create a new document using the modified schema.
  const doc = EditorUtils.createDocument(mySchema, '');

  // Return the custom EditorView object that will be used by Editor.
  return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc, plugins })
      }
  );
};

interface EmailRow {
  id: number;
  emailName: string;
  emailType: string;
  askGuestToRegister: boolean;
  readOnly: boolean;
  active: boolean;
  selectedCount: number;
  lastSent: string | null;
  data: any[];
}
// 
const MassEmailMaintenanceTable = () => {
  const [emails, setEmails] = useState<EmailRow[]>([]);
  const [dialogObj, setDialogObj] = useState({ msg: '', id: 0 });
  const [reload, setReload] = useState(0);
  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailFormData, setEmailFormData] = useState<EmailRow | null>(null);
  const [emailType, setEmailType] = useState(null);
  const [emailTypeObject, setEmailTypeObject] = useState({typedescription: '', typeid: null, typeshowaskguestregister: null});
  const [selectedRow, setSelectedRow] = useState({askGuestToRegister: true, emailName: null, emailType: null, id: null, lastSent: null, readOnly: null, selectedCount: null})
  const [askForAuctionSnap, setAskForAuctionSnap] = useState(false);
  const [isEmailEditModalOpen, setIsEmailEditModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<EmailRow | null>();
  const [tabSel, setTabSel] = React.useState<number>(0)
  const [massemailid, setMassemailid] = React.useState<number>(null)
  const [emailTypes, setEmailTypes] = useState([]); // State to store email types for dropdown
  const { eventid } = useParams<Params>()
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>();
  useAutoEvent(eventid)
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.post('evtmassemail', { eventid: eventid });
        const data = response.data.jsonString ? JSON.parse(response.data.jsonString) : null;

        if (data && data.status === "success") {
          // Update the emails state with massemaillist

          setEmails(data.massemaillist.map(item => ({
            id: item.massemailid,
            emailName: item.massemailname,
            emailType: item.massemailtypedescription,
            askGuestToRegister: item.massemailaskguesttoregister,
            readOnly: item.readonly,
            active: item.active,
            selectedCount: item.massemailselectedcount,
            lastSent: item.massemaillastsent || null,
          })));

          // Update email types for the dropdown
          // console.log(data.typelist)
          setEmailTypes(data.typelist);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [reload]);

  useEffect(() => {
    // console.log(emailTypeObject)
  }, [emailTypeObject])


  const handleOpenEmailEditModal = (email: EmailRow | null) => {
    setSelectedEmail(email); // null for new email, or pass the selected email for editing
    setIsEmailEditModalOpen(true);
  };

  const handleCloseEmailEditModal = () => {
    setIsEmailEditModalOpen(false);
    setSelectedEmail(null);
  };
  // Fetch data (replace with your API call)

  const handleEdit = (row) => {
    const emailTypeObject = emailTypes.find(t => t.typedescription === row.emailType)
    if (emailTypeObject) {
      setEmailTypeObject(emailTypeObject)
    }

    setEmailFormData(row)
    setIsModalOpen(true)
  };

  const handleEmailTypeChange = (event) => {
    const emailTypeObject = emailTypes.find(t => t.typeid === event.target.value)
    setEmailTypeObject(emailTypeObject)
  }

  const handleAuctionSnapChange = (event) => {
    setAskForAuctionSnap(event.target.checked);
  };
  const handleOpenModal = () => {
    setEmailTypeObject({typedescription: '', typeid: null, typeshowaskguestregister: null})
    setEmailFormData(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSel(newValue)
  }

  const handleSaveEmail = async () => {
    var body = {
      eventid: eventid,
      massemailname: emailFormData?.emailName,
      massemailtypeid: emailTypeObject.typeid,
      massemailaskguesttoregister: askForAuctionSnap,
      active: true,
      // delete: false
    }

    if (emailFormData.id) {
      body['massemailid'] = emailFormData.id
    }

    const response =  await axios.put('evtmassemail', body);
    setReload(reload+1)
    handleCloseModal();
  };
  const columns: GridColDef[] = [
    
    {
      field: 'emailName',
      headerName: 'Email Name',
      type: 'string',
      width: 200,
    },
    {
      field: 'emailType',
      headerName: 'Type',
      type: 'string',
      width: 250,
    },
    {
      field: 'selectedCount',
      headerName: 'Selected Count',
      type: 'number',
      width: 200,
    },
    
    {
      field: 'totalSent',
      headerName: 'Total Sent',
      type: 'number',
      width: 100,
    },
    {
      field: 'askGuestToRegister',
      headerName: 'Ask Guest to Register',
      type: 'boolean',
      width: 200,
    },
   
    {
      field: 'lastSent',
      headerName: 'Last Sent',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => params.value ? dayjs(params.value as string).format('MM-DD-YYYY') : '',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      renderCell: (params) => (
        <IconButton onClick={() => handleEdit(params.row)}>
          <Edit />
        </IconButton>
      ),
      width: 100
    },
  ];

  const handleRowClick = (param : GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    console.log(param.row)
    setSelectedRow(param.row)
  }

  return (
    <div className={styles.root}>
      <h1>Email Maintenance {selectedRow?.emailName ? ' - ' + selectedRow.emailName : ''}</h1>

      <AppBar position="static" color="default">
        <Tabs
            value={tabSel}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
        >
          <Tab value={0} label={'Listing'}></Tab>
          <Tab value={1} label={'Edit Email Body'} disabled={selectedRow?.id === null}></Tab>
          <Tab value={3} label={'Recipients'} disabled={selectedRow?.id === null}></Tab>
          <Tab value={4} label={'Review & Send'} disabled={selectedRow?.id === null}></Tab>
        </Tabs>
        {tabSel === 0 && (
            <>
              <div style={{ padding: 20 }}>
                <InputLabel style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin', marginTop: '10px', marginBottom: '10px', marginLeft: '5px'}} >
                  <Button
                      variant="contained"
                      size="small"
                      onClick={handleOpenModal}
                      color="primary">
                    Add Email
                  </Button>
                </InputLabel>

                <DataGridPremium
                    autoHeight
                    rows={emails}
                    columns={columns}
                    disableMultipleSelection={true}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    onRowClick={handleRowClick}
                />
              </div>
            </>
        )}
        {tabSel === 1 && (
            <EmailBody eventid={eventid} massemailid={selectedRow.id}/>
        )}
        {tabSel === 3 && (
            <EmailRecipients eventid={eventid} massemailid={selectedRow.id}/>
        )}

      </AppBar>
   

      {/* Popup Modal for Adding/Editing Email */}
      <Dialog maxWidth="xl" fullWidth={true} open={isModalOpen} onClose={handleCloseModal} aria-labelledby="form-dialog-title" className={styles.dialog}>
        <DialogTitle id="form-dialog-title">{emailFormData ? 'Edit Email' : 'Add New Email'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="emailName"
            label="Mailing Name"
            type="text"
            fullWidth
            value={emailFormData?.emailName || ''}
            onChange={(e) => setEmailFormData({ ...emailFormData, emailName: e.target.value })}
          />
                    {/* Dropdown for Email Types */}
                    <FormControl fullWidth margin="dense">
      <InputLabel id="email-type-label">Type of Email</InputLabel>
      <Select
        labelId="email-type-label"
        id="email-type-select"
        value={emailTypeObject?.typeid}
        onChange={handleEmailTypeChange}
        label="Type of Email"
      >
        {emailTypes.map((type) => (
            <MenuItem key={type.typeid} value={type.typeid}>{type.typedescription}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
          {/* Checkbox for Auction Snap Registration */}
          {emailTypeObject.typeshowaskguestregister && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={askForAuctionSnap}
                  onChange={handleAuctionSnapChange}
                  name="askForAuctionSnap"
                />
              }
              label="Ask Guest if they want to be registered for Auction Snap"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEmail} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EmailEditModal
        isOpen={isEmailEditModalOpen}
        handleClose={handleCloseEmailEditModal}
        emailData={selectedEmail} // Pass the selected email data for editing
      />
    </div>
  
  );
};

export default MassEmailMaintenanceTable;
