import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, IconButton, Switch, TextField, Typography
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
// import SelectInput from '../../components/common/inputs/SelectInput'
import SelectInput from '../../components/common/inputs/SelectInput/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
import styles from './EventTransactionsTable.module.css'
import axios from "axios";
import {EventValueForm} from "./EventValueForm";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
  FormControl,
  FormControlLabel, FormLabel, Grid,
  InputLabel,
} from "@mui/material";
import {useEventState} from "../../slices/eventSlice";
import styled from "@emotion/styled";
import {formatMoney} from "../../util/formatHelpers";
import {statesArray} from "../../util/formValidation";

interface FormData {
  id: number
  paymentmethod: string
  additionaldonation: number
  discount: number
  promoCode: string
  first: string
  last: string
  address: string
  address2: string
  city: string
  state: string
  zip: string
  email: string
}

const SpanImage = styled.span`
  margin-top: 5px;
`

const Image = styled.img`
  height: 30px;
`

export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;

const Span = styled.span`
  padding-left: 0.5em;
`

export const EventCheckoutForm = ({ isOpen, setIsOpen, setIsAddEventCheckout, eventId, transactionid, setItemQuantity, setIsEventReview }) => {

  const formMethods = useForm<FormData>()
  const eventState = useEventState()
  const title = "Checkout"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [loading, setLoading] = React.useState(false);
  const [isValueOpen, setIsValueOpen] = useState<boolean>(false)
  const [activeId, setActiveId] = useState<number>(1)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [cryptogram, setCryptogram] = useState('');
  const [invalidateIframe, setInvalidateIframe] = React.useState(1)
  const [includeCCFees, setIncludeCCFees] = React.useState<boolean>(false);
  const [subtotal, setSubtotal] = useState(0);
  const [ccfees, setCCFees] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [additionalDonation, setAdditionalDonation] = React.useState(0)
  const [discount, setDiscount] = React.useState(0)
  const [totalAmount, setTotalAmount] = React.useState(0)
  const [isLoadingBackdrop, setisLoadingBackdrop] = React.useState(false)

  useEffect(() => {
    window.addEventListener("message", processPayment, false)
    return () => {
      window.removeEventListener("message", processPayment)
    }
  }, [])

  const handleCCFeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeCCFees(event.target.checked);
  }

  const [cartData, setCartData] = React.useState({
    cartitems: [],
    ccfee: 0,
    creditcardiframe: '',
    transcenterid: '',
    processorid: '',
    transactiontype: '',
    javascriptfile: '',
    additionaldonation: 0,
    discount: 0,
    allowpaymentoffees: false,
    subtotal: 0,
    hasdiscount: true
  })
  const [controlDropdownValues, setControlDropdownValues] = useState([])
  const paymentMethodWatch = watch('paymentmethod')
  const discountCodeWatch = watch('promoCode')
  const firstWatch = watch('first')
  const lastWatch = watch('last')
  const cityWatch = watch('city')
  const stateWatch = watch('state')
  const zipWatch = watch('zip')
  const addressWatch = watch('address')
  const address2Watch = watch('address2')
  const emailWatch = watch('email')

  useEffect(() => {
    if (paymentMethodWatch !== 'CC') {
      setIncludeCCFees(false)
    }
  }, [paymentMethodWatch])

  useEffect(() => {
    console.log(discountCodeWatch)
  }, [discountCodeWatch])

  useEffect(() => {
    setValue('first', '')
    setValue('last', '')
    setValue('promoCode', '')
    setValue('address', '')
    setValue('address2', '')
    setValue('city', '')
    setValue('state', '')
    setValue('zip', '')
    setValue('email', '')
    setValue('additionaldonation', 0)
    setAdditionalDonation(0)

    getCartData()
  }, [isOpen])

  useEffect(() => {
    // console.log(creditCardInfo)
    if (cartData && cartData.javascriptfile) {
      const script = document.createElement("script")
      script.src = cartData?.javascriptfile
      script.id =  "firstpay-script-cryptogram"
      script.type = "text/javascript"
      script.setAttribute("data-transcenter", cartData?.transcenterid)
      script.setAttribute("data-processor", cartData?.processorid)
      document.body.appendChild(script)

      setAdditionalDonation(cartData?.additionaldonation)

      const amount = cartData.subtotal
      setSubtotal(amount)
      setQuantity(cartData?.cartitems?.length | 0)

      var ccAmount = 0
      if (amount > 0) {
        ccAmount += amount
      }
      if (additionalDonation > 0) {
        ccAmount += additionalDonation
      }
      if (discount > 0) {
        ccAmount -= discount
      }
      setCCFees(ccAmount * (cartData?.ccfee / 100))
      const total = (eventState?.allowpaymentoffees && includeCCFees && eventState?.ccfee > 0.000000001) ?
          ccAmount + (ccAmount * (eventState?.ccfee / 100)) :
          ccAmount
      setTotalAmount(total)
    }
  }, [cartData])

  useEffect(() => {
    const amount = cartData.subtotal
    setSubtotal(cartData.subtotal)

    var ccAmount = 0
    if (amount > 0) {
      ccAmount += amount
    }
    if (additionalDonation > 0) {
      ccAmount += additionalDonation
    }
    if (discount > 0) {
      ccAmount -= discount
    }
    setCCFees(((ccAmount) * (cartData?.ccfee / 100)))

    const total = (cartData?.allowpaymentoffees && includeCCFees && cartData?.ccfee > 0.000000001) ?
        ccAmount + (ccAmount * (cartData?.ccfee / 100)) :
        ccAmount
    setTotalAmount(total)
  }, [includeCCFees, additionalDonation, discount])

  const getCartData = async () => {
    setLoading(true)
    const { data } = await axios.post('/evtactivitytransactionscart', {eventid: eventId, transactionid})
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    setCartData(parsedData)
    // setTransactionId(parsedData?.transactionid)
    setLoading(false)
  }

  const handleAdditionalDonationChange = (newValue: number | null) => {
    if (newValue > 0) {
      setAdditionalDonation(newValue)
    }
    else {
      setAdditionalDonation(0)
    }
  }

  const processPayment = (e: any) => {
    if (e.data.firstpay) {
      const ccData = e.data
      if (ccData.type === 'newCryptogram') {
        setCryptogram(ccData.cryptogram)
      }
    }
  }

  const onSubmit = async () => {
    setisLoadingBackdrop(true)
    var errorMessage = ''
    if (paymentMethodWatch === "CC" && cryptogram.length <= 0) {
      errorMessage = "Please enter a valid credit card."
    }

    var error = false
    if (firstWatch.length <= 0) {
      setError('first', { type: 'required', message: 'First name is required.' })
      error = true
    }
    else {
      clearErrors('first')
    }
    if (lastWatch.length <= 0) {
      setError('last', { type: 'required', message: 'Last name is required.' })
      error = true
    }
    else {
      clearErrors('last')
    }
    if (zipWatch.length <= 0 && paymentMethodWatch === "CC") {
      setError('zip', { type: 'required', message: 'Zip code is required.' })
      error = true
    }
    else {
      clearErrors('zip')
    }
    if (error) {
      setisLoadingBackdrop(false)
      return
    }

    if (errorMessage.length > 0) {
      setisLoadingBackdrop(false)
      setDialogMsg(errorMessage)
    }
    else {
      var body = {
        eventid: eventId,
        transactionid: transactionid,
        totalamount: Number(totalAmount.toFixed(2)),
        discountamount: discount,
        additionaldonation: additionalDonation,
        ccfeesexactamount: includeCCFees ? cartData?.ccfee : 0, // Number(ccfees.toFixed(2)),
        paymentmethod: paymentMethodWatch,
        emerchtoken: cryptogram,
        subtotal: subtotal,
        first: firstWatch,
        last: lastWatch,
        address: addressWatch,
        address2: address2Watch,
        city: cityWatch,
        state: stateWatch,
        zip: zipWatch,
        email: emailWatch
      }
      const { data } = await axios.put('/evtactivitytransactionscheckout', body)
      console.log(data)

      const parsedResponse = JSON.parse(data?.jsonString)
      console.log(parsedResponse)
      setIsEventReview(true)
      if (parsedResponse?.status === 'failed') {
        setDialogMsg(parsedResponse.message)
      }
      else {
        // setDialogMsg("Checkout was successfull.")
        setInvalidateIframe(invalidateIframe+1)
        setCryptogram('')

        setValue('first', '')
        setValue('last', '')
        setValue('promoCode', '')
        setValue('address', '')
        setValue('address2', '')
        setValue('city', '')
        setValue('state', '')
        setValue('zip', '')
        setValue('email', '')
        setValue('additionaldonation', 0)

        setItemQuantity({})

      }
      setIsOpen(false)
    }
    setisLoadingBackdrop(false)
  }

  const onBack = () => {
    setIsOpen(false)
    setIsAddEventCheckout(true)
  }

  const closeDialog = () => {
    setDialogObj({msg: '', id: 0})
    setDialogMsg("")
  }

  const deleteDialog = async () => {
    setControlDropdownValues(controlDropdownValues?.filter((i) => i.id !== dialogObj.id))
    setDialogObj({msg: '', id: 0})
  }

  const getDiscount = async () => {
    const body = {"discountcode": discountCodeWatch, eventid: eventId, transactionid: transactionid}
    const {data} = await axios.post('/evtactivitytransactionsdiscount', body)
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    if (parsedData.status === 'success') {
      console.log(parsedData.discountamount)
      setDiscount(parsedData.discountamount)
    }
    else {
      setDiscount( 0)
    }
  }

  return (
    <>
      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpen} maxWidth="xl" fullWidth={true} className={styles.dialog}>
        <HookForm
            methods={formMethods}
            className={styles.form}
            formProps={{ id: 'checkout-form' }}
            onSubmit={onSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <LoadingBackdrop open={loading} />
        <>
          <Dialog
              open={dialogMsg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <EventValueForm isValueOpen={isValueOpen} setIsValueOpen={setIsValueOpen} activeId={activeId} controlDropdownValues={controlDropdownValues} setControlDropdownValues={setControlDropdownValues} />
        <DialogContent>
          {/*<HookForm*/}
          {/*    methods={formMethods}*/}
          {/*    onSubmit={onSubmit}*/}
          {/*    className={styles.itemsForm}*/}
          {/*    formProps={{ id: 'items-form' }}>*/}

          {/*    {checkoutData?.map((section) => (*/}
          {/*        <>*/}
          {/*          <Grid container xs={12} spacing={1} columns={2}>*/}
          {/*            <Typography style={{display: 'flex', alignItems: 'center'}} variant="h6">{section.sectionname}</Typography>*/}
          {/*            /!*<InputLabel>{section.sectionname}</InputLabel>*!/*/}
          {/*          </Grid>*/}

          {/*        </>*/}
          {/*     ))}*/}
          {/*</HookForm>*/}


          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={12} className={styles.selectcontrol}>
              <b>Items</b>
            </Grid>
          </Grid>
          {cartData?.cartitems?.map((cd) => (
              <Grid container style={{marginTop: '5px'}} >
                <Grid item xs={6} className={styles.selectcontrol}>
                  {cd.name}
                </Grid>
                <Grid item xs={6} className={styles.selectcontrol}>
                  <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{cd.quantity + ' x $' + cd.price + ' = $' + cd.lineamount}</InputLabel>
                </Grid>
              </Grid>
          ))}
          <Grid container spacing={1} style={{marginTop: '15px', marginBottom: '15px', borderTop: '1px solid #eeeeee'}}>
            <Grid item xs={10} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Subtotal:&nbsp;</b></InputLabel>
            </Grid>
            <Grid item xs={2} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(subtotal | 0)} ({quantity | 0} items)</InputLabel>
            </Grid>
          </Grid>

            {paymentMethodWatch === 'CC' &&
                <Grid container spacing={1} style={{marginTop: '5px'}}>
                  <Grid item xs={12} className={styles.selectcontrol}>
                    {(cartData.allowpaymentoffees) &&
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          <Switch
                              color="primary"
                              name="includefees"
                              onChange={handleCCFeesChange}
                              checked={includeCCFees}
                          />
                          <FormLabel>Include CC Fees: {formatMoney(ccfees)}</FormLabel>
                        </div>
                    }
                  </Grid>
                </Grid>
            }

            <Grid container spacing={1} style={{marginTop: '5px'}}>
              <Grid item xs={3} className={styles.selectcontrol}>
                <CurrencyTextField
                    label={"Additional Donation"}
                    name={"additionaldonation"}
                    variant="outlined"
                    allowNegativeValue={false}
                    // default={Number(cartData?.additionaldonation)}
                    value={additionalDonation}
                    fullWidth={true}
                    currencySymbol="$"
                    outputFormat="number"
                    minimumValue={0}
                    decimalPlaces={0}
                    textAlign={"left"}
                    required={false}
                    decimalCharacter="."
                    onChange={(event, value) => handleAdditionalDonationChange(value)}
                />
              </Grid>

              <Grid item xs={7} className={styles.selectcontrol}>
                <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Additional Donation:&nbsp;</b></InputLabel>
              </Grid>
              <Grid item xs={2} className={styles.selectcontrol}>
                <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(additionalDonation)}</InputLabel>
              </Grid>
            </Grid>

          {cartData.hasdiscount &&
            <Grid container spacing={1} style={{marginTop: '5px'}}>
              <Grid item xs={3} className={styles.selectcontrol}>
                <TextInput
                    name="promoCode"
                    label="Promo Code"
                />
              </Grid>
              <Grid item xs={3} className={styles.selectcontrol}>
                <Button onClick={() => {getDiscount()}}>Apply Promo Code</Button>
              </Grid>
              <Grid item xs={4} className={styles.selectcontrol}>
                <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Promo Code:&nbsp;</b></InputLabel>
              </Grid>
              <Grid item xs={2} className={styles.selectcontrol}>
                <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(discount)}</InputLabel>
              </Grid>
            </Grid>
          }



              <Grid container spacing={1} style={{marginTop: '5px'}}>
                <Grid item xs={10} className={styles.selectcontrol}>
                  <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Total:&nbsp;</b></InputLabel>
                </Grid>
                <Grid item xs={2} className={styles.selectcontrol}>
                  <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(totalAmount)}</InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{marginTop: '5px'}}>
                <Grid item xs={6} className={styles.selectcontrol}>
                  <TextInput name="first" label="First Name" required={true}  />
                </Grid>
                <Grid item xs={6} className={styles.selectcontrol}>
                  <TextInput name="last" label="Last Name" required={true}  />
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{marginTop: '5px'}}>
                <Grid item xs={8} className={styles.selectcontrol}>
                  <TextInput name="address" label="Address" required={false} />
                </Grid>
                <Grid item xs={4} className={styles.selectcontrol}>
                  <TextInput name="address2" label="Address 2"  />
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{marginTop: '5px'}}>
                <Grid item xs={3} className={styles.selectcontrol}>
                  <TextInput name="city" label="City" required={false}  />
                </Grid>
                <Grid item xs={2} className={styles.selectcontrol}>
                  <SelectInput
                      name="state"
                      label="State"
                      includeNone={false}
                      required={false}
                      options={statesArray.map((state) => {
                        return {label: state.value, value: state.value}
                      })}
                  />
                </Grid>
                <Grid item xs={2} className={styles.selectcontrol}>
                  <TextInput name="zip" label="Zip" required={(paymentMethodWatch === 'CC' ? true : false)}  />
                </Grid>
                <Grid item xs={5} className={styles.selectcontrol}>
                  <TextInput name="email" label="Email" required={false}/>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{marginTop: '5px'}}>
                <Grid item xs={12} className={styles.selectcontrol} style={{marginTop: '10px', marginBottom: '10px'}}>
                  <SelectInput
                      name="paymentmethod"
                      label="Payment Method"
                      includeNone={false}
                      // disabled={dataRows?.isreadonly}
                      defaultValue={'CC'}
                      // required={true}
                      options={[
                        { value: 'billme', label: 'Bill Me Later' },
                        { value: 'check', label: 'Check' },
                        { value: 'cash', label: 'Cash' },
                        { value: 'CC', label: 'Credit Card' }
                      ]}
                  />
                </Grid>
            </Grid>
          {paymentMethodWatch === 'CC' &&
              <SpanImage id="goe-logo">Payment securely processed by: <a href="https://goemerchant.com" target="_blank">
                <Image alt="goEmerchant Payment Processing" src="https://donorsnap.com/graphics/entry/goe-logo.png"></Image>
              </a></SpanImage>
          }

            <iframe
                title="1stpayment-iframe"
                id="firstpay-iframe"
                key={transactionid + invalidateIframe}
                src={ cartData?.creditcardiframe }
                data-transcenter-id={ cartData?.transcenterid }
                data-processor-id={ cartData?.processorid }
                data-transaction-type={ cartData?.transactiontype }
                text-card-number={0}
                data-manual-submit="false"
                style={{display: (paymentMethodWatch === 'CC' ? 'inline' : 'none')}}
            />
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              // color="secondary"
              onClick={() => {onBack()}}
          >
            Back to Items
          </Button>
          <Button
              variant="contained"
              className={styles.submitButton}
              type="submit"
              onClick={() => {onSubmit()}}
              form="items-form">
            Submit Payment
          </Button>
        </DialogActions>
      </HookForm>
      </Dialog>
    </>
  )
}
