import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { Box, Container, IconButton } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { ArrowBack, Menu, Settings } from '@material-ui/icons'
import LaunchIcon from '@mui/icons-material/Launch';
import { PagePathTypes } from '../../../App'
import { useEventState } from '../../../slices/eventSlice'
import { useOrgState } from '../../../slices/orgSlice'
import Header from './Header'
import MainMenu from './MainMenu'

// eslint-disable-next-line no-unused-vars

type Props = {
  children: any
  showHome?: boolean
  showArrow?: boolean
  showSettings?: boolean
  showMenu?: boolean
  showBackArrow?: boolean
  showOrgTitle?: boolean
  backArrowToAuction?: boolean
  showEventUrl?: boolean
  showAuctionUrl?: boolean
}

const Layout: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const orgState = useOrgState()
  const eventState = useEventState()
  const title = props.showOrgTitle ? orgState.name : eventState.eventName
  const goBack = () => {
   if(props.backArrowToAuction){
    history.push('/auction/' + eventState.eventId )
   }
   else{
    history.goBack()}
  }

  useEffect(() => {
    if (pathname) {
      setIsOpen(false)
    }
  }, [pathname])

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <MainMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      <Header showMenu={props.showMenu} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div
        style={{
          marginTop: 60,
          display: 'grid',
          alignItems: 'center',
          gridColumn: '1/3',
          justifyItems: 'center',
          position: 'relative'
        }}>
        <div style={{ position: 'absolute', left: '24px', display: 'flex' }}>
          {props.showBackArrow && (
            <IconButton
              edge="start"
              aria-label="go to home page"
              onClick={goBack}>
              <ArrowBack
                fontSize="large"
                style={{ color: 'var(--very-dark)' }}
              />
            </IconButton>
          )}
          {props.showArrow && (
            <Link to={PagePathTypes.home}>
              <IconButton edge="start" aria-label="go to home page">
                <ArrowBack
                  fontSize="large"
                  style={{ color: 'var(--very-dark)' }}
                />
              </IconButton>
            </Link>
          )}
           {/*Hamburger menu disabled*/}
          {/*{!props.showBackArrow && props.showMenu && (*/}
          {/*  <IconButton*/}
          {/*    onClick={() => setIsOpen(true)}*/}
          {/*    edge="start"*/}
          {/*    aria-label="go to home page">*/}
          {/*    <Menu fontSize="large" style={{ color: 'var(--very-dark)' }} />*/}
          {/*  </IconButton>*/}
          {/*)}*/}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left'
          }}>
          <h1
            style={{
              gridColumn: '2/3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              justifySelf: 'center',
              fontWeight: 600
            }}>
            {title}
              {(props.showEventUrl || props.showAuctionUrl) && (
                  <a href={props.showEventUrl ? eventState.eventUrl : eventState.auctionUrl} target="_blank">
                      <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="go to event settings"
                          // onClick={() => {console.log()}}
                          style={{marginLeft: '2px'}}
                      >
                          <LaunchIcon
                              fontSize="large"
                              style={{ color: 'var(--very-dark)', justifySelf: 'start' }}
                          />
                      </IconButton>
                  </a>
              )}
          </h1>
        </div>
        <div style={{ position: 'absolute', right: 24 }}>
          {props.showSettings && (
            <Link to={PagePathTypes.settings}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="go to event settings">
                <Settings
                  fontSize="large"
                  style={{ color: 'var(--very-dark)', justifySelf: 'start' }}
                />
              </IconButton>
            </Link>
          )}
        </div>
      </div>
      <Box
        component="main"
        style={{
          // background: 'var(--background)',
          flexGrow: 1,
          display: 'grid',
          height: '100%', padding: '0 25px 25px 25px',
        }}>
        {/*<Container*/}
        {/*  // maxWidth="lg"*/}
        {/*  style={{*/}
        {/*    padding: '2rem 0 0 0',*/}
        {/*    width: '100%',*/}
        {/*    minHeight: 'calc(100vh - 128px)',*/}
        {/*    height: '100%',*/}
        {/*    justifySelf: 'center',*/}
        {/*    display: 'flex',*/}
        {/*    flexDirection: 'column'*/}
        {/*  }}>*/}
          {/* <Breadcrumb /> */}
          {props.children}
        {/*</Container>*/}
      </Box>
    </Box>
  )
}

export default Layout
