import React, { useState } from 'react'

import {Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef,  GridRowModel,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useFetchAuctionItems, useUpdateItemOrder } from '../../../queries/useActionItemsFetch'
import { AuctionItemsForm } from './AuctionItemsForm'
import styles from './AuctionItemsTable.module.css'
import {EventState, setEvent, useEventState} from "../../../slices/eventSlice";
import {useFetchLocationTags} from "../../../queries/events/useFetchLocationTags";
import {useCreateEventItem} from "../../../mutations/useCreateEventItem";
import {useParams} from "react-router";
import {useAutoEvent} from "../../../queries/useAutoEvent";
import {useFetchDonationTypes} from "../../../queries/useFetchDonationTypes";
import {Grid} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import {useQueryClient} from "react-query";
import {getValues} from "export-from-json/dist/types/utils";


const auctionItemsDefaults = {
  itemid: null,
  itemnumber: '',
  name: '',
  shortdesc: '',
  longdesc: '',
  locationid: 0,
  taglistids: [],
  itemtype: 0,
  liveauctiononly: false,
  onsitesaleitem: false,
  allowautobid: false,
  donatedby: '',
  donatedbyemail: 0,
  donationtypeid: 0,
  accountingcodeid: 0,
  fairmarketvalue: 0,
  startingbid: 0,
  minimumraise: 1,
  buyitnowprice: 0,
  buyitnowonly: false,
  quantityunlimited: false,
  quantity: 0,
  maxpurchasequantity: 0,
  imageloaded: false,
  logo: "",
  logourl: "",
  pickupinstructions: "",
  active: true
}

interface Params {
  id: string
}

const AuctionItemsTable = () => {
  const { id } = useParams<Params>()
  useAutoEvent(id)
  const queryClient = useQueryClient()
  const eventState = useEventState()
  // const { data: donationTypes } = useFetchDonationTypes()
  const eventItemCreate = useCreateEventItem()
  const [gridParams, setGridParams] = useState(auctionItemsDefaults)
  const [rowOrdering, setRowOrdering] = useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [reloadForm, setReloadForm] = useState<number>(0)

  const { data, isLoading } = useFetchAuctionItems( Number(eventState.eventId) )
  const { data: locationAndTags } = useFetchLocationTags(Number(eventState.eventId))
  const updateItemOrder = useUpdateItemOrder(eventState.eventId)
  const [isFormShowing, setIsFormShowing] = useState(false)
  var rows = []
  if (data) {
    console.log(data)
    console.log(locationAndTags)
    rows = data.map((item) => {
      var tag = { name: ""}
      var location = { name: "" }
      var donationTypes = { name: "" }
      var accountingCodes = { name: "" }
      if (locationAndTags.tags) {
        tag = locationAndTags.tags.filter((e) => (e.active && Number(e.id) === Number(item.taglistids)))[0]
      }
      if (locationAndTags.locations) {
        location = locationAndTags.locations.filter((e) => (e.active && Number(e.id) === Number(item.locationid)))[0]
      }
      if (locationAndTags.donationtype) {
        donationTypes = locationAndTags.donationtype.filter((e) => (e.active && Number(e.id) === Number(item.donationtypeid)))[0]
      }
      // if (locationAndTags.accountingcode) {
      //   accountingCodes = locationAndTags.accountingcode.filter((e) => (e.active && Number(e.id) === Number(item.accountingcodeid)))[0]
      // }
      return { id: item.itemid, ...item,  tags: tag ? tag.name : "", location: location ? location.name : "", donationtype: donationTypes ? donationTypes.name : ""}
    })
  }

  const handleAdd = () => {
    setReloadForm(reloadForm+1)
    setGridParams(null)
    setIsFormShowing(true)
  }

  const getAuctionType = ((auctionTypeId: number) => {
    if (auctionTypeId === 1) {
      return 'Online Auction'
    }
    else if  (auctionTypeId === 2) {
      return 'Live Auction Only'
    }
    else if  (auctionTypeId === 3) {
      return 'Buy It Now Only (Online)'
    }
    else if  (auctionTypeId === 4) {
      return 'Buy It Now Only (In-Person Only)'
    }
  })

  const getDonationType = ((donationId: number) => {
    const dt = locationAndTags?.donationtype?.find((type) => (type.active && type.id === donationId))
    return ((dt && dt.name) ? dt.name : '')
  })

  const getAccountCode = ((accountingCodeId: number) => {
    const at = locationAndTags?.accountingcode?.find((type) => (type.active && type.id === accountingCodeId))
    return ((at && at.name) ? at.name : '')
  })

  const getTagsDisplayString = ((tagsArray: any) => {
    var stringDisplay = ''
    tagsArray?.forEach(x => {
      const cTag = locationAndTags?.tags?.find((findTag) => (findTag.active && findTag.id === x))
      if (cTag) {
        if (stringDisplay.length > 0) {
          stringDisplay += ', '
        }
        stringDisplay += cTag.name
      }
    })
    return stringDisplay
  })

  const handleEdit = (params: GridCellParams) => {
    setReloadForm(reloadForm+1)
    const ai = rows.filter((item) => item.itemid === Number(params.id))[0]
    setGridParams( {
      itemid: ai.itemid,
      itemnumber: ai.itemnumber,
      name: ai.name,
      shortdesc: ai.shortdesc,
      longdesc: ai.longdesc,
      locationid: ai.locationid,
      taglistids: ai.taglistids,
      itemtype: ai.itemtype,
      liveauctiononly: ai.liveauctiononly,
      onsitesaleitem: ai.onsitesaleitem,
      allowautobid: ai.allowautobid,
      donatedby: ai.donatedby,
      donatedbyemail: ai.donatedbyemail,
      donationtypeid: ai.donationtypeid,
      accountingcodeid: ai.accountingcodeid,
      fairmarketvalue: ai.fairmarketvalue,
      startingbid: ai.startingbid,
      minimumraise: ai.minimumraise,
      buyitnowprice: ai.buyitnowprice,
      buyitnowonly: ai.buyitnowonly,
      quantityunlimited: ai.quantityunlimited,
      quantity: ai.quantity,
      maxpurchasequantity: ai.maxpurchasequantity,
      imageloaded: ai.imageloaded,
      logo: "",
      logourl: ai.logourl,
      pickupinstructions: ai.pickupinstructions,
      active: ai.active
    })
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.getValue(params.id, 'itemnumber').toString() + "\"?",
      id: Number(params.id)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var finaldata = {
      "eventid": Number(eventState.eventId),
      "itemid": id,
      "delete": true
    }
    const { data: response } = await eventItemCreate.mutateAsync({
      ...finaldata
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "failure") {
          setDialogObj({
            msg: '',
            id: 0
          })
          setDialogMsg(json.message)
        }
        else {
          setDialogObj({
            msg: '',
            id: 0
          })
        }
      }
    })
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const canDelete = (active) => {
    if (new Date(eventState.bidStart) <= new Date()) {
      // console.log('date is valid')
      return !active
    }
    else {
      return true
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      renderHeader: () => {
        return (
          <IconButton
            className={styles.submitButton}
            onClick={() => handleAdd()}
            size="small">
            <Add />
          </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
          <Edit />
        </IconButton>
      )
    },
    {
      field: 'id',
      hide: true,
      sortable: false,
    },
    {
      field: 'itemnumber',
      type: 'string',
      headerName: 'Item #',
      minWidth: 120,
      sortable: false,
    },
    {
      field: 'itemorder',
      type: 'number',
      headerName: 'Item Order',
      minWidth: 120,
      hide: true
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'shortdesc',
      type: 'string',
      headerName: 'Short Desc',
      minWidth: 150,
      sortable: false,
      hide: true,
    },
    {
      field: 'longdesc',
      type: 'string',
      headerName: 'Long Desc',
      minWidth: 150,
      sortable: false,
      hide: true,
    },
    {
      field: 'liveauctiononly',
      type: 'string',
      headerName: 'Live Auction Only',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'onsitesaleitem',
      type: 'string',
      headerName: 'OnSite Only',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'allowautobid',
      type: 'string',
      headerName: 'Allow Auto Bid',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'donatedby',
      type: 'string',
      headerName: 'Donated By',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'donatedbyemail',
      type: 'string',
      headerName: 'Donated By Email',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'startingbid',
      type: 'string',
      headerName: 'Starting Bid',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'buyitnowprice',
      type: 'string',
      headerName: 'Buy It Now Price',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'buyitnowonly',
      type: 'string',
      headerName: 'Buy It Now Only',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'quantityunlimited',
      type: 'string',
      headerName: 'Quantity Unlimited',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'pickupinstructions',
      type: 'string',
      headerName: 'Pickup Instructions',
      minWidth: 100,
      sortable: false,
      hide: true,
    },
    {
      field: 'itemtype',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Auction Type',
      width: 250,
      editable: false,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? getAuctionType(Number(params.value)) : 'None')
      },
    },
    // {
    //   field: 'donationtypeid',
    //   type: 'number',
    //   align: 'left',
    //   headerAlign: 'left',
    //   headerName: 'Donation Type',
    //   width: 175,
    //   editable: false,
    //   sortable: false,
    //   valueFormatter: (params: GridValueFormatterParams) => {
    //     return (params.value ? getDonationType(Number(params.value)) : "None")
    //   },
    // },
    // {
    //   field: 'accountingcodeid',
    //   type: 'number',
    //   align: 'left',
    //   headerAlign: 'left',
    //   headerName: 'Accounting Code',
    //   width: 175,
    //   editable: false,
    //   sortable: false,
    //   valueFormatter: (params: GridValueFormatterParams) => {
    //     return (params.value ? getAccountCode(Number(params.value)) : "None")
    //   },
    // },
    {
      field: 'location',
      type: 'string',
      headerName: 'Location',
      sortable: false,
      width: 175
    },
    {
      field: 'taglistids',
      type: 'string',
      headerName: 'Tags',
      width: 175,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? getTagsDisplayString(params.value) : "")
      },
    },
    {
      field: 'quantity',
      type: 'string',
      headerName: 'Quantity',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 175,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value > 0 ? params.value : "Unlimited")
      },
    },
    {
      field: 'fairmarketvalue',
      type: 'string',
      headerName: 'Fair Market Value',
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return "$" + params.value
      },
      width: 175
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? "Active" : "Inactive")
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      disableExport: true,
      width: 120,
      sortable: false,
      renderCell: (params) => (
          // <Tooltip title={"Only items with a status of Inactive can be deleted."} >
          <div title={canDelete(params.row.active) ? '' : "Only items with a status of Inactive can be deleted."}>
            <div>
              <IconButton disabled={!canDelete(params.row.active)} className={styles.button} onClick={() => { handleDelete(params) }}>
                <Delete />
              </IconButton>
            </div>
          </div>
          // </Tooltip>
      )
    }
  ]

  const ReOrderToggle = async () =>{
    //todo: Data is being pushed up and saved successfully however it isnt being saved locally so the rerender destroys it until refetched.
    if(rowOrdering){
      for(let i = 0; i<rows.length; i++){
        rows[i].itemorder = i;
        data.find(x => x.itemid === rows[i].itemid).itemorder = i;

      }
      let itemListingOrder =  rows.map(x => {
        return {itemid: x.itemid, order: x.itemorder}
      })
      var finaldata = {
        eventid: eventState.eventId,
        items: itemListingOrder
      }
      console.log(finaldata)
      updateItemOrder.mutate({
        ...finaldata
      },{
        onSuccess: (data) => {
          console.log(data)
          queryClient.invalidateQueries(['eventitems', Number(eventState.eventId)])
          // const json = JSON.parse(data.data.jsonString)
          // if (json.status === "failure") {
          //   setDialogObj({
          //     msg: '',
          //     id: 0
          //   })
          //   setDialogMsg(json.message)
          // }
          // else {
          //   setDialogObj({
          //     msg: '',
          //     id: 0
          //   })
          // }
        }
      })
    }
   
    setRowOrdering(!rowOrdering)
  }
   const updateRowPosition = (
    initialIndex: number,
    newIndex: number,
    rows: Array<GridRowModel>,
  ) => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        return rowsClone;
      }
  
  
  const handleRowOrderChange =  (params: GridRowOrderChangeParams) => {
    //setLoading(true);
    const newRows =  updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );
     rows = newRows 
   
  };

  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridSortedRowIdsSelector(apiRef);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
      options.allColumns = true;
      apiRef.current.exportDataAsCsv(options);
    }


    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    return (
        <>
          <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                  <Button
                      {...buttonBaseProps}
                      onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                  >
                    EXPORT
                  </Button>
                  
                  <Button
                      onClick={() => ReOrderToggle()}
                  >
                    {rowOrdering ? 'Save' : 'Re-Order'}
                    
                  </Button>
                  {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
          </GridToolbarContainer>
        </>
    )
  }

  return (
    <>

      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <AuctionItemsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm}/>
      <DataGridPremium
        autoHeight
        rowReordering={rowOrdering}
        onRowOrderChange={handleRowOrderChange}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
      />
    </>
  )
}

export default AuctionItemsTable
