import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {Add, Launch} from '@material-ui/icons'

import {
  Button,
  Card,
  CardActions,
  CardContent, Dialog, DialogActions, DialogContent, DialogContentText,
  FormControlLabel, IconButton, List, ListItem,
  Switch
} from '@material-ui/core'

import ViewList from '../../components/common/ViewList'
import {EventAuctions, Events, useFetchEventData, useFetchEvents} from '../../queries/events/useFetchEvents'
import { formatMoney } from '../../util/formatHelpers'
import styles from './HomePage.module.css'
import {getConfig} from "../../AppConfig";
import {
  useEditEventSettings,
  useUpdateEventClone,
  useUpdateEventStatus
} from "../AuctionSettingsForm/useEditEventSettings";
import {useFetchOrgSettings} from "../OrgSettingsPage/useFetchOrgSettings";
import {EventState, setEvent} from "../../slices/eventSlice";
import {OrgState, setOrg} from "../../slices/orgSlice";
import {useDispatch} from "react-redux";
import {useAuthToken} from "../../slices/authSlice";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import * as events from "events";
import {useMutation, useQueryClient} from "react-query";
import axios from "axios";

const HomePage = () => {
  let { data } = useFetchEventData()
  const queryClient = useQueryClient()
  const editMutation = useEditEventSettings()
  const editStatusMutation = useUpdateEventStatus()
  const editCloneMutation = useUpdateEventClone()
  const [validAuction, setValidAuction] = useState(true)
  const [validEvent, setValidEvent] = useState(true)
  const [newbuttontext, setnewbuttontext] = useState('')
  const [eventAuctionsData, setEventAuctionsData] = useState<EventAuctions[]>(null)
  const localActiveOnly = localStorage.getItem('activeOnly')
  const history = useHistory()
  const dispatch = useDispatch()
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [dialogActivateMsg, setActivateDialogMsg] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeEvent, setActiveEvent] = React.useState({eventid: 0, active: true});
  const [dialogCloneMessage, setDialogCloneMsg] = React.useState<string>("");
  const authToken = useAuthToken()
  const { data: settings } = useFetchOrgSettings({ userid: authToken?.userid })
  const { auctionSnapURL } = getConfig()

  useEffect(() => {
    if (settings) {
      const orgState: OrgState = {
        name: settings?.name, message: settings?.message, isdonorsnapcustomer: settings?.isdonorsnapcustomer, twilio: settings?.twilio
      }
      dispatch(setOrg(orgState))
    }
  }, [settings])

  const handleChange = (checked) => {
    localStorage.setItem('activeOnly', checked)

    if (data && data.eventauctions) {
      const filteredEvents = (checked ? data?.eventauctions?.filter((e) => e.active === true) : data.eventauctions)
      setEventAuctionsData(filteredEvents.map((eventItem) => {
        return {...eventItem}
      }))
    }
    else {
      setEventAuctionsData(null)
    }
  }

  const closeDialog = () => {
    setDialogMsg("")
    setActivateDialogMsg("")
    setDialogCloneMsg("")
  }

  const deleteDialog = () => {
    editMutation.mutate({
      delete: true,
      eventid: activeEvent.eventid
    })
    setDialogMsg("")
  }

  const cloneContinueDialog = () => {
    editCloneMutation.mutate(
        {
          eventid: activeEvent.eventid
        }, {
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === 'failure') {
          setDialogMsg(json.message)
        }
        else {
          setDialogCloneMsg("")

          const eventPath = '/auction/' + json.eventid
          history.push(eventPath)
          const eventPathSettings = '/auction/' + json.eventid + '/settings'
          history.push(eventPathSettings)
        }
      }
    }
    )
  }

  const activateDialog = () => {
    editStatusMutation.mutate({
      active: !activeEvent.active,
      eventid: activeEvent.eventid
    })
    setActivateDialogMsg("")
  }

  useEffect(() => {
    if (data && data.eventauctions) {
      setValidAuction(data.validauction)
      setValidEvent(data.validevent)
      setnewbuttontext(data.newbuttontext)
      const filteredEvents = ('true' === localActiveOnly ? data?.eventauctions?.filter((e) => e.active === true) : data.eventauctions)
      setEventAuctionsData(filteredEvents.map((eventItem) => {
        return {...eventItem}
      }))
    }
    else {
      setEventAuctionsData(null)
    }
    setIsLoading(false)
  }, [data])

  return (
  <div className={styles.root}>
    <LoadingBackdrop open={isLoading} />
    <>
      <Dialog
          open={dialogCloneMessage.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Clicking the continue button will clone the full event.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={cloneContinueDialog}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
    <>
      <Dialog
          open={dialogMsg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>

    <>
      <Dialog
          open={dialogActivateMsg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogActivateMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={activateDialog}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>

      <div className={styles.topRow}>
        <Button
          variant="contained"
          className={styles.successBtn}
          onClick={() => {
            history.push('eventnew')
          }}>
          Add New {newbuttontext}
        </Button>
        <FormControlLabel
          label={'Show Only Active'}
          labelPlacement="start"
          control={
            <Switch
              color="primary"
              defaultChecked={'true' === localStorage.getItem('activeOnly')}
              classes={{ switchBase: styles.switchBase }}
              onChange={(event) => {handleChange(event.target.checked)}}
            />
          }
        />
      </div>


    {eventAuctionsData?.map((event, i) => {
      console.log(event)

      const eventId = event.eventid
      const auctionPath = 'auction/' + eventId
      const eventPath = 'event/' + eventId
      let liValues = event.eventdetail.map((ed) => {
        return {
          label: ed.title,
          value: ed.value,
          title: ed.title,
          hover: ed.hover,
          islink: ed.islink,
          aligntitle: ed.aligntitle,
          alignvalue: ed.alignvalue,
          widthtitle: ed.widthtitle,
          widthvalue: ed.widthvalue,
          eventname: event.eventname,
          eventid: event.eventid,
          active: event.active,
        }
      })

      return (
          <Card key={i} style={{ display: 'grid' }}>
            <CardContent style={{paddingLeft: '8px', paddingRight: '0px'}}>
              <h2>{event.eventname}</h2>
              <ViewList values={liValues} numOfCols={1} />
            </CardContent>
            <CardActions className={styles.cardActions}>
              {validAuction &&
                  <>
                    <Button
                        size="small"
                        variant="contained"
                        className={styles.successBtn}
                        onClick={() => {
                          history.push(auctionPath)
                        }}>
                      Auction
                    </Button>
                  </>
              }
              {validEvent &&
                  <Button
                      size="small"
                      variant="contained"
                      className={styles.successBtn}
                      onClick={() => {
                        history.push(eventPath)
                      }}>
                    Event
                  </Button>
              }
              <Button
                size="small"
                variant="contained"
                className={styles.cloneBtn}
                onClick={() => {
                  setActiveEvent({eventid: event.eventid, active: event.active})
                  setDialogCloneMsg("clone")
                }}>
                Clone
              </Button>
              <Button
                  onClick={() => {
                    setActiveEvent({eventid: event.eventid, active: event.active})
                    const message = (event.active ? "Are you sure you want to disable this event?" : "Are you sure you want to enable this event?" )
                    setActivateDialogMsg(message)
                  }}
                  size="small"
                  variant="contained"
                  className={styles.deleteBtn}>
                {event.active && "Disable"}
                {!event.active && "Enable"}
              </Button>
            </CardActions>
            <CardActions className={styles.cardActions}>
              {!event.active &&
                <Button
                    onClick={() => {
                      setActiveEvent({eventid: event.eventid, active: event.active})
                      setDialogMsg("Are you sure you want to delete this event?")
                    }}
                    size="small"
                    variant="contained"
                    className={styles.deleteBtn}>
                  Delete
                </Button>
              }
            </CardActions>
          </Card>
        )
      })}
    </div>
  )
}

export default HomePage
