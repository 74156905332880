import { DropzoneDialog } from 'material-ui-dropzone'
import React, {useEffect, useState} from 'react'
import exportFromJSON from 'export-from-json'
import { useForm } from 'react-hook-form'
import XLSX from 'xlsx'
import styled from "@emotion/styled"
import {Button, Box, Dialog, DialogContent, DialogContentText, DialogActions, List} from '@material-ui/core'
import HookForm from '../../../components/common/HookForm'
import SelectInput from '../../../components/common/inputs/SelectInput'
import styles from './ImportPage.module.css'
import download from 'downloadjs';
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import {
  Typography,
  StepLabel,
  Step,
  Stepper,
  ListItemText,
  ListItem,
  Grid,
  ListItemButton,
  FormControl, Select, MenuItem
} from "@mui/material";
import Dropzone from "react-dropzone";
import LoadingBackdrop from "../../../components/common/LoadingBackdrop";
import {
  useImportParticipants,
  useParticipantsImportFields,
  useParticipantsImportDMPList,
  useParticipantsImportDataMiner
} from "../../../pages/ParticipantsPage/ImportPage/useImportParticipants"
import {useEventState} from "../../../slices/eventSlice";
import {useHistory} from "react-router-dom";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {emailRegex} from "../../../util/formValidation";
import {useParams} from "react-router";
import {useAutoEvent} from "../../../queries/useAutoEvent";
import {useMutation, useQueryClient} from "react-query";
import axios from "axios";

type FormData = {
  isDMPMethod: boolean
  dmp: string
  dmpsource: string
  // first: string
  // last: string
  // email: string
  // phone: string
  // address1: string
  // address2: string
  // city: string
  // state: string
  // zip: string
  workbookSelection: string
}



interface Params {
  eventid: string
  importtype: string
}

const ImportPage = () => {
  
  const { eventid, importtype } = useParams<Params>()
  const queryClient = useQueryClient()
  useAutoEvent(eventid)

  const source = importtype === 'dmp' ? 'Select DataMiner Source' : 'Select Source'
  const steps = [source, 'Match Fields', 'Import Participants'];

  // const event = useEventState()
  const history = useHistory()
  const formMethods = useForm<FormData>()
  const {watch, getValues, setValue} = formMethods
  const [workbook, setWorkBook] = useState<XLSX.WorkBook>(null)
  const [isUploaderOpen, setIsUploaderOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File>(null)
  const [selectedWorkbookSheet, setSelectedWorkbookSheet] = useState<string>(null)
  const [tableData, setTableData] = useState<any>(null)
  const [finalTableData, setFinalTableData] = useState<any>([])
  const [jsonString, setJsonString] = useState<string>(null)
  const [jsonErrorString, setJsonErrorString] = useState<string>(null)
  const [jsonFinalString, setJsonFinalString] = useState<string>(null)
  const [dataKeys, setDataKeys] = useState<string[]>(null)
  const [selectedField, setSelectedField] = useState<{ value: string, label: string }[]>([{ value: 'First', label: 'First' }, { value: 'Last', label: 'Last' }, { value: 'Phone', label: 'Phone' }, { value: 'Email', label: 'Email' }, { value: 'Address1', label: 'Address1' }, { value: 'Address2', label: 'Address2' }, { value: 'City', label: 'City' }, { value: 'State', label: 'State' }, { value: 'Zip', label: 'Zip' }])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [checkWatch, setCheckWatch] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [dropOptions, setDropOptions] = useState(new Set());
  const importParticipants = useImportParticipants();
  const importParticipantsFields = useParticipantsImportFields();
  const importParticipantsDMPList = useParticipantsImportDMPList();
  const importParticipantsDataMiner = useParticipantsImportDataMiner();
  const importParticipantsSpreadsheet = useImportParticipants();
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [errorList, setErrorList] = useState<string>(null)


  const [fieldData, setFieldData] = useState<any>([])
  const [displayFieldData, setDisplayFieldData] = useState<any>([])
  const [bypassFieldData, setBypassFieldData] = useState<any>([])
  const [requiredFields, setRequiredFields] = useState<any>([])
  const [dmpList, setDMPList] = useState<any>([])
  const [finalColumns, setfinalColumns] = useState<  {field: string, type: string, headerName: string, width: number}[]>()

  const batchIdWatch = watch('dmpsource')

  useEffect(() => {
    loadFieldsData(batchIdWatch)
    setNextEnabled(true)
  }, [batchIdWatch])

  useEffect(() => {
    if (activeStep === 1) {
      var enabledNext = true
      requiredFields.forEach(item => {
        if (getValues(item) === 'none') {
          enabledNext = false
        }
      })
      setNextEnabled(enabledNext)
    }
  }, [checkWatch])

  useEffect(() => {
    loadFieldsData(null)

    if (importtype === 'dmp') {
      loadDMPList()
    }
  }, [])

  const onClickSelect = () => {
    setCheckWatch(checkWatch+1)
  }

  // useEffect(() => {
  //   const field = "dnpsource"
  //   setValue(field, dmpList[0].batch)
  // }, [dmpList])

  enum importSteps {
    selectSource = 0,
    matchFields = 1,
    importParticipants = 2
  }

  const setupDropOptions = (opts) => {
    const newOptions = new Set();
    opts.forEach((key) => {
      newOptions.add(key)
    })
    setDropOptions(newOptions)
  }

  const isStepOptional = (step) => {
    return false;
  }

  const closeDialog = () => {
    history.goBack()
    setDialogMsg('')
  }

  const generateErrorReport = () => {
    saveAsExcel(errorList)
  }

  const loadDMPList = async () => {
    await importParticipantsDMPList.mutateAsync({
      eventid: Number(eventid),
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "success") {
          setDMPList(json.batchlist)
        }
      }
    })
  }

  const loadFieldsData = async (batchId: any) => {
    const body = (batchId ? { eventid: Number(eventid), batchid: batchId } : { eventid: Number(eventid) })
    const callType = (batchId ? importParticipantsDMPList : importParticipantsFields)
    await callType.mutateAsync(body,{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          console.log(json)
          if (json.status === "success") {
            const fieldConvertedData = json.fieldlist.map(field => {
              return {field: field.fieldmatch, header: field.title, required: field.required, display: field.display, minlength: field.minlength, maxlength: field.maxlength}
            })



            setFieldData(fieldConvertedData)
            setDisplayFieldData(fieldConvertedData.filter((v) => v.display))
            setBypassFieldData(fieldConvertedData.filter((v) => v.display === false))

            setRequiredFields(fieldConvertedData.filter((v) => (v.display === true && v.required === true)).map(requiredWatch => {
              return requiredWatch.field
            }))
          }
        }
      })
  }

  const canDisplayField = (field) => {
    // console.log(fieldData)
    // console.log(field)
    // const filteredFieldData = fieldData.filter((v) => v.field === field)
    // if (filteredFieldData && filteredFieldData.length > 0) {
    //   return  filteredFieldData[0].display
    // }
    // return false
    return field
  }

  const handleNext = async () => {
    if (activeStep === 0 && importtype === 'dmp') {
      setIsLoading(true)
      await importParticipantsDMPList.mutateAsync({
        eventid: Number(eventid),
        batchid: batchIdWatch
      },{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          if (json.status === "failure") {
            setDialogMsg(json.message)
          }
          else {
            let rowObject = json.batchdata.map(({rownumber, ...rest}) => ({...rest}));
            // let rowObject = json.batchdata
            const keys = rowObject && rowObject.length > 0 ? Object.keys(rowObject[0]) : ['']
            const filteredKey = keys.filter((key) => canDisplayField(key))
            setDataKeys(filteredKey)
            setupDropOptions(filteredKey)
            setTableData(
                rowObject.slice(0, 5).map((r, i) => {
                  return {...(r as object), id: i}
                })
            )
            processKeys(filteredKey)
            const data = JSON.stringify(rowObject)
            setJsonString(data)
          }
          setIsLoading(false)
        }
      })
    }

    if (activeStep === 2) {
      const batchId = batchIdWatch
      const body = (batchId ? { eventid: Number(eventid), batchid: batchId, participantsData: JSON.parse(jsonFinalString) } : { eventid: Number(eventid), participantsData: JSON.parse(jsonFinalString) })
      const callType = (batchId ? importParticipantsDataMiner : importParticipantsSpreadsheet)
      setIsLoading(true)
      await callType.mutateAsync(body,{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          setErrorList(json.errorlist)

          if (json.message.length > 0) {
            setDialogMsg(json.message)
          }
          else {
            setDialogMsg('Import has completed successfully.')
          }
          queryClient.invalidateQueries(['participant', Number(eventid)])
          setIsLoading(false)
        }
      })
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  async function saveAsExcel(data) {
    let header = Object.keys(data[0]);
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(data, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "error_report.xlsx");
      });
    });
  }

  const handleBack = () => {
    setNextEnabled(true)
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const columns = dataKeys?.map((key) => {
      return {
        field: key,
        type: 'string',
        headerName: key,
        width: 120
      }
  })

  const finalcolumns = displayFieldData.map((key) => {
    return {
      field: key.field,
      type: 'string',
      headerName: key.header,
      width: 130
    }
  })

  const selectDMPOptions =
      dmpList?.map((obj) => {
          return { value: obj.batchid, label: obj.batch }
        }) || []

  const selectOptions =
    dataKeys?.map((obj) => {
      return { value: obj, label: obj }
    }) || []

  const onSubmit = async (formData: FormData) => {
    console.log('onsubmit')
    console.log(activeStep)
      const formDataKeys = Object.keys(formData)

      if (activeStep === 2) {
        let errorJson = []
        let goodJson = []
        const dataObject = JSON.parse(jsonString)

        dataObject.forEach(item => {
          //  var errorItem = null
          var addItem = {}
          // console.log(item)

          bypassFieldData.forEach((v) => {
            addItem[v.field] = item[v.field]
          })

          // console.log(formDataKeys)
          formDataKeys.forEach(formKey => {
            if (formKey === 'dmpsource' || formKey === 'workbookSelection') {
              return
            }
            const value = formData[formKey]
            if (value !== 'none') {
              const fieldDataFiltered = fieldData.filter((v, i) => v.field === formKey)
              if (fieldDataFiltered && fieldDataFiltered?.length > 0) {
                addItem[formKey] = item[value]?.toString()
              }
            }
          })

          goodJson.push(addItem)
        })

        setFinalTableData(
            goodJson.map((r, i) => {
              return { ...(r as object), id: i }
            })
        )
        const data = JSON.stringify(goodJson)
        setJsonFinalString(data)
      }
  }

  const getSheet = () => {
    const workbookSelection1 = watch('workbookSelection')
    setSelectedWorkbookSheet(workbookSelection1)
    processWorksheet(workbookSelection1)
  }

  const processWorksheet = (sheetToProcess: string) => {
    workbook.SheetNames.forEach((sheet) => {
      if (sheet === sheetToProcess) {
        let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet],{raw: true, defval:null})
        const keys = rowObject && rowObject.length > 0 ? Object.keys(rowObject[0]) : ['']

        setDataKeys(keys)
        setupDropOptions(keys)
        setTableData(
            rowObject.slice(0, 5).map((r, i) => {
              return {...(r as object), id: i}
            })
        )
        const data = JSON.stringify(rowObject)
        console.log(data)
        setJsonString(data)
      }
    })
  }

  const processKeys = (keys) => {
    // console.log('keys' + keys)
    keys.forEach(key => {
        fieldData.map((fieldD) => {
          const currentFieldValue = getValues(fieldD.field)
          if (!currentFieldValue && key.toLowerCase().includes(fieldD.field) || fieldD.field.toLowerCase().includes(key)) {
            // console.log('setting - ' + fieldD.field + ' value - ' + key)
            setValue(fieldD.field, key);
          }
        }
    )})


    fieldData.map((fieldD) => {
      const fieldValue = getValues(fieldD.field)
      if (fieldValue === undefined || fieldValue?.length === 0) {
        setValue(fieldD.field, 'none');
      }
    })
  }

  const onSave = async (files: File[]) => {
    if (files && files.length > 0) {
      setIsUploaderOpen(false)
      setSelectedFile(files[0])
      const fileReader = new FileReader()
      fileReader.onload = function (event) {
        const data = event.target.result

        const loadedWorkbook = XLSX.read(data, {
          type: 'binary'
        })
        setWorkBook(loadedWorkbook)

        const sheet = loadedWorkbook.SheetNames[0]
        setSelectedWorkbookSheet(sheet)
        let rowObject = XLSX.utils.sheet_to_json(loadedWorkbook.Sheets[sheet], {raw: true, defval:null})
        const keys = rowObject && rowObject.length > 0 ? Object.keys(rowObject[0]) : ['']

        // Process matches on keys
        processKeys(keys)
        setDataKeys(keys)
        setupDropOptions(keys)
        setTableData(
            rowObject.slice(0, 5).map((r, i) => {
              return { ...(r as object), id: i }
            })
        )
        const jsonData = JSON.stringify(rowObject)
        console.log(jsonData)
        setJsonString(jsonData)
      }
      fileReader.readAsBinaryString(files[0])
      setNextEnabled(true)
    }
    else {
      setSelectedFile(null)
      setNextEnabled(false)
    }
  }

  return (
    <div className={styles.root}>
      <h4>Import Participants</h4>
      <LoadingBackdrop
          open={ isLoading }
      />
      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {(errorList && errorList.length > 0) &&
                <Button onClick={generateErrorReport}>Download Error Report</Button>
            }
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <HookForm
          methods={formMethods}
          formProps={{ id: 'import-form' }}
          onSubmit={onSubmit}
      >
        <Box>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                )
              }

              return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
              )
            })}
          </Stepper>

            {activeStep === steps.length ? (
                  <>
                    {/*<Typography sx={{ mt: 2, mb: 1 }}>*/}
                    {/*  All steps completed - you&apos;re finished*/}
                    {/*</Typography>*/}
                    {/*<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>*/}
                    {/*  <Box sx={{ flex: '1 1 auto' }} />*/}
                    {/*  <Button onClick={handleReset}>Reset</Button>*/}
                    {/*</Box>*/}
                  </>
                ) : (
                  <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      {(activeStep === importSteps.selectSource && importtype === "dmp") && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12} className={styles.selectcontrol}>
                                <SelectInput
                                    name={'dmpsource'}
                                    label={'DataMiner Source'}
                                    options={selectDMPOptions}
                                    includeNone={false}
                                    required
                                />
                                </Grid>
                              </Grid>
                          </>
                      )}
                      {(activeStep === importSteps.selectSource && importtype !== "dmp") && (
                        <div className={styles.fileUpload}>
                          <Dropzone
                              maxFiles={1}
                              accept={'.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                              onDrop={onSave}
                          >
                            {({ getRootProps,
                                getInputProps,
                                isDragActive,
                                isDragAccept,
                                isDragReject,
                                acceptedFiles,
                                fileRejections
                            }) => (
                                <section>
                                  <div {...getRootProps()} className={isDragActive ? (isDragAccept ? styles.dropzoneselect : styles.dropzoneinvalid) : styles.dropzone}>
                                    <input {...getInputProps()} />
                                    {isDragAccept && (<p>File is a valid Excel. Go ahead and drop.</p>)}
                                    {!isDragActive && (<p>Drag 'n' drop Excel files here, or click to select Excel files</p>)}
                                    {isDragReject && (<p>File cannot be dropped. It's an invalid type.</p>)}
                                    <em>(The file must be of type .xls, .xlsx, or .csv.)</em>
                                  </div>
                                      <div className={styles.selectedFile}>
                                        {workbook && workbook.SheetNames.length === 1 && selectedFile && selectedFile?.name && (<h4>Click the 'NEXT' button below to continue processing '{selectedFile && selectedFile?.name} - {selectedFile?.size} bytes'</h4>)}
                                        {fileRejections && fileRejections.length > 0 && (<h4>The file type is invalid. The file must be of type .xls, .xlsx, or .csv.</h4>)}
                                        {workbook && workbook.SheetNames?.length > 1 && (<h4>Click the 'NEXT' button below to continue processing the sheet named '{selectedWorkbookSheet}'</h4>)}
                                        {workbook && workbook.SheetNames.length > 1 &&
                                          <SelectInput
                                            name="workbookSelection"
                                            label=""
                                            defaultValue={workbook.SheetNames[0]}
                                            options={workbook.SheetNames}
                                            onClick={getSheet}
                                            required
                                            includeNone={false}
                                          />
                                        }
                                      </div>
                                </section>
                            )}
                          </Dropzone>
                        </div>
                    )}
                    </Typography>
                    {activeStep === importSteps.matchFields && (
                        <div className={styles.matchFieldsContainer}>
                          <div className={styles.selectRow}>
                            {displayFieldData.map((fieldData) =>
                                <SelectInput
                                    name={fieldData.field}
                                    label={fieldData.header}
                                    options={selectOptions}
                                    onClick={() => {onClickSelect()}}
                                    // required={fieldData.required}
                                    rules={{
                                      validate: (val) => (fieldData.required && (val === 'none' || val?.length === 0)) ?  fieldData.header + " is required." : true,
                                    }}
                                />
                            )}
                          </div>
                          <div className={styles.gridContainer}>
                            <div className={styles.textRow}>
                              <Typography
                                  component="div"
                                  variant="h5"
                                  style={{
                                    position: 'relative',
                                    padding: '0rem 1rem 1rem 0rem',
                                  }}
                              >
                                Preview Uploaded File
                              </Typography>
                            </div>
                            <DataGridPro
                                rows={tableData || []}
                                columns={columns || []}
                                pagination={false}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                            />
                          </div>
                        </div>
                    )}
                    {activeStep === importSteps.importParticipants && (
                        <>
                          <div className={styles.matchFieldsContainer}>
                            <DataGridPro
                                rows={finalTableData}
                                columns={finalcolumns}
                                pagination={true}
                                autoHeight={true}
                                hideFooterRowCount={true}
                                hideFooterSelectedRowCount={true}
                            />
                          </div>
                        </>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      {activeStep > importSteps.selectSource && (
                          <Button
                              onClick={handleBack}
                              disabled={activeStep === 0}>
                            Back
                          </Button>
                      )}

                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button className={styles.importButton} onClick={handleNext} type="submit" form="import-form" disabled={!nextEnabled}>
                        {activeStep === steps.length - 1 ? 'Import' : 'Next'}
                      </Button>
                    </Box>
                  </>
                )}
          </Box>
      </HookForm>
    </div>
  )
}

export default ImportPage
