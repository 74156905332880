import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Grid, List, ListItemButton, TextField} from "@mui/material";
import {Editor, EditorChangeEvent, EditorTools, EditorUtils, ProseMirror} from "@progress/kendo-react-editor";
import styles from "../../AuctionSettingsForm/AuctionSettingsForm.module.css";
import {styled} from "@mui/material/styles";
import {Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, ListItemText} from "@material-ui/core";
import {InsertShortcodeTool} from "../../../components/common/inputs/RTEInsertTool";

const { Schema, EditorView, EditorState } = ProseMirror;
const { Bold, Italic, Underline, ViewHtml } = EditorTools;

const EmailBody = ({eventid, massemailid}) => {
    const [emailData, setEmailData] = useState({fieldlist: null, massemailbody: '', massemailfrom: '', massemailfromname: '', massemailid: 0, massemailsubject: ''});
    const [bodyData, setBodyData] = useState('');
    const [dialogMsg, setDialogMsg] = React.useState("");



    useEffect(() => {
        const body = {'eventid': eventid, 'massemailid': massemailid}

        const fetchData = async () => {
            const { data } = await axios.post('/evtmassemailbody', body)
            const parsedData = JSON.parse(data.jsonString)
            console.log(parsedData)
            setEmailData(parsedData[0])
            setBodyData(parsedData[0].massemailbody)
        }

        fetchData()
    }, [])

    const handleBodyDataChange = (event: EditorChangeEvent) => {
        setBodyData(event.html);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value
        });
    };

    const FloatingButton = styled(Button)<ButtonProps>(({ theme }) => ({
        margin:'auto',
        top: 'auto',
        //  right: '40%',
        bottom: 20,
        left: '25%',
        position: 'fixed',
        width: '50%'
    }))

    const save = async () => {
        const body = {
            'eventid': eventid,
            'massemailid': massemailid,
            'massemailfrom': emailData.massemailfrom,
            'massemailfromname': emailData.massemailfromname,
            'massemailsubject': emailData.massemailsubject,
            'massemailbody': bodyData
        }

        const { data } = await axios.put('/evtmassemailbody', body)
        const parsedData = JSON.parse(data.jsonString)
        var message = "Successfully saved."
        if (parsedData.message) {
            parsedData.message = message
        }
        setDialogMsg(message)
    }

    const reset = async () => {
        setBodyData('')
        setEmailData({fieldlist: null, massemailbody: '', massemailfrom: '', massemailfromname: '', massemailid: 0, massemailsubject: ''});
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    const handleToggle = (value: string) => () => {
        setBodyData(bodyData + ' @' + value)
    }

    const { Schema, EditorView, EditorState } = ProseMirror;

    const nonEditable = {
        name: "nonEditable",
        inline: true,
        group: "inline",
        content: "inline+",
        marks: "",
        attrs: {
            contenteditable: { default: null },
            class: { default: null },
            style: { default: null }
        },
        atom: true,
        parseDOM: [{ tag: "span.uneditable", priority: 51 }],
        // The styles can be added via the class as well
        toDOM: () => [
            "span",
            {
                contenteditable: false,
                class: "uneditable",
                style: "user-select: none; opacity: 0.5;"
            },
            0
        ]
    };

    const onMount = event => {
        const { viewProps } = event;
        const { plugins, schema } = viewProps.state;

        // Append a new node.
        let nodes = schema.spec.nodes.addToEnd('nonEditable', nonEditable);

        // Create the new schema.
        const mySchema = new Schema({ nodes: nodes, marks: schema.spec.marks });

        // Create a new document using the modified schema.
        const doc = EditorUtils.createDocument(mySchema, '');

        // Return the custom EditorView object that will be used by Editor.
        return new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                state: EditorState.create({ doc, plugins })
            }
        );
    }

    return (
        <>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <div style={{ padding: 20 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <TextField name={"massemailfromname"} label={"From Name:"} value={emailData.massemailfromname} onChange={handleInputChange} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name={"massemailfrom"} label={"From Email:"} value={emailData.massemailfrom} onChange={handleInputChange} fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name={"massemailsubject"} label={"Subject:"}  value={emailData.massemailsubject} onChange={handleInputChange} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Editor
                            value={bodyData}
                            onChange={(e) => handleBodyDataChange(e)}
                            tools={[[Bold, Italic, Underline, ViewHtml, InsertShortcodeTool]]}
                            contentStyle={{ height: 300 }}
                            onMount={onMount}
                        />
                    </Grid>
                    {/*<Grid item xs={2}>*/}
                    {/*    <List dense={true}>*/}
                    {/*        {emailData?.fieldlist?.map(field => {*/}
                    {/*            return (*/}
                    {/*                <ListItemButton id={field.fieldname} onClick={handleToggle(field.fieldname)}>*/}
                    {/*                    <ListItemText*/}
                    {/*                        secondary={'@' + field.fieldname}*/}
                    {/*                    />*/}
                    {/*                </ListItemButton>*/}
                    {/*            )*/}
                    {/*        })}*/}
                    {/*    </List>`*/}
                    {/*</Grid>*/}
                    <Grid item xs={6}>
                        <Button type="submit" variant="contained" className={styles.button} onClick={save} fullWidth>
                            Save
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button type="submit" variant="contained" className={styles.button} onClick={reset} fullWidth>
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default EmailBody

