import React, { useState } from 'react'

import {Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef,  GridRowModel,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useCreateEvtItem, useFetchEventItems, useUpdateItemOrder} from '../../queries/useEventItemsFetch'
import { EventItemsForm } from './EventItemsForm'
import styles from './EventItemsTable.module.css'
import {EventState, setEvent, useEventState} from "../../slices/eventSlice";
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {Grid} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import {useQueryClient} from "react-query";
import {formatMoney} from "../../util/formatHelpers";

const eventItemsDefaults = {
  itemid: 0,
  itemitemid: 0,
  itemname: '',
  itemdescription: '',
  itemprice: 0,
  itemnondeductibleamount: 0,
  itemtypeid: 0,
  itemdonationtypeid: 0,
  itemaccountcodeid: 0,
  itemquantity: 0,
  itemgroupquantity: 0,
  itemquantityunlimited: true,
  itemmaxquantitypertransaction: 0,
  itemonlygroupavailable: true,
  itemlinktoregistration: true,
  itemwaiverrequired: true,
  itemwaiverurl: '',
  itemstopsellingdate: '',
  itemstopselling: true,
  active: true,
  delete: true
}

interface Params {
  eventid: string
}

const EventItemsTable = () => {
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)
  const queryClient = useQueryClient()
  const eventState = useEventState()
  const eventItemCreate = useCreateEvtItem()
  const [gridParams, setGridParams] = useState(eventItemsDefaults)
  const [rowOrdering, setRowOrdering] = useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [reloadForm, setReloadForm] = useState<number>(0)

  const { data, isLoading } = useFetchEventItems( Number(eventState.eventId) )
  const updateItemOrder = useUpdateItemOrder(eventState.eventId)
  const [isFormShowing, setIsFormShowing] = useState(false)
  var rows = []
  if (data) {
    console.log(data)
    rows = data.map((item) => {
      return { id: item.itemid, ...item}
    })
  }

  const handleAdd = () => {
    setReloadForm(reloadForm+1)
    setGridParams(null)
    setIsFormShowing(true)
  }

  const getAuctionType = ((auctionTypeId: number) => {
    if (auctionTypeId === 1) {
      return 'Online Auction'
    }
    else if  (auctionTypeId === 2) {
      return 'Live Auction Only'
    }
    else if  (auctionTypeId === 3) {
      return 'Buy It Now Only (Online)'
    }
    else if  (auctionTypeId === 4) {
      return 'Buy It Now Only (In-Person Only)'
    }
  })

  const handleEdit = (params: GridCellParams) => {
    setReloadForm(reloadForm+1)
    const ai = rows.filter((item) => item.itemid === Number(params.id))[0]
    console.log(ai)
    setGridParams( {
      itemid: ai.itemid,
      itemitemid: ai.itemitemid,
      itemname: ai.itemname,
      itemdescription: ai.itemdescription,
      itemprice: ai.itemprice,
      itemnondeductibleamount: ai.itemnondeductibleamount,
      itemtypeid: ai.itemtypeid,
      itemdonationtypeid: ai.itemdonationtypeid,
      itemaccountcodeid: ai.itemaccountcodeid,
      itemquantity: ai.itemquantity,
      itemgroupquantity: ai.itemgroupquantity,
      itemquantityunlimited: ai.itemquantityunlimited,
      itemmaxquantitypertransaction: ai.itemmaxquantitypertransaction,
      itemonlygroupavailable: ai.itemonlygroupavailable,
      itemlinktoregistration: ai.itemlinktoregistration,
      itemwaiverrequired: ai.itemwaiverrequired,
      itemwaiverurl: ai.itemwaiverurl,
      itemstopsellingdate: ai.itemstopsellingdate,
      itemstopselling: ai.itemstopselling,
      active: ai.active,
      delete: ai.delete,
    })
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.getValue(params.id, 'itemname').toString() + "\"?",
      id: Number(params.id)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var finaldata = {
      "eventid": Number(eventid),
      "itemid": id,
      "delete": true
    }
    const { data: response } = await eventItemCreate.mutateAsync({
      ...finaldata
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "failure") {
          setDialogObj({
            msg: '',
            id: 0
          })
          setDialogMsg(json.message)
        }
        else {
          setDialogObj({
            msg: '',
            id: 0
          })
        }
      }
    })
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const canDelete = (active) => {
    if (new Date(eventState.bidStart) <= new Date()) {
      // console.log('date is valid')
      return !active
    }
    else {
      return true
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      renderHeader: () => {
        return (
            <IconButton
                className={styles.submitButton}
                onClick={() => handleAdd()}
                size="small">
              <Add />
            </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
            <Edit />
          </IconButton>
      )
    },
    {
      field: 'itemname',
      type: 'string',
      headerName: 'Item Name',
      minWidth: 350,
      sortable: true,
    },
    {
      field: 'itemtype',
      type: 'string',
      headerName: 'Type',
      minWidth: 200,
    },
    {
      field: 'itemprice',
      type: 'string',
      headerAlign: 'left',
      align: 'right',
      headerName: 'Price',
      minWidth: 150,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
      },
    },
    {
      field: 'itemgroupquantity',
      type: 'number',
      headerName: 'Quantity',
      minWidth: 100,
      sortable: true,
      renderCell: (params) => (
          <>
            {/*{params.row.itemquantityunlimited  ?*/}
            {/*    <div>Unlimited</div>*/}
            {/*    :*/}
            {/*    <div>{params.row.itemgroupquantity}</div>*/}
            {/*}*/}
            <div>{params.row.itemgroupquantity}</div>
          </>
      )
      // valueFormatter: (params: GridValueFormatterParams) => {
      //   console.log(params)
      //   return params.value
      // },
    },
    {
      field: 'itemquantityremaining',
      type: 'number',
      headerName: 'Qty Remaining',
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (
          <>
            {params.row.itemquantityunlimited  ?
                <div>Unlimited</div>
                :
                <div>{params.row.itemquantityremaining}</div>
            }
          </>
      )
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? "Active" : "Inactive")
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      width: 70,
      sortable: true,
      renderCell: (params) => (
          <div title={canDelete(params.row.active) ? '' : "Only items with a status of Inactive can be deleted."}>
            <div>
              <IconButton disabled={!canDelete(params.row.active)} className={styles.button} onClick={() => { handleDelete(params) }}>
                <Delete />
              </IconButton>
            </div>
          </div>
          // </Tooltip>
      )
    }
  ]
  const ReOrderToggle = async () =>{
    //todo: Data is being pushed up and saved successfully however it isnt being saved locally so the rerender destroys it until refetched.
    if(rowOrdering){
      for(let i = 0; i<rows.length; i++){
        rows[i].itemorder = i;
        data.find(x => x.itemid === rows[i].itemid).itemorder = i;

      }
      let itemListingOrder =  rows.map(x => {
        return {itemid: x.itemid, order: x.itemorder}
      })
      var finaldata = {
        eventid: eventState.eventId,
        items: itemListingOrder
      }
      console.log(finaldata)
      updateItemOrder.mutate({
        ...finaldata
      },{
        onSuccess: (data) => {
          console.log(data)
          queryClient.invalidateQueries(['evtitems', Number(eventState.eventId)])
          // const json = JSON.parse(data.data.jsonString)
          // if (json.status === "failure") {
          //   setDialogObj({
          //     msg: '',
          //     id: 0
          //   })
          //   setDialogMsg(json.message)
          // }
          // else {
          //   setDialogObj({
          //     msg: '',
          //     id: 0
          //   })
          // }
        }
      })
    }
   
    setRowOrdering(!rowOrdering)
  }
   const updateRowPosition = (
    initialIndex: number,
    newIndex: number,
    rows: Array<GridRowModel>,
  ) => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        return rowsClone;
      }
  
  
  const handleRowOrderChange =  (params: GridRowOrderChangeParams) => {
    //setLoading(true);
    const newRows =  updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows,
    );
     rows = newRows 
   
  };

  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridSortedRowIdsSelector(apiRef);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
      options.allColumns = true;
      apiRef.current.exportDataAsCsv(options);
    }


    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    return (
        <>
          <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                  <Button
                      {...buttonBaseProps}
                      onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                  >
                    EXPORT
                  </Button>
                  
                  <Button
                      onClick={() => ReOrderToggle()}
                  >
                    {rowOrdering ? 'Save' : 'Re-Order'}
                    
                  </Button>
                  {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
          </GridToolbarContainer>
        </>
    )
  }

  return (
      <div className={styles.root}>
        <h1>Tickets and Items</h1>
        <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <EventItemsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} eventid={eventid} itemrows={rows}/>
      <DataGridPremium
        autoHeight
        rowReordering={rowOrdering}
        onRowOrderChange={handleRowOrderChange}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
      />
    </div>
  )
}

export default EventItemsTable
