import axios, { AxiosResponse } from 'axios';

// Define the type for the log data
interface LogData {
    eventid: number;
    category: string;
    location: string;
    message: string;
    iserror: boolean;
}

// Define the class for logging
export default class Logger {

    // Method to send log data
    static async logEvent(logData: LogData): Promise<AxiosResponse<any>> {
        try {
            const response = await axios.put("/logging", logData);
            return response;
        } catch (error) {
            // Handle error appropriately
            console.error('Error sending log data', error);
            //throw error;
        }
    }
}
