import { useForm } from 'react-hook-form'
import {Link, useHistory} from 'react-router-dom'

import { Button } from '@material-ui/core'

import { PagePathTypes } from '../../../App'
import HookForm from '../../../components/common/HookForm'
import TextInput from '../../../components/common/inputs/TextInput'
import {
  checkPasswordValidity,
  checkPasswordsMatch
} from '../../../util/formValidation'
import { AuthPageSubPaths } from '../AuthPage'
import styles from './ResetPasswordForm.module.css'
import {useParams} from "react-router";
import {passwordResetEmail, passwordResetWithToken} from "../../../queries/useResetPassword";
import {useState} from "react";
import Alerts from "../../../components/common/Alerts";

type FormData = {
  password: string
  confirmPassword: string
}

interface Params {
  token: string
}

const ResetPasswordForm = () => {
  const [successMsg, setSuccessMsg] = useState<string>(null)
  const formMethods = useForm<FormData>({
    mode: 'onChange'
  })
  const { watch } = formMethods
  const history = useHistory()
  const { token } = useParams<Params>()
  const onSubmit = async (formData: FormData) => {
    const worked = await passwordResetWithToken(token, formData.password)
    if (worked) {
      setSuccessMsg('Your password was reset. You can now login with your new password.')
      setTimeout(() => {
        history.push('/auth/login')
      }, 3000);
    }
    else {
      setSuccessMsg('The request failed. Please try to reset your password again..')
    }
  }

  return (
    <div className={styles.root}>
      <h2>Reset Password</h2>
      <Alerts successMsg={successMsg} />
      <HookForm
        methods={formMethods}
        onSubmit={onSubmit}
        className={styles.form}>
        {/* {isLoading && (
    <CircularProgress className={styles.loadingWheel} size="5rem" />
  )} */}

        <TextInput
          name="password"
          label="New Password"
          required
          // rules={{ validate: checkPasswordValidity }}
          rules={{
            required: 'Password field is required',
            minLength: {
              value: 6,
              message: 'Must be at least 6 characters long'
            }
          }}
          textFieldProps={{ type: 'password' }}
        />
        <TextInput
          name="confirmPassword"
          label="Confirm Password"
          required
          rules={{
            validate: (val) => checkPasswordsMatch(val, watch)
          }}
          textFieldProps={{ type: 'password' }}
        />

        <Button type="submit" variant="contained" className={styles.button}>
          Reset Password
        </Button>

        {/* <Alerts errorMsg={serverMessages.error} /> */}
      </HookForm>
      <Link to={`${PagePathTypes.auth}${AuthPageSubPaths.login}`}>
        Back to Login
      </Link>
    </div>
  )
}

export default ResetPasswordForm
