import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  CheckoutObject {
    status: string
    message: string
    showccfees: boolean,
    ccfeespercentage: number,
    creditcardiframe: string,
    javascriptfile: string,
    transcenterid: string,
    processorid: string,
    transactiontype: string,
    checkoutlist: CheckoutData[]
}

export interface  CheckoutData {
    rownumber: number
    biddernumber: number
    paddlenumbertext: string
    receiptnumber: number
    participantname: string
    checkoutstatus: string
    amount: string
    isreadonly: boolean
    key: number
}

const CheckoutDefaults = {
    rownumber:  null,
    biddernumber: null,
    paddlenumbertext: null,
    receiptnumber: null,
    participantname: null,
    checkoutstatus: null,
    amount: null,
    isreadonly: false,
    key: 0
}

export const useFetchCheckout = (eventIdvalue) => {
    return useQuery<CheckoutObject>(
        ['checkout', eventIdvalue],
        () => globalCheckoutFetcher(eventIdvalue),
        {
            staleTime: 0,
            keepPreviousData: false
        }
    )
}

export const useUpdateCheckout = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`checkoutlist`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['checkout'])
        }
    })
}

export const globalCheckoutFetcher = async (eventIdvalue) => {
    const { data } = await axios.post('/checkoutlist', { eventid: eventIdvalue })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as CheckoutObject
}
