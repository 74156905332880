import axios from 'axios'
import { useQuery } from 'react-query'

export interface Twilio {
    twiliophone: string
    twilioaccountsid: string
    twilioauthtoken: string
    valid: boolean
}

export const orgSettingsTwilioFetcher = async () => {
    const response = await axios.get(`/orgsettingstwilio`, {
        params: {}
    })
    const parsedResponse = JSON.parse(response?.data?.jsonString)
    const twilio = JSON.parse(parsedResponse.twilio)
    return twilio
}

export const useFetchOrgSettingsTwilio = ({
                                        onSuccess = () => null,
                                        onError = () => null
                                    }: {
    onSuccess?: any
    onError?: any
} = {}) => {
    return useQuery<Twilio>(
        ['orgsettingstwilio'],
        () => orgSettingsTwilioFetcher(),
        {
            staleTime: 120000,
            onSuccess,
            onError,
            retry: false
        }
    )
}
