import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useTermsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (body: any) => {
      return axios.put(`/termsofservice`, body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['termsofservice'])
      }
    }
  )
}
