import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'


export interface  CheckoutDetailObject {
    status: string
    message: string
    checkoutheader: CheckoutHeaderData[]
    checkoutitems: CheckoutItemsData[]
}

export interface  CheckoutHeaderData {
    biddernumber: number
    company: string
    participantid: number
    participantname: string
    first: string
    last: string
    address: string
    address2: string
    city: string
    state: string
    zip: string
    country: string
    phone: string
    email: string
    additionaldonation: number
    ccfeesexactamount: number
    totalamount: number
    ccfeesamount?: number
    paymentmethod: string
    sendemail: boolean
}

export interface  CheckoutItemsData {
    itemid: number
    historyid: number
    itemnumber: string
    itemname: string
    quantity: number
    fairmarketvalue: number
    amount: number
}

export const useFetchCheckoutDetail = (eventIdvalue, key, isreadonly) => {
    return useQuery<CheckoutDetailObject>(
        ['checkoutdetail', eventIdvalue, key, isreadonly],
        () => globalCheckoutDetailFetcher(eventIdvalue, key, isreadonly),
        {}
    )
}

export const useFetchCheckoutDetailAdd = (eventIdvalue) => {
    return useQuery<CheckoutDetailObject>(
        ['checkoutdetailadd', eventIdvalue],
        () => checkoutDetailAdd(eventIdvalue),
        {}
    )
}

export const useUpdateCheckoutDetail = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`checkoutdetail`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['checkoutdetail'])
        }
    })
}


export const useAddCheckoutDetail = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.post(`checkoutdetail`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['checkoutdetail'])
        }
    })
}

export const checkoutDetailAdd = async (eventid) => {
    const { data } = await axios.post('/checkoutdetailadd', {eventid: eventid})
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const checkout = async (body) => {
    const { data } = await axios.put('/checkoutadmin', body)
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const resendCheckout = async (eventid, key, email) => {
    const {data} = await axios.post('/checkoutresend', {eventid: eventid, key: key, email: email})
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const generatePDF = async (eventid, key) => {
    const {data} = await axios.post('/checkoutpdf', {eventid: eventid, key: key}, {responseType: "blob"})
    return data
}

export const manualBid = async (eventid, itemid, buyitnow, quantity, bidderparticipantid) => {
    const { data } = await axios.put('/manualbid', { eventid: eventid, itemid: itemid, buyitnow: buyitnow, quantity: quantity, bidderparticipantid: bidderparticipantid })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const globalCheckoutDetailFetcher = async (eventIdvalue, key, isreadonly) => {
    const { data } = await axios.post('/checkoutdetail', { eventid: eventIdvalue, isreadonly: isreadonly, key: key })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as CheckoutDetailObject
}
