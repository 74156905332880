import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import {Events} from "../queries/events/useFetchEvents";

export const useOrgSettings = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.put(`orgsettings`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['orgsettings'])
        }
    })
}

export const useOrgSettingsTwilio = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.put(`orgsettingstwilio`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['orgsettingstwilio'])
        }
    })
}

export const useResetTwilio = async () => {
    const { data } = await axios.get('/resettwilio')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const useOrgSettingsGEM = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.put(`orgsettingsgem`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['orgsettingsgem'])
        }
    })
}

export const useResetGEM = async () => {
    const { data } = await axios.get('/resetgem')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}
