import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, TextField, Typography
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import SelectInput from '../../components/common/inputs/SelectInput'
import Switch from '../../components/common/inputs/Switch'
import TextInput from '../../components/common/inputs/TextInput'
import styles from './EventItemsTable.module.css'
import MaskedInput from "../../components/common/inputs/MaskedInput";
import {Checkbox, FormControlLabel} from "@mui/material";
import NumberFormat from 'react-number-format'
import axios from "axios";
import {useCreateEvtItem} from "../../queries/useEventItemsFetch";

interface FormData {
  itemid: number
  itemitemid: number
  itemname: string
  itemdescription: string
  itemprice: number
  itemnondeductibleamount: number
  itemtypeid: number
  itemdonationtypeid: number
  itemaccountcodeid: number
  itemquantity: number
  itemgroupquantity: number
  itemquantityunlimited: boolean
  itemmaxquantitypertransaction: number
  itemonlygroupavailable: boolean

  itemlinktoregistration: boolean
  itemwaiverrequired: boolean

  itemwaiverurl: string

  itemstopsellingdate: string

  itemstopselling: boolean
  active: boolean
  delete: boolean
}

export const EventItemsForm = ({ isOpen, setIsOpen, gridParams, reloadForm, eventid, itemrows }) => {
  const formMethods = useForm<FormData>()
  const title = gridParams ? "Edit Event Item" : "Create Event Item"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [priceValue, setPriceValue] = useState<number>(1)
  const [maxQtyPerTransChange, setMaxQtyPerTransChange] = useState<number>(1)
  const [dialogMsg, setDialogMsg] = React.useState("")
  const itemqtyUnlimited = watch('itemquantityunlimited')
  const [loading, setLoading] = React.useState(false);
  const itemtypeidWatch = watch('itemtypeid')
  const [activeTicketType, setActiveTicketTyp] = React.useState({name: '', id: 0, active: false});
  const [quantityValue, setQuantityValue] = useState<number>(1)
  const [itemGroupQuantity, setItemGroupQuantity] = useState<number>(1)
  const [activeValue, setActiveValue] = useState<boolean>(true)
  const [registationValue, setRegistrationValue] = useState<boolean>(false)
  const [onlyGroupAvailableValue, setOnlyGroupAvailableValue] = useState<boolean>(false)
  const [stopSalesValue, setStopSalesValue] = useState<boolean>(false)
  const [nonDeductableAmount, setNonDeductableAmount] = useState<number>(1)
  const [dropdownData, setDropdownData] = React.useState({
    accountingcode: [],
    donationtype: [],
    tickettype: [],
  });
  const [groupValues, setGroupValues] = useState({
    id: 0,
    name: ''
  })

  useEffect(() => {
    getEventDropdowns()
  }, [])

  useEffect(() => {
    const tixType = dropdownData?.tickettype?.find(ticketT => ticketT.id === itemtypeidWatch)
    setActiveTicketTyp(tixType)
  }, [itemtypeidWatch])

  const getEventDropdowns = async () => {
    setLoading(true)
    axios.post('/evtitemsdropdowns', {eventid: eventid}, {responseType: "json"}).then(response => {
      const parsedData = JSON.parse(response.data.jsonString)
      setDropdownData(parsedData)
      setLoading(false)
    })
  }

  useEffect(() => {
    console.log(gridParams)
    setPriceValue(gridParams?.itemprice)
    setActiveValue(gridParams ? gridParams?.active : true)
    setQuantityValue(gridParams ? gridParams?.itemquantity : 1)
    setItemGroupQuantity(gridParams ? gridParams?.itemgroupquantity : 1)
    setMaxQtyPerTransChange(gridParams?.itemmaxquantitypertransaction)
    setRegistrationValue(gridParams?.itemlinktoregistration)
    setOnlyGroupAvailableValue(gridParams?.itemonlygroupavailable)
    setStopSalesValue(gridParams?.itemstopselling)
    setNonDeductableAmount(gridParams?.itemnondeductibleamount)



    reset({
      itemid: (gridParams ? gridParams.itemid : null),
      itemitemid: (gridParams ? gridParams.itemitemid : null),
      itemname: (gridParams ? gridParams.itemname : null),
      itemdescription: (gridParams ? gridParams.itemdescription : null),
      itemprice: (gridParams ? gridParams.itemprice : null),
      itemnondeductibleamount: (gridParams ? gridParams.itemnondeductibleamount : null),
      itemtypeid: (gridParams ? gridParams.itemtypeid : 1),
      itemdonationtypeid: (gridParams ? Number(gridParams.itemdonationtypeid) : 0),
      itemaccountcodeid: (gridParams ? Number(gridParams.itemaccountcodeid) : 0),
      itemquantity: (gridParams ? gridParams.itemquantity : 1),
      // itemquantity: (gridParams ? gridParams.itemquantity : 1),
      itemgroupquantity: (gridParams ? gridParams.itemgroupquantity : 1),
      itemquantityunlimited: (gridParams ? gridParams.itemquantityunlimited : false),
      itemmaxquantitypertransaction: (gridParams ? gridParams.itemmaxquantitypertransaction : 1),
      itemonlygroupavailable: (gridParams ? gridParams.itemonlygroupavailable : false),
      itemlinktoregistration: (gridParams ? gridParams.itemlinktoregistration : false),
      itemwaiverrequired: (gridParams ? gridParams.itemwaiverrequired : false),
      itemwaiverurl: (gridParams ? gridParams.itemwaiverurl : ''),
      itemstopsellingdate: (gridParams ? gridParams.itemstopsellingdate : ''),
      itemstopselling: (gridParams ? gridParams.itemstopselling : false),
      delete: (gridParams ? gridParams.delete : false),
    })
  }, [reloadForm])

  const donationTypeOptions = () => {
    console.log(dropdownData.donationtype)
    const dtRows = dropdownData.donationtype?.filter((e) => e.active === true).map((types) => {
      return {value: types.id, label: types.name}
    })
    if (dtRows && dtRows.length > 0) {
      return [{value: 0, label: 'None'}, ...dtRows]
    }
    else {
      return [{value: 0, label: 'None'}]
    }
  }

  const ticketTypeOptions = () => {
    var rows = dropdownData.tickettype?.filter((e) => e.active === true).map((types) => {
      return {value: types.id, label: types.name}
    })

    if (itemrows && itemrows?.length <= 0) {
      // Filter out Group
      rows = rows?.filter((e) => e.label !== 'Group of Tickets').map((types) => {
        return {value: types.value, label: types.label}
      })
    }

    if (rows && rows.length > 0) {
      return [...rows]
    }
    else {
      return []
    }
  }

  const linkedTicketTypeOptions = () => {
    const linkedTickets = itemrows?.filter((i) => i.itemtype === 'Ticket').map((types) => {
      return {value: types.id, label: types.itemname}
    })

    if (linkedTickets && linkedTickets.length > 0) {
      return [...linkedTickets]
    }
    else {
      return []
    }
  }

  const accountingCodeOptions = () => {
    const dtRows = dropdownData.accountingcode?.filter((e) => e.active === true).map((types) => {
      return {value: types.id, label: types.name}
    })
    if (dtRows && dtRows.length > 0) {
      return [{value: 0, label: 'None'}, ...dtRows]
    }
    else {
      return [{value: 0, label: 'None'}]
    }
  }

  const createMutation = useCreateEvtItem()

  const onSubmit = async (data: FormData) => {
    console.log(data)
    var finalData = gridParams ? { ...data, eventid: Number(eventid)  } : { ...data, eventid: Number(eventid) }
    console.log(finalData)

    // const isGroupType = itemrows?.find((i) => i.itemtype === 'Group of Tickets')
    // console.log(isGroupType)
    // const unlimited = (isGroupType ? false : finalData.itemquantityunlimited)
    // console.log(unlimited)

    // setItemGroupQuantity
    var bodyData = {
      eventid: Number(eventid),
      itemname: finalData.itemname,
      itemdescription: finalData.itemdescription,
      itemprice: priceValue,
      itemnondeductibleamount: nonDeductableAmount,
      itemtypeid: finalData.itemtypeid,
      itemdonationtypeid: Number(finalData.itemdonationtypeid),
      itemaccountcodeid: Number(finalData.itemaccountcodeid),
      itemquantity: Number(itemGroupQuantity),
      itemgroupquantity: Number(itemGroupQuantity),
      itemquantityunlimited: finalData.itemquantityunlimited,
      itemmaxquantitypertransaction: Number(maxQtyPerTransChange) > 0 ? Number(maxQtyPerTransChange) : 0,
      itemonlygroupavailable: onlyGroupAvailableValue,
      itemlinktoregistration: registationValue,
      itemwaiverrequired: finalData.itemwaiverrequired,
      itemwaiverurl: finalData.itemwaiverurl,
      itemstopselling: finalData.itemstopselling,
      active: activeValue
    }
    if (finalData.itemid > 0) {
      bodyData['itemid'] = Number(finalData.itemid)
    }

    if (finalData.itemitemid > 0) {
      bodyData['itemitemid'] = Number(finalData.itemitemid)
    }

    const { data: response } = await createMutation.mutateAsync(bodyData)

    const parsedResponse = JSON.parse(response?.jsonString)
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse.message)
    }
    else {
      setIsOpen(false)
    }
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const handlePriceChange = (event) => {
    if (Number(event.value) >= 0) {
      setPriceValue(event.value)
    }
    else {
      setPriceValue(gridParams ? gridParams?.itemprice : 1)
    }
  }

  const handleNonDeductableAmountChange = (event) => {
    setNonDeductableAmount(event.value)
  }

  const handleQuantityChange = (event) => {
    console.log(event)
    if (Number(event.value) >= 0) {
      setItemGroupQuantity(Number(event.value))
    }
    else {
      setItemGroupQuantity(gridParams && gridParams?.itemgroupquantity > 0 ? gridParams?.itemgroupquantity : 1)
    }
  }

  const handleGroupQuantityChange = (event) => {
    console.log(event)
    if (Number(event.value) >= 0) {
      setItemGroupQuantity(Number(event.value))
    }
    else {
      setItemGroupQuantity(gridParams && gridParams?.itemgroupquantity > 0 ? gridParams?.itemgroupquantity : 1)
    }
  }

  const handleMaxQtyPerTransChange = (event) => {
    setMaxQtyPerTransChange(event.value)
  }

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveValue(event.target.checked);
  }

  const handleRegistrationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationValue(event.target.checked);
  }

  const handleOnlyGroupAvailableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyGroupAvailableValue(event.target.checked);
  }

  const handleStopSalesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStopSalesValue(event.target.checked);
  }

  return (
    <Dialog open={isOpen} maxWidth="xl" fullWidth={true} className={styles.dialog}>
      <DialogTitle>{title}</DialogTitle>
      <LoadingBackdrop open={createMutation.isLoading || loading} />
      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={onSubmit}
          className={styles.itemsForm}
          formProps={{ id: 'items-form' }}>

          <SelectInput
              name="itemtypeid"
              label="Item Type"
              options={ticketTypeOptions()}
              includeNone={false}
              required={true}
              // tooltipText={''}
          />

          {activeTicketType?.name === 'Ticket' &&
              <>
                <TextInput name="itemname" label="Item Name" required tooltipText={'The item name that will be used in the item listing and detail page.'} />
                <TextInput name="itemdescription" label="Display Description" tooltipText={''} />
                <div className={styles.twoControlContainer}>
                  <MaskedInput
                      name="itemprice"
                      label="Price"
                      defaultValue="0"
                      required={true}
                      onValueChange={handlePriceChange}
                      tooltipText={''}
                  />
                  <MaskedInput
                      name="itemnondeductibleamount"
                      label="Non-Deductible Amount"
                      defaultValue="0"
                      onValueChange={handleNonDeductableAmountChange}
                      tooltipText={''}
                  />
                </div>
                <div className={styles.twoControlContainer}>
                  <SelectInput
                      name="itemdonationtypeid"
                      label="Donation Type"
                      options={donationTypeOptions()}
                      includeNone={false}
                      tooltipText={'Used for the item’s donation record in DonorSnap or your donor management system.'}
                  />
                  <SelectInput
                      name="itemaccountcodeid"
                      label="Accounting Code"
                      options={accountingCodeOptions()}
                      includeNone={false}
                      tooltipText={'Used for the item’s accounting code in DonorSnap or your donor management system.'}
                  />
                </div>
                <div className={styles.twoControlContainer}>
                  <Typography
                      component="div"
                      variant="body1"
                      style={{
                        position: 'relative',
                      }}
                  >
                    <NumberFormat
                        name="itemgroupquantity"
                        customInput={TextField}
                        title={'The number of items available.'}
                        className={styles.textContainer}
                        label="Quantity"
                        defaultValue={itemGroupQuantity?.toString()}
                        value={itemGroupQuantity?.toString()}
                        required={true}
                        // disabled={watch('itemquantityunlimited')}
                        thousandSeparator={false}
                        prefix=""
                        onValueChange={handleQuantityChange}
                        rules={{
                          validate: (val) => ((val > 0 && val <= 1000 || watch('itemquantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                        }}
                        size={'small' as any}
                        variant="outlined"
                    />
                    {/*<FormControlLabel*/}
                    {/*    className={styles.formContainer}*/}
                    {/*    labelPlacement="start" control={*/}
                    {/*  <Switch*/}
                    {/*      name="itemquantityunlimited"*/}
                    {/*  />*/}
                    {/*} label="Unlimited" />*/}
                  </Typography>
                  <NumberFormat
                      name="itemmaxquantitypertransaction"
                      customInput={TextField}
                      title={'The number of items available.'}
                      className={styles.textContainer}
                      label="Max Quantity per Transaction"
                      defaultValue={maxQtyPerTransChange?.toString()}
                      value={maxQtyPerTransChange?.toString()}
                      thousandSeparator={false}
                      prefix=""
                      onValueChange={handleMaxQtyPerTransChange}
                      rules={{
                        validate: (val) => ((val > 0 && val <= 1000 || watch('itemquantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                      }}
                      size={'small' as any}
                      variant="outlined"
                  />
                </div>

                <div className={styles.twoControlContainer}>
                  <FormControlLabel control={<Checkbox name="itemlinktoregistration" checked={registationValue} onChange={handleRegistrationChange}/>} label="Link to Registration" />
                  <FormControlLabel control={<Checkbox name="itemonlygroupavailable" checked={onlyGroupAvailableValue} onChange={handleOnlyGroupAvailableChange}/>} label="Only available as part of a group" />
                </div>
                <div className={styles.twoControlContainer}>
                  <FormControlLabel control={<Checkbox name="itemstopselling" checked={stopSalesValue} onChange={handleStopSalesChange}/>} label="Stop Sales Now" />
                  <FormControlLabel control={<Checkbox name="active" checked={activeValue} onChange={handleActiveChange}/>} label="Active" />
                </div>
              </>
          }


          {activeTicketType?.name === 'Merchandise' &&
              <>
                <TextInput name="itemname" label="Item Name" required tooltipText={'The item name that will be used in the item listing and detail page.'} />
                <TextInput name="itemdescription" label="Display Description" required tooltipText={''} />
                <div className={styles.twoControlContainer}>
                  <MaskedInput
                      name="itemprice"
                      label="Price"
                      defaultValue="0"
                      required={true}
                      onValueChange={handlePriceChange}
                      tooltipText={''}
                  />
                  <MaskedInput
                      name="itemnondeductibleamount"
                      label="Non-Deductible Amount"
                      defaultValue="0"
                      onValueChange={handleNonDeductableAmountChange}
                      tooltipText={''}
                  />
                </div>
                <div className={styles.twoControlContainer}>
                  <SelectInput
                      name="donationtypeid"
                      label="Donation Type"
                      options={donationTypeOptions()}
                      includeNone={false}
                      tooltipText={'Used for the item’s donation record in DonorSnap or your donor management system.'}
                  />
                  <SelectInput
                      name="accountingcodeid"
                      label="Accounting Code"
                      options={accountingCodeOptions()}
                      includeNone={false}
                      tooltipText={'Used for the item’s accounting code in DonorSnap or your donor management system.'}
                  />
                </div>
                <div className={styles.twoControlContainer}>
                  <Typography
                      component="div"
                      variant="body1"
                      style={{
                        position: 'relative',
                      }}
                  >
                    <NumberFormat
                        name="itemquantity"
                        customInput={TextField}
                        title={'The number of items available.'}
                        className={styles.textContainer}
                        label="Quantity"
                        defaultValue={itemGroupQuantity?.toString()}
                        value={itemGroupQuantity?.toString()}
                        // disabled={watch('itemquantityunlimited')}
                        required={true}
                        thousandSeparator={false}
                        prefix=""
                        onValueChange={handleQuantityChange}
                        rules={{
                          validate: (val) => ((val > 0 && val <= 1000 || watch('itemquantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                        }}
                        size={'small' as any}
                        variant="outlined"
                    />
                    {/*<FormControlLabel*/}
                    {/*    className={styles.formContainer}*/}
                    {/*    labelPlacement="start" control={*/}
                    {/*  <Switch*/}
                    {/*      name="itemquantityunlimited"*/}
                    {/*  />*/}
                    {/*} label="Unlimited" />*/}
                  </Typography>
                  <NumberFormat
                      name="itemmaxquantitypertransaction"
                      customInput={TextField}
                      title={'The number of items available.'}
                      className={styles.textContainer}
                      label="Max Quantity per Transaction"
                      defaultValue={maxQtyPerTransChange?.toString()}
                      value={maxQtyPerTransChange?.toString()}
                      thousandSeparator={false}
                      prefix=""
                      onValueChange={handleMaxQtyPerTransChange}
                      rules={{
                        validate: (val) => ((val > 0 && val <= 1000 || watch('itemquantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                      }}
                      size={'small' as any}
                      variant="outlined"
                  />
                </div>
                <FormControlLabel control={<Checkbox name="active" checked={activeValue} onChange={handleActiveChange}/>} label="Active" />
              </>
          }


          {activeTicketType?.name === 'Group of Tickets' &&
              <>
                <SelectInput
                    name="itemitemid"
                    label="Linked Ticket Item"
                    options={linkedTicketTypeOptions()}
                    includeNone={false}
                    required={true}
                    // tooltipText={''}
                />
                <TextInput name="itemname" label="Item Name" required tooltipText={'The item name that will be used in the item listing and detail page.'} />
                <TextInput name="itemdescription" label="Display Description" tooltipText={''} />

                <div className={styles.twoControlContainer}>
                  <MaskedInput
                      name="itemprice"
                      label="Price"
                      defaultValue="0"
                      required={true}
                      onValueChange={handlePriceChange}
                      tooltipText={''}
                  />
                  <NumberFormat
                      name="itemgroupquantity"
                      customInput={TextField}
                      title={'The number of items available.'}
                      className={styles.textContainer}
                      label="Quantity of Tickets Included"
                      defaultValue={itemGroupQuantity?.toString()}
                      value={itemGroupQuantity?.toString()}
                      required={true}
                      thousandSeparator={false}
                      prefix=""
                      onValueChange={handleGroupQuantityChange}
                      rules={{
                        validate: (val) => ((val > 0 && val <= 1000 || watch('itemquantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                      }}
                      size={'small' as any}
                      variant="outlined"
                  />
                </div>
                <FormControlLabel control={<Checkbox name="active" checked={activeValue} onChange={handleActiveChange}/>} label="Active" />
              </>
          }
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="items-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
