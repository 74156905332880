import { useForm } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import SelectInput from '../../../components/common/inputs/SelectInput'
import TextInput from '../../../components/common/inputs/TextInput'
import { useEventState } from '../../../slices/eventSlice'
import styles from './ParticipantUDTitle.module.css'
import { useUpdateTitles } from './useUpdateUDTitles'
import React, {useEffect} from "react";

type FormData = {
  userdefinedtitle1: string
  userdefinedtitle2: string
  userdefinedtitle3: string
  userdefinedtitle4: string
  userdefinedtitle5: string
 
}
export const UDTitlesForm = ({ isOpen, setIsOpen, gridParams, titles }) => {
  const formMethods = useForm<FormData>()
  const { reset } = formMethods
  const eventState = useEventState()
  const updateMutation = useUpdateTitles()
  const [dialogMsg, setDialogMsg] = React.useState("")

  useEffect(() => {
     console.log(titles);
     console.log(titles.titles.userdefinedtitle1);
    if (titles) {
      reset({
        userdefinedtitle1: titles.titles.userdefinedtitle1,
        userdefinedtitle2: titles.titles.userdefinedtitle2,
        userdefinedtitle3: titles.titles.userdefinedtitle3,
        userdefinedtitle4: titles.titles.userdefinedtitle4,
        userdefinedtitle5: titles.titles.userdefinedtitle5
      })
    }
    else {
      reset({ userdefinedtitle1: 'User Defined 1', 
      userdefinedtitle2: 'User Defined 2',
      userdefinedtitle3: 'User Defined 3',
      userdefinedtitle4: 'User Defined 4',
      userdefinedtitle5: 'User Defined 5'
     })
    }
  }, [isOpen, titles, reset])

  const onSubmit = async (formData: FormData) => {

    try {
      await updateMutation.mutateAsync({
        userdefinedtitle1: formData.userdefinedtitle1,
        userdefinedtitle2: formData.userdefinedtitle2,
        userdefinedtitle3: formData.userdefinedtitle3,
        userdefinedtitle4: formData.userdefinedtitle4,
        userdefinedtitle5: formData.userdefinedtitle5,
        eventid: Number(eventState.eventId),
      },{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          if (json.status === "failure") {
            setDialogMsg(json.message)
          }
          else {
            setIsOpen(false)
          }
        }
      })
    } catch {}
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Rename User Defined Field</DialogTitle>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <DialogContent>
        <p>Renaming a User Defined Field will not clear any data previously stored in it. If it was already used for a different purpose, best practice is to use one that was previously not used.</p>
        <HookForm
          methods={formMethods}
          onSubmit={onSubmit}
          className={styles.udtitlesForm}
          formProps={{ id: 'ud-form' }}>
          <TextInput name="userdefinedtitle1" label="User Defined Field 1" required />
          <TextInput name="userdefinedtitle2" label="User Defined Field 2" required />
          <TextInput name="userdefinedtitle3" label="User Defined Field 3" required />
          <TextInput name="userdefinedtitle4" label="User Defined Field 4" required />
          <TextInput name="userdefinedtitle5" label="User Defined Field 5" required />
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="ud-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
