import React, {useEffect, useMemo, useState} from 'react'
import {useForm, useFormContext} from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, FormGroup, Grid, InputAdornment, TextField, Typography
} from '@material-ui/core'
import {Close, PhotoLibrary} from '@material-ui/icons'

import HookForm from '../../../components/common/HookForm'
import LoadingBackdrop from '../../../components/common/LoadingBackdrop'
import ChipsList from '../../../components/common/inputs/ChipsList'
import SelectInput from '../../../components/common/inputs/SelectInput'
import Switch from '../../../components/common/inputs/Switch'
// import Switch from '@mui/material/Switch';
import TextInput from '../../../components/common/inputs/TextInput'
import { useCreateEventItem } from '../../../mutations/useCreateEventItem'
import { useFetchLocationTags } from '../../../queries/events/useFetchLocationTags'
import { useFetchDonationTypes } from '../../../queries/useFetchDonationTypes'
import styles from './AuctionItemsTable.module.css'
import {useEventState} from "../../../slices/eventSlice";
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import {emailRegex} from "../../../util/formValidation";
import Dropzone from "react-dropzone";
import {FormControlLabel, ToggleButton, ToggleButtonGroup} from "@mui/material";
import NumberFormat from 'react-number-format'
import CustomMaskedInput from "../../../components/common/inputs/MaskedInput/CustomMaskedInput";
import imageCompression from "browser-image-compression";


interface FormData {
  itemid: number
  itemnumber: string
  name: string
  shortdesc: string
  longdesc: string
  location: number
  taglistids: [{
    tag: number
  }]
  allowautobid: boolean
  donatedby: string
  donatedbyemail: string
  donationtypeid: number
  accountingcodeid: number
  fairmarketvalue: number
  startingbid: number
  minimumraise: number
  buyitnowprice: number
  buyItNowValueOnlineAuction: number
  buyItNowChangeOnlineOnly: number
  quantityunlimited: boolean
  quantity: number
  maxpurchasequantity: number
  imageloaded: boolean
  logo: string
  logourl: string
  logoremoved: boolean
  pickupinstructions: string
  active: string
  toggleItemType: string
}

export const AuctionItemsForm = ({ isOpen, setIsOpen, gridParams, reloadForm }) => {
  const formMethods = useForm<FormData>()
  // const { control, formState } = useFormContext()
  const eventState = useEventState()
  const title = gridParams ? "Edit Auction Item" : "Create Auction Item"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [tags, setTags] = useState<{ value: any; label: string }[]>([])
  const { data: locationAndTags } = useFetchLocationTags(Number(eventState.eventId))
  // const { data: donationTypes } = useFetchDonationTypes()
  const [quantityValue, setQuantityValue] = useState<number>(1)
  const [fairMarketValue, setFairMarketValue] = useState<number>(0)
  const [startingBidValue, setStartingBidValue] = useState<number>(0)
  const [buyItNowValue, setbuyItNowValue] = useState<number>(null)
  const [buyItNowValueOnlineAuction, setbuyItNowValueOnlineAuction] = useState<number>(null)
  const [buyItNowValueOnlineOnly, setbuyItNowValueOnlineOnly] = useState<number>(null)
  const [minimumRaiseValue, setMinimumRaiseValue] = useState<number>(1)
  const [buyItNowActive, setbuyItNowActive] = useState<boolean>(true)
  const [dialogMsg, setDialogMsg] = React.useState("")
  const watchLogo = watch('logourl')
  const allowautobidWatch = watch('allowautobid')
  const watchLogoData = watch('logo')
  const minNumberRegex = /^([1-9][0-9]{0,2}|1000)$/
  const [toggleItemType, setToggleItemType] = React.useState("online-auction");
  const [allowAutoBidValue, setAllowAutoBidValue] = useState<boolean>()
  const qtyUnlimited = watch('quantityunlimited')

  useEffect(() => {
    if (qtyUnlimited) {
      setQuantityValue(0)
      setValue('quantity', 0)
    }
    else {
      setQuantityValue(1)
      setValue('quantity', 1)
    }
  }, [qtyUnlimited])

  useEffect(() => {
    if (allowautobidWatch) {
      setValue('quantity', 1)
    }
  }, [allowautobidWatch])

  const handleQuantityChange = (event) => {
    var maxQty = event.value
    if (maxQty > 25) {
      maxQty = 25
    }
    if (maxQty > 0) {
      setValue('maxpurchasequantity', maxQty)
    }

    setQuantityValue(event.value)
  }

  const handleFairmarketChange = (event) => {
    setFairMarketValue(event.floatValue)
  }

  const handleStartingBidChange = (event) => {
    setStartingBidValue(event.floatValue)
  }

  const handleBuyItNowChange = (event) => {

    if (event.floatValue <= 0 && (toggleItemType === 'buy-it-now-online' || toggleItemType === 'buy-it-now-in-person')) {
      setbuyItNowValue(event.floatValue)
      setError('buyitnowprice', { type: 'required', message: 'Buy it now price is required for Online Items.' })
      return
    }

    setbuyItNowValue(event.floatValue)
  }

  const handleBuyItNowChangeOnlineAuction = (event) => {
    if (event.floatValue <= 0 && (toggleItemType === 'buy-it-now-online' || toggleItemType === 'buy-it-now-in-person')) {
      setbuyItNowValueOnlineAuction(event.floatValue)
      setError('buyitnowprice', { type: 'required', message: 'Buy it now price is required for Online Items.' })
      return
    }
    setbuyItNowValueOnlineAuction(event.floatValue)
  }

  const handleBuyItNowChangeOnlineOnly = (event) => {
    if (event.floatValue <= 0 && (toggleItemType === 'buy-it-now-online' || toggleItemType === 'buy-it-now-in-person')) {
      setbuyItNowValueOnlineOnly(event.floatValue)
      setError('buyItNowChangeOnlineOnly', { type: 'required', message: 'Buy it now price is required for Online Items.' })
      return
    }

    setbuyItNowValueOnlineOnly(event.floatValue)
  }

  const handleMinimumRaiseChange = (event) => {
    setMinimumRaiseValue(event.floatValue)
  }

  useEffect(() => {
    let selectedTags = []
    gridParams?.taglistids.forEach(tagId => {
      const tagToAdd = locationAndTags?.tags?.find(tag => tag.id === tagId);

      if (tagToAdd && tagToAdd.active) {
        selectedTags.push({value: tagToAdd.id, label: tagToAdd.name})
      }
    })
    setTags(selectedTags)

    setFairMarketValue(gridParams ? gridParams.fairmarketvalue : 0)
    setStartingBidValue(gridParams ? gridParams.startingbid : 0)
    setMinimumRaiseValue(gridParams ? gridParams.minimumraise : 1)
    setbuyItNowValue(gridParams ? gridParams.buyitnowprice : null)
    setbuyItNowValueOnlineAuction(gridParams ? gridParams.buyitnowprice : null)
    setbuyItNowValueOnlineOnly(gridParams ? gridParams.buyitnowprice : null)

    setQuantityValue(gridParams?.quantity)
    setAllowAutoBidValue(gridParams ? gridParams.allowautobid : false)


    if (gridParams && gridParams.itemtype === 1) {
      setToggleItemType('online-auction')
    }
    else if (gridParams && gridParams.itemtype === 2) {
      setToggleItemType('live-auction')
    }
    else if (gridParams && gridParams.itemtype === 3) {
      setToggleItemType('buy-it-now-online')
    }
    else if (gridParams && gridParams.itemtype === 4) {
      setToggleItemType('buy-it-now-in-person')
    }

    reset({
      itemid: (gridParams ? gridParams.itemid : 0),
      itemnumber: (gridParams ? gridParams.itemnumber : ''),
      name: (gridParams ? gridParams.name : ''),
      shortdesc: (gridParams ? gridParams.shortdesc : ''),
      longdesc: (gridParams ? gridParams.longdesc : ''),
      location: (gridParams ? gridParams.locationid : 0),
      taglistids: (gridParams ? gridParams.taglistids : []),
      allowautobid: (gridParams ? gridParams.allowautobid : false),
      donatedby: (gridParams ? gridParams.donatedby : ''),
      donatedbyemail: (gridParams ? gridParams.donatedbyemail : ''),
      donationtypeid: ((gridParams && gridParams.donationtypeid) ? gridParams.donationtypeid : 0),
      accountingcodeid: ((gridParams && gridParams.accountingcodeid)  ? gridParams.accountingcodeid : 0),
      fairmarketvalue: (gridParams ? gridParams.fairmarketvalue : 0),
      startingbid: (gridParams ? gridParams.startingbid : 0),
      minimumraise: (gridParams ? gridParams.minimumraise : 1),
      buyitnowprice:(gridParams && gridParams.buyitnowprice > 0 ? gridParams.buyitnowprice : null),
      buyItNowValueOnlineAuction: (gridParams && gridParams.buyitnowprice > 0 ? gridParams.buyitnowprice : null),
      buyItNowChangeOnlineOnly:(gridParams && gridParams.buyitnowprice > 0 ? gridParams.buyitnowprice : null),
      quantityunlimited: (gridParams ? gridParams.quantityunlimited : false),
      quantity: (gridParams ? gridParams.quantity : 1),
      maxpurchasequantity: (gridParams ? gridParams.maxpurchasequantity : 1),
      imageloaded: (gridParams ? gridParams.imageloaded : false),
      logo: (gridParams ? gridParams.logo : ''),
      logourl: (gridParams ? gridParams.logourl : ''),
      logoremoved: false,
      pickupinstructions: (gridParams ? gridParams.pickupinstructions : ''),
      active: (gridParams ? (gridParams.active ? 'Active' : 'Inactive') : 'Active'),
    })
  }, [reloadForm, locationAndTags])
  
  const tagOptions = useMemo(
    () =>
      locationAndTags?.tags?.filter((e) => e.active === true).map((tag) => {
          return { value: tag.id, label: tag.name }
      }),
    [locationAndTags]
  )

  const locations = locationAndTags ? locationAndTags : { locations:[] }

  const locationOptions = useMemo(
      () =>
          locations.locations?.filter((e) => e.active === true).map((location) => {
            return { value: location.id, label: location.name }
          }),
      [locationAndTags]
  )

  const donationnTypes = locationAndTags ? locationAndTags : { donationtype:[] }
  const donationTypeOptions = () => {
    const dtRows = donationnTypes.donationtype?.filter((e) => e.active === true).map((types) => {
      return {value: types.id, label: types.name}
    })
    if (dtRows && dtRows.length > 0) {
      return [{value: 0, label: 'None'}, ...dtRows]
    }
    else {
      return [{value: 0, label: 'None'}]
    }
  }

  const accountingTypes = locationAndTags ? locationAndTags : { accountingcode:[] }
  const accountingCodeOptions = () => {
    const dtRows = accountingTypes.accountingcode?.filter((e) => e.active === true).map((types) => {
      return {value: types.id, label: types.name}
    })
    if (dtRows && dtRows.length > 0) {
      return [{value: 0, label: 'None'}, ...dtRows]
    }
    else {
      return [{value: 0, label: 'None'}]
    }
  }


  const createMutation = useCreateEventItem()

  const onSubmit = async (data: FormData) => {
    if (toggleItemType === 'online-auction' && data.location === 0 ) {
      setError('location', { type: 'required', message: 'Location is required' })
      return
    }

    if (buyItNowValue <= 0 && toggleItemType === 'buy-it-now-in-person') {
      setError('buyitnowprice', { type: 'required', message: 'Buy it now price is required for Online Items.' })
      return
    }

    if (buyItNowValueOnlineOnly <= 0 && toggleItemType === 'buy-it-now-online') {
      setError('buyItNowChangeOnlineOnly', { type: 'required', message: 'Buy it now price is required for Online Items.' })
      return
    }

    const tagsToSave = tags.map(tag => {
      return tag.value
    })
    const locs = locationAndTags?.locations?.filter(item => {
      return data.location === item.id
    });

    var finalData = gridParams ? { ...data, eventid: Number(eventState.eventId) } : { ...data, eventid: Number(eventState.eventId) }

    var localItemType = 1
    var buyItNowVal = buyItNowValue
    if (toggleItemType === 'online-auction') {
      localItemType = 1
      buyItNowVal = buyItNowValueOnlineAuction
    }
    else if (toggleItemType === 'live-auction') {
      localItemType = 2
    }
    else if (toggleItemType === 'buy-it-now-online') {
      localItemType = 3
      buyItNowVal = buyItNowValueOnlineOnly
    }
    else if (toggleItemType === 'buy-it-now-in-person') {
      localItemType = 4
    }

    const { data: response } = await createMutation.mutateAsync(    {
      eventid: Number(eventState.eventId),
      itemid: Number(finalData.itemid),
      itemnumber: finalData.itemnumber,
      name: finalData.name,
      shortdesc: finalData.shortdesc,
      longdesc: finalData.longdesc,
      locationid: (locs && locs.length > 0 ? locs[0].id : 0),
      taglistids: tagsToSave,
      allowautobid: finalData.allowautobid,
      donatedby: finalData.donatedby,
      donatedbyemail: finalData.donatedbyemail,
      donationtypeid: (finalData.donationtypeid && finalData.donationtypeid.toString().length > 0 && finalData.donationtypeid.toString() !== 'none' ? finalData.donationtypeid : 0),
      accountingcodeid: (finalData.accountingcodeid && finalData.accountingcodeid.toString().length > 0 && finalData.accountingcodeid.toString() !== 'none' ? finalData.accountingcodeid : 0),
      fairmarketvalue: fairMarketValue,
      startingbid: startingBidValue,
      minimumraise: minimumRaiseValue,
      buyitnowprice: buyItNowVal,
      quantityunlimited: finalData.quantityunlimited,
      quantity: Number(quantityValue),
      maxpurchasequantity: Number(finalData.maxpurchasequantity),
      imageloaded: finalData.imageloaded,
      logo: watchLogoData,
      logourl: "",
      logoremoved: finalData.logoremoved,
      pickupinstructions: finalData.pickupinstructions,
      active: finalData.active === 'Active',
      itemtype: localItemType
    })

    const parsedResponse = JSON.parse(response?.jsonString)
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse.message)
    }
    else {
      setIsOpen(false)
    }
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  const onLogoDrop = async (files: File[]) => {
    const options = {
      maxSize: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    const compressedFile = await imageCompression(files[0], options)

    const fileReader = new FileReader()
    fileReader.onload = function (event) {
      const data = event.target.result as string
      setValue('logo', data.split(';base64,')[1])
    }

    fileReader.readAsDataURL(compressedFile)
  }

  const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const clickRemoveLogo = () => {
    setValue("logourl", "")
    setValue('logoremoved', true)
  }

  const handleTypeChange = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string
  ) => {
    if (newAlignment !== null) {
      const loc : any = getValues('location')
      if (newAlignment === 'online-auction' && loc === 'none') {
        setError('location', { type: 'required', message: 'Location is required' })
      }
      else if (newAlignment == 'live-auction') {
        setQuantityValue(1)
      }

      setToggleItemType(newAlignment)
    }
  }

  const handleAllowAutoBidChange = (event) => {
    // localStorage.setItem('activeOnly', event.target.checked)
    // if (event.target.checked) {
    //   setEvents(data.filter((e) => e.active === true))
    // } else {
    //   setEvents(data)
    // }
  }
  // const { errors } = formState

  return (
    <Dialog open={isOpen} maxWidth="xl" className={styles.dialog}>
      <DialogTitle>{title}</DialogTitle>
      <LoadingBackdrop open={createMutation?.isLoading} />
      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={onSubmit}
          className={styles.itemsForm}
          formProps={{ id: 'items-form' }}>
          <TextInput name="itemnumber" label="Item Number" required tooltipText={'Unique number or code to identify an item. Can contain numbers or letters.'}/>
          <TextInput name="name" label="Name" required tooltipText={'The item name that will be used in the item listing and detail page.'} />
          <TextInput
            name="shortdesc"
            label="Short Description"
            textFieldProps={{ multiline: true, rows: 3, rowsMax: 10 }}
            tooltipText={'The description of an item that is visible on the item detail page.'}
          />
          <TextInput
            name="longdesc"
            label="Description"
            textFieldProps={{ multiline: true, rows: 8, rowsMax: 20 }}
            tooltipText={'The larger description section when more space is needed that is initially hidden until clicked.'}
          />
          <TextInput
              name="pickupinstructions"
              label="Pickup Instructions"
              textFieldProps={{ multiline: true, rows: 2, rowsMax: 10 }}
              tooltipText={'Tell buyers what they need to do to receive this item.'}
          />
          <SelectInput
            name="donationtypeid"
            label="Donation Type"
            options={donationTypeOptions()}
            includeNone={false}
            tooltipText={'Used for the item’s donation record in DonorSnap or your donor management system.'}
          />
          <SelectInput
              name="accountingcodeid"
              label="Accounting Code"
              options={accountingCodeOptions()}
              includeNone={false}
              tooltipText={'Used for the item’s accounting code in DonorSnap or your donor management system.'}
          />
          <ChipsList
            list={tags}
            setList={setTags}
            options={tagOptions}
            label="Enter a Tag"
            tooltipText={'Categorize your items so that users can filter on similar items.'}
          />
          <SelectInput
              name="active"
              label="Status"
              options={[
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' }
              ]}
              includeNone={false}
              required
              tooltipText={'Active items will be displayed in the auction app. Inactive items will be hidden.'}
          />
          <ToggleButtonGroup
              color="primary"
              value={toggleItemType}
              exclusive
              onChange={handleTypeChange}
              aria-label="Platform"
          >
            <ToggleButton classes={{ selected: '#980e0e' }} value="online-auction" title={'This item will have bidding enabled in the auction app.'}>Online Auction</ToggleButton>
            <ToggleButton  value="live-auction" title={'This item will be visible in the auction app, but online bidding is disabled. Bidding will take place in-person only.'}>Live Auction Only</ToggleButton>
            <ToggleButton  value="buy-it-now-online" title={'This item will be available for purchase in the auction app at a set price. Bidding is not allowed.'}>Buy it now only (Online)</ToggleButton>
            <ToggleButton value="buy-it-now-in-person" title={'This item will not be visible in the auction app. It will be available for purchase through the admin checkout system only.'}>Buy it now only (IN-PERSON ONLY)</ToggleButton>
          </ToggleButtonGroup>


          {(toggleItemType === 'online-auction' && allowautobidWatch) &&
              <>
              {/*</div>*/}
                <div className={styles.singleContainer}>
                  <FormControlLabel labelPlacement="start" control={
                    <Switch
                        name="allowautobid"
                        // onChange={handleAllowAutoBidChange}
                        // defaultChecked={allowAutoBidValue}
                    />
                  } label="Allow Auto Bid:" title={'Option to set a max bid that will automatically place bids on behalf of a bidder up to that amount.'}/>
                </div>
                <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="quantity"
                      label="Quantity"
                      defaultValue="1"
                      disabled={true}
                      thousandSeparator={false}
                      thousandsGroupStyle={""}
                      prefix=""
                      onValueChange={handleQuantityChange}
                      rules={{
                        validate: (val) => ((val > 0 && val <= 1000 || watch('quantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                      }}
                      tooltipText={'The number of items available.'}
                  />
                  <MaskedInput
                      name="startingbid"
                      label="Starting Bid"
                      defaultValue="0"
                      onValueChange={handleStartingBidChange}
                      tooltipText={'Start bidding at a higher specified amount.'}
                  />
                </div>
                <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="minimumraise"
                      label="Default Bid Increment"
                      defaultValue="1"
                      onValueChange={handleMinimumRaiseChange}
                      tooltipText={'The amount used to determine the next bid amount.'}
                  />
                  <MaskedInput
                      name="fairmarketvalue"
                      label="Fair Market Value"
                      defaultValue="0"
                      onValueChange={handleFairmarketChange}
                      tooltipText={'Optional. Assign a value to the item for calculating tax benefits.'}
                  />
                </div>
                <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="buyItNowValueOnlineAuction"
                      label="Buy It Now Price (optional)"
                      // title={'The amount a user can pay to win the item immediately.'}
                      defaultValue={null}
                      required={false}
                      placeholder={"Add price to enable"}
                      onValueChange={handleBuyItNowChangeOnlineAuction}
                      tooltipText={'The amount a user can pay to win the item immediately.'}
                  />
                    {/*<NumberFormat*/}
                    {/*    name="buyitnowprice"*/}
                    {/*    customInput={TextField}*/}
                    {/*    title={'The amount a user can pay to win the item immediately.'}*/}
                    {/*    classes={{ root: styles.root }}*/}
                    {/*    className={styles.buyitnowtextContainer}*/}
                    {/*    label={(buyItNowValueOnlineAuction == undefined || buyItNowValueOnlineAuction == 0) ? "Add price to enable 1" : "Buy It Now Price"}*/}
                    {/*    defaultValue={null}*/}
                    {/*    placeholder={"Buy It Now Price (optional)"}*/}
                    {/*    thousandSeparator={true}*/}
                    {/*    thousandGroupStyle={'thousand'}*/}
                    {/*    allowNegative={false}*/}
                    {/*    value={buyItNowValueOnlineAuction > 0 ? buyItNowValueOnlineAuction : null}*/}
                    {/*    prefix="$"*/}
                    {/*    onValueChange={handleBuyItNowChangeOnlineAuction}*/}
                    {/*    size={'small' as any}*/}
                    {/*    variant="outlined"*/}
                    {/*    // InputProps={{*/}
                    {/*    //   classes: {*/}
                    {/*    //     root: styles.input,*/}
                    {/*    //     error: styles.error,*/}
                    {/*    //     focused: styles.focus*/}
                    {/*    //   },*/}
                    {/*    //   startAdornment: <InputAdornment position="start">{''}</InputAdornment>,*/}
                    {/*    //   endAdornment: <InputAdornment position="end">{''}</InputAdornment>*/}
                    {/*    // }}*/}
                    {/*    // InputLabelProps={{*/}
                    {/*    //   classes: {*/}
                    {/*    //     root: styles.label,*/}
                    {/*    //     error: styles.error,*/}
                    {/*    //     focused: styles.focus*/}
                    {/*    //   },*/}
                    {/*    //   shrink: true*/}
                    {/*    // }}*/}
                    {/*    // startAdornment={''}*/}
                    {/*    // endAdornment={''}*/}
                    {/*/>*/}
                  <TextInput name="donatedby" label="Donated By" tooltipText={'Display who donated this item on the item detail page.'}/>
                </div>
                <SelectInput
                    name="location"
                    label="Location"
                    required={true}
                    includeNone={false}
                    options={locationOptions}
                    rules={{
                      validate: (val) => (val?.length === 0 || val === 'none' || val === 0) ?  "Location is required." : clearErrors('location'),
                    }}
                    tooltipText={'The table number or other type of location grouping for the item.'}
                />
              </>
            }
          {(toggleItemType === 'online-auction' && !allowautobidWatch) &&
              <>
                <div className={styles.singleContainer}>
                  <FormControlLabel labelPlacement="start" control={
                    <Switch
                      name="allowautobid"
                      // onChange={handleAllowAutoBidChange}
                      // defaultChecked={allowAutoBidValue}
                    />} label="Allow Auto Bid:" title={'Option to set a max bid that will automatically place bids on behalf of a bidder up to that amount.'}/>
                </div>
                <div className={styles.buyItNowContainer}>
                  <Typography
                      component="div"
                      variant="body1"
                      style={{
                        position: 'relative',
                      }}
                  >
                    <CustomMaskedInput
                        name="quantity"
                        label="Quantity"
                        tooltipText={'The number of items available.'}
                        defaultValue={quantityValue?.toString()}
                        disabled={watch('quantityunlimited')}
                        thousandSeparator={false}
                        thousandsGroupStyle={""}
                        prefix=""
                        onValueChange={handleQuantityChange}
                        rules={{
                          validate: (val) => ((val > 0 && val <= 1000)) || 'Please enter a number between 1 and 1000.',
                        }}

                    />
                    {/*<FormControlLabel*/}
                    {/*    className={styles.formContainer}*/}
                    {/*    labelPlacement="start" control={*/}
                    {/*  <Switch*/}
                    {/*      name="quantityunlimited"*/}
                    {/*  />*/}
                    {/*} label="Unlimited" />*/}
                  </Typography>
                  <MaskedInput
                      name="startingbid"
                      label="Starting Bid"
                      tooltipText={'Start bidding at a higher specified amount.'}
                      defaultValue="0"
                      onValueChange={handleStartingBidChange}
                  />
                </div>

                <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="buyItNowValueOnlineAuction"
                      label="Buy It Now Price (optional)"
                      defaultValue={null}
                      required={false}
                      placeholder={"Add price to enable"}
                      onValueChange={handleBuyItNowChangeOnlineAuction}
                      tooltipText={'The amount a user can pay to win the item immediately.'}
                  />
                  <MaskedInput
                      name="maxpurchasequantity"
                      label="Max Quantity Per Purchase"
                      tooltipText={'The number of items displayed in the quantity dropdown list while using the Buy It Now option.'}
                      defaultValue="1"
                      thousandSeparator={false}
                      thousandsGroupStyle={""}
                      prefix=""
                      rules={{
                        validate: (val) => (val > 0 && val <= 1000) || 'Please enter a number between 1 and 1000.',
                      }}
                  />
                </div>
                <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="minimumraise"
                      tooltipText={'The amount used to determine the next bid amount.'}
                      label="Default Bid Increment"
                      defaultValue="1"
                      onValueChange={handleMinimumRaiseChange}
                  />
                  <MaskedInput
                      name="fairmarketvalue"
                      label="Fair Market Value"
                      tooltipText={'Optional. Assign a value to the item for calculating tax benefits.'}
                      defaultValue="0"
                      onValueChange={handleFairmarketChange}
                  />
                </div>
                <div className={styles.buyItNowContainer}>
                  <TextInput name="donatedby" label="Donated By" tooltipText={'Display who donated this item on the item detail page'}/>
                  <SelectInput
                      name="location"
                      label="Location"
                      tooltipText={'The table number or other type of location grouping for the item.'}
                      required={true}
                      includeNone={false}
                      options={locationOptions}
                      rules={{
                        validate: (val) => (val?.length === 0 || val === 'none') ?  "Location is required." : true,
                      }}
                  />
                </div>
              </>
          }
          {toggleItemType === 'live-auction' &&
              <>
                <div className={styles.buyItNowContainer}>
                  {/*<FormControlLabel labelPlacement="start" control={<Switch name="allowautobid" disabled={quantityValue > 1} />} label="Allow Auto Bid:" />*/}
                  <CustomMaskedInput
                      name="quantity"
                      label="Quantity"
                      tooltipText={'The number of items available.'}
                      defaultValue={quantityValue?.toString()}
                      disabled={true}
                      // disabled={watch('quantityunlimited')}
                      thousandSeparator={false}
                      thousandsGroupStyle={""}
                      prefix=""
                      onValueChange={handleQuantityChange}
                      rules={{
                        validate: (val) => ((val > 0 && val <= 1000)) || 'Please enter a number between 1 and 1000.',
                      }}
                  />
                  <MaskedInput
                      name="startingbid"
                      label="Starting Bid"
                      tooltipText={'Start bidding at a higher specified amount.'}
                      defaultValue="0"
                      onValueChange={handleStartingBidChange}
                  />
                </div>
                <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="fairmarketvalue"
                      label="Fair Market Value"
                      tooltipText={'Optional. Assign a value to the item for calculating tax benefits.'}
                      defaultValue="0"
                      onValueChange={handleFairmarketChange}
                  />
                  <TextInput name="donatedby" label="Donated By" tooltipText={'Display who donated this item on the item detail page'}/>
                </div>
                {/*<SelectInput*/}
                {/*    name="location"*/}
                {/*    label="Location"*/}
                {/*    tooltipText={'The table number or other type of location grouping for the item.'}*/}
                {/*    required={false}*/}
                {/*    options={locationOptions}*/}
                {/*/>*/}
              </>
          }

          {toggleItemType === 'buy-it-now-online' &&
              <>
              <div className={styles.buyItNowContainer}>
                <Typography
                    component="div"
                    variant="body1"
                    style={{
                      position: 'relative',
                    }}
                >
                  <NumberFormat
                      name="quantity"
                      customInput={TextField}
                      title={'The number of items available.'}
                      className={styles.textContainer}
                      label="Quantity"
                      defaultValue={quantityValue?.toString()}
                      value={quantityValue?.toString()}
                      disabled={watch('quantityunlimited')}
                      thousandSeparator={false}
                      prefix=""
                      onValueChange={handleQuantityChange}
                      rules={{
                        validate: (val) => ((val > 0 && val <= 1000 || watch('quantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                      }}
                      size={'small' as any}
                      variant="outlined"
                  />
                  <FormControlLabel
                      className={styles.formContainer}
                      labelPlacement="start" control={
                    <Switch
                        name="quantityunlimited"
                    />
                  } label="Unlimited" />
                </Typography>
                <MaskedInput
                    name="buyItNowChangeOnlineOnly"
                    label="Buy It Now Price"
                    defaultValue={null}
                    required={true}
                    placeholder={""}
                    rules={{
                      validate: (val) => (val?.length === 0 || val === 'none' || val === 0) ?  "Buy it now price is required for Online Items." : clearErrors('buyItNowChangeOnlineOnly'),
                    }}
                    onValueChange={handleBuyItNowChangeOnlineOnly}
                    tooltipText={'The amount a user can pay to win the item immediately.'}
                />
              </div>
              <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="maxpurchasequantity"
                      label="Max Quantity Per Purchase"
                      tooltipText={'The number of items displayed in the quantity dropdown list while using the Buy It Now option.'}
                      defaultValue="1"
                      thousandSeparator={false}
                      thousandsGroupStyle={""}
                      prefix=""
                      rules={{
                        validate: (val) => (val > 0 && val <= 1000) || 'Please enter a number between 1 and 1000.',
                      }}
                  />
                  <MaskedInput
                    name="fairmarketvalue"
                    label="Fair Market Value"
                    tooltipText={'Optional. Assign a value to the item for calculating tax benefits.'}
                    defaultValue="0"
                    onValueChange={handleFairmarketChange}
                  />
              </div>
              <div className={styles.buyItNowContainer}>
                <TextInput name="donatedby" label="Donated By" tooltipText={'Display who donated this item on the item detail page'}/>
                <SelectInput
                  name="location"
                  label="Location"
                  tooltipText={'The table number or other type of location grouping for the item.'}
                  required={false}
                  options={locationOptions}
                />
              </div>
              </>
          }
          {toggleItemType === 'buy-it-now-in-person' &&
              <>
                <div className={styles.buyItNowContainer}>
                  <Typography
                      component="div"
                      variant="body1"
                      style={{
                        position: 'relative',
                      }}
                  >
                    <NumberFormat
                        name="quantity"
                        customInput={TextField}
                        className={styles.textContainer}
                        title={'The number of items available.'}
                        label="Quantity"
                        defaultValue={quantityValue?.toString()}
                        disabled={watch('quantityunlimited')}
                        value={quantityValue?.toString()}
                        thousandSeparator={false}
                        prefix=""
                        onValueChange={handleQuantityChange}
                        rules={{
                          validate: (val) => ((val > 0 && val <= 1000 || watch('quantityunlimited'))) || 'Please enter a number between 1 and 1000.',
                        }}
                        size={'small' as any}
                        variant="outlined"
                    />
                    <FormControlLabel
                        className={styles.formContainer}
                        labelPlacement="start" control={
                      <Switch
                          name="quantityunlimited"
                      />
                    } label="Unlimited" />
                  </Typography>
                  <MaskedInput
                      name="buyitnowprice"
                      label="Buy It Now Price"
                      defaultValue={null}
                      required={true}
                      placeholder={""}
                      tooltipText={'The amount a user can pay to win the item immediately.'}
                      onValueChange={handleBuyItNowChange}
                      rules={{
                        validate: (val) => (val?.length === 0 || val === 'none' || val === 0) ?  "Buy it now price is required for Online Items." : clearErrors('buyitnowprice'),
                      }}
                  />
                </div>
                <div className={styles.buyItNowContainer}>
                  <MaskedInput
                      name="maxpurchasequantity"
                      label="Max Quantity Per Purchase"
                      tooltipText={'The number of items displayed in the quantity dropdown list while using the Buy It Now option.'}
                      defaultValue="1"
                      thousandSeparator={false}
                      thousandsGroupStyle={""}
                      prefix=""
                      rules={{
                        validate: (val) => (val > 0 && val <= 1000) || 'Please enter a number between 1 and 1000.',
                      }}
                  />
                  <MaskedInput
                      name="fairmarketvalue"
                      label="Fair Market Value"
                      tooltipText={'Optional. Assign a value to the item for calculating tax benefits.'}
                      defaultValue="0"
                      onValueChange={handleFairmarketChange}
                  />
                </div>
                <div className={styles.buyItNowContainer}>
                  <TextInput name="donatedby" label="Donated By" tooltipText={'Display who donated this item on the item detail page'}/>
                  {/*<SelectInput*/}
                  {/*    name="location"*/}
                  {/*    label="Location"*/}
                  {/*    tooltipText={'The table number or other type of location grouping for the item.'}*/}
                  {/*    required={false}*/}
                  {/*    options={locationOptions}*/}
                  {/*/>*/}
                </div>
              </>
          }

          <Grid container spacing={1}>
            {watchLogo && (
                <Grid item xs={6} className={styles.selectcontrol}>
                  <div> <Close fontSize="small" onClick={clickRemoveLogo} /></div>
                  <div className={styles.logoWrapper}>
                    <img src={watchLogo} alt="sponsor logo" />
                  </div>
                </Grid>
            )}

            <Grid item xs={6} className={styles.selectcontrol}>
              {watchLogoData && (
                  <>
                    <div>New Item Image</div>
                    <div className={styles.logoWrapper}>
                      <img src={`data:image/png;base64,${watchLogoData}`} alt="event logo" />
                    </div>
                  </>
              )}
            </Grid>
          </Grid>
          <Dropzone onDrop={onLogoDrop}>
            {({ getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
                acceptedFiles,
                fileRejections
            }) => (
                <section>
                  <h5>Upload Image</h5>
                  <div {...getRootProps()} className={isDragActive ? (isDragAccept ? styles.dropzoneselect : styles.dropzoneinvalid) : styles.dropzone}>
                    <input {...getInputProps()} />
                    {isDragAccept && (<h4>File is a valid image type. Go ahead and drop.</h4>)}
                    {!isDragActive && (<h4>Drag 'n' drop a files here, or click to select a file</h4>)}
                    {isDragReject && (<h4>File cannot be dropped. It's an invalid type.</h4>)}
                    <h5>Item Images will be displayed with a max height of 450px. Only .jpg, .jpeg, .png, .gif, and .bmp images under 5MB are allowed.</h5>
                  </div>
                </section>
            )}
          </Dropzone>
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="items-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
