import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useLocationTags = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.put(`locationtags`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['locationtags'])
        }
    })
}
