import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  FormLabel
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import Switch from '../../../components/common/inputs/Switch'
import TextInput from '../../../components/common/inputs/TextInput'
import { useEventState } from '../../../slices/eventSlice'
import { Participant, udTitles } from '../useFetchParticipants'
import styles from './ParticipantsForm.module.css'
import { useCreateParticipant } from './useCreateParticipant'
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import {emailRegex, phoneRegex, zipCodeRegex} from "../../../util/formValidation";

type FormData = {
  bidnumber: number
  first: string
  last: string
  email: string
  phone: string
  authorizedbidder: boolean
  address: string
  address2: string
  city: string
  state: string
  zip: string
  source: string
  userdefined1: string
  userdefined2: string
  userdefined3: string
  userdefined4: string
  userdefined5: string
  paddlenumbertext: string
}

type Props = {
  isOpen: boolean
  setIsOpen: Function
  particpant?: Participant
  bidnumber?: number
  reloadForm?: number
  nextBidNumber?: number
  udTitles?: udTitles
}
const UserForm = ({ isOpen, setIsOpen, particpant, reloadForm, nextBidNumber, udTitles }: Props) => {
  const isEdit = Boolean(particpant)
  const formMethods = useForm<FormData>()
  const { reset, setValue } = formMethods
  const eventState = useEventState()
  const createMutation = useCreateParticipant()
  const editMutation = useCreateParticipant()
  const queryClient = useQueryClient()
  const [dialogMsg, setDialogMsg] = React.useState("")
  useEffect(() => {
      reset({
        bidnumber: (particpant ? particpant.bidnumber : nextBidNumber),
        first: (particpant ? particpant.first : ''),
        last: (particpant ? particpant.last : ''),
        email: (particpant ? particpant.email : ''),
        phone: (particpant ? particpant.phone : ''),
        authorizedbidder: (particpant ? particpant.authorizedbidder : true),
        address: (particpant ? particpant.address : ''),
        address2: (particpant ? particpant.address2 : ''),
        city: (particpant ? particpant.city : ''),
        state: (particpant ? particpant.state : ''),
        zip: (particpant ? particpant.zip : ''),
        source: 'manual',
        userdefined1: (particpant ? particpant.userdefined1 : ''),
        userdefined2: (particpant ? particpant.userdefined2 : ''),
        userdefined3: (particpant ? particpant.userdefined3 : ''),
        userdefined4: (particpant ? particpant.userdefined4 : ''),
        userdefined5: (particpant ? particpant.userdefined5 : ''),
        paddlenumbertext: (particpant ? particpant.paddlenumbertext : '')
      })

  }, [reloadForm])

  const onCreateSubmit = async (formData: FormData) => {
    try {
      await createMutation.mutateAsync({
        eventid: Number(eventState.eventId),
        source: 'manual',
        bidnumber: formData.bidnumber,
        first: formData.first,
        last: formData.last,
        email: formData.email,
        phone: formData.phone,
        authorizedbidder: formData.authorizedbidder,
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        userdefined1: formData.userdefined1,
        userdefined2: formData.userdefined2,
        userdefined3: formData.userdefined3,
        userdefined4: formData.userdefined4,
        userdefined5: formData.userdefined5,
        paddlenumbertext: formData.paddlenumbertext,
      },{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          if (json.status === "failure") {
            setDialogMsg(json.message)
          }
          else {
            setIsOpen(false)
            queryClient.invalidateQueries(['participant'])
          }
        }
      })
    } catch {}
  }
  const onEditSubmit = async (formData: FormData) => {
    try {
      await editMutation.mutateAsync({
        participantid: particpant.participantid,
        eventid: Number(eventState.eventId),
        source: 'manual',
        bidnumber: formData.bidnumber,
        first: formData.first,
        last: formData.last,
        email: formData.email,
        phone: formData.phone,
        authorizedbidder: formData.authorizedbidder,
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        userdefined1: formData.userdefined1,
        userdefined2: formData.userdefined2,
        userdefined3: formData.userdefined3,
        userdefined4: formData.userdefined4,
        userdefined5: formData.userdefined5,
        paddlenumbertext: formData.paddlenumbertext
      },{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          if (json.status === "failure") {
            setDialogMsg(json.message)
          }
          else {
            setIsOpen(false)
            queryClient.invalidateQueries(['participant'])
          }
        }
      })
    } catch {}
  }

  const closeDialog = () => {
    setDialogMsg('')
  }

  const handleZip = (event) => {
    setValue("zip", event.toString())
  }

  const handlePhone = (event) => {
    setValue("phone", event.toString())
  }

  return (
    <Dialog
        fullWidth={true}
        open={isOpen}>
      <DialogTitle>{isEdit ? 'Edit' : 'Create'} Participant</DialogTitle>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={isEdit ? onEditSubmit : onCreateSubmit}
          className={styles.form}
          formProps={{ id: 'participant-form' }}>
          {isEdit && (
            <TextInput
              name="bidnumber"
              label="Bidder Number"
              textFieldProps={{ disabled: true }}
            />
          )}
          <TextInput name="first" label="First Name" required disabled={particpant? particpant.addressreadonly: false} />
          <TextInput name="last" label="Last Name" required disabled={particpant? particpant.addressreadonly: false} />
          <TextInput
              name="email"
              label="Email"
              disabled={particpant?.lastlogindate && particpant?.lastlogindate.length > 0}
              rules={{
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email address.'
                }
              }}
          />
          <MaskedInput
              name="phone"
              label="Phone"
              // defaultValue={isEdit ? particpant.phone : ""}
              onValueChange={handlePhone}
              thousandSeparator={false}
              thousandsGroupStyle={""}
              prefix={""}
              format={"(###) ###-####"}
              mask={"_"}
              rules={{
                pattern: {
                  value: phoneRegex,
                  message: 'Please enter a valid phone number.'
                }
              }}
          />
          <div>
            <FormLabel>Authorized Bidder: </FormLabel>
            <Switch name="authorizedbidder" defaultValue={true}/>
          </div>
          <TextInput name="paddlenumbertext" label="Paddle Number" />
          <TextInput name="address" label="Address 1" disabled = {particpant? particpant.addressreadonly: false}/>
          <TextInput name="address2" label="Address 2" disabled = {particpant? particpant.addressreadonly: false}/>
          <TextInput name="city" label="City" disabled = {particpant? particpant.addressreadonly: false}/>
          <TextInput name="state" label="State" disabled = {particpant? particpant.addressreadonly: false}/>
          <MaskedInput
              disabled = {particpant? particpant.addressreadonly: false}
              name="zip"
              label="Zip"
              defaultValue=""
              onValueChange={handleZip}
              thousandSeparator={false}
              thousandsGroupStyle={""}
              prefix={""}
              format={"#####"}
              mask={"_"}
              rules={{
                pattern: {
                  value: zipCodeRegex,
                  message: 'Please enter a valid zip code.'
                }
              }}
          />
      <TextInput name="userdefined1" label={udTitles? udTitles.userdefinedtitle1 : ""} />
      <TextInput name="userdefined2" label={udTitles? udTitles.userdefinedtitle2: ""} />
      <TextInput name="userdefined3" label={udTitles? udTitles.userdefinedtitle3 : ""} />
      <TextInput name="userdefined4" label={udTitles? udTitles.userdefinedtitle4:""} />
      <TextInput name="userdefined5" label={udTitles? udTitles.userdefinedtitle5:""} />

        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="participant-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserForm
