import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, IconButton, TextField, Typography
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import styles from './EventTransactionsTable.module.css'
import axios from "axios";
import {EventValueForm} from "./EventValueForm";
import {
  FormControl,
  FormControlLabel, Grid,
  InputLabel,
} from "@mui/material";
import {useEventState} from "../../slices/eventSlice";
import styled from "@emotion/styled";
import {formatMoney} from "../../util/formatHelpers";

interface FormData {
  id: number
}

export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;


export const EventAddCheckoutForm = ({ isOpen, setIsOpen, gridParams, reloadForm, eventid, transactionId, setTransactionId, handleNext, itemQuantity, setItemQuantity }) => {
  const formMethods = useForm<FormData>()
  const eventState = useEventState()
  const title = gridParams ? "Add Items for Checkout" : "Add Items for Checkout"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [loading, setLoading] = React.useState(false);
  const [isValueOpen, setIsValueOpen] = useState<boolean>(false)
  const [activeId, setActiveId] = useState<number>(1)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  // const [itemQuantity, setItemQuantity] = React.useState({})
  const [checkoutData, setCheckoutData] = React.useState([])
  const [controlDropdownValues, setControlDropdownValues] = useState([])
  const [itemPrice, setItemPrice] = React.useState({})
  const [quantityModal, setQuantityModal] = React.useState({active: false, quantity: 0, item: ""})

  useEffect(() => {
    getCheckoutData()
  }, [isOpen])

  const getCheckoutData = async () => {
    console.log('getting checkout data')
    setLoading(true)
    const { data } = await axios.post('/evtactivitytransactionssetup', {eventid: eventid})
    const parsedData = JSON.parse(data.jsonString)
    if (transactionId <= 0) {
      setTransactionId(parsedData.transactionid)
    }
    setCheckoutData(parsedData?.sections)
    setLoading(false)
  }

  const onSubmit = async (data: FormData) => {
    console.log(data)
  }

  const closeDialog = () => {
    setDialogObj({msg: '', id: 0})
    setDialogMsg("")
    setQuantityModal({active: false, quantity: 0, item: null})
  }

  const quantityUpdate = async (value, item) => {
    console.log('quantityUpdate')
      const quantity = {...itemQuantity}
      quantity[item.id] = Number(value)
      setItemQuantity(quantity)

      var prices = {...itemPrice}
      prices[item.id] =  '$' + item.price * value
      setItemPrice(prices)
  }
  const quantityBlur = async (value, item) => {
    console.log(item)
    const body = {quantity: Number(value), itemid: Number(item.id), eventid: eventid, transactionid: transactionId}

    const data = await axios.post('/evtactivitytransactionsadditem', body, {responseType: "json"}).then(response => {
      const parsedData = JSON.parse(response.data.jsonString)
      console.log(parsedData)

      // if (parsedData.status === 'expired') {
      //   localStorage.removeItem("token")
      //   history.push(`/${eventcode}/event`);
      // }
      if (parsedData.status === 'failure') {
        const quantity = {...itemQuantity}
        quantity[parsedData.itemid] = Number(parsedData.quantityremaining)
        setItemQuantity(quantity)
        setQuantityModal({active: true, quantity: parsedData.quantityremaining, item: parsedData.itemname})
      }
    })
  }

  const calcItemPrice = (item) => {
    var quantityValue = itemQuantity[item.id]
    if (item.maxquantitypertransaction > quantityValue) {
      quantityValue = item.maxquantitypertransaction
    }
    var priceUnformatted = 0
    if (itemPrice && itemPrice[item.id]) {
      priceUnformatted = Number(itemPrice[item.id].split("$").pop())
    }
    const total = formatMoney(priceUnformatted)
    const price = formatMoney(item.price)

    var totalPrice = (item.id in itemPrice) ? `X ${price} = ${total}` : `X ${price} = $0`

    return totalPrice
  }

  useEffect(() => {
    const quantity = {...itemQuantity}
    const prices = {...itemPrice}
    checkoutData.map((item, index) => {
        item.data.map(itemdata => {
          if (itemdata && itemdata.id > 0) {
            quantity[itemdata.id] = Number(itemdata.quantity)
            prices[itemdata.id] =  '$' + itemdata.price * Number(itemdata.quantity)
          }
      })
    })
    setItemQuantity(quantity)
    setItemPrice(prices)
  }, [checkoutData])

  return (
    <>
      <Dialog open={isOpen} maxWidth="xl" fullWidth={true} className={styles.dialog}>
        <DialogTitle>{title}</DialogTitle>
        {/*<LoadingBackdrop open={createMutation.isLoading || loading} />*/}
        <>
          <Dialog
              open={quantityModal.active}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <h1>{"Insuffcient Quantity Available"}</h1>
              <h4>{"We're sorry, but the quantity available is lower than you seleced:"}</h4>

              <Grid container spacing={1}>
                <Grid item xs={6} className={styles.selectcontrol}>
                  {quantityModal.item + ':'}
                </Grid>
                <Grid item xs={6} className={styles.selectcontrol}>
                  {quantityModal.quantity + ' Remaining'}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <>
          <Dialog
              open={dialogMsg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <EventValueForm isValueOpen={isValueOpen} setIsValueOpen={setIsValueOpen} activeId={activeId} controlDropdownValues={controlDropdownValues} setControlDropdownValues={setControlDropdownValues} />
        <DialogContent>
          <HookForm
              methods={formMethods}
              onSubmit={onSubmit}
              className={styles.itemsForm}
              formProps={{ id: 'items-form' }}>

              {checkoutData.map((section) => (
                  <>
                    <Grid container xs={12} spacing={1} columns={2}>
                      <Typography style={{display: 'flex', alignItems: 'center'}} variant="h6">{section.sectionname}</Typography>
                      {/*<InputLabel>{section.sectionname}</InputLabel>*/}
                    </Grid>
                    {section?.data.map((sectiondata) => (
                        <>
                        <Grid container xs={12} spacing={0}>
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={styles.selectcontrol} >
                            <InputLabel style={{display: 'flex', alignItems: 'center'}}>{sectiondata.name}</InputLabel>
                          </Grid>
                          <Grid item xs={1} className={styles.selectcontrol}>
                            <Input
                                type="number"
                                id=""
                                min={0}
                                placeholder=""
                                name=""
                                onChange={(e) => {quantityUpdate(e.target.value, sectiondata)}}
                                onBlur={(e) => {quantityBlur(e.target.value, sectiondata)}}
                                value={itemQuantity[sectiondata.id]}
                                defaultValue={itemQuantity[sectiondata.id] > 0 ? itemQuantity[sectiondata.id] : 0}
                            />
                          </Grid>
                          <Grid item xs={8} sm={6} md={4} lg={2} xl={2} className={styles.selectcontrol}>
                            {(!sectiondata?.isquantityunlimited && sectiondata?.quantityremaining <= 0) &&
                                <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >SOLD OUT</InputLabel>
                            }
                            {(sectiondata?.isquantityunlimited || sectiondata?.quantityremaining > 0) &&
                                <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{calcItemPrice(sectiondata)}</InputLabel>
                            }
                          </Grid>
                        </Grid>
                        </>
                    ))}
                  </>
               ))}
          </HookForm>
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setTransactionId(0)
                setCheckoutData([])
                setIsOpen(false)
              }}>
            Cancel
          </Button>
          <Button
              variant="contained"
              className={styles.submitButton}
              type="submit"
              form="items-form"
              onClick={handleNext}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
