import React, {useEffect, useState} from 'react'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from '@mui/material'

import { ConfirmModal } from '../../components/common/ConfirmModal'
import CheckoutDetails from './CheckoutDetails'
import CheckoutForm from './CheckoutForm'
import CheckoutOrdersGrid from './CheckoutOrdersGrid'
import styles from './CheckoutPage.module.css'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {useEventState} from "../../slices/eventSlice";
import {CheckoutData, CheckoutObject} from "../../queries/useFetchCheckout";

export enum CheckoutPageTab {
  Checkout,
  ResendReceipt,
  PDF,
  Void,
  None
}

interface Params {
    eventid: string
}

const CheckoutPage = () => {
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const eventState = useEventState()
    const [tab, setTab] = useState(CheckoutPageTab.None)
    const [showPayment, setShowPayment] = useState(true)

    const [selectedUser, setSelectedUser] =
        useState<{ id: number; status: 'Open' | 'CheckedOut' }>(null)
    const [creditCardInfo, setCreditCardInfo] =
        useState<{ showccfees: boolean,
            ccfeespercentage: number,
            creditcardiframe: string,
            javascriptfile: string,
            transcenterid: string,
            processorid: string, transactiontype: string
    }>({
            showccfees: false,
            ccfeespercentage: 0,
            creditcardiframe: "https://secure.1stpaygateway.net/secure/PaymentHostedForm/v3/CreditCard",
            javascriptfile: "https://secure.1stpaygateway.net/secure/PaymentHostedForm/Scripts/firstpay/firstpay.cryptogram.js",
            processorid: "70802",
            transactiontype: "Sale",
            transcenterid: "92754"
    })

    const [checkoutDataObject, setCheckoutDataObject] = useState<CheckoutData>(null)
    const onResendEmail = () => {
        //Send email here
        setTab(CheckoutPageTab.None)
    }

  const onVoid = () => {
    //Void order here
    setTab(CheckoutPageTab.None)
  }

    const [cryptogram, setCryptogram] = useState('');

    useEffect(() => {
        window.addEventListener("message", processPayment, false)
        return () => {
            window.removeEventListener("message", processPayment)
        }
    }, [])

    const processPayment = (e: any) => {
        if (e.data.firstpay) {
            const ccData = e.data
            if (ccData.type === 'newCryptogram') {
                setCryptogram(ccData.cryptogram)
            }
        }
    }

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        <CheckoutOrdersGrid checkoutDataObject={checkoutDataObject} setCheckoutDataObject={setCheckoutDataObject} setCreditCardInfo={setCreditCardInfo} />
      </div>
      <ConfirmModal
        text="Are you sure you want to resend the receipt via email?"
        isOpen={tab === CheckoutPageTab.ResendReceipt}
        onClose={() => setTab(CheckoutPageTab.None)}
        onConfirm={() => onResendEmail()}
        confirmText="Resend Receipt"
      />
      <ConfirmModal
        text="Are you sure you want to void the transaction?"
        isOpen={tab === CheckoutPageTab.Void}
        onClose={() => setTab(CheckoutPageTab.None)}
        onConfirm={() => onVoid()}
        confirmText="Void Transaction"
      />
      <CheckoutForm checkoutDataObject={checkoutDataObject} creditCardInfo={creditCardInfo} setShowPayment={setShowPayment} />

      {/*{checkoutDataObject?.isreadonly && <CheckoutDetails />}*/}
      <div className={styles.tabs}>
        {selectedUser?.status === 'CheckedOut' && (
          <>
            <Button
              variant="contained"
              onClick={() => setTab(CheckoutPageTab.ResendReceipt)}>
              Resend Receipt
            </Button>
            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  onClick={() => setTab(CheckoutPageTab.PDF)}>*/}
            {/*  PDF*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  onClick={() => setTab(CheckoutPageTab.Void)}>*/}
            {/*  Void*/}
            {/*</Button>*/}
          </>
        )}
      </div>
    </div>
  )
}

export default CheckoutPage
