import { useSelector } from 'react-redux'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AppState } from '../App/store'

export interface EventState {
  eventId: string
  eventName: string
  eventCode?: string
  eventStart?: string
  eventEnd?: string
  bidStart?: string
  bidEnd?: string
  allowpaymentoffees?: boolean
  ccfee?: number
  eventUrl?: string
  auctionUrl?: string
}

const initialState: EventState = {
  eventId: '',
  eventName: '',
  eventCode: '',
  eventStart: '',
  eventEnd: '',
  bidStart: '',
  bidEnd: '',
  allowpaymentoffees: false,
  ccfee: 0,
  eventUrl: '',
  auctionUrl: ''
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, { payload }: PayloadAction<EventState>) => {
      state.eventId = payload.eventId
      state.eventName = payload.eventName
      state.eventCode = (payload.eventCode ? payload.eventCode : state.eventCode)
      state.eventStart = (payload.eventStart ? payload.eventStart : state.eventStart)
      state.eventEnd = (payload.eventEnd ? payload.eventEnd : state.eventEnd)
      state.bidStart = (payload.bidStart ? payload.bidStart : state.bidStart)
      state.bidEnd = (payload.bidEnd ? payload.bidEnd : state.bidEnd)
      state.allowpaymentoffees = payload.allowpaymentoffees
      state.ccfee = payload.ccfee
    },
    setAuctionURL: (state, { payload }: PayloadAction<string>) => {
      state.auctionUrl = payload
    },
    setEventURL: (state, { payload }: PayloadAction<string>) => {
      state.eventUrl = payload
    },
  }
})

/** ACTION EXPORTS */
export const {
    setEvent,
    setEventURL,
    setAuctionURL
} = eventSlice.actions

/** REDUCER EXPORT */
export default eventSlice.reducer

/** STATE HOOK EXPORTS - hooks for components to get state */
export const useEventState = () => useSelector((state: AppState) => state.event)
