import axios from 'axios'
import { useQuery } from 'react-query'

export type CheckoutDetail = {
    rownumber: number
    receiptnumber: number
    biddernumber: number
    biddername: string
    itemnumber: string
    itemname: string
    quantity: number
    amount: number
}
 const checkoutDetailDefault = {
    rownumber: 0,
    receiptnumber: 0,
    biddernumber: 0,
    biddername: "",
    itemnumber: "",
    itemname: "",
    quantity: 0,
    amount: 0
}
export type CheckoutHeader = {
    rownumber: number
    receiptnumber: number
    biddernumber: number
    biddername: string
    checkoutdate: string
    subtotal: number
    additionaldonation: number
    ccfees: number
    totalamount: number
    source: string
    paymentmethod: string,
    details: CheckoutDetail[]
}

const checkoutHeaderDefault = {
    rownumber: 0,
    receiptnumber: 0,
    biddernumber: 0,
    biddername: "",
    checkoutdate: "",
    subtotal: 0,
    additionaldonation: 0,
    ccfees: 0,
    totalamount: 0,
    source: "",
    paymentmethod: "",
    details : [checkoutDetailDefault]
}



export const globalAuctionReportFetcher = async (body) => {
    const { data } = await axios.post('/reportcheckout', body)
    const parsedData = JSON.parse(data.jsonString)
    const headers = parsedData.checkoutheaderlist as CheckoutHeader[]
    const details = parsedData.checkoutdetaillist as CheckoutDetail[]
    if(details)
    {
        details.forEach( detail => {
            var header = headers.find(header=> header.biddernumber === detail.biddernumber && header.receiptnumber === detail.receiptnumber) 
            if(header.details){
                header.details.push(detail)
            }else{
                header.details = [detail]
            }
        }
            )
    }
    return headers
}

export const useFetchReportCheckout = (eventiDvalue) => {
    return useQuery<CheckoutHeader[]>(
        ['checkoutheader', eventiDvalue],
        () => globalAuctionReportFetcher({ "eventid": eventiDvalue }),
        {
            staleTime: 120000,
            placeholderData: [checkoutHeaderDefault]        }
    )
}

export const globalAuctionReportDetailsFetcher = async (body) => {
    const { data } = await axios.post('/reportcheckout', body)
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    const details = parsedData.checkoutdetaillist as CheckoutDetail[]
   
    return details
}

export const useFetchReportCheckoutDetails = (eventiDvalue) => {
    return useQuery<CheckoutDetail[]>(
        ['checkoutdetails', eventiDvalue],
        () => globalAuctionReportDetailsFetcher({ "eventid": eventiDvalue }),
        {
            staleTime: 120000,
            placeholderData: [checkoutDetailDefault]
        }
    )
}
