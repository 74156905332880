import axios from 'axios'
import { useQuery } from 'react-query'

export interface Participant {
  participantid: number
  authorizedbidder: boolean
  first: string
  last: string
  company: string
  address: string
  address2: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  email: string
  source: string
  bidnumber: number
  active: boolean
  lastlogindate: string
  numberofbids: number
  numberwinningbids: number
  addressreadonly: boolean
  userdefined1:string
  userdefined2: string
  userdefined3: string
  userdefined4: string
  userdefined5: string
  paddlenumbertext: string
}

export interface udTitles {
  userdefinedtitle1: string
  userdefinedtitle2: string
  userdefinedtitle3: string
  userdefinedtitle4: string
  userdefinedtitle5: string
}

export interface ParticipantsWithTitles {
  participants: Participant[]
  udTitles: udTitles
}

export const fetchers = async (eventid) => {
  const { data } = await axios.post('/participant', {
    eventid: Number(eventid)
  })
  const parsedData = JSON.parse(data.jsonString)

  return parsedData?.participants || []
}

export const useFetchParticpants = (eventid) => {
  return useQuery<Participant[]>(
    ['participant', eventid],
    () => fetchers(eventid),
    {}
  )
}

export const fetchwitUDTitles = async (eventid) => {
  const { data } = await axios.post('/participant', {
    eventid: Number(eventid)
  })
  const parsedData = JSON.parse(data.jsonString)
   let  udti : ParticipantsWithTitles = {
    participants: parsedData?.participants || [],
    udTitles: {
    userdefinedtitle1: parsedData?.userdefinedtitle1 || "",
    userdefinedtitle2: parsedData?.userdefinedtitle2 || "",
    userdefinedtitle3: parsedData?.userdefinedtitle3 || "",
    userdefinedtitle4: parsedData?.userdefinedtitle4 || "",
    userdefinedtitle5: parsedData?.userdefinedtitle5 || ""}}
    
  
  return udti
}

export const useFetchParticpantswithUDTitles = (eventid) => {
  return useQuery<ParticipantsWithTitles>(
    ['participant', eventid],
    () => fetchwitUDTitles(eventid),
    {}
  )
}
