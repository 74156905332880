import React, {useEffect, useState} from 'react'
import { IconButton } from '@material-ui/core'
import { Add, AddShoppingCart, AttachMoneyOutlined, Delete, Edit } from '@material-ui/icons'
import styles from './ManualBidEntryPage.module.css'
import {BidForm} from "./BidForm";
import {ManualBidData, ManualBidDataObject, useFetchManualBid} from "../../queries/useManualBid";
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {formatMoney} from "../../util/formatHelpers";
import {DataGridPro, GridValueFormatterParams} from "@mui/x-data-grid-pro";
import {DataGridPremium, GridCellParams} from "@mui/x-data-grid-premium";

interface Params {
  eventid: string
}

const ManualBidEntryPage = () => {
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)
  const [isBidFormShowing, setBidIsFormShowing] = useState(false)
  const [buyItNow, setBuyItNow] = useState(false)

  const { data: ManualBidDataObject } = useFetchManualBid(Number(eventid))
  const [dataRows, setDataRows] = React.useState([]);
  const [bidderList, setBidderList] = useState([])
  console.log(ManualBidDataObject)

  useEffect(() => {
    if (ManualBidDataObject?.bidderlist && ManualBidDataObject?.bidderlist?.length > 0) {
      setBidderList(ManualBidDataObject.bidderlist)
    }

    if (ManualBidDataObject?.manualbiditemlist && ManualBidDataObject?.manualbiditemlist?.length > 0) {
      const dataRows = getMappedRecords()
      setDataRows(dataRows)
    }
    else {
      setDataRows([])
    }
  }, [ManualBidDataObject])

  const getMappedRecords = () => {
    const dataReturn = ManualBidDataObject?.manualbiditemlist.map((manualList: ManualBidData, i) => {
      return {
        id: manualList.itemid,
        itemid: manualList.itemid,
        itemnumber: manualList.itemnumber,
        name: manualList.name,
        tags: manualList.tags,
        itemtype: manualList.itemtype,
        numberofbids: manualList.numberofbids,
        startingbid: manualList.startingbid,
        bidincrement: manualList.bidincrement,
        lastbid: manualList.lastbid,
        open: manualList.open,
        closing: manualList.closing,
        opencd: manualList.opencd,
        closingcd: manualList.closingcd,
        buyitnowavailable: manualList.buyitnowavailable,
        buyitnowonly: manualList.buyitnowonly,
        allowautobid: manualList.allowautobid,
        buyitnowprice: manualList.buyitnowprice,
        bidstatus: manualList.bidstatus,
        quantityavailable: manualList.quantityavailable,
        location: manualList.location,
        unlimitedquantity: manualList.unlimitedquantity,
        maxpurchasequantity: manualList.maxpurchasequantity,
        biddername: manualList.biddername,
        biddernumber: manualList.biddernumber,
        biddersource: manualList.biddersource,
        imageurl: manualList.imageurl,

      }
    })
    return dataReturn
  }


  const [gridParams, setGridParams] = useState({})

  const getRecord = (id: number) => {
    const returnRows = dataRows.filter((v, i) => v.id === id)
    if (returnRows && returnRows.length > 0) {
      return returnRows[0]
    }
    else {
      return {}
    }
  }

  const handleBid = (params: GridCellParams, buyItNowParam: boolean) => {
    setBuyItNow(buyItNowParam)
    setGridParams({...params.row, buyitnow: buyItNowParam})
    setBidIsFormShowing(true)
  }

  const columns= [
    {
      field: 'Edit',
      renderHeader: () => {
        return (<></>
          // <IconButton
          //   className={styles.submitButton}
          //   onClick={() => setIsFormShowing(true)}
          //   size="small">
          //   <Add />
          // </IconButton>
        )
      },
      width: 120,
      sortable: false,
      renderCell: (params) => (
          <>
            {!params.row.buyitnowonly && (params.row.unlimitedquantity || params.row.quantityavailable > 0) &&
                <IconButton title={'Place bid: Add a bid on behalf of a bidder.'} className={styles.button} onClick={() => { handleBid(params, false) }}>
                  <AttachMoneyOutlined />
                </IconButton>
            }
            {params.row.buyitnowavailable && (params.row.unlimitedquantity || params.row.quantityavailable > 0) &&
                <IconButton title={'Buy It Now: Item will be added to the participant’s cart and ready for checkout.'} className={styles.button} onClick={() => {
                  handleBid(params, true)
                }}>
                  <AddShoppingCart/>
                </IconButton>
            }
          </>
      )
    },
    {
      field: 'itemnumber',
      type: 'number',
      headerName: 'Item #',
      width: 150
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      width: 200
    },
    {
      field: 'location',
      type: 'string',
      headerName: 'Location',
      width: 200
    },
    {
      field: 'itemtype',
      type: 'string',
      headerName: 'Item Type',
      width: 200
    },
    {
      field: 'quantityavailable',
      type: 'string',
      headerName: 'Quantity Available',
      width: 200,
      valueFormatter: (params) => {
        const rec = getRecord(params.id)
        return (rec?.unlimitedquantity ? 'Unlimited' : (params.value === 0 ? 'Sold Out' : params.value))
      },
    },
    {
      field: 'lastbid',
      type: 'string',
      headerName: 'Current Bid',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
      },
    },
    {
      field: 'buyitnowprice',
      type: 'number',
      headerName: 'Buy It Now',
      width: 225,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
      },
    },
    {
      field: 'biddername',
      type: 'string',
      headerName: 'Bidder Name',
      width: 200
    }
  ]

  return (
    <div className={styles.root}>
      <h2>Manual Bid Entry</h2>
      <BidForm isOpen={isBidFormShowing} setIsOpen={setBidIsFormShowing} gridParams={gridParams} bidderList={bidderList} buyItNow={buyItNow} />

      <DataGridPremium
        autoHeight
        density={'compact'}
        rows={dataRows}
        hideFooterSelectedRowCount={true}
        columns={columns}
        loading={false}
        pagination={false as any}
      />
    </div>
  )
}

export default ManualBidEntryPage
