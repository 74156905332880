import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export const globalEventDiscountCodesFetcher = async (body) => {
    const { data } = await axios.post('/evtdiscountcodes', body)
    const parsedData = JSON.parse(data.jsonString)
    return parsedData
}

export const useFetchDiscountCodes = (eventIDvalue) => {
    return useQuery(
        ['evtdiscountcodes', eventIDvalue],
        () => globalEventDiscountCodesFetcher({ "eventid": eventIDvalue }),
    )
}

export const useCreateEventDiscountCode = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined'])
        }
    })
}

export const useUpdateDiscountCodes = (eventIDvalue) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['EventActivityDiscountCodes', eventIDvalue])
        }
    })
}