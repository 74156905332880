import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export const globalTextMessageFetcher = async (eventIdvalue) => {
    const { data } = await axios.post('/broadcasttextmessage', { eventid: eventIdvalue })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.broadcastlist as []
}

export const useUpdateBroadcastTextMessage = (body) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.put(`/broadcasttextmessage`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['textmessage', body.eventid])
        }
    })
}

export const useFetchTextMessages = (eventid) => {
    return useQuery<[]>(
        ['textmessage', eventid],
        () => globalTextMessageFetcher(eventid),
        {}
    )
}