import * as React from 'react';
import { useDroppable } from '@progress/kendo-react-common';

import { ListItem } from './ListItem';
import { List, ListProps } from './List';
import {DataItem, DragContext, DropContext} from "./EventGroupMaintenance";
export interface DroppableListProps extends ListProps {
    data: DataItem;
    onDrop?: Function;
}

export const DroppableList = (props: DroppableListProps) => {
    const list = React.useRef<HTMLUListElement>(null);
    const dropInstance = React.useRef<{element: React.RefObject<HTMLElement | null>, data: DataItem}>(null);
    React.useImperativeHandle(dropInstance, () => ({ element: list, data: props.data }));

    const [drag] = React.useContext(DragContext);
    const [drop, setDrop] = React.useContext(DropContext);

    const handleDragEnter = () => {
        if(!props.children || !React.Children.count(props.children)) {
            setDrop(dropInstance.current);
        }
    }

    const handleDragLeave = () => {
        if(!props.children || !React.Children.count(props.children)) {
            setDrop(null);
        }
    }

    const handleDrop = () => {
        if(props.onDrop) {
            props.onDrop();
        }
    }

    useDroppable(list, {
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop
    })

    return (
        <List ref={list} {...props}>
            {props.children && React.Children.count(props.children)
                ? props.children
                : (drag && drop && drop.data.ticketid === props.data.ticketid) && <ListItem style={{opacity: '0.7'}}>{drag.data.ticketname}</ListItem>
            }
        </List>)
}