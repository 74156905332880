import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'

export const ConfirmModal = ({
  isOpen,
  text,
  onClose,
  onConfirm,
  confirmText = 'Confirm',
  title = 'Confirm'
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ background: 'var(--success)', color: 'white' }}
            onClick={onConfirm}>
            {confirmText}
          </Button>
        </DialogActions>
      </DialogActions>
    </Dialog>
  )
}
