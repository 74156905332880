import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'

import { Button } from '@material-ui/core'

import { PagePathTypes } from '../../../App'
import Alerts from '../../../components/common/Alerts'
import HookForm from '../../../components/common/HookForm'
import LoadingBackdrop from '../../../components/common/LoadingBackdrop'
import TextInput from '../../../components/common/inputs/TextInput'
import { emailRegex } from '../../../util/formValidation'
import { AuthPageSubPaths } from '../AuthPage'
import styles from './LoginForm.module.css'
import { useLoginMutation } from './useLoginMutation'
import {useQueryClient} from "react-query";

type FormData = {
  email: string
  password: string
  siteCode: string
}

const LoginForm = () => {
  const queryClient = useQueryClient()
  const formMethods = useForm<FormData>({
    mode: 'onChange'
  })

  const loginMutation = useLoginMutation()

  const onSubmit = ({ email, password, siteCode }: FormData) => {
    queryClient.invalidateQueries(['events'])
    loginMutation.mutate({ password, email, customerid: siteCode })
  }

  return (
    <div className={styles.root}>
      <h2>Login</h2>
      <HookForm
        methods={formMethods}
        onSubmit={onSubmit}
        className={styles.form}>
        <LoadingBackdrop open={loginMutation.isLoading} />

        <TextInput name="siteCode" label="Site Code" required />
        <TextInput
          name="email"
          label="Email"
          required
          rules={{
            pattern: {
              value: emailRegex,
              message: 'Enter a valid email'
            }
          }}
        />
        <TextInput
          name="password"
          label="Password"
          rules={{
            required: 'Field is required'
            //   minLength: {
            //     value: 6,
            //     message: 'Must be at least 6 characters long'
            //   }
          }}
          required
          textFieldProps={{ type: 'password' }}
        />
        <Button type="submit" variant="contained" className={styles.button}>
          Login
        </Button>

        <Alerts errorMsg={(loginMutation.error as any)?.message} />
      </HookForm>
      <Link to={`${PagePathTypes.auth}${AuthPageSubPaths.forgotPassword}`}>
        Forgot Password?
      </Link>
    </div>
  )
}

export default LoginForm
