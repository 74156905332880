import React, {useEffect, useState} from 'react'
import styles from './EventGroupMaintenance.module.css'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {DragAndDrop} from '@progress/kendo-react-common';
import axios from "axios";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import {
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    List,
    ListItem, TextField,
    Typography
} from "@material-ui/core";
import {
    Card,
    CardHeader,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Button from "@mui/material/Button";
import {DroppableList} from "./DroppableList";
import {DraggableListItem} from "./DropableListItem";
import {Delete, Edit, Note} from "@material-ui/icons";
import {GridCellParams} from "@mui/x-data-grid-premium";

interface Params {
  eventid: string
}
//
// export interface DataItem {
//     groupid: number
//     groupname: string
//     guests: [
//         {
//             ticketid: number | string
//             ticketname?: string
//         }
//     ]
// }

export interface DataItem {
    ticketid: number | string
    ticketname?: string
    groupid?: number | string
}


export const DragContext = React.createContext<[null | {element: React.RefObject<HTMLElement | null>, data: DataItem}, Function]>([null, () => {/* noop */}]);
export const DropContext = React.createContext<[null | {element: React.RefObject<HTMLElement | null>, data: DataItem}, Function]>([null, () => {/* noop */}]);
export const DirectionContext = React.createContext<["before" | "after", Function]>(["before", () => {/* noop */}]);

const inRange = (min, max, index) => {
    return Math.max(min, Math.min(max, index));
}
const EventGroupMaintenance = () => {
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)

    const dropTimeout = React.useRef<number>();

    const [data, setData] = React.useState<DataItem[]>();
    const [drag, setDrag] = React.useState<{element: React.RefObject<HTMLElement | null>, data: DataItem} | null>(null);
    const [drop, setDrop] = React.useState<{element: React.RefObject<HTMLElement | null>, data: DataItem} | null>(null);
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [groupName, setGroupName] = React.useState({name: "", groupid: 0})
    const [newGroupName, setNewGroupName] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [reloadForRename, setreloadForRename] = React.useState(false)
    const [ticketItem, setTicketItem] = useState("-1")
    const [groupData, setGroupData] = React.useState({
        ticketgroups: [],
        ticketitemlist: [],
        selectedticketitemid: 0,
    })

    useEffect(() => {
        getGroups()
    }, [])

    useEffect(() => {
        setTicketItem(groupData.selectedticketitemid.toString())
    }, [groupData])

    useEffect(() => {
        setreloadForRename(false)
        getGroups()
    }, [ticketItem, reloadForRename === true])

    const handleSelectionChange = async (event: SelectChangeEvent) => {
        setTicketItem(event.target.value as string);
    }

    const getGroups = async () => {
        var body = {eventid: eventid}

        if (Number(ticketItem) > 0) {
            body['ticketitemid'] = Number(ticketItem)
        }

        setLoading(true)
        const { data } = await axios.post('/evtactivitygroups', body)
        const parsedData = JSON.parse(data.jsonString)
        console.log(parsedData)
        setGroupData(parsedData)
        // parsedData.
        // setData(newData)

        setData(parsedData.ticketgroups)

        setLoading(false)
    }

    const getTicketItemOptions = () => {
        return groupData?.ticketitemlist?.map(item => (
            <MenuItem key={item.ticketitemid} value={item.ticketitemid}>
                {item.ticketitemname}
            </MenuItem>
        ))
    }

    const [direction, setDirection] = React.useState<"before" | "after">("before");

    const handleDropChange = React.useCallback(
        (newDrop: {element: React.RefObject<HTMLElement | null>, data: DataItem} | null) => {
            if(!newDrop) {
                dropTimeout.current = window.setTimeout(() => { setDrop(newDrop) }, 50)
            } else {
                clearTimeout(dropTimeout.current);
                setDrop(newDrop);
            }
        },
        []
    );

    const groupFromTicketIndex = (data, ticketid) => {
        var newgroup = null
        data.forEach(group => {
            if (group.guests.find(ticket => ticket.ticketid === ticketid)) {
                newgroup = group
            }
        })
        return newgroup
    }

    const handleItemDrop = React.useCallback(
        async () => {
            if(!drag || !drop) { return; }
            const body = {
                eventid: eventid,
                ticketitemid: ticketItem,
                groupid: drop.data.groupid,
                ticketlist: [
                    {ticketid: drag.data.ticketid}
                ]
            }

            await axios.put('/evtactivitygroups', body)
            setreloadForRename(true)
        },
        [data, direction, drag, drop]
    );

    const handleListDrop = React.useCallback(
        () => {
            if(!drag || !drop) { return; }
            console.log('handleListDrop')
            // console.log(data)
            // const newData = data.slice();
            // const oldIndex = newData.findIndex((d) => d.ticketid === drag.data.ticketid);
            // const dataItem = newData[oldIndex];
            // console.log(dataItem)
            //
            // if(dataItem) {
            //     newData.splice(newData.length - 1, 0, newData.splice(oldIndex, 1)[0]);
            //     setData(newData);
            // }
        },
        [data, drag, drop]
    )

    const handleEditGroup = (group) => {
        setNewGroupName(group.groupname)
        setGroupName({
            name: group.groupname,
            groupid: Number(group.groupid)
        })
    }

    const addNewGroup = () => {
        setNewGroupName('New Group Name')
        setGroupName({
            name: 'New Group Name',
            groupid: Number(-1)
        })
    }

    const saveGroupName = async () => {
        var finaldata = {
            "eventid": Number(eventid),
            "groupname": newGroupName,
            "groupid": groupName.groupid,
            "ticketitemid": Number(ticketItem),
        }

        const { data } = await axios.put('/evtactivitygroupname', finaldata)
        const json = JSON.parse(data.jsonString)
        if (json.status === "failure") {
            // setNewGroupName('')
            setGroupName({
                name: '',
                groupid: 0
            })
            setDialogMsg(json.message)
        }
        else {
            setNewGroupName('')
            setGroupName({
                name: '',
                groupid: 0
            })
            setreloadForRename(true)
        }
    }

    const handleDeleteGroup = (group) => {
        setDialogObj({
            msg: 'Are you sure you want to delete \"' + group.groupname + "\"?",
            id: Number(group.groupid)
        })
    }

    const deleteDialog = async () => {
        await handleDeleteConfirm(dialogObj.id)
    }

    const handleDeleteConfirm = async (groupid: number) => {
        var finaldata = {
            "eventid": Number(eventid),
            "groupid": groupid,
            "ticketitemid": Number(ticketItem),
            "delete": true
        }

        const { data } = await axios.delete('/evtactivitygroupname', {data: finaldata})
        const json = JSON.parse(data.jsonString)
        if (json.status === "failure") {
            setDialogObj({
                msg: '',
                id: 0
            })
            setDialogMsg(json.message)
        }
        else {
            setDialogObj({
                msg: '',
                id: 0
            })
            setreloadForRename(true)
        }
    }

    const closeDialog = () => {
        setGroupName({
            name: '',
            groupid: 0
        })
        setDialogMsg('')
        setDialogObj({
            msg: '',
            id: 0
        })
    }

    return (
        <div className={styles.root}>
            <Dialog
                open={dialogObj.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObj.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={deleteDialog}>DELETE</Button>
                </DialogActions>
            </Dialog>

            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>


            <>
                <Dialog
                    open={groupName.name.length > 0}
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                id="start"
                                name="start"
                                style={{marginTop: '10px', width: '100%'}}
                                label="Group Name:"
                                value={newGroupName}
                                onChange={(e) => {setNewGroupName(e.target.value)}}
                                type="string"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={saveGroupName}>Save</Button>
                    </DialogActions>
                </Dialog>
            </>

            <LoadingBackdrop open={loading} />
            <h1>Group Maintenance</h1>
            <Grid
                container
                direction="row"
                justifyContent="flex-begin"
                alignItems="center"
                // border={2}
                spacing={2}
                style={{marginBottom: '20px'}}
                xs={12} sm={12} md={12}
            >
                <Grid item xs={4}>
                    <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} >
                        <Select
                            onChange={handleSelectionChange}
                            style={{ width: '100%', height:'30px' }}
                            value={ticketItem}>
                            {getTicketItemOptions()}
                        </Select>
                    </InputLabel>
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => {addNewGroup()}}
                            color="primary">
                            Add Group
                        </Button>
                    </InputLabel>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-begin"
                alignItems="center"
                // border={2}
                spacing={2}
                xs={12} sm={12} md={12}
            >
                <DragContext.Provider value={[drag, setDrag]}>
                    <DropContext.Provider value={[drop, handleDropChange]}>
                        <DirectionContext.Provider value={[direction, setDirection]}>
                            <DragAndDrop>
                                    {/*<Card style={{ display: 'grid', height: '100%' }}>*/}
                                        {groupData.ticketgroups.map(group => {
                                            return (
                                                <Grid item xs={4} alignItems={'left'} style={{height: '100%', display: 'grid'}}>
                                                <>
                                                   <Card>
                                                        <CardHeader
                                                            action={
                                                                <div>
                                                                    <IconButton
                                                                        disabled={group?.allowchangegroupname === false}
                                                                        onClick={() => {handleEditGroup(group)}}
                                                                        aria-label="settings"
                                                                    >
                                                                        <Edit />
                                                                    </IconButton>
                                                                    <IconButton disabled={group?.guests?.length === 0}
                                                                        onClick={() => {handleDeleteGroup(group)}}
                                                                        aria-label="settings"
                                                                    >
                                                                        <Delete />
                                                                    </IconButton>
                                                                </div>
                                                            }
                                                            title={group.groupname} />
                                                        <CardContent>
                                                            {/*<Typography style={{display: 'block'}}>{group.groupname}</Typography>*/}
                                                            <DroppableList onDrop={handleListDrop} data={group}>
                                                                {group.guests.map((item) => (
                                                                    <DraggableListItem onDrop={handleItemDrop} key={item.ticketid} data={{'groupid': group.groupid, ...item}}>{item.ticketname}</DraggableListItem>))}
                                                            </DroppableList>
                                                        </CardContent>
                                                    </Card>
                                                </>
                                                </Grid>
                                        )
                                        })}

                                    {/*</Card>*/}

                            </DragAndDrop>
                        </DirectionContext.Provider>
                    </DropContext.Provider>
                </DragContext.Provider>

            </Grid>

        </div>
    )
}

export default EventGroupMaintenance
