import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, IconButton, Switch, TextField, Typography
} from '@material-ui/core'
import styles from './EventTransactionsTable.module.css'
import axios from "axios";
import {
  FormControl,
  FormControlLabel, FormLabel, Grid,
  InputLabel,
} from "@mui/material";
import {useEventState} from "../../slices/eventSlice";
import styled from "@emotion/styled";
import {formatMoney} from "../../util/formatHelpers";
import {Add, Edit} from "@material-ui/icons";
import {DataGridPremium, GridCellParams} from "@mui/x-data-grid-premium";
import {useQueryClient} from "react-query";
import {GenericForm} from "../GenericForm/GenericForm";

interface FormData {
  id: number
  paymentmethod: string
  additionaldonation: number
  discount: number
}

export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;

const Span = styled.span`
  padding-left: 0.5em;
`

export const EventCheckoutReview = ({ isOpen, setIsOpen, eventId, transactionid, setTransactionId, setItemQuantity }) => {
  const queryClient = useQueryClient()
  const formMethods = useForm<FormData>()
  const eventState = useEventState()
  const title = "Review Transaction"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [isGuestOpen, setIsGuestOpen] = React.useState(false);
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [ticketList, setTicketList] = React.useState([])
  const [ticketId, setTicketId] = React.useState(0)

  const [setupData, setSetupData] = React.useState({})
  const [transactionData, setTransactionData] = React.useState({
    // transactionid: 0,
    transactiontotalamount: 0,
    transactionpaymentmethod: '',
    transactionadditionaldonation: 0,
    transactionaddress: '',
    transactionaddress2: '',
    transactioncity: '',
    transactioncountry: '',
    transactiondate: '',
    transactiondiscountamount: 0,
    transactionemail: '',
    transactionfirst: '',
    transactionlast: '',
    transactionphone: '',
    transactionprocessingfees: 0,
    transactionsource: '',
    transactionstate: '',
    transactionsubtotal: 0,
    transactionzip: '',
    itemdetail: [],
    ticketlist: [],
  })

  useEffect(() => {
    getTransactionData(true)
  }, [isOpen])

  useEffect(() => {
    getTransactionData(false)
  }, [isGuestOpen])

  const getTransactionData = async (updateTransData) => {
    if (eventId > 0 && transactionid > 0) {
      setLoading(true)
      console.log(transactionid)
      console.log(eventId)
      const { data } = await axios.post('/evtactivitytransactions', {eventid: eventId, transactionid: transactionid})
      const parsedData = JSON.parse(data.jsonString)
      if (updateTransData) {
        setTransactionData(parsedData)
      }

      console.log(parsedData?.ticketlist)
      const newTicketList = parsedData?.ticketlist?.map(ticket => {
        return {guestname: ticket.guestname, groupname: ticket.groupname, ticket: ticket.ticket, ticketid: ticket.ticketid, id: ticket.ticketid}
      })
      setTicketList(newTicketList)
      setLoading(false)
    }
  }

  const onSubmit = async () => {
      queryClient.invalidateQueries(['EventTransaction', Number(eventId)])

      setItemQuantity({})
      setTransactionId(0)
      setIsOpen(false)
  }

  const closeDialog = () => {
    setDialogObj({msg: '', id: 0})
    setDialogMsg("")
  }

  const handleEdit = (params: GridCellParams) => {
    setTicketId(params.row.ticketid)
    // getEventTicket(params.row.ticketid)
    setIsGuestOpen(true)
  }

  const columns = [
    {
      field: 'Edit',
      disableExport: true,
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
            <Edit />
          </IconButton>
      )
    },
    {
      field: 'guestname',
      type: 'string',
      headerName: 'Guest Name',
      minWidth: 300
    },
    {
      field: 'groupname',
      type: 'string',
      headerName: 'Group Name',
      minWidth: 300
    },
    {
      field: 'ticket',
      type: 'string',
      headerName: 'Ticket',
      minWidth: 300
    },
  ]

  return (
    <>
      <Dialog open={isOpen} maxWidth="xl" fullWidth={true} className={styles.dialog}>
        <DialogTitle>{title}</DialogTitle>
        {/*<LoadingBackdrop open={createMutation.isLoading || loading} />*/}
        <>
          <Dialog
              open={dialogMsg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <DialogContent>
          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={12} className={styles.selectcontrol}>
              <b>Items</b>
            </Grid>
          </Grid>
            {transactionData?.itemdetail?.map((cd) => (
                <Grid container style={{marginTop: '5px'}} >
                  <Grid item xs={6} className={styles.selectcontrol}>
                    {cd.transactiondetailitemname}
                  </Grid>
                  <Grid item xs={6} className={styles.selectcontrol}>
                    <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{cd.transactiondetailqty + ' x ' + formatMoney(cd.transactiondetailprice) + ' = ' + formatMoney(cd.transactiondetaillinetotal)}</InputLabel>
                  </Grid>
                </Grid>
            ))}

          <Grid container spacing={1} style={{marginTop: '15px', marginBottom: '15px', borderTop: '1px solid #eeeeee'}}>
            <Grid item xs={9} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Subtotal:&nbsp;</b></InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(transactionData.transactionsubtotal | 0)} ({transactionData?.itemdetail?.length | 0} items)</InputLabel>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={6} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} ><b>Customer Information:</b></InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>CC Fees:&nbsp;</b></InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(transactionData.transactionprocessingfees)}</InputLabel>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={6} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} >{transactionData.transactionfirst + ' ' + transactionData.transactionlast}</InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Discount:&nbsp;</b></InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(transactionData.transactiondiscountamount)}</InputLabel>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={6} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} >{transactionData.transactionaddress + ' ' + transactionData.transactioncity + ' ' + transactionData.transactionstate + ' ' + transactionData.transactionzip}</InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Additional Donation:&nbsp;</b></InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(transactionData.transactionadditionaldonation)}</InputLabel>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={6} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} >{transactionData.transactionemail}</InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Total:&nbsp;</b></InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{formatMoney(transactionData.transactiontotalamount)}</InputLabel>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={9} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} ><b>Payment Method:&nbsp;</b></InputLabel>
            </Grid>
            <Grid item xs={3} className={styles.selectcontrol}>
              <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >{transactionData.transactionpaymentmethod}</InputLabel>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{marginTop: '5px'}}>
            <Grid item xs={12} className={styles.selectcontrol}>
              <b>Guest Registration</b>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{marginTop: '5px'}}>
            {ticketList && ticketList.length > 0 &&
                <DataGridPremium
                    autoHeight
                    rows={ticketList}
                    columns={columns}
                    loading={false}
                    pagination={false as any}
                />
            }
          </Grid>
          <GenericForm isGuestOpen={isGuestOpen} setIsGuestOpen={setIsGuestOpen} endpoint={'evtticket'} eventId={eventId} genericid={ticketId} />
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              className={styles.submitButton}
              type="submit"
              onClick={() => {onSubmit()}}
              form="items-form">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
