import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, FormGroup, Grid, InputAdornment, TextField, Typography
} from '@material-ui/core'

import HookForm from '../../components/common/HookForm'
import SelectInput from '../../components/common/inputs/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
import { useFetchLocationTags } from '../../queries/events/useFetchLocationTags'
import styles from './AuctionItemsTable.module.css'
import { useEventState } from "../../slices/eventSlice";
import { FormControlLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import QRCode from "react-qr-code";
import MaskedInput from "../../components/common/inputs/MaskedInput";
import { useAutoEvent } from '../../queries/useAutoEvent'
import { useParams } from 'react-router'
import { getConfig } from '../../AppConfig'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { useAuctionInfo } from '../../queries/useFetchAuctionInfo'

interface Params {
  id: string
}



interface FormData {
  multipleitems: string
  location: number
  duration: number
  itemsperpage: number
}

export const AuctionCarouselSetup = () => {
  const { auctionSnapURL } = getConfig()

  const { id } = useParams<Params>()
  useAutoEvent(id)
  const formMethods = useForm<FormData>()
  const eventState = useEventState()
  const title = "Item Carousel Setup"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const { data: locationAndTags } = useFetchLocationTags(Number(eventState.eventId))
  const watchDuration = watch("duration", 10) // you can supply default value as second argument
  const watchItems = watch("itemsperpage", 6) // you can supply default value as second argument
  const watchTable = watch("location") // you can supply default value as second argument
  const watchAll = watch(["multipleitems", "location", "itemsperpage", "duration"]) // you can supply default value as second argument

  const [Duration, setDuration] = useState<number>(10)
  const [ItemsperPage, setItemsperPage] = useState<number>(6)
  const [url, setURL] = useState<string>("")
  const [toggleItemType, setToggleItemType] = React.useState("detail");
  const {data: aucInfo} = useAuctionInfo(id)

  // useEffect(() => {




  //   reset({
  //     multipleitems: "Multiple",
  //     location: "",
  //     duration: 10,
  //     itemsperpage: 8,

  //   })
  // }, [ locationAndTags])

  const locations = locationAndTags && locationAndTags.locations ? locationAndTags.locations : []

  const locationOptions = useMemo(
    () => locations.filter((e) => e.active === true).map((location) => {
      return { value: location.id, label: location.name }
    }),
    [locations]
  )
  useEffect(() => {
    if (eventState.eventCode != "") {
      setURL(aucInfo.auctionlink +"detail/")
    }
  }, [eventState.eventCode]);

  useEffect(() => {
    console.log(watchAll);
    console.log(aucInfo.auctionlink);

    let locationName = locationOptions.filter(f => f.value === watchAll[1])[0]
    let url = buildUrl(aucInfo.auctionlink, toggleItemType, locationName, { duration: "" + watchAll[3], itemsperpage: "" + watchAll[2] });
    console.log(url)
    setURL(url)
  }, [watchAll]);

  const onSubmit = async (data: FormData) => {
    console.log(toggleItemType)
    console.log(data)
    console.log(eventState)
    let locationName = locationOptions.filter(f => f.value === data.location)[0]
    let url = buildUrl(aucInfo.auctionlink, toggleItemType, locationName, data);
    console.log(url)
    setURL(url)
  }
  function buildUrl(auctionSnapURLParam, toggleItemTypeParam, locationNameParam, dataParam) {
    let parts = [auctionSnapURLParam];
    console.log(dataParam)
    
    if (toggleItemTypeParam) parts.push(toggleItemTypeParam);
    if (locationNameParam && locationNameParam.label) parts.push(encodeURIComponent(locationNameParam.label));
    if (dataParam && dataParam.duration && watchTable > 0) parts.push(dataParam.duration);
    if (dataParam && dataParam.itemsperpage && watchTable > 0 && toggleItemType != 'detail') parts.push(dataParam.itemsperpage);
    parts = parts.map(part => part.replace(/\/+$/, ''));

    return parts.join('/');
  }
  const h3Style = {
    margin: 0,
    wordWrap: 'break-word',
    overflowWrap: 'break-word'
  };
  const handleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    // if (newAlignment !== null) {
    //   const loc : any = getValues('location')
    //   if (newAlignment === 'online-auction' && loc === 'none') {
    //     setError('location', { type: 'required', message: 'Location is required' })
    //   }
    //   else if (newAlignment == 'live-auction') {
    //     setQuantityValue(1)
    //   }
    if (newAlignment !== null) {

      setToggleItemType(newAlignment)
    }
    // }
  }
  // console.log("tester");

  console.log(watchTable)
  const copyTextToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text successfully copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const handleh3Click = () => {
    const text = url;
    copyTextToClipboard(text);
  };
  return (

    <div style={{ textAlign: 'center' }}>
      <h1>Item Carousel Setup</h1>
      <Grid container spacing={1}>
      <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>

      <HookForm
        methods={formMethods}
        onSubmit={onSubmit}
        className={styles.itemsForm}
        formProps={{ id: 'items-form' }}
      >


        <ToggleButtonGroup
          color="primary"
          value={toggleItemType}
          exclusive
          onChange={handleTypeChange}
          aria-label="Platform"
        >
          <ToggleButton classes={{ selected: '#980e0e' }} value="detail" title={''}>One Item Per Page</ToggleButton>
          <ToggleButton value="list" title={''}>Multiple Items Per Page</ToggleButton>
        </ToggleButtonGroup>

        <SelectInput
          name="location"
          label="Location"
          // required={true}
          includeNone={false}
          includeAll={true}
          options={locationOptions}
          defaultValue="0"
          tooltipText={'The table number or other type of location grouping for the item.'}
        />
        {watchTable > 0 && (
          <>
            <TextInput textFieldProps={{ inputMode: 'numeric', }} name='duration' label="Duration" defaultValue='10' rules={{
              pattern: {
                value: /^[0-9]+$/,
                message: 'Please enter a number',
              }
            }} />
            {toggleItemType != 'detail' && (
              <TextInput textFieldProps={{ inputMode: 'numeric' }} name='itemsperpage' label="Items Per Page" defaultValue='6' rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter a number',
                }
              }} />
            )}

          </>
        )}

      </HookForm>

      {/* <Button
        variant="contained"
        className={styles.submitButton}
        type="submit"
        form="items-form"
      >
        Submit
      </Button> */}

</Grid>
<Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>

      <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
        <QRCode
          //  key={url}

          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={url}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
  <IconButton onClick={handleh3Click} title="Copy to clipboard">
    <ContentCopyIcon />
  </IconButton>
  <a href={url} style={{ margin: '0 10px', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
    {url}
  </a>
</div>
      </Grid>
</Grid>
    </div>

  )
}
