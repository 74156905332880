import React, { useState, useMemo, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { useForm } from 'react-hook-form'
import HookForm from '../../components/common/HookForm'
import AutocompleteSelect from "../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"
import styles from './ParticipantMergePage.module.css'
import {  TextField } from '@material-ui/core'

import {
  useFetchparticipentsListing,
  useFetchMergeparticipentsListing,
  usePutParticipantMerge,
  useFetchparticipentforMerge
} from '../../queries/useParticipantLinking'
import { useAutoEvent } from '../../queries/useAutoEvent'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { EventState, setEvent, useEventState } from '../../slices/eventSlice'
import dayjs from 'dayjs'
import { makeStyles, Button } from '@material-ui/core'
import SearchBar from '../../components/common/inputs/SearchBar'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'

interface Params {
  id: string
}


const ParticipantMergePage = () => {

  const { id } = useParams<Params>()
  useAutoEvent(id)
  const event = useEventState()
  const putLink = usePutParticipantMerge()
  const [selectedOldParticipant, setSelectedOldParticipant] = useState(null);
  const [selectedNewParticipant, setSelectedNewParticipant] = useState(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [mergeSuccess, setMergeSuccess] = useState(false);
  const formMethods = useForm<FormData>({
    defaultValues: {
      participantidold: null,
      participantidnew: null,
    },
  });
  const { data, isLoading } = useFetchMergeparticipentsListing(event.eventId)
  if (data) {
    console.log(data)
    //rows = data
  }
  const { watch } = formMethods;
  const selectedOldParticipantId = watch('participantidold');
  const selectedNewParticipantId = watch('participantidnew');
  const getparticipantsOptions = () => {
    return data?.map(item =>{
      return {value:item.participantid, label:item.participantname }
    })
  }
  const participantsOptions = useMemo(() => getparticipantsOptions(), [data]);

  const [editedData, setEditedData] = useState({
    first: "",
    last: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    currentWinningBids: "",
    numberOfCheckouts: "",
    numberOfIntegrated: "",
    paddlenumbertext: ""
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({
      ...editedData,
      [name]: value
    });
  };
type FormData = {
  participantidold: number
  participantidnew: number
}
const [formData, setFormData] = useState(null);

 
const handleConfirmDialogOpen = (formData: FormData)  => {
  setFormData(formData);
  setConfirmDialogOpen(true);
};

const handleConfirmDialogClose = () => {
  setConfirmDialogOpen(false);
};

const handleSuccessDialogClose = () => {
  setSuccessDialogOpen(false);
  if (mergeSuccess) {
    // Reset the selection and any other necessary state
    setSelectedOldParticipant(null);
    setSelectedNewParticipant(null);
    formMethods.reset();
    // ... any other state reset logic
  }
};

 
  // useEffect(() => {
  //   if (selectedOldParticipantId) {
  //     const selectedParticipant = data?.find(item => item.participantid === selectedOldParticipantId);
  //     setSelectedOldParticipant(selectedParticipant);
  //   }
  // }, [selectedOldParticipantId, data]);

  // useEffect(() => {
  //   if (selectedNewParticipantId) {
  //     const selectedParticipant = data?.find(item => item.participantid === selectedNewParticipantId);
  //     setSelectedNewParticipant(selectedParticipant);
  //   }
  // }, [selectedNewParticipantId, data]);
  
  useEffect(() => {
    if (selectedOldParticipantId) {
      axios.post('/participantmerge', {
        eventid: event.eventId,
        participantid: selectedOldParticipantId
      }).then(response => {
        const data = JSON.parse(response.data.jsonString)
        setSelectedOldParticipant(data);
      }).catch(error => {
        console.error('Error fetching old participant data:', error);
      });
    }
  }, [selectedOldParticipantId, event.eventId]);

  useEffect(() => {
    if (selectedNewParticipantId) {
      axios.post('/participantmerge', {
        eventid: event.eventId,
        participantid: selectedNewParticipantId
      }).then(response => {
        const data = JSON.parse(response.data.jsonString)
        setSelectedNewParticipant(data);
      }).catch(error => {
        console.error('Error fetching new participant data:', error);
      });
    }
  }, [selectedNewParticipantId, event.eventId]);

  const onSubmit = async () => {
    if (formData) {

    handleConfirmDialogClose(); // Close confirmation dialog

    console.log(data)

    try {
      await putLink.mutateAsync({
        eventid: Number(event.eventId),
        participantidold: formData.participantidold,
        participantidnew: formData.participantidnew,
        first: editedData.first,
        last: editedData.last,
        address: editedData.address,
        address2: editedData.address2,
        city: editedData.city,
        state: editedData.state,
        zip: editedData.zip,
        phone: editedData.phone,
        paddlenumbertext :  editedData.paddlenumbertext

      },{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          console.log(json)
          if (json.status === "success") {
            setDialogMessage(` ${selectedOldParticipant.first} ${selectedOldParticipant.last} was successfully merged into  ${selectedNewParticipant.first} ${selectedNewParticipant.last}`);
            setMergeSuccess(true);
          } else {
            setDialogMessage(`An error has occurred. Participant merge was not successful.`);
            setMergeSuccess(false);
          }
          setSuccessDialogOpen(true);
        }
      })
    } catch {
      setDialogMessage(`An error has occurred. Participant merge was not successful.`);
    setMergeSuccess(false);
    setSuccessDialogOpen(true);
    }
  }
  }
  useEffect(() => {
    if (selectedNewParticipant) {
      setEditedData({
        first: selectedNewParticipant.first || "",
        last: selectedNewParticipant.last || "",
        phone: selectedNewParticipant.phone || "",
        address: selectedNewParticipant.address || "",
        address2: selectedNewParticipant.address2 || "",
        city: selectedNewParticipant.city || "",
        state: selectedNewParticipant.state || "",
        zip: selectedNewParticipant.zip || "",
        currentWinningBids: selectedNewParticipant.currentwinningbids || "",
        numberOfCheckouts: selectedNewParticipant.numberofcheckouts || "",
        numberOfIntegrated: selectedNewParticipant.numberofintegrated || "",
        paddlenumbertext : selectedNewParticipant.paddlenumbertext || ""
      });
    }
  }, [selectedNewParticipant]);



  return (
    <div>
        <HookForm
          methods={formMethods}
          onSubmit={handleConfirmDialogOpen}
          className={styles.bidForm}
          formProps={{ id: 'contactMerge' }}>


          <Grid container spacing={1}>
            <Grid item xs={4} className={styles.selectcontrol}>
              <div>
                <AutocompleteSelect
                    name="participantidold"
                    label="Old Participant"
                    options={getparticipantsOptions()}
                    required
                />
              </div>
              {selectedOldParticipant && (
<>         <Typography variant="h6">Old Participant Details</Typography>
    <Typography>Bidder Number: {selectedOldParticipant.biddernumber}</Typography>
    <Typography>Paddle Number: {selectedOldParticipant.paddlenumbertext}</Typography>
    <Typography>First Name: {selectedOldParticipant.first}</Typography>
    <Typography>Last Name: {selectedOldParticipant.last}</Typography>
    <Typography>Email: {selectedOldParticipant.email}</Typography>
    <Typography>Phone: {selectedOldParticipant.phone}</Typography>
    <Typography>Address: {selectedOldParticipant.address}</Typography>
    <Typography>Address 2: {selectedOldParticipant.address2}</Typography>
    <Typography>City: {selectedOldParticipant.city}</Typography>
    <Typography>State: {selectedOldParticipant.state}</Typography>
    <Typography>ZIP: {selectedOldParticipant.zip}</Typography>
    <Typography>Last Login Date: {selectedOldParticipant.lastlogindate ? dayjs(selectedOldParticipant.lastlogindate as string).format('MM-DD-YYYY hh:mm A') : 'Never'}</Typography>
    <Typography>Participant Source: {selectedOldParticipant.participantsource}</Typography>
    <Typography>Participant Contact Key: {selectedOldParticipant.participantcontactkey > 0 ? selectedOldParticipant.participantcontactkey : "None"}</Typography>
    <Typography>Current Winning Bids: {selectedOldParticipant.currentwinningbids}</Typography>
    <Typography>Number of checkouts: {selectedOldParticipant.numberofcheckouts}</Typography>
    <Typography>Authorized: {selectedOldParticipant.authorizedbidder ? 'YES' : 'NO'}</Typography>
    {/* <Typography>Number of Checkouts: {selectedOldParticipant.numberofcheckouts}</Typography>
    <Typography>Number of Integrated: {selectedOldParticipant.numberofintegrated}</Typography> */}
            </>   
        )}
            </Grid>
            
            <Grid item xs={4} className={styles.selectcontrol}>
              <div>
                <AutocompleteSelect
                    name="participantidnew"
                    label="New Participant"
                    options={getparticipantsOptions()}
                    required
                />
              </div>
              {selectedNewParticipant && (
  <>
    <Typography variant="h6">New Participant Details</Typography>
    <Typography>Bidder Number: {selectedNewParticipant.biddernumber}</Typography>
    <Typography>Paddle Number: {selectedNewParticipant.paddlenumbertext}</Typography>
    <Typography>First Name: {selectedNewParticipant.first}</Typography>
    <Typography>Last Name: {selectedNewParticipant.last}</Typography>
    <Typography>Email: {selectedNewParticipant.email}</Typography>
    <Typography>Phone: {selectedNewParticipant.phone}</Typography>
    <Typography>Address: {selectedNewParticipant.address}</Typography>
    <Typography>Address 2: {selectedNewParticipant.address2}</Typography>
    <Typography>City: {selectedNewParticipant.city}</Typography>
    <Typography>State: {selectedNewParticipant.state}</Typography>
    <Typography>ZIP: {selectedNewParticipant.zip}</Typography>

    {/* <Typography>{selectedNewParticipant.userdefinedtitle1}: {selectedNewParticipant.userdefined1}</Typography>
    <Typography>{selectedNewParticipant.userdefinedtitle2}: {selectedNewParticipant.userdefined2}</Typography>
    <Typography>{selectedNewParticipant.userdefinedtitle3}: {selectedNewParticipant.userdefined3}</Typography>
    <Typography>{selectedNewParticipant.userdefinedtitle4}: {selectedNewParticipant.userdefined4}</Typography>
    <Typography>{selectedNewParticipant.userdefinedtitle5}: {selectedNewParticipant.userdefined5}</Typography> */}
    <Typography>Last Login Date: {selectedNewParticipant.lastlogindate ? dayjs(selectedNewParticipant.lastlogindate as string).format('MM-DD-YYYY hh:mm A') : 'Never'}</Typography>
    <Typography>Participant Source: {selectedNewParticipant.participantsource}</Typography>
    <Typography>Participant Contact Key: {selectedNewParticipant.participantcontactkey > 0 ? selectedNewParticipant.participantcontactkey : "None"}</Typography>
    <Typography>Current Winning Bids: {selectedNewParticipant.currentwinningbids}</Typography>
    <Typography>Number of checkouts: {selectedNewParticipant.numberofcheckouts}</Typography>
    <Typography>Authorized: {selectedNewParticipant.authorizedbidder ? 'YES' : 'NO'}</Typography>

    {/* <Typography>Number of Checkouts: {selectedNewParticipant.numberofcheckouts}</Typography>
    <Typography>Number of Integrated: {selectedNewParticipant.numberofintegrated}</Typography> */}
  </>
)}
            </Grid>
            <Grid item xs={4} className={styles.selectcontrol}>
            <Typography variant="h3">Resulting Data</Typography>

              <Grid item xs={6}>
      <TextField label="First Name" name="first" value={editedData.first} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={6}>
      <TextField label="Last Name" name="last" value={editedData.last} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={6}>
      <TextField label="Paddle Number" name="paddlenumbertext" value={editedData.paddlenumbertext} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={4}>
    <Typography variant="body1">
      Email: {selectedNewParticipant?.email }
    </Typography>
  </Grid>
    <Grid item xs={6}>
      <TextField label="Phone" name="phone" value={editedData.phone} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={6}>
      <TextField label="Address" name="address" value={editedData.address} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={6}>
      <TextField label="Address 2" name="address2" value={editedData.address2} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={6}>
      <TextField label="City" name="city" value={editedData.city} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={4}>
      <TextField label="State" name="state" value={editedData.state} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={2}>
      <TextField label="ZIP" name="zip" value={editedData.zip} onChange={handleInputChange} fullWidth />
    </Grid>
    <Grid item xs={4}>
    <Typography variant="body1">
      Bidder Number: {selectedNewParticipant?.biddernumber}
    </Typography>
    <Typography variant="body1">
      Last Login: {selectedNewParticipant?.lastlogindate ? dayjs(selectedNewParticipant.lastlogindate as string).format('MM-DD-YYYY hh:mm A') : 'Never'}
    </Typography>
    <Typography variant="body1">
      Participant Source: {selectedNewParticipant?.participantsource}
    </Typography>
    <Typography variant="body1">
      Contact Key: {selectedNewParticipant?.participantcontactkey  > 0 ? selectedNewParticipant.participantcontactkey : "None"}
    </Typography>
    <Typography variant="body1">
      Authorized: {selectedNewParticipant?.authorizedbidder ? 'YES' : 'NO'}
    </Typography>
    <Typography variant="body1">Number of checkouts:  {((selectedOldParticipant?.numberofcheckouts || 0) + (selectedNewParticipant?.numberofcheckouts || 0))}</Typography>
    <Typography variant="body1">
      Current Winning Bids: {((selectedOldParticipant?.currentwinningbids || 0) + (selectedNewParticipant?.currentwinningbids || 0))}
    </Typography>
   
  </Grid>
  {/* <Grid item xs={4}>
    <Typography variant="body1">
      Number of Checkouts: {((selectedOldParticipant?.numberofcheckouts || 0) + (selectedNewParticipant?.numberofcheckouts || 0))}
    </Typography>
  </Grid>
  <Grid item xs={4}>
    <Typography variant="body1">
      Number of Integrated: {((selectedOldParticipant?.numberofintegrated || 0) + (selectedNewParticipant?.numberofintegrated || 0))}
    </Typography>
    </Grid> */}
            <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="contactMerge"
          >
          Submit
        </Button>
  </Grid>
           
          </Grid>

           
        </HookForm>
        <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
      <DialogTitle>{"Confirm Merge"}</DialogTitle>
      <DialogContent>
      <DialogContentText>
      Are you sure you want to merge 
      <b>{selectedOldParticipant ? ` ${selectedOldParticipant.first} ${selectedOldParticipant.last} ` : ' [old contact]'}</b> 
      into 
      <b>{selectedNewParticipant ? ` ${selectedNewParticipant.first} ${selectedNewParticipant.last}` : ' [new contact]'}</b>? 
      This operation cannot be undone. All bids/carts/checkouts will be moved to the 
      <b>{selectedNewParticipant ? ` ${selectedNewParticipant.first} ${selectedNewParticipant.last} ` : ' [new contact]'}</b> 
      record and 
      <b>{selectedOldParticipant ? ` ${selectedOldParticipant.first} ${selectedOldParticipant.last} ` : ' [old contact]'}</b> 
      will be permanently deleted.
    </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit()} color="primary" autoFocus>
          Merge Contacts
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
      <DialogTitle>{"Operation Result"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSuccessDialogClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>

    </div>
  )
}
export default ParticipantMergePage
