import React, { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import {
  ClickAwayListener,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {
  BurstMode as BurstIcon,
  Web as WebIcon,
  LocationOn as LocationIcon,
  Gavel as GavelIcon,
  People as PeopleIcon,
  AddShoppingCart as AddShoppingCartIcon,
  ShoppingCart as ShoppingCartIcon,
  RemoveShoppingCart as RemoveShoppingCartIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Assessment as AssessmentIcon,
  OpenInNew as OpenInNewIcon,
  GroupAdd as GroupAddIcon,
  LibraryAdd as LibraryAddIcon,
  ChevronLeft as ChevronIcon,
  ExpandLess,
  ExpandMore,
  Home as HomeIcon,
  SettingsOutlined as SettingsIcon
} from '@material-ui/icons'

import { cn } from '../../../../util/classnames'
import styles from './MainMenu.module.css'
import {useEventState} from "../../../../slices/eventSlice";

type MenuItem = {
  label: string
  Icon?: any
  linkTo?: string
  children?: MenuItem[]
}

const MainMenu = ({ isOpen, setIsOpen }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const eventState = useEventState()

  const MENU_ITEMS: MenuItem[] = [
    // {
    //   label: 'Home',
    //   linkTo: '/home'
    // },
    // {
    //   label: 'Organization Settings',
    //   linkTo: '/settings'
    // },
    {
      label: 'Auction Setup',
      linkTo: '',
      children: [
        {
          label: 'Auction Settings',
          linkTo: '/auction/' + eventState.eventId + '/settings',
          Icon: SettingsIcon
        },
        {
          label: 'Auction Sponsors',
          linkTo: '/auction/' + eventState.eventId + '/sponsors',
          Icon: BurstIcon
        },
        {
          label: 'Page/Email Customization',
          linkTo: '/auction/' + eventState.eventId + '/custommessages/',
          Icon: WebIcon
        },
        {
          label: 'Dropdown Fields',
          linkTo: '/auction/' + eventState.eventId + '/locationstags',
          Icon: LocationIcon
        },
        {
          label: 'Auction Items',
          linkTo: '/auction/' + eventState.eventId + '/auctionitems/',
          Icon: GavelIcon
        },
        {
          label: 'Participants',
          linkTo: '/auction/' + eventState.eventId + '/participants',
          Icon: PeopleIcon
        }
      ]
    },
    {
      label: 'Auction Activity',
      linkTo: '',
      children: [
        {
          label: 'Manual Bid Entry',
          linkTo: '/auction/' + eventState.eventId + '/manualbidentry',
          Icon: AddShoppingCartIcon
        },
        {
          label: 'Bid Maintenance',
          linkTo: '/auction/' + eventState.eventId + '/bidmaintenance',
          Icon: RemoveShoppingCartIcon
        },
        {
          label: 'Auction Status',
          linkTo: '/auction/' + eventState.eventId + '/AuctionStatus',
          Icon: RemoveShoppingCartIcon
        },
        {
          label: 'Live Auction Bid',
          linkTo: '/auction/' + eventState.eventId + '/liveauction',
          Icon: RemoveShoppingCartIcon
        },
        {
          label: 'Checkout',
          linkTo: '/auction/' + eventState.eventId + '/checkout',
          Icon: ShoppingCartIcon
        }
      ]
    },
    {
      label: 'Reports',
      linkTo: '',
      children: [
        {
          label: 'Participant Listing',
          linkTo: '/auction/' + eventState.eventId + '/participantlisting',
          Icon: SupervisedUserCircleIcon
        },
        // {
        //   label: 'Accounting Report',
        //   linkTo: '/event/' + eventState.eventId + '/accountingreport',
        //   Icon: AssessmentIcon
        // }
      ]
    },
    {
      label: 'Integrations',
      linkTo: '',
      children: [
        {
          label: 'DS Event Settings',
          linkTo: '/auction/groupadd',
          Icon: GroupAddIcon
        },
        {
          label: 'Link Regs to DS',
          linkTo: '/auction/groupadd',
          Icon: GroupAddIcon
        },
        {
          label: 'Transfer trans to DS',
          linkTo: '/auction/transfer',
          Icon: OpenInNewIcon
        },
        {
          label: 'Transfer trans from DS',
          linkTo: '/auction/transfer',
          Icon: OpenInNewIcon
        },
        {
          label: 'Transfer new contacts to DS',
          linkTo: '/auction/transfer',
          Icon: OpenInNewIcon
        },
        {
          label: 'Interaction Records',
          linkTo: '/auction/transfer',
          Icon: OpenInNewIcon
        }]
    }
  ]

  const initialMenuState = MENU_ITEMS.reduce(
      (state, currentItem) =>
          !currentItem.linkTo ? { ...state, [currentItem.label]: false } : state,
      {}
  )

  const [isListOpen, setIsListOpen] = useState(initialMenuState)
  // const user = useCurrentUser()

  const getMenuItems = (items: MenuItem[], isSubMenu = false) => {
    return items.map(({ linkTo, label, Icon, children }, i) => {
      return (
        <div key={i}>
          <ListItem
            button
            selected={linkTo && pathname === linkTo}
            className={cn(isOpen && isSubMenu && styles.nested)}
            onClick={() =>
              linkTo
                ? history.push(linkTo)
                : setIsListOpen({
                    ...isListOpen,
                    [label]: !isListOpen[label]
                  })
            }>
            {Icon && (
              <ListItemIcon>
                <Icon
                  style={{
                    color: `var(--${isSubMenu ? 'purple' : 'black'})`,
                    opacity: isSubMenu ? 1 : 0.7
                  }}
                  height={19}
                  width={19}
                />
              </ListItemIcon>
            )}
            <ListItemText
              primary={label}
              classes={{ root: styles.link, primary: styles.truncate }}
            />
            {children && (isListOpen[label] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          {children && (
            <Collapse in={isListOpen[label]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {getMenuItems(children, true)}
              </List>
            </Collapse>
          )}
        </div>
      )
    })
  }

  return (
    <Drawer
      open={isOpen}
      classes={{
        paper: cn(styles.drawerPaper, !isOpen && styles.close)
      }}>
      <div className={styles.toolbarIcon}>
        <IconButton onClick={() => setIsOpen((c) => !c)}>
          <ChevronIcon style={{ color: 'var(--gray)' }} />
        </IconButton>
      </div>
      <Divider />
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <List className={styles.list}>{getMenuItems(MENU_ITEMS)}</List>
      </ClickAwayListener>
    </Drawer>
  )
}
export default MainMenu
