import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import SelectInput from '../../components/common/inputs/SelectInput/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import styles from './GenericForm.module.css'
import axios from "axios"
import {Grid, InputLabel} from "@mui/material";
import styled from "@emotion/styled"

interface FormData {
  id: number
  control: number
  fieldname: string
  defaultcontrol1: boolean
  defaultcontrol2: number
  defaultcontrol3: number
  defaultcontrol4: string
  defaultcontrol5: string
  defaultcontrol6: string
  displayforallguest: boolean
  items: []
  active: boolean
  delete: boolean
}

export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;

const Span = styled.span`
  padding-left: 0.5em;
`

export const GenericForm = ({ isGuestOpen, setIsGuestOpen, endpoint, eventId, genericid }) => {
  const formMethods = useForm()
  const {watch, getValues, setValue, register, reset} = formMethods
  const [loading, setLoading] = React.useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0, title: "" })
  const [dialogMsg, setDialogMsg] = React.useState({active: false, msg: ''})
  const [ticketData, setTicketData] = React.useState({
    ticketfieldslist: [],
    tickettitle: '',
    showbuttonreassign: false,
    showbuttonunassign: false,
    ticketlookuplist: [],
  })

  useEffect(() => {
    if (isGuestOpen) {
      getEventTicket()
    }
  }, [eventId, genericid, endpoint, isGuestOpen])

  const getTicketLookupDataOptions = (lookupfieldid) => {
    const dtRows = ticketData?.ticketlookuplist?.filter((e) => e.lookupactive === 1 && e.lookupfieldid === lookupfieldid).map((types) => {
      return {value: types.lookupid, label: types.lookup}
    })
    if (dtRows && dtRows.length > 0) {
      return [...dtRows]
    }
    else {
      return []
    }
  }

  const unassignEventTicket = async () => {
    setLoading(true)
    const { data } = await axios.put('/' + endpoint, {eventid: eventId, genericid: genericid, unassign: true})
    const parsedData = JSON.parse(data.jsonString)

    setDialogObj({
      title: '',
      id: 0,
      msg: ''
    })
    if (parsedData.status === 'success') {
      setDialogMsg({active: false, msg: ''})
      setIsGuestOpen(false)
      // setDialogMsg({active: false, msg: dialogMsg.msg})

    }
    else {
      // setDialogMsg({active: false, msg: parsedData.message})
      setDialogMsg({active: true, msg: parsedData.message})
    }

    setLoading(false)
  }

  const getEventTicket = async () => {
    var body = {eventid: eventId}

    if (genericid > 0) {
      body['genericid'] = genericid
    }

    setLoading(true)
    const { data } = await axios.post('/' + endpoint, body)
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    setTicketData(parsedData)

    var newFormValues = {}

    parsedData?.ticketfieldslist?.map(item => {
      newFormValues["defaultcontrol" + item.fieldid] = item['fieldvalue' + item.fieldcontrol]
    })
    reset(newFormValues)
    setLoading(false)
  }
  const handleClose = () => {
    setIsGuestOpen(false)
  }

  const onSubmit = async (formData: FormData) => {
    var fieldList = []
    const formKeys = Object.keys(formData)
    formKeys.map(key => {
      const fieldId = key.split("defaultcontrol").pop()
      var element = ticketData?.ticketfieldslist?.find(field => Number(field.fieldid) === Number(fieldId))
      const defaultValueControl = 'defaultcontrol' + fieldId
      var fieldDataObject = {fieldid: element.fieldid}
      if (formData && formData[defaultValueControl]) {
        fieldDataObject['fieldvalue' + element.fieldcontrol] = formData[defaultValueControl]
        fieldList.push(fieldDataObject)
      }
    });
    const body = {eventid: eventId, genericid: genericid, fieldslist: fieldList}
    const {data} = await axios.put('/' + endpoint, body)
    const parsedData = JSON.parse(data.jsonString)

    console.log(parsedData)
    setIsGuestOpen(false)
  }

  const handleSelectionClick = async (fieldControlId, fieldData) => {
    console.log(getValues(fieldControlId))
    console.log(fieldData)


    if (fieldData && fieldData.onchange) {
      var body = {}
      console.log(fieldData?.onchange?.params)
      fieldData?.onchange?.params?.map(key => {
        if (key.startsWith('fieldid')) {
          body['fieldid'] = getValues(fieldControlId)
        }
        else if (key.endsWith('-id')) {
          const newKey = key.replace('-', '')
          body[newKey] = genericid
        }
        else if (key === 'eventid') {
          body['eventid'] = eventId
        }
      })
      if (fieldData.onchange.endpoint) {
        const {data} = await axios.post(fieldData.onchange.endpoint, body)
        if (data.jsonString) {
          const parsedData = JSON.parse(data.jsonString)
          console.log(parsedData)
          setTicketData(parsedData)

          var newFormValues = {}

          parsedData?.ticketfieldslist?.map(item => {
            newFormValues["defaultcontrol" + item.fieldid] = item['fieldvalue' + item.fieldcontrol]
          })
          reset(newFormValues)
          console.log(body)
        }
      }
    }
  }

  const handleUnassign = () => {
    setDialogObj({
      title: '',
      id: 0,
      msg: 'Are you sure you want to unassign this guest?'
    })
  }

  const handleReassign = () => {
    const newTicketData = {...ticketData}
    console.log(newTicketData?.ticketfieldslist)
    var ticket = newTicketData?.ticketfieldslist.find(ticket => ticket.fieldid === -1001)
    ticket.fieldreadonly = false
    setTicketData(newTicketData)
  }

  const unassignDialog = async () => {
    await handleUnassignConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogObj({
      msg: '',
      id: 0,
      title: ""
    })
  }

  const handleUnassignConfirm = async (id: number) => {
    // var finaldata = {
    //   "eventid": Number(eventState.eventId),
    //   "historyid": id,
    //   "delete": true
    // }
    // const { data: response } = await updateBidMaintenanceMutation.mutateAsync({
    //   ...finaldata
    // },{
    //   onSuccess: (data) => {
    //     const json = JSON.parse(data.data.jsonString)
    //     if (json.status === "failure") {
    //       setDialogObj({
    //         msg: '',
    //         id: 0,
    //         title: ""
    //       })
    //       setDialogMsg(json.message)
    //     }
    //     else {
    //       setDialogObj({
    //         msg: '',
    //         id: 0,
    //         title: ""
    //       })
    //     }
    //   }
    // })
  }

  return (
      <>
        <Dialog open={isGuestOpen} maxWidth="xl" fullWidth={true} className={styles.dialog}>
          <>
            <Dialog
                open={dialogObj.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dialogObj.title}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {dialogObj.msg}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={unassignEventTicket}>Unassign</Button>
              </DialogActions>
            </Dialog>
          </>
          <>
            <Dialog
                open={dialogMsg.active}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dialogMsg.msg}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog}>Ok</Button>
              </DialogActions>
            </Dialog>
          </>

          <DialogTitle>{ticketData.tickettitle}</DialogTitle>
          <LoadingBackdrop open={loading} />
          <DialogContent>
            <HookForm
                methods={formMethods}
                onSubmit={onSubmit}
                className={styles.itemsForm}
                formProps={{ id: 'guest-items-form' }}>

              {(ticketData.showbuttonreassign || ticketData.showbuttonunassign) &&
                  <Grid container spacing={1} style={{marginTop: '5px'}}>
                    {ticketData.showbuttonunassign &&
                        <Grid item xs={2} className={styles.selectcontrol}>
                          <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} >
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                // className={classes.button}
                                onClick={() => {handleUnassign()}}>Unassign Ticket
                            </Button>
                          </InputLabel>
                        </Grid>
                    }

                    {ticketData.showbuttonreassign &&
                        <Grid item xs={3} className={styles.selectcontrol}>
                          <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} >
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                // className={classes.button}
                                onClick={() => {handleReassign()}}>Reassign a ticket to another guest
                            </Button>
                          </InputLabel>
                        </Grid>
                    }
                  </Grid>
              }


              <Grid container spacing={1} style={{marginTop: '5px'}}>
                {/*fieldrowgrouping*/}
                {ticketData?.ticketfieldslist?.sort((a, b) => a.fieldrowgrouping > b.fieldrowgrouping ? 1 : -1).map((ticketFieldData) => {
                  if (ticketFieldData.fieldcontrol === 1) {
                    return (
                        <Grid item xs={ticketFieldData.fieldwidth} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                          <SelectInput
                              name={"defaultcontrol" + ticketFieldData.fieldid}
                              label={ticketFieldData.fieldlabel}
                              options={[
                                {label: 'Yes', value: true},
                                {label: 'No', value: false}
                              ]}
                              includeNone={false}
                              disabled={ticketFieldData.fieldreadonly}
                          />
                        </Grid>
                    )
                  }
                  if (ticketFieldData.fieldcontrol === 2) {
                    return (
                        <Grid item xs={ticketFieldData.fieldwidth} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                          <SelectInput
                              name={"defaultcontrol" + ticketFieldData.fieldid}
                              label={ticketFieldData.fieldlabel}
                              options={getTicketLookupDataOptions(ticketFieldData.fieldid)}
                              includeNone={false}
                              disabled={ticketFieldData.fieldreadonly}
                              onClick={() => {handleSelectionClick("defaultcontrol" + ticketFieldData.fieldid, ticketFieldData)}}
                          />
                        </Grid>
                    )
                  }
                  else if (ticketFieldData.fieldcontrol === 4) {
                    return (
                        <Grid item xs={ticketFieldData.fieldwidth} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                          <CurrencyTextField
                              name={"defaultcontrol" + ticketFieldData.fieldid}
                              label={ticketFieldData.fieldlabel}
                              // variant="outline"
                              // value={currencyNumber}
                              currencySymbol="$"
                              outputFormat="string"
                              textAlign={"left"}
                              onChange={handleSelectionClick("defaultcontrol" + ticketFieldData.fieldid, ticketFieldData)}
                          />
                        </Grid>
                    )
                  }
                  else if (ticketFieldData.fieldcontrol === 5) {
                    return (
                        <Grid item xs={ticketFieldData.fieldwidth} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                          <TextInput
                              name={"defaultcontrol" + ticketFieldData.fieldid}
                              label={ticketFieldData.fieldlabel}
                              disabled={ticketFieldData.fieldreadonly}
                              defaultValue={""}
                          />
                        </Grid>
                    )
                  }

                })}
              </Grid>

              {/*{controlWatch === 3 &&*/}
              {/*  <MaskedInput*/}
              {/*      name="defaultcontrol3"*/}
              {/*      label="Default Value"*/}
              {/*      onValueChange={handleAmountChange}*/}
              {/*      thousandSeparator={false}*/}
              {/*      thousandsGroupStyle={""}*/}
              {/*      prefix={""}*/}
              {/*  />*/}
              {/*}*/}


              {/*{controlWatch ===  6 &&*/}
              {/*    <LocalizationProvider dateAdapter={AdapterDayjs}>*/}
              {/*      <DatePicker key="defaultcontrol6"*/}
              {/*                  label="Default Value"*/}
              {/*                  // disableCloseOnSelect={false}*/}
              {/*                  onChange={(e: Dayjs | null) => { handleDateTimeChange(e)}}*/}
              {/*                  // renderInput={(params) => <TextField {...params} />}*/}
              {/*      />*/}
              {/*    </LocalizationProvider>*/}
              {/*}*/}
            </HookForm>

          </DialogContent>
          <DialogActions>
            <Button
                variant="contained"
                onClick={() => {handleClose()}}
                form="items-form">
              Close
            </Button>
            <Button
                variant="contained"
                className={styles.submitButton}
                type="submit"
                form="guest-items-form">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
  )
}
