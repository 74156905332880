import React, {useEffect, useMemo, useState} from 'react'
import Dropzone from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { ExpandMore } from '@material-ui/icons'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, ButtonProps,  Accordion,
  AccordionDetails,
  AccordionSummary } from '@material-ui/core'
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alerts from '../../components/common/Alerts'
import HookForm from '../../components/common/HookForm'
import { Controller} from 'react-hook-form'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import CheckboxGroup from '../../components/common/inputs/CheckboxGroup'
import SelectInput from '../../components/common/inputs/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
 import {EventSettings, useFetchEventSettings} from '../../queries/events/useFetchEvtSettings'
 import {EvtState, setEvt, useEvtState} from '../../slices/evtSlice'
import styles from './EventSettingsForm.module.css'
import { useEditEventSettings } from './useEditEventSettings'
import { useFetchTimezones } from "../../queries/useFetchTimezones";
import {Lock, LockOpen, Close } from '@material-ui/icons'
import dayjs from "dayjs";
import {Dayjs} from "dayjs";

import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
 import {useAutoEvent} from "../../queries/useAutoEvent";
import {useAuthToken} from "../../slices/authSlice";
import {useFetchOrgSettings} from "../OrgSettingsPage/useFetchOrgSettings";
import {OrgState, setOrg, useOrgState} from "../../slices/orgSlice";
import imageCompression from "browser-image-compression";
import {QueryClient} from "react-query";
import axios from "axios";

type FormData = {
  type: string;
  status: string;
  message: string;
  eventid: number;
  name: string;
  code: string;
  actual: Dayjs;
  timezone: string;
  textprefix: string;
  ccfee: number;
  logo: string; 
  logourl: string;
  logoremoved: boolean; 
  eventprivacy: string;
  storeheader: string;
  storebuttonevent: string;
  storebuttonauction: string;
  storefooter: string;
  active: boolean;
  delete: boolean; 
  checkboxsettings : {
    billme: boolean;
    allowattendedbytickettype: boolean;
    allowpaymentoffees: boolean;
    storebuttoneventshow: boolean;
    storebuttonauctionshow: boolean;
  }
 
}
interface Params {
  id: string
}

const EventSettingsForm = ({ isEdit = false }) => {
    const warningMsg = "WARNING: Changing the Event Code will change your event URL."
    const { id } = useParams<Params>()
    useAutoEvent(id)
    const orgState = useOrgState()

    const formMethods = useForm<FormData>()
    const { reset, watch, setValue } = formMethods
    const watchLogo = watch('logourl')
    const watchLogoData = watch('logo')
    const history = useHistory()
    const [dialogObj, setDialogObj] = React.useState({ msg: "", show: false })

    const dispatch = useDispatch()
    const authToken = useAuthToken()
    const queryClient = new QueryClient()
    const { data: settings } = useFetchOrgSettings({ userid: authToken?.userid })

    useEffect(() => {
        if (settings) {
            const orgState: OrgState = {
                name: settings?.name, message: settings?.message, isdonorsnapcustomer: settings?.isdonorsnapcustomer, twilio: settings?.twilio
            }
            dispatch(setOrg(orgState))
        }
    }, [settings])

    const [errorMsg, setErrorMsg] = useState({
        show: false,
        msg: ""
    })

    const [successMsg, setSuccessMsg] = useState<boolean>(false)
    const [lockCode, setLockCode] = useState<boolean>(true)
    const [dialogMsg, setDialogMsg] = React.useState("");
    const [openFailure, setFailureOpen] = React.useState(false);
    const [failureMessage, setFailureMessage] = React.useState<string>("");
    const [storeHeader, setStoreHeader] = useState('')
    const [storeFooter, setStoreFooter] = useState('')
    const [eventPrivacy, setEventPrivacy] = useState('')

    const [eventsettings, setEventSettings] = useState({
        type: "",
        status: "",
        message: "",
        eventid: 0,
        name: "",
        code: "",
        actual: "",
        timezone: "",
        allowtexting: false,
        textprefix: "",
        allowpaymentoffees: false,
        billme: false,
        allowattendedbytickettype: false,
        ccfee: 0,
        logourl: "",
        eventprivacy: "",
        storeheader: "",
        storebuttonevent: "",
        storebuttoneventshow: false,
        storebuttonauction: "",
        storebuttonauctionshow: false,
        storefooter: "",
        active: false
    })

    useEffect(() => {
        getEventSettingsData(Number(id))
    }, [])

    // useEffect(() => {
    //     console.log(eventsettings)
    // }, [eventsettings])

    const getEventSettingsData = async (eventId) => {
        const json = JSON.stringify({ eventid: eventId })
        const { data } = await axios.post('/evtsettings', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const parsedData = JSON.parse(data.jsonString)
        setEventSettings(parsedData)
    }

    const { data : timeZoneData } = useFetchTimezones()
    const timezones = timeZoneData?.map(timeZone => {
        return { value: timeZone.timezone, label: timeZone.timezone }
    })
    const editMutation = useEditEventSettings()
    const eventCodeRegex = /^[-_A-Za-z0-9]+$/
    const richTextTools = [
        [EditorTools.Bold, EditorTools.Italic, EditorTools.Underline, EditorTools.Strikethrough],
        // [EditorTools.Subscript, EditorTools.Superscript],
        EditorTools.ForeColor,
        EditorTools.BackColor,
        [EditorTools.CleanFormatting],
        [EditorTools.AlignLeft, EditorTools.AlignCenter, EditorTools.AlignRight, EditorTools.AlignJustify],
        [EditorTools.Indent, EditorTools.Outdent],
        [EditorTools.OrderedList, EditorTools.UnorderedList],
        // [EditorTools.NumberedList, EditorTools.BulletedList],
        EditorTools.FontSize,
        EditorTools.FontName,
        EditorTools.FormatBlock,
        [EditorTools.SelectAll],
        [EditorTools.Undo, EditorTools.Redo],
        [EditorTools.Link, EditorTools.Unlink, EditorTools.InsertImage, EditorTools.ViewHtml],
        [EditorTools.InsertTable, EditorTools.InsertFile],
        // [EditorTools.Pdf, EditorTools.Print],
        [EditorTools.AddRowBefore, EditorTools.AddRowAfter, EditorTools.AddColumnBefore, EditorTools.AddColumnAfter],
        [EditorTools.DeleteRow, EditorTools.DeleteColumn, EditorTools.DeleteTable],
        [EditorTools.MergeCells, EditorTools.SplitCell],
    ]
    const onEditSubmit = (formData: FormData) => {
        console.log('onEditSubmit')
        console.log(formData)
        const {
            checkboxsettings,
            actual,
            ...rest
        } = formData
        editMutation.mutate({
            eventid: Number(id),
            actual: dayjs(actual).format('YYYY-MM-DD'),
            ...rest,
            ...checkboxsettings,
            ccfee: Number(formData.ccfee),
            storeheader: storeHeader,
            storefooter: storeFooter,
            eventprivacy: eventPrivacy
        },{
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                if (json.status === "failure") {
                    setFailureMessage(json.message)
                    setFailureOpen(true)
                }
                else {
                    const eventState: EvtState = {
                      eventId: json.eventId,
                      eventName: json.name,
                      eventCode: json.code,
                      allowpaymentoffees: json.allowpaymentoffees,
                      billme: json.billme,
                      allowattendedbytickettype: json.allowattendedbytickettype,
                      ccfee: json.ccfee
                    }
                    dispatch(setEvt(eventState))
                    setDialogMsg("Event was updated successfully.")
                }
            }
        })
    }

    const handleClickContinue = () => {
        const isLock = (warningMsg === dialogMsg);
        setDialogMsg("")
        if(isLock){
            setLockCode(!lockCode)
        }

        if (!isLock && !isEdit) {
            history.goBack()
        }
    };

    const handleClose = () => {
        setDialogMsg("")
    };

    const closeFailure = () => {
        setFailureOpen(false);
    };

    const showDialog = () => {
        return (
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {((warningMsg === dialogMsg)) && (
                            <Button onClick={handleClose}>Cancel</Button>
                        )}
                        <Button onClick={handleClickContinue} autoFocus>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const showFailureDialog = () => {
        return (
            <>
                <Dialog
                    open={openFailure}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <b>Failure:</b> {failureMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeFailure}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const FloatingButton = styled(Button)<ButtonProps>(({ theme }) => ({
        margin:'auto',
        top: 'auto',
        //  right: '40%',
        bottom: 20,
        left: '25%',
        position: 'fixed',
        width: '50%'
    }))

    const onCreateSubmit = (formData: FormData) => {
        const {
            actual,
            ...rest
        } = formData
        editMutation.mutate({
            ...rest,
            actual: dayjs(actual).format('YYYY-MM-DD'),
            ccfee: Number(formData.ccfee),
            active: true
        },{
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                if (json.status === "failure") {
                    setFailureMessage(json.message)
                    setFailureOpen(true)
                }
                else {
                    // setDialogMsg("Event was created successfully.")
                    history.push('/auction/' + json.eventid)
                }
            }
        })
    }

    const onLogoDrop = async (files: File[]) => {
        const options = {
            maxSize: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        const compressedFile = await imageCompression(files[0], options)

        const fileReader = new FileReader()
        fileReader.onload = function (event) {
            const data = event.target.result as string
            setValue('logo', data.split(';base64,')[1])
        }
        fileReader.readAsDataURL(compressedFile)
    }

    const clickLockOpen = () => {
        setDialogMsg(warningMsg);
    }

    const clickLockClose = () => {
        setDialogMsg("");
        setLockCode(!lockCode)
    }

    const clickRemoveLogo = () => {
        setValue("logourl", "")
        setValue('logoremoved', true)
    }

    useEffect(() => {
        if (eventsettings) {
            // const bidEndDate = eventsettings.actual ? eventsettings?.actual?.split(' ').join('T') : new Date().toISOString()
            // const timeZoneForm = eventsettings.timezone ? eventsettings.timezone : 'Central Standard Time'

            reset({
                type: eventsettings.type,
                status: eventsettings.status,
                message: eventsettings.message,
                eventid: eventsettings.eventid,
                name: eventsettings.name,
                code: eventsettings.code,
                actual: dayjs(eventsettings.actual),
                timezone: eventsettings.timezone,
                textprefix: eventsettings.textprefix,
                ccfee: eventsettings.ccfee,
                //logo: eventsettings.logo,
                logourl: eventsettings.logourl,
                logoremoved: false,
                eventprivacy: eventsettings.eventprivacy,
                storeheader: eventsettings.storeheader,
                storebuttonevent: eventsettings.storebuttonevent,
                storebuttonauction: eventsettings.storebuttonauction,
                storefooter: eventsettings.storefooter,
                active: eventsettings.active,
                delete: false,
                checkboxsettings: {
                    allowpaymentoffees: eventsettings.allowpaymentoffees,
                    billme: eventsettings.billme,
                    allowattendedbytickettype: eventsettings.allowattendedbytickettype,
                    storebuttoneventshow: eventsettings.storebuttoneventshow,
                    storebuttonauctionshow: eventsettings.storebuttonauctionshow
                }
            });
            setStoreHeader(eventsettings.storeheader)
            setStoreFooter(eventsettings.storefooter)
            setEventPrivacy(eventsettings.eventprivacy)

        }}, [eventsettings, isEdit])

    const handleChange = (e, field) => {
        if (field.name === "checkboxothersettings.approvalofbidders" && field.value === false) {
            setDialogObj({msg: "Caution: Checking this box means that you will need to regularly check the Participants page to manually approve any newly registered participants. Participants that are not approved will not be able to bid on any items. To automatically approve all newly registered participants, leave this setting unchecked.", show: true})
        }
        else if (field.name === "checkboxothersettings.allowcustomlocations" && field.value === false) {
            setDialogObj({msg: "Caution: Checking this box means that all bidding open/close times are controlled by the Location settings. Changing the bidding open/close times on this page will not affect any existing location close times. Only use this setting if you plan to close tables at different times.", show: true})
        }
    }

    const disableTexting = () => {
        const twilio = orgState?.twilio

        return twilio.valid === false
    }

    return (
        <div className={styles.root}>
            {showDialog()}
            {showFailureDialog()}

            <>
                <Dialog
                    open={dialogObj.show}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setDialogObj({ msg: '', show: false })
                            }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            <h1>Event Settings</h1>
            <HookForm
                methods={formMethods}
                onSubmit={onEditSubmit}
                className={styles.form}
                formProps={{ id: 'EventSettings-form-new' }}>
                {/*<LoadingBackdrop open={isFetching} />*/}
                <TextInput
                    name="name"
                    label="Event Name"
                    required
                    tooltipText={'The name that will be displayed to your guests'}
                />
                <Grid container spacing={1}>
                    <Grid item xs={11} className={styles.selectcontrol}>
                        <TextInput
                            name="code"
                            label="Event Code"
                            required
                            disabled={isEdit && lockCode}
                            rules={{
                                pattern: {
                                    value: eventCodeRegex,
                                    message:
                                        'Event code can only contain letters, numbers, hyphens, underscores, and no spaces.'
                                }
                            }}
                            tooltipText={
                                'The part of the URL to specify your event. No spaces or special characters allowed. Keep it short and easy to remember.'
                            }
                        />
                    </Grid>
                    {isEdit && (
                        <Grid item xs={1} className={styles.selectcontrol}>
                            {!lockCode && <Lock fontSize="large" onClick={clickLockClose} />}
                            {lockCode && (
                                <LockOpen fontSize="large" onClick={clickLockOpen} />
                            )}
                        </Grid>
                    )}
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={6} className={styles.selectcontrol}>
                        <Controller
                            control={formMethods.control}
                            name="actual"
                            defaultValue={dayjs()}
                            rules={{ required: true }}
                            render={({ field }) => {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date"
                                            slotProps={{ textField: { size: 'small' } }}
                                            defaultValue={dayjs()}
                                            value={field.value}
                                            inputRef={field.ref}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                        />
                                    </LocalizationProvider>

                                );
                            }}
                        />

                    </Grid>
                    <Grid item xs={6} className={styles.selectcontrol}>
                        <SelectInput
                            name="timezone"
                            label={'Timezones'}
                            options={timezones}
                            defaultValue={
                                timezones && timezones.length > 0 ? timezones[0].label : ''
                            }
                            includeNone={false}
                            tooltipText={
                                'The timezone where your event will be held. Dates/times will automatically adjust for each user’s location on their device.'
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.selectcontrol}>
                        <CheckboxGroup
                            name="checkboxsettings"
                            onChange={(e, field) => {
                                handleChange(e, field)
                            }}
                            options={[
                                {
                                    value: 'billme',
                                    label: 'Allow Bill Me',
                                    tooltipText: 'Allow checkout without immediate payment in order to send an invoice at a later date.'
                                }
                            ]}
                            label=""
                        />
                    </Grid>
                    <Grid item xs={4} className={styles.selectcontrol}>
                        <CheckboxGroup
                            name="checkboxsettings"
                            onChange={(e, field) => {
                                handleChange(e, field)
                            }}
                            options={[
                                {
                                    value: 'allowattendedbytickettype',
                                    label: 'Track Attended per Ticket',
                                    tooltipText: ''
                                }
                            ]}
                            label=""
                        />
                    </Grid>
                    <Grid item xs={4} className={styles.selectcontrol}>
                        <CheckboxGroup
                            name="checkboxsettings"
                            options={[
                                {
                                    value: 'allowpaymentoffees',
                                    label: 'Allow Payment of CC Fees',
                                    tooltipText:
                                        'Allow user to pay the CC Fee.',
                                    textoptions: {
                                        name:"ccfee",
                                        label:"CC Fee % to Add",
                                        defaultValue:"2.5",
                                        endAdornment:"%",
                                        required: true,
                                        tooltipText:
                                            'If the user opts to cover the payment processing fees, this percentage will be added to their payment total.'
                                    }
                                }
                            ]}
                            label=""
                        />
                    </Grid>
                </Grid>


                <Grid container spacing={1}>
                    {watchLogo && (
                        <Grid item xs={6} className={styles.selectcontrol}>
                            <div>
                                {' '}
                                <Close fontSize="small" onClick={clickRemoveLogo} />
                            </div>
                            <div className={styles.logoWrapper}>
                                <img src={watchLogo} alt="event logo" />
                            </div>
                        </Grid>
                    )}

                    <Grid item xs={6} className={styles.selectcontrol}>
                        {watchLogoData && (
                            <>
                                <div>New Event Logo</div>
                                <div className={styles.logoWrapper}>
                                    <img
                                        src={`data:image/png;base64,${watchLogoData}`}
                                        alt="event logo"
                                    />
                                </div>
                            </>
                        )}
                    </Grid>
                </Grid>
                <h3>Event Logo</h3>
                <Dropzone onDrop={onLogoDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()} className={styles.dropzone}>
                                <input {...getInputProps()} />
                                <p>
                                    Drag 'n' drop a file here, or click to select a file. Event
                                    logo will be displayed with a max height of 54px. For best
                                    mobile experience, your logo should not exceed 215px by 54px.
                                    Only .jpg, .jpeg, .png, .gif, and .bmp images under 2MB are
                                    allowed.
                                </p>
                            </div>
                        </section>
                    )}
                </Dropzone>

                <div className={styles.inputsContainer}>
                    <h3>Event Welcome Page Header</h3>
                    <h5>This content will show above the login fields while the event is open.</h5>
                    <Editor
                        tools={richTextTools}
                        contentStyle={{ height: 230 }}
                        value={storeHeader}
                        onChange={(eventState) => {
                            // console.log(eventState)
                            setStoreHeader(eventState.html)
                        }}
                    />
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={4} className={styles.selectcontrol}>
                        <CheckboxGroup
                            name="checkboxsettings"
                            checkboxProps={{ disabled: disableTexting() }}
                            options={[
                                {
                                    value: 'storebuttoneventshow',
                                    label: 'Show Event Button',
                                    tooltipText:
                                        '',
                                    textoptions: {
                                        name: 'storebuttonevent',
                                        label: 'Event Button Text',
                                        defaultValue: 'Register',
                                        maxLength: 50,
                                        tooltipText:
                                            ''
                                    }
                                }
                            ]}
                            label=""
                        />
                    </Grid>
                    <Grid item xs={4} className={styles.selectcontrol}>
                        <CheckboxGroup
                            name="checkboxsettings"
                            checkboxProps={{ disabled: disableTexting() }}
                            options={[
                                {
                                    value: 'storebuttonauctionshow',
                                    label: 'Show Auction Button',
                                    tooltipText:
                                        '',
                                    textoptions: {
                                        name: 'storebuttonauction',
                                        label: 'Auction Button Text',
                                        defaultValue: 'Auction',
                                        maxLength: 50,
                                        tooltipText:
                                            ''
                                    }
                                }
                            ]}
                            label=""
                        />
                    </Grid>
                </Grid>
                <div className={styles.inputsContainer}>
                    <h3>Event Welcome Page Footer</h3>
                    <h5>This content will show above the login fields while the event is open.</h5>
                    <Editor
                        tools={richTextTools}
                        contentStyle={{ height: 230 }}
                        value={storeFooter}
                        onChange={(eventState) => {
                            setStoreFooter(eventState.html)
                        }}
                    />
                </div>
                <div className={styles.inputsContainer}>
                    <h3>Event Privacy/Refund Policy</h3>
                    <h5>This content will show above the login fields while the event is open.</h5>
                    <Editor
                        tools={richTextTools}
                        contentStyle={{ height: 230 }}
                        value={eventPrivacy}
                        onChange={(eventState) => {
                            setEventPrivacy(eventState.html)
                        }}
                    />
                </div>

                <FloatingButton
                    type="submit"
                    variant="contained"
                    className={styles.button}
                    form="EventSettings-form-new"
                >
                    Save
                </FloatingButton>

                <Alerts
                    errorMsg={errorMsg.show ? errorMsg.msg : null}
                    successMsg={successMsg ? 'Submitted successfully' : null}
                />
            </HookForm>
        </div>
    )
}

export default EventSettingsForm
