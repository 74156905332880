import React, {useEffect} from 'react'
import styles from "./TransferPage.module.css";
import {useParams} from "react-router";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import Paper from "@material-ui/core/Paper";
import SelectInput from "../../components/common/inputs/SelectInput";
import TextInput from "../../components/common/inputs/TextInput";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import axios from "axios";
import HookForm from "../../components/common/HookForm";
import {useForm} from "react-hook-form";
import {DateInput} from "@progress/kendo-react-dateinputs";
import dayjs from "dayjs";

interface FormData {
    batchCodeSelect: string
    batchCodeText: string
    CampaignSelect: string
    CampaignText: string
    AppealSelect: string
    AppealText: string
    AccountingCodeSelect: string
    AccountingCodeText: string
    DonationTypeSelect: string
    DonationTypeText: string
}
const DonationPage = (params) => {
    const formMethods = useForm<FormData>()
    const { setValue, watch, getValues } = formMethods
    const { reset } = formMethods
    const eventid = params.eventid

    const batchCodeSelectWatch = watch('batchCodeSelect')
    const batchCodeTextWatch = watch('batchCodeText')
    const campaignSelectWatch = watch('CampaignSelect')
    const campaignTextWatch = watch('CampaignText')
    const appealSelectWatch = watch('AppealSelect')
    const appealTextWatch = watch('AppealText')
    const donationTypeSelectWatch = watch('DonationTypeSelect')
    const dontaionTypeTextWatch = watch('DonationTypeText')
    const accountCodeSelectWatch = watch('AccountingCodeSelect')
    const accountCodeTextWatch = watch('DonationTypeText')
    const [donationData, setDonationData] = React.useState({numberOfCheckoutsPosted: 0, numberOfItemsPosted: 0, totalAmtPosted: 0, numberOfCheckoutsUnpostedLink: 1,
        numberOfItemsUnpostedLink: 1, totalAmtUnpostedLink: 1,
        extraAmtUnpostedLink: 0, numberOfCheckoutsUnpostedUnlink: 0,
        numberOfItemsUnpostedUnlink: 0, totalAmtUnpostedUnlink: 0, campaignlist: [], appeallist: [], batchcodelist: [], accountingcodelist: [], donationtypelist:[]})


    const [valueDate, setDateValue] = React.useState(new Date())
    const [dialogComplete, setdialogComplete] = React.useState({open: false, message: ""})
    const changeDate = (event) => {
        setDateValue(event.value);
    }

    const [checkedAck, setAckChecked] = React.useState(false);
    const handleAckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAckChecked(event.target.checked);
    }

    const [checkedFair, setFairChecked] = React.useState(false);
    const handleFairChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFairChecked(event.target.checked);
    }

    useEffect(() => {
        getIntegrationDonation()
    }, [eventid])

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            button: {
                margin: theme.spacing(1),
                paddingLeft: 10,
                marginLeft: 10,
                marginRight: 10,
            },
            paper: {
                padding: theme.spacing(2),
                spacing: theme.spacing(100),
                paddingLeft: 10,
                marginLeft: 10,
                marginRight: 10,
                textAlign: 'left',
                fontSize: 25,
                color: theme.palette.text.secondary
            }
        })
    )
    const classes = useStyles()

    const getBatchCodeText = (id) => {
        var textReturn = ''
        donationData?.batchcodelist?.forEach((data) => {
            if (data.id === id) {
                textReturn = data.name
            }
        })
        return textReturn
    }

    const batchCodeOptions = () => {
        const selectOptions = [{value: 'none', label: 'None'}]
        return [...selectOptions, ...donationData?.batchcodelist?.map((data) => {
            return {value: data.id, label: data.name}
        })]
    }

    const getCampaignText = (id) => {
        var textReturn = ''
        donationData?.campaignlist?.forEach((data) => {
            if (data.id === id) {
                textReturn = data.name
            }
        })
        return textReturn
    }

    const getCampaignOptions = () => {
        const selectOptions = [{value: 'none', label: 'None'}]
        return [...selectOptions, ...donationData?.campaignlist?.map((data) => {
            return {value: data.id, label: data.name}
        })]
    }


    const getIntegrationDonation = async () => {
        axios.post('/integrationdonation', {eventid: eventid}, {responseType: "json"}).then(response => {
            const parsedData = JSON.parse(response.data.jsonString)
            console.log(parsedData)
            setDonationData(parsedData)
        })
    }

    const getAppealText = (id) => {
        var textReturn = ''
        donationData?.appeallist?.forEach((data) => {
            if (data.id === id) {
                textReturn = data.name
            }
        })
        return textReturn
    }

    const getAppealOptions = () => {
        const selectOptions = [{value: 'none', label: 'None'}]
        return [...selectOptions, ...donationData?.appeallist?.map((data) => {
            return {value: data.id, label: data.name}
        })]
    }

    const getAccountingText = (id) => {
        var textReturn = ''
        donationData?.accountingcodelist?.forEach((data) => {
            if (data.id === id) {
                textReturn = data.name
            }
        })
        return textReturn
    }

    const getAccountingCodeOptions = () => {
        const selectOptions = [{value: 'none', label: 'None'}]
        return [...selectOptions, ...donationData?.accountingcodelist?.map((data) => {
            return {value: data.id, label: data.name}
        })]
        // return [{value: 'none', label: 'None'}]
    }

    const getDonationText = (id) => {
        var textReturn = ''
        donationData?.donationtypelist?.forEach((data) => {
            if (data.id === id) {
                textReturn = data.name
            }
        })
        return textReturn
    }

    const getDonationTypeOptions = () => {
        const selectOptions = [{value: 'none', label: 'None'}]
        return [...selectOptions, ...donationData?.donationtypelist?.map((data) => {
            return {value: data.id, label: data.name}
        })]
    }

    const onSubmit = async (data: any) => {

        console.log(data)
    }

    const handleSubmit = () => {
        var body = {}
        if (batchCodeTextWatch?.length > 0) {
            body['batchcode'] = batchCodeTextWatch
        }
        else {
            body['batchcode'] = getBatchCodeText(batchCodeSelectWatch)
        }
        if (campaignTextWatch?.length > 0) {
            body['campaign'] = campaignTextWatch
        }
        else {
            body['campaign'] = getCampaignText(campaignSelectWatch)
        }
        if (appealTextWatch?.length > 0) {
            body['appeal'] = appealTextWatch
        }
        else {
            body['appeal'] = getAppealText(appealSelectWatch)
        }
        if (dontaionTypeTextWatch?.length > 0) {
            body['donation'] = dontaionTypeTextWatch
        }
        else {
            body['donation'] = getDonationText(donationTypeSelectWatch)
        }
        if (accountCodeTextWatch?.length > 0) {
            body['accountingcode'] = accountCodeTextWatch
        }
        else {
            body['accountingcode'] = getAccountingText(accountCodeSelectWatch)
        }
        body['fairmarketvalue'] = checkedFair
        body['donationack'] = checkedAck
        body['eventid'] = Number(eventid)
        body['accountingdate'] = dayjs(valueDate).format('MM-DD-YYYY')


        axios.put('/integrationdonation', body, {responseType: "json"}).then(response => {
            const parsedData = JSON.parse(response.data.jsonString)
            console.log(parsedData)
            setdialogComplete({message: parsedData.message, open: true})
            getIntegrationDonation()

        })
    }

    return (
        <div>
            <>
                <Dialog open={dialogComplete.open}>
                    <DialogContent>
                        <HookForm
                            methods={formMethods}
                            onSubmit={onSubmit}
                            className={styles.donationForm}
                            formProps={{ id: 'donation-form' }}>
                            {/*<text>Successfully added {dialogComplete.count} contacts to DonorSnap.</text>*/}
                            <text>{dialogComplete.message}</text>
                        </HookForm>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            className={styles.submitButton}
                            form="donation-form"
                            onClick={() => setdialogComplete({open: false, message: ''})}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </>

            <Grid container spacing={1} columns={{ xs: 12 }}>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.donationForm}
                    formProps={{ id: 'donation-form' }}>
                <Grid item xs={12} >
                    <Paper className={classes.paper}>Donation Record Settings to Upload into DonorSnap</Paper>
                </Grid>
                <Grid container sx={{  marginLeft: 2, marginTop: 2 }} spacing={1} columns={{ xs: 12}}>
                    <Grid item xs={4} className={styles.donationcontrol}>
                        <SelectInput
                            name="batchCodeSelect"
                            label="Batch Code"
                            options={batchCodeOptions()}
                            includeNone={false}
                            disabled={batchCodeTextWatch?.length > 0}
                            tooltipText={''}
                        />
                    </Grid>
                    <Grid item xs={0.5} className={styles.donationcontrol}>
                        <div style={{justifyContent: 'space-evenly', display: "flex", alignItems: 'center', verticalAlign: 'middle'}}>OR</div>
                    </Grid>
                    <Grid item xs={4} className={styles.donationcontrol}>
                        <TextInput name="batchCodeText" label="New Batch Code" maxLength={200}/>
                    </Grid>
                </Grid>
                <Grid container sx={{  marginLeft: 2, marginTop: 2 }} spacing={1} columns={{ xs: 12}}>
                    <Grid item xs={4} className={styles.donationcontrol}>
                        <SelectInput
                            name="CampaignSelect"
                            label="Campaign"
                            options={getCampaignOptions()}
                            includeNone={false}
                            disabled={campaignTextWatch?.length > 0}
                            tooltipText={''}
                        />
                    </Grid>
                    <Grid item xs={0.5} className={styles.donationcontrol}>
                        <div style={{justifyContent: 'space-evenly', display: "flex", alignItems: 'center', verticalAlign: 'middle'}}>OR</div>
                    </Grid>
                    <Grid item xs={4} className={styles.donationcontrol}>
                        <TextInput name="CampaignText" label="New Campaign" maxLength={200}/>
                    </Grid>
                </Grid>
                <Grid container sx={{  marginLeft: 2, marginTop: 2 }} spacing={1} columns={{ xs: 12}}>
                    <Grid item xs={4} className={styles.donationcontrol}>
                        <SelectInput
                            name="AppealSelect"
                            label="Appeal"
                            options={getAppealOptions()}
                            includeNone={false}
                            disabled={appealTextWatch?.length > 0}
                            tooltipText={''}
                        />
                    </Grid>
                    <Grid item xs={0.5} className={styles.donationcontrol}>
                        <div style={{justifyContent: 'space-evenly', display: "flex", alignItems: 'center', verticalAlign: 'middle'}}>OR</div>
                    </Grid>
                    <Grid item xs={4} className={styles.donationcontrol}>
                        <TextInput name="AppealText" label="New Appeal" maxLength={200}/>
                    </Grid>
                </Grid>
                <Grid container sx={{  marginLeft: 2, marginTop: 2 }} spacing={1} columns={{ xs: 12}}>
                    <Grid item xs={4} className={styles.donationcontrol}>
                        <DateInput name="accountingdate"  value={valueDate} onChange={changeDate} label="Accounting Date" />
                    </Grid>
                </Grid>
                <Grid container sx={{  marginLeft: 2, marginTop: 2 }} spacing={1} columns={{ xs: 12}}>
                    <Grid item xs={12} >
                        <FormControlLabel control={<Checkbox name="fairmarktervalue" onChange={handleFairChange}/>} label="Set value received to be the Fair Market Value" />
                    </Grid>
                </Grid>
                <Grid container sx={{  marginLeft: 2 }} spacing={1} columns={{ xs: 12}}>
                    <Grid item xs={12} >
                        <FormControlLabel control={<Checkbox name="donationack"  onChange={handleAckChange}/>} label="Mark all donation records as Acknowledged" />
                    </Grid>
                </Grid>

                {donationData.extraAmtUnpostedLink > 0 &&
                    <>
                            <Grid container sx={{  marginLeft: 2, marginTop: 3 }} spacing={1} columns={{ xs: 12}}>
                                <Grid item xs={12} >
                                    <div><b>For all additional donations made during checkout, use the following settings:</b></div>
                                </Grid>
                            </Grid>
                            <Grid container sx={{  marginLeft: 2, marginTop: 2 }} spacing={1} columns={{ xs: 12}}>
                                <Grid item xs={4} className={styles.donationcontrol}>
                                    <SelectInput
                                        name="DonationTypeSelect"
                                        label="Donation Type"
                                        options={getDonationTypeOptions()}
                                        includeNone={false}
                                        disabled={dontaionTypeTextWatch?.length > 0}
                                        tooltipText={''}
                                    />
                                </Grid>
                                <Grid item xs={0.5} className={styles.donationcontrol}>
                                    <div style={{justifyContent: 'space-evenly', display: "flex", alignItems: 'center', verticalAlign: 'middle'}}>OR</div>
                                </Grid>
                                <Grid item xs={4} className={styles.donationcontrol}>
                                    <TextInput name="DonationTypetext" label="New Donation Type" maxLength={200}/>
                                </Grid>
                            </Grid>
                            <Grid container sx={{  marginLeft: 2, marginTop: 2, marginBottom: 4 }} spacing={1} columns={{ xs: 12}}>
                                <Grid item xs={4} className={styles.donationcontrol}>
                                    <SelectInput
                                        name="AccountingCodeSelect"
                                        label="Accounting Code"
                                        options={getAccountingCodeOptions()}
                                        includeNone={false}
                                        disabled={accountCodeTextWatch?.length > 0}
                                        tooltipText={''}
                                    />
                                </Grid>
                                <Grid item xs={0.5} className={styles.donationcontrol}>
                                    <div style={{justifyContent: 'space-evenly', display: "flex", alignItems: 'center', verticalAlign: 'middle'}}>OR</div>
                                </Grid>
                                <Grid item xs={4} className={styles.donationcontrol}>
                                    <TextInput name="AccountingCodetext" label="New Accounting Code" maxLength={200}/>
                                </Grid>
                            </Grid>
                    </>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} className={styles.selectcontrol}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            // type="submit"
                            disabled={donationData?.numberOfCheckoutsUnpostedLink <= 0}
                            form="donation-form"
                            onClick={() => {
                                handleSubmit()
                            }}
                        >
                            UPLOAD {donationData?.numberOfCheckoutsUnpostedLink} CHECKOUTS LINKED TO DONORSNAP CONTACTS
                        </Button>
                    </Grid>
                    <Grid item xs={12} className={styles.selectcontrol}>
                        <div style={{justifyContent: 'left', display: "flex"}}>{donationData.numberOfCheckoutsUnpostedLink} Checkouts not linked to DonorSnap Contacts</div>
                    </Grid>
                    <Grid item xs={12} className={styles.selectcontrol}>
                        <div style={{justifyContent: 'left', display: "flex"}}>{donationData.numberOfCheckoutsPosted} Checkouts already uploaded to DonorSnap</div>
                    </Grid>
                </Grid>

            </HookForm>
            </Grid>

        </div>
    )
}
export default DonationPage