import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

const SearchBar = (props) => {
  const [searchText, setsearchText] = useState("")
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      
      e.preventDefault();
      props.onClick(searchText)
    }
  }
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '90%' }}
    >
     
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        type = 'text'
        placeholder={props.placeHolder}
        onChange={(e) => setsearchText(e.target.value)}
        onKeyPress={(e) => onKeyPress(e)}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) =>  props.onClick(searchText)}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default SearchBar