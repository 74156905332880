import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export const globalEventUserDefinedFetcher = async (body) => {
    const { data } = await axios.post('/evtuserdefined', body)
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.eventuserdefined
}

export const useFetchUserDefined = (eventiDvalue) => {
    return useQuery(
        ['UserDefined', eventiDvalue],
        () => globalEventUserDefinedFetcher({ "eventid": eventiDvalue }),
        {
            staleTime: 120000,
            //keepPreviousData: true
        }
    )
}

export const useCreateEvtUserDefined = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined'])
        }
    })
}

export const useUpdateEvtUserDefined = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.post(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined'])
        }
    })
}

export const useUpdateUserDefined = (eventiDvalue) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined', eventiDvalue])
        }
    })
}