import axios from 'axios'
import { useQuery } from 'react-query'

export type timezone = {
    rownumber: number
    timezone: string
}

const timezoneDefaults = {
    rownumber: 0,
    timezone: "South Sudan Standard Time"
}

export const globalTimezonesFetcher = async () => {
    const { data } = await axios.get('/timezone')
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData.timezones as timezone[]
}

export const useFetchTimezones = () => {
    return useQuery<timezone[]>(
        ['timezone'],
        () => globalTimezonesFetcher(),
        {
            staleTime: 120000,
            placeholderData: [timezoneDefaults],
            keepPreviousData: true
        }
    )
}
