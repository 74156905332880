import React, {useEffect, useState} from 'react'

import {Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip} from '@material-ui/core'
import {Add, Add as AddIcon, Delete, Edit, AddShoppingCartOutlined, ShoppingCartOutlined} from '@material-ui/icons'
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef,  GridRowModel,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useCreateEventTransaction, useFetchTransaction, useUpdateTransaction} from '../../queries/events/useEventTransactionFetch'
import { EventTransactionsForm } from './EventTransactionsForm'
import styles from './EventTransactionsTable.module.css'
import {EventState, setEvent, useEventState} from "../../slices/eventSlice";
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {Grid} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import {useQueryClient} from "react-query";
import dayjs from "dayjs";
import {EventTransactionsDetailForm} from "./TransactionDetailForm";
import {EventAddCheckoutForm} from "./EventAddCheckoutForm";
import {EventCheckoutForm} from "./EventCheckoutForm";
import {EventCheckoutReview} from "./EventCheckoutReview";

const eventItemsDefaults = {
  id: 0,
  control: 0,
  fieldname: '',
  fieldtype: '',
  displayvalue: '',
  active: true,
  uditem: '',
  items: '',
  itemids: [],
  displayforallguest: false,
}

interface Params {
  eventid: string
}

const EventTransactionsTable = () => {

  const { eventid } = useParams<Params>()
  console.log(eventid)
  useAutoEvent(eventid)
  const queryClient = useQueryClient()
  const eventState = useEventState()
  // const eventUserDefineCreate = useCreateEvtUserDefined()
  const [gridParams, setGridParams] = useState(eventItemsDefaults)
  const [rowOrdering, setRowOrdering] = useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [reloadForm, setReloadForm] = useState<number>(0)
  const [transactionId, setTransactionId] = useState<number>(0)
  const { data, isLoading } = useFetchTransaction( Number(eventid) )
  const [isFormShowing, setIsFormShowing] = useState(false)
  const [isAddEventCheckout, setIsAddEventCheckout] = useState(false)
  const [isEventCheckout, setIsEventCheckout] = useState<boolean>(false)
  const [isEventReview, setIsEventReview] = useState<boolean>(false)
  const [itemQuantity, setItemQuantity] = React.useState({})
  var rows = []

  if (data) {
    console.log(data)
    rows = data.map((item) => {
      return { id: item.transactionid, ...item}
    })
  }

  useEffect(() => {
    console.log(transactionId)
  }, [transactionId])

  const handleAdd = () => {
    setReloadForm(reloadForm+1)
    setGridParams(null)
    setIsAddEventCheckout(true)
  }

  const handleCheckoutReview = (params: GridCellParams) => {
    setReloadForm(reloadForm+1)
    const purchaser = rows.find((item) => item.id === Number(params.id))
    setGridParams(purchaser)
    setTransactionId(purchaser.transactionid)
    setIsEventReview(true)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      renderHeader: () => {
        return (
            <IconButton
                className={styles.submitButton}
                onClick={() => handleAdd()}
                size="small">
              <Add />
            </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <>
            <IconButton className={styles.tablebutton} onClick={() => { handleCheckoutReview(params) }}>
              <ShoppingCartOutlined />
            </IconButton>
          </>
      )
    },
    {
      field: 'id',
      hide: true,
      sortable: false,
    },
    {
      field: 'transactiondate',
      headerName: 'Date Added',
      width: 180,
      valueFormatter: (params: GridValueFormatterParams) => {
        return dayjs(params.value as string).format('MM/DD/YYYY hh:mm A')
      },
    },
    {
      field: 'transactionname',
      type: 'string',
      headerName: 'Name',
      minWidth: 200
    },
    {
      field: 'transactionemail',
      type: 'string',
      headerName: 'Email',
      minWidth: 250
    },
    {
      field: 'transactionsource',
      type: 'string',
      headerName: 'Source',
      minWidth: 120
    },
    {
      field: 'transactiontotalamount',
      type: 'string',
      headerName: 'Amount',
      width: 120,
      align: "right",
      valueFormatter: (params: GridValueFormatterParams) => {
        return '$' + params.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      },
    },
    {
      field: 'transactionitemtype',
      type: 'string',
      headerName: 'Transaction Type',
      minWidth: 480
    },

  ]
  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridSortedRowIdsSelector(apiRef);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
      options.allColumns = true;
      apiRef.current.exportDataAsCsv(options);
    }


    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    return (
        <>
          <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                  <Button
                      {...buttonBaseProps}
                      onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                  >
                    EXPORT
                  </Button>
                  {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
          </GridToolbarContainer>
        </>
    )
  }

  const handleNext = () => {
    setIsAddEventCheckout(false)
    setIsEventCheckout(true)
  }

  return (
      <div className={styles.root}>
        <h1>Event Transactions</h1>
      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <EventTransactionsDetailForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} eventid={eventid} itemrows={rows}/>
      <EventAddCheckoutForm isOpen={isAddEventCheckout} setIsOpen={setIsAddEventCheckout} gridParams={gridParams} reloadForm={reloadForm} eventid={eventid} transactionId={transactionId} setTransactionId={setTransactionId} handleNext={handleNext} itemQuantity={itemQuantity} setItemQuantity={setItemQuantity} />
      <EventCheckoutForm isOpen={isEventCheckout} setIsOpen={setIsEventCheckout} setIsAddEventCheckout={setIsAddEventCheckout} eventId={eventid} transactionid={transactionId} setItemQuantity={setItemQuantity} setIsEventReview={setIsEventReview} />
      <EventCheckoutReview isOpen={isEventReview} setIsOpen={setIsEventReview} eventId={eventid} transactionid={transactionId} setTransactionId={setTransactionId} setItemQuantity={setItemQuantity} />

      <DataGridPremium
        autoHeight
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
      />
    </div>
  )
}

export default EventTransactionsTable
