import { useForm } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import Switch from '../../../components/common/inputs/Switch'
import HookForm from '../../../components/common/HookForm'
import SelectInput from '../../../components/common/inputs/SelectInput'
import TextInput from '../../../components/common/inputs/TextInput'
import styles from './UsersTable.module.css'
import { useUser } from '../../../mutations/useUser'
import React, {useEffect, useState} from "react";
import validator from 'validator'
import {passwordResetEmail} from "../../../queries/useResetPassword";
import {useEventState} from "../../../slices/eventSlice";
import {AuthToken} from "../../../slices/authSlice";
import {parseJwt} from "../../AuthPage/LoginForm/useLoginMutation";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";

type FormData = {
  userid: number
  email: string
  password: string
  active: boolean
  globalsetup: boolean
  auctionsettings: boolean
  sponsors: boolean
  customusermessage: boolean
  customemail: boolean
  location: boolean
  auctionitems: boolean
  participants: boolean
  manualbidentry: boolean
  checkout: boolean
  participantlisting: boolean
  accountingreport: boolean
  transfertransactionsto: boolean
  transfertransactionsfrom: boolean
  transfernewcontactsto: boolean
  createinteractionrecords: boolean
}

export const OrgUserForm = ({ isOpen, setIsOpen, user, reloadForm }) => {
  const putUser = useUser()
  const formMethods = useForm<FormData>()
  const title = user ? "Edit User" : "Create User"
  const { reset, getValues, setValue } = formMethods
  const [dialogMsg, setDialogMsg] = React.useState("")

  // console.log((user ? user.email : "blank"))
  useEffect(() => {
    // console.log(user)
    setValue('email', "")
    reset({
      // userid: (user ? user.userid : ""),
      email: (user ? user.email : ""),
      password: (user ? "123456789" : ""),
      active: (user ? user.active : true),
      globalsetup: (user ? user.globalsetup : false),
      auctionsettings: (user ? user.auctionsettings : false),
      sponsors: (user ? user.sponsors : false),
      customusermessage: (user ? user.customusermessage : false),
      customemail: (user ? user.customemail : false),
      location: (user ? user.location : false),
      auctionitems: (user ? user.auctionitems : false),
      participants: (user ? user.participants : false),
      manualbidentry: (user ? user.manualbidentry : false),
      checkout: (user ? user.checkout : false),
      participantlisting: (user ? user.participantlisting : false),
      accountingreport: (user ? user.accountingreport : false),
      transfertransactionsto: (user ? user.transfertransactionsto : false),
      transfertransactionsfrom: (user ? user.transfertransactionsfrom : false),
      transfernewcontactsto: (user ? user.transfernewcontactsto : false),
      createinteractionrecords: (user ? user.createinteractionrecords : false)
    })
  }, [reloadForm])

  const onFormSubmit = async (data: FormData) => {
    const finalData = user ? { ...data, userid: user.userid } : { ...data }
    const { data: response } = await putUser.mutateAsync({
      ...finalData
    })
    const parsedResponse = JSON.parse(response?.jsonString)
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse.message)
    }
    else {
      setIsOpen(false)
    }
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  const onResetEmail = async () => {
    console.log('send onSubmit')
    const token = localStorage.getItem('tokenadmin')
    const parsedToken: AuthToken = parseJwt(token)

    const emailSent = await passwordResetEmail(parsedToken.sitecode, getValues("email"))
    if (emailSent) {
      setDialogMsg("An email was sent to " + user?.email + ". Please click the link within the email to reset your password.")
    }
  }

  const passwordFieldEnabled = () => {
    return user ? true : false
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={onFormSubmit}
          className={styles.userForm}
          formProps={{ id: 'user-org-form-entry' }}>
          <TextInput
              name="email"
              label="Email"
              rules={{
                validate: (val) => validator.isEmail(val) || 'Please enter a valid email address.'
              }}
          />
          <TextInput
              name="password"
              label="Password"
              textFieldProps={{ type: 'password', disabled: passwordFieldEnabled() }}
              required
          />
          <SelectInput
            name="active"
            label="Status"
            defaultValue={'Active'}
            options={[
              { label: 'Active', value: true },
              { label: 'Inactive', value: false }
            ]}
            includeNone={false}
          />
          {passwordFieldEnabled() && (
            <Button
              className={styles.resetBtn}
              variant="contained"
              size="small"
              onClick={() => onResetEmail()}>
              Reset User Password
            </Button>
          )}

        {/*<TableContainer component={Paper}>*/}
        {/*  <Table size="small" stickyHeader>*/}
        {/*    <TableHead>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Page</TableCell>*/}
        {/*        <TableCell>Access Level</TableCell>*/}
        {/*      </TableRow>*/}
        {/*    </TableHead>*/}
        {/*    <TableBody>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Global Setup</TableCell>*/}
        {/*        <TableCell><Switch name={'globalsetup'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Auction Settings</TableCell>*/}
        {/*        <TableCell><Switch name={'auctionsettings'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Sponsors</TableCell>*/}
        {/*        <TableCell><Switch name={'sponsors'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Page/Email Customization</TableCell>*/}
        {/*        <TableCell><Switch name={'customusermessage'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Custom Email</TableCell>*/}
        {/*        <TableCell><Switch name={'customemail'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Location</TableCell>*/}
        {/*        <TableCell><Switch name={'location'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Auction Items</TableCell>*/}
        {/*        <TableCell><Switch name={'auctionitems'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Participants</TableCell>*/}
        {/*        <TableCell><Switch name={'participants'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Manual Bid Entry</TableCell>*/}
        {/*        <TableCell><Switch name={'manualbidentry'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Checkout</TableCell>*/}
        {/*        <TableCell><Switch name={'checkout'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Participant Listings</TableCell>*/}
        {/*        <TableCell><Switch name={'participantlisting'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      /!*<TableRow>*!/*/}
        {/*      /!*  <TableCell>Accounting Report</TableCell>*!/*/}
        {/*      /!*  <TableCell><Switch name={'accountingreport'} ></Switch></TableCell>*!/*/}
        {/*      /!*</TableRow>*!/*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Transfer Transactions To</TableCell>*/}
        {/*        <TableCell><Switch name={'transfertransactionsto'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Transfer Transactions From</TableCell>*/}
        {/*        <TableCell><Switch name={'transfertransactionsfrom'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Transfer New Contacts To</TableCell>*/}
        {/*        <TableCell><Switch name={'transfernewcontactsto'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>Create Interaction Records</TableCell>*/}
        {/*        <TableCell><Switch name={'createinteractionrecords'} ></Switch></TableCell>*/}
        {/*      </TableRow>*/}
        {/*    </TableBody>*/}
        {/*  </Table>*/}
        {/*</TableContainer>*/}
        </HookForm>
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="user-org-form-entry">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
