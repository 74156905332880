import React, {useEffect, useState} from 'react'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {AppBar, Button, Grid, Typography} from '@mui/material'

import { ConfirmModal } from '../../components/common/ConfirmModal'
import CheckoutDetails from './CheckoutDetails'
import CheckoutForm from './CheckoutForm'
import CheckoutOrdersGrid from './CheckoutOrdersGrid'
import styles from './CheckoutPage.module.css'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {useEventState} from "../../slices/eventSlice";
import {CheckoutData, CheckoutObject} from "../../queries/useFetchCheckout";
import CheckoutPage from "./CheckoutPage";
import CheckoutAllPage from "./CheckoutAllPage";

export enum CheckoutPageTab {
    Checkout,
    ResendReceipt,
    PDF,
    Void,
    None
}

interface Params {
    eventid: string
}

const CheckoutMainPage = () => {
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const eventState = useEventState()
    const [tab, setTab] = useState(CheckoutPageTab.None)
    const [showPayment, setShowPayment] = useState(true)
    const [tabSel, setTabSel] = React.useState<number>(0)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }


    return (
        <div className={styles.root}>
            <h2>Checkout</h2>
            <div className={styles.grid}>
                <div className={styles.root}>
                    <Grid container spacing={1}>
                        <AppBar position="static" color="default">
                            {/*<Typography*/}
                            {/*    variant="h6"*/}
                            {/*    color="primary"*/}
                            {/*    align="center"*/}
                            {/*    component="div">*/}
                            {/*    Checkout*/}
                            {/*</Typography>*/}
                            <Tabs
                                value={tabSel}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab value={0} label={'Checkout Participant'}></Tab>
                                <Tab value={1} label={'Checkout History'}></Tab>
                            </Tabs>
                            {tabSel === 0 && (
                                <CheckoutAllPage />
                            )}
                            {tabSel === 1 && (
                                <CheckoutPage />
                            )}
                        </AppBar>
                    </Grid>
                </div>
             </div>
         </div>
    )
}

export default CheckoutMainPage
