import { DropzoneDialog } from 'material-ui-dropzone'
import { readAsDataURL } from 'promise-file-reader'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, Grid
} from '@material-ui/core'
import {Close} from '@material-ui/icons'

import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import TextInput from '../../components/common/inputs/TextInput'
import { Sponsor } from '../../queries/events/useFetchSponsors'
import { useEventState } from '../../slices/eventSlice'
import styles from './SponsorsForm.module.css'
import { useSponsorsMutation } from './useSponsorsMutation'
import Dropzone from "react-dropzone";
import SelectInput from "../../components/common/inputs/SelectInput";
import imageCompression from "browser-image-compression";

type FormData = {
  name: string
  logo: string
  logourl: string
  logoremoved: boolean
  active: boolean
}

type Props = {
  isOpen: boolean
  setIsOpen: Function
  sponsor?: Sponsor
  reloadForm?: number
}

const UserForm = ({ isOpen, setIsOpen, sponsor, reloadForm }: Props) => {
  const event = useEventState()
  const formMethods = useForm<FormData>()
  const { setValue, watch, reset } = formMethods
  const watchLogo = watch('logourl')
  const watchLogoData = watch('logo')

  const isEdit = Boolean(sponsor)

  const createMutation = useSponsorsMutation()
  const editMutation = useSponsorsMutation()
  const [dialogMsg, setDialogMsg] = React.useState("")

  useEffect(() => {
      reset({
        logo: (sponsor ? sponsor.logo : ""),
        logourl: (sponsor ? sponsor.logourl : ""),
        logoremoved: false,
        name: (sponsor ? sponsor.name : ""),
        active: (sponsor ? sponsor.active : true)
      })
  }, [reloadForm])

  const onCreateSubmit = async (data: FormData) => {
    const { data: response } = await createMutation.mutateAsync({
      ...data,
      eventid: Number(event.eventId),
      logo: data.logo
    })
    const parsedResponse = JSON.parse(response?.jsonString)
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse.message)
    }
    else {
      setIsOpen(false)
    }
  }

  const onEditSubmit = async (data: FormData) => {
    const { data: response } = await editMutation.mutateAsync({
      ...data,
      sponsorid: sponsor.sponsorid,
      eventid: Number(event.eventId),
    })
    const parsedResponse = JSON.parse(response?.jsonString)
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse.message)
    }
    else {
      setIsOpen(false)
    }
  }

  const onLogoDrop = async (files: File[]) => {
    const options = {
      maxSize: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    const compressedFile = await imageCompression(files[0], options)

    if (files && files.length > 0) {
      const fileReader = new FileReader()
      fileReader.onload = function (event) {
        const data = event.target.result as string
        setValue('logo', data.split(';base64,')[1])
      }
      fileReader.readAsDataURL(compressedFile)
    }
  }

  const clickRemoveLogo = () => {
    setValue("logourl", "")
    setValue('logoremoved', true)
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{isEdit ? 'Edit' : 'Create'} Sponsor</DialogTitle>
      <DialogContent>
        <LoadingBackdrop
          open={editMutation.isLoading || createMutation.isLoading}
        />
        <>
          <Dialog
              open={dialogMsg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <HookForm
          methods={formMethods}
          onSubmit={isEdit ? onEditSubmit : onCreateSubmit}
          className={styles.form}
          formProps={{ id: 'sponsor-form' }}>
          <TextInput name="name" label="Sponsor Name" required />
          <SelectInput
              name="active"
              label="Status"
              includeNone={false}
              defaultValue={"Active"}
              options={[
                { label: 'Active', value: true },
                { label: 'Inactive', value: false }
              ]}
          />
          <Grid container spacing={1}>
            {watchLogo && (
                <Grid item xs={6} className={styles.selectcontrol}>
                  <div> <Close fontSize="small" onClick={clickRemoveLogo} /></div>
                  <div className={styles.logoWrapper}>
                    <img src={watchLogo} alt="sponsor logo" />
                  </div>
                </Grid>
            )}

            <Grid item xs={6} className={styles.selectcontrol}>
              {watchLogoData && (
                  <>
                    <h5>New Sponsor Logo</h5>
                    <div className={styles.logoWrapper}>
                      <img src={`data:image/png;base64,${watchLogoData}`} alt="event logo" />
                    </div>
                  </>
              )}
            </Grid>
          </Grid>

          <Dropzone
              maxFiles={1}
              accept={'image/jpeg, image/png, image/gif image/bmp'}
              onDrop={onLogoDrop}>
            {({ getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
                acceptedFiles,
                fileRejections
              }) => (
                <section>
                  <h5>Upload Image</h5>
                  <div {...getRootProps()} className={isDragActive ? (isDragAccept ? styles.dropzoneselect : styles.dropzoneinvalid) : styles.dropzone}>
                    <input {...getInputProps()} />
                    {isDragAccept && (<h4>File is a valid image type. Go ahead and drop.</h4>)}
                    {!isDragActive && (<h4>Drag 'n' drop a files here, or click to select a file</h4>)}
                    {isDragReject && (<h4>File cannot be dropped. It's an invalid type.</h4>)}
                    <h5>Sponsor Images will be displayed with a max size of 400px by 90px. Only .jpg, .jpeg, .png, .gif, and .bmp images under 2MB are allowed.</h5>
                  </div>
                </section>
            )}
          </Dropzone>
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setIsOpen(false)}
          className={styles.cancelButton}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color={"primary"}
          className={styles.submitButton}
          type="submit"
          form="sponsor-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserForm
