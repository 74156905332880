import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, IconButton, TextField, Typography
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import SelectInput from '../../components/common/inputs/SelectInput'
import Switch from '../../components/common/inputs/Switch'
import TextInput from '../../components/common/inputs/TextInput'
import styles from './EventTransactionsTable.module.css'
import MaskedInput from "../../components/common/inputs/MaskedInput";
import {Checkbox, FormControlLabel} from "@mui/material";
import NumberFormat from 'react-number-format'
import axios from "axios";
import {useCreateEvtItem} from "../../queries/useEventItemsFetch";
import {DataGridPremium, GridColDef} from "@mui/x-data-grid-premium";
import {Add, Edit} from "@material-ui/icons";

interface FormData {
  name: string
  id: number
  active: boolean
  delete: boolean
}

export const EventValueForm = ({ isValueOpen, setIsValueOpen, activeId, controlDropdownValues, setControlDropdownValues }) => {
  const formMethods = useForm<FormData>()
  const title = activeId ? "Edit Dropdown" : "Add New Dropdown"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [loading, setLoading] = React.useState(false);
  const [activeValue, setActiveValue] = useState<boolean>(true)
  const [dropdownData, setDropdownData] = React.useState({
    fieldtypelist: [],
    itemlist: [],
  });
  const [groupValues, setGroupValues] = useState({
    id: 0,
    name: '',
    active: true
  })

  const createMutation = useCreateEvtItem()

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveValue(event.target.checked);
  }

  useEffect(() => {
    var element = controlDropdownValues.find(c => c.id === activeId)
    if (element) {
      setActiveValue(element?.active)
      reset({
        name: element?.name
      })
    }
    else {
      reset({
        name: ''
      })
    }
}, [activeId, controlDropdownValues])

  const onSubmit = async (data: FormData) => {
    const cdv = controlDropdownValues
    if (activeId) {
      var element = cdv.find(c => c.id === activeId)
      element.name = data.name
      element.active = activeValue
    }
    else {
      var id = -1
      // if (cdv && cdv.length > 0) {
      //   id = Math.max(...cdv.map(o => o.id)) + 1
      // }

      const newValues = [...cdv, {id: id, name: data.name, active: activeValue}]
      setControlDropdownValues(newValues)
    }

    setIsValueOpen(false)
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  return (
    <Dialog open={isValueOpen} maxWidth="xl"  className={styles.dialog}>
      <DialogTitle>{title}</DialogTitle>
      <LoadingBackdrop open={createMutation.isLoading || loading} />
      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={onSubmit}
          className={styles.itemsForm}
          formProps={{ id: 'value-form' }}>
          <TextInput name="name" label="Name" required />
          <FormControlLabel control={<Checkbox name="active" checked={activeValue} onChange={handleActiveChange}/>} label="Active" />
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsValueOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="value-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
