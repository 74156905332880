import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export const globalEventTransactionFetcher = async (body) => {
    const { data } = await axios.post('/evtactivitytickets', body)
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData
}

export const useFetchTickets = (eventIDvalue, ticketItemId) => {
    return useQuery(
        ['EventActivityTickets', eventIDvalue, ticketItemId],
        () => globalEventTransactionFetcher({ "eventid": eventIDvalue, ticketitemid: ticketItemId }),
    )
}

export const useCreateEventTickets = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined'])
        }
    })
}

export const useUpdateTickets = (eventIDvalue) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined', eventIDvalue])
        }
    })
}