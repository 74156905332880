export const getConfig = () => {
  if (window.location.hostname === 'localhost') {
    console.log('Setting up for Local')
    return {
      apiUrl: 'https://stagingapi.auctionsnap.com:8443',
      auctionSnapURL: 'localhost:3000'
    }
  }
  else if (window.location.hostname === 'testadmin.auctionsnap.com') {
    console.log('Setting up for Dev')
    return {
      apiUrl: 'https://testapi.auctionsnap.com:8443',
      auctionSnapURL: 'https://testapp.auctionsnap.com',
      version: 'AuctionSnap Admin 1.0.10',
    }
  }
  else if (window.location.hostname === 'stagingadmin.auctionsnap.com') {
    console.log('Setting up for Staging')
    return {
      apiUrl: 'https://stagingapi.auctionsnap.com:8443',
      auctionSnapURL: 'https://stagingapp.auctionsnap.com',
      version: 'AuctionSnap Admin 1.0.10',
    }
  }
  else if (window.location.hostname === 'admin.auctionsnap.com') {
    console.log('Setting up for Live')
    return {
      apiUrl: 'https://api.auctionsnap.com:8443',
      auctionSnapURL: 'https://app.auctionsnap.com',
      version: 'AuctionSnap Admin 1.0.10',
    }
  }
}
