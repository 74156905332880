import React, {useEffect, useState} from 'react'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Button, Grid} from '@mui/material'

import { ConfirmModal } from '../../components/common/ConfirmModal'
import styles from './CheckoutPage.module.css'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {useEventState} from "../../slices/eventSlice";
import {CheckoutData, CheckoutObject, useFetchCheckout} from "../../queries/useFetchCheckout";
import SelectInput from "../../components/common/inputs/SelectInput";
import {
    CheckoutListAllData,
    CheckoutListAllObject,
    useFetchCheckoutListAll
} from "../../queries/useFetchCheckoutListAll";
import CheckoutAllForm from "./CheckoutForm/CheckoutAllForm";
import axios from "axios";

export enum CheckoutPageTab {
  Checkout,
  ResendReceipt,
  PDF,
  Void,
  None
}

interface Params {
    eventid: string
}

const CheckoutAllPage = () => {
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const eventState = useEventState()
    const [tab, setTab] = useState(CheckoutPageTab.None)
    const [showPayment, setShowPayment] = useState(true)
    const [tabSel, setTabSel] = React.useState<number>(0)
    const [dataRows, setDataRows] = React.useState([])
    const [participantsData, setParticipantsData] = React.useState([])
    const [checkoutListData, setCheckoutListData] = React.useState({
        status: '',
        message: '',
        showccfees: false,
        ccfeespercentage: null,
        creditcardiframe: '',
        javascriptfile: '',
        transcenterid: '',
        processorid: '',
        transactiontype: '',
        checkoutlist: []
    })

    const getMappedRecords = () => {
        var participants = []
        const checkoutListDataNew = checkoutListData?.checkoutlist.map((checkoutData: CheckoutListAllData, i) => {
            participants.push({
                participantinfo: checkoutData.participantinfo,
                key: checkoutData.key,
            })
            return {
                id: checkoutData.rownumber,
                rownumber: checkoutData.rownumber,
                biddernumber: checkoutData.biddernumber,
                receiptnumber: checkoutData.receiptnumber,
                participantname: checkoutData.participantname,
                checkoutstatus: checkoutData.checkoutstatus,
                amount: checkoutData.amount,
                isreadonly: checkoutData.isreadonly,
                key: checkoutData.key,
                participantinfo: checkoutData.participantinfo
            }
        })
        setParticipantsData(participants)
        return checkoutListDataNew
    }

    const getCheckoutListAllData = async (eventIdvalue) => {
        const { data } = await axios.post('/checkoutlistall', { eventid: eventIdvalue })
        const parsedData = JSON.parse(data.jsonString)
        setCheckoutListData(parsedData)
    }

    // const { data, isLoading } = useFetchCheckoutListAll(Number(eventState.eventId))
    useEffect(() => {
        if (checkoutListData?.checkoutlist && checkoutListData?.checkoutlist?.length > 0) {
            console.log('WHY OH WHY')
            console.log(checkoutListData)
            const dataRowsTemp = getMappedRecords()
            setDataRows(dataRowsTemp)
        }
        if (checkoutListData) {
            setCreditCardInfo(
                { showccfees: checkoutListData.showccfees,
                    ccfeespercentage: checkoutListData.ccfeespercentage,
                    creditcardiframe: checkoutListData.creditcardiframe,
                    javascriptfile: checkoutListData.javascriptfile,
                    transcenterid: checkoutListData.transcenterid,
                    processorid: checkoutListData.processorid,
                    transactiontype: checkoutListData.transactiontype
                }
            )
        }
    }, [checkoutListData])


    useEffect(()=> {
        getCheckoutListAllData(eventid)
    }, [eventid])

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSel(newValue)
    }

    const [selectedUser, setSelectedUser] =
        useState<{ id: number; status: 'Open' | 'CheckedOut' }>(null)
    const [creditCardInfo, setCreditCardInfo] =
        useState<{ showccfees: boolean,
            ccfeespercentage: number,
            creditcardiframe: string,
            javascriptfile: string,
            transcenterid: string,
            processorid: string
            transactiontype: string
    }>({
            showccfees: false,
            ccfeespercentage: 0,
            creditcardiframe: "https://secure.1stpaygateway.net/secure/PaymentHostedForm/v3/CreditCard",
            javascriptfile: "https://secure.1stpaygateway.net/secure/PaymentHostedForm/Scripts/firstpay/firstpay.cryptogram.js",
            transcenterid: "92754",
            processorid: "70802",
            transactiontype: "Sale"
    })

    const [checkoutDataObject, setCheckoutDataObject] = useState<CheckoutData>(null)
    const onResendEmail = () => {
        //Send email here
        setTab(CheckoutPageTab.None)
    }

  const onVoid = () => {
    //Void order here
    setTab(CheckoutPageTab.None)
  }

    const [cryptogram, setCryptogram] = useState('');

    useEffect(() => {
        setCryptogram('')
        window.addEventListener("message", processPayment, false)
        return () => {
            setCryptogram('')
            window.removeEventListener("message", processPayment)
        }
    }, [])

    const processPayment = (e: any) => {
        if (e.data.firstpay) {
            const ccData = e.data
            if (ccData.type === 'newCryptogram') {
                setCryptogram(ccData.cryptogram)
            }
        }
    }

  return (
    <div className={styles.root}>
      <ConfirmModal
        text="Are you sure you want to resend the receipt via email?"
        isOpen={tab === CheckoutPageTab.ResendReceipt}
        onClose={() => setTab(CheckoutPageTab.None)}
        onConfirm={() => onResendEmail()}
        confirmText="Resend Receipt"
      />
      <ConfirmModal
        text="Are you sure you want to void the transaction?"
        isOpen={tab === CheckoutPageTab.Void}
        onClose={() => setTab(CheckoutPageTab.None)}
        onConfirm={() => onVoid()}
        confirmText="Void Transaction"
      />
      <CheckoutAllForm dataRows={dataRows} creditCardInfo={creditCardInfo} participantsData={participantsData}/>

      {/*{checkoutDataObject?.isreadonly && <CheckoutDetails />}*/}
      <div className={styles.tabs}>
        {selectedUser?.status === 'CheckedOut' && (
          <>
            <Button
              variant="contained"
              onClick={() => setTab(CheckoutPageTab.ResendReceipt)}>
              Resend Receipt
            </Button>
            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  onClick={() => setTab(CheckoutPageTab.PDF)}>*/}
            {/*  PDF*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  onClick={() => setTab(CheckoutPageTab.Void)}>*/}
            {/*  Void*/}
            {/*</Button>*/}
          </>
        )}
      </div>
    </div>
  )
}

export default CheckoutAllPage
