import React, {useEffect, useState} from 'react'

import {Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef,  GridRowModel,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useCreateEventDiscountCode, useFetchDiscountCodes, useUpdateDiscountCodes} from '../../queries/events/useEventDiscountCodesFetch'
import { EventDiscountCodesForm } from './EventDiscountCodesForm'
import styles from './EventDiscountCodesTable.module.css'
import {EventState, setEvent, useEventState} from "../../slices/eventSlice";
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import {useQueryClient} from "react-query";
import dayjs from "dayjs";
import {formatMoney} from "../../util/formatHelpers";
import axios from "axios";

const eventItemsDefaults = {
  id: 0,
  control: 0,
  fieldname: '',
  fieldtype: '',
  displayvalue: '',
  active: true,
  uditem: '',
  items: '',
  itemids: [],
  displayforallguest: false,
}

interface Params {
  eventid: string
}

const EventDiscountCodesTable = () => {
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)
  const queryClient = useQueryClient()
  const [gridParams, setGridParams] = useState(eventItemsDefaults)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [reloadForm, setReloadForm] = useState<number>(0)
  const [ticketItem, setTicketItem] = useState("-1")
  const [discountId, setDiscountId] = useState(0)
  const { data, isLoading } = useFetchDiscountCodes( Number(eventid) )
  const [isFormShowing, setIsFormShowing] = useState(false)
  var rows = []

  if (data) {
    console.log(data)
    rows = data.discounts.map((item) => {
      return { id: item.discountid, ...item}
    })
  }

  const handleAdd = () => {
    setReloadForm(reloadForm+1)
    setGridParams(null)
    setDiscountId(-1)
    setIsFormShowing(true)
  }

  useEffect(() => {
    if (data && data.selectedticketitemid) {
      setTicketItem(data.selectedticketitemid)
    }
  }, [data])

  useEffect(() => {
    queryClient.invalidateQueries(['EventActivityDiscountCodes', Number(eventid), ticketItem])
  }, [ticketItem])

  const handleEdit = (params: GridCellParams) => {
    setReloadForm(reloadForm+1)
    setDiscountId(Number(params.row.discountid))
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.row.discountcode + "\"?",
      id: Number(params.row.discountid)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var body = {
      "eventid": Number(eventid),
      "discountid": id,
      "delete": true
    }

    const { data } = await axios.put('/evtdiscountcodes', body)
    const parsedData = JSON.parse(data.jsonString)
    queryClient.invalidateQueries(['evtdiscountcodes'])

    if (parsedData.status === "failure") {
      setDialogObj({
        msg: '',
        id: 0
      })
      setDialogMsg(parsedData.message)
    }
    else {
      setDialogObj({
        msg: '',
        id: 0
      })
    }
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      renderHeader: () => {
        return (
            <IconButton
                className={styles.submitButton}
                onClick={() => handleAdd()}
                size="small">
              <Add />
            </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
            <Edit />
          </IconButton>
      )
    },
    {
      field: 'id',
      hide: true,
      sortable: false,
    },
    {
      field: 'discountcode',
      type: 'string',
      headerName: 'Code',
      minWidth: 220
    },
    {
      field: 'discountdescription',
      type: 'string',
      headerName: 'Description',
      minWidth: 280
    },
    {
      field: 'discounttype',
      type: 'string',
      headerName: 'Type',
      minWidth: 180
    },
    {
      field: 'discountamount',
      type: 'string',
      align: 'right',
      headerName: 'Amount / Percentage',
      minWidth: 180,
      renderCell: (params) => {
        // console.log(params)
        if (params.row.discountmethod === 'Dollar Amount') {
          return ( <InputLabel style={{color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', fontSize: '0.875rem', fontFamily: "Roboto,Helvetica,Arial,sans-serif"}}>{formatMoney(params.row.discountamount)}</InputLabel>)
        }
        else {
          return ( <InputLabel style={{color: 'rgba(0, 0, 0, 0.87)', fontWeight: '400', fontSize: '0.875rem', fontFamily: "Roboto,Helvetica,Arial,sans-serif"}}>{params.row.discountamount + '%'}</InputLabel>)
        }
      }
      // valueFormatter: (params: GridValueFormatterParams) => {
      //   return formatMoney(params.value)
      // }
    },
    {
      field: 'discountdatestart',
      type: 'string',
      headerName: 'Start Date',
      minWidth: 180,
      valueFormatter: (params: GridValueFormatterParams) => {
        return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
      },
    },
    {
      field: 'discountdateend',
      type: 'string',
      headerName: 'End Date',
      minWidth: 180,
      valueFormatter: (params: GridValueFormatterParams) => {
        return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
      },
    },
    {
      field: 'discountactive',
      type: 'string',
      headerName: 'Active',
      minWidth: 120,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? "Active" : "Inactive")
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      width: 70,
      sortable: false,
      renderCell: (params) => (
          // <div title={canDelete(params.row.active) ? '' : "Only items with a status of Inactive can be deleted."}>
          <>
            {params.row.discountcandelete &&
                <div>
                  <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                    <Delete />
                  </IconButton>
                </div>
            }
          </>


          // </div>
          // </Tooltip>
      )
    }
  ]
  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridSortedRowIdsSelector(apiRef);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
      options.allColumns = true;
      apiRef.current.exportDataAsCsv(options);
    }

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    return (
        <>
          <GridToolbarContainer >
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
              <Button
                  {...buttonBaseProps}
                  onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
              >
                EXPORT
              </Button>
            </Grid>
          </GridToolbarContainer>
        </>
    )
  }

  return (
      <div className={styles.root}>
        <h1>Promo Codes</h1>

      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <EventDiscountCodesForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} reloadForm={reloadForm} eventid={eventid} discountid={discountId}/>
      <DataGridPremium
        autoHeight
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
      />
    </div>
  )
}

export default EventDiscountCodesTable
