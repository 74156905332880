import React, {useEffect} from 'react'
import {Checkbox, DialogTitle, FormControlLabel, Grid, TextField, useTheme} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from "./TransferPage.module.css";
import {useParams} from "react-router";
import HookForm from "../../components/common/HookForm";
import {useForm} from "react-hook-form";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, useMediaQuery} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import axios from "axios";
import download from 'downloadjs';
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import DonationPage from "./DonationPage";
import {useAutoEvent} from "../../queries/useAutoEvent";

interface Params {
    eventid: string
}

const TransferPage = () => {
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const formMethods = useForm<FormData>()
    const [dialog, setDialog] = React.useState({count:0, open: false, checkedoutonly: false})
    const [dialogComplete, setdialogComplete] = React.useState({count:0, open: false, message: ""})

    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [contactData, setContactData] = React.useState({linkparticipants: 0, linkparticipantsnotauthorized: 0, message: "", totalparticipants: 0,
        unlinkcheckedoutparticipants: 0, unlinkcheckedoutparticipantsnotauthorized: 0, unlinkparticipants: 0, unlinkparticipantsnotauthorized: 0,
        unlinkpendingcartitemsparticipants: 0, unlinkpendingcartitemsparticipantsnotauthorized: 0});

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            button: {
                margin: theme.spacing(1),
                paddingLeft: 10,
                marginLeft: 10,
                marginRight: 10,
            },
            paper: {
                padding: theme.spacing(2),
                spacing: theme.spacing(100),
                paddingLeft: 10,
                marginLeft: 10,
                marginRight: 10,
                textAlign: 'left',
                fontSize: 25,
                color: theme.palette.text.secondary
            }
        })
    )
    const classes = useStyles()

    useEffect(() => {
        getContactDetails()
    }, [eventid])

    const onSubmit = async (data: any) => {
        let body = {
            eventid: eventid,
            message: data.message
        }

        if (data.email !== 'All') {
            body['email'] = data.email
        }
    }

    const getContactDetails = async () => {
        setLoading(true)
        axios.post('/integrationcontact', {eventid: eventid}, {responseType: "json"}).then(response => {
            const parsedData = JSON.parse(response.data.jsonString)
            console.log(parsedData)
            setContactData(parsedData)
            setLoading(false)
        })
    }

    const putIntegrationContact = async (checkedoutOnly) => {
        setLoading(true)
        axios.put('/integrationcontact', {eventid: eventid, checkedoutonly: checkedoutOnly}, {responseType: "json"}).then(response => {
            const parsedData = JSON.parse(response.data.jsonString)
            setLoading(false)
            setdialogComplete({count: dialog.count, open: true, message: parsedData.message})
            setDialog({count: 0, open: false, checkedoutonly: false})
            getContactDetails()
        })
    }

    const promptWithDialog = async (messageCount, checkoutonly) => {
        setDialog({count: messageCount, open: true, checkedoutonly: checkoutonly})
    }

    const generateContactReport = async () => {
        setLoading(true)
        axios.post('/integrationcontactreport', {eventid: eventid}, {responseType: "blob"}).then(response => {
            var filename = 'integrationcontactreport.xlsx';
            var disposition = response.headers['content-disposition']
            var contentType = response.headers['content-type']
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            if (response.data) {
                download(response.data, filename, contentType);
            }
            setLoading(false)
        })
    }

    const batchCodeOptions = () => {
        return [{value: 'none', label: 'None'}]
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog open={dialog.open}>
                    <DialogTitle>Upload Participants to DonorSnap</DialogTitle>
                    <DialogContent>
                        <HookForm
                            methods={formMethods}
                            onSubmit={onSubmit}
                            className={styles.donationForm}
                            formProps={{ id: 'donation-form' }}>
                            <text>You are about to upload {dialog.count} participants into your DonorSnap account. Are you sure you want to continue?</text>
                        </HookForm>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setDialog({count: 0, open: false, checkedoutonly: false})}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className={styles.submitButton}
                            type="submit"
                            form="donation-form"
                            onClick={() => putIntegrationContact(dialog.checkedoutonly)}
                        >
                            UPLOAD TO DONORSNAP
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            <>
                <Dialog open={dialogComplete.open}>
                    <DialogContent>
                        <HookForm
                            methods={formMethods}
                            onSubmit={onSubmit}
                            className={styles.donationForm}
                            formProps={{ id: 'donation-form' }}>
                            {/*<text>Successfully added {dialogComplete.count} contacts to DonorSnap.</text>*/}
                            <text>{dialogComplete.message}</text>
                        </HookForm>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            className={styles.submitButton}
                            form="donation-form"
                            onClick={() => setdialogComplete({count: 0, open: false, message: ''})}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </>


            <HookForm
                methods={formMethods}
                onSubmit={onSubmit}
                className={styles.form}>
            <LoadingBackdrop open={loading} />


            <h2>Transfer to DonorSnap / Export</h2>
                    <Tabs style={{marginBottom: 10}} value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Contact" {...a11yProps(0)} />
                        <Tab label="Donation" {...a11yProps(1)} />
                    </Tabs>
                    {value === 0 && (
                        <Grid container spacing={2}>
                            {/*<Grid item xs={12} >*/}
                            {/*    <Paper className={classes.paper}>Upload Options</Paper>*/}
                            {/*</Grid>*/}
                            <Grid item xs={4} className={styles.selectcontrol}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    disabled={contactData.totalparticipants <= 0}
                                    onClick={() => {
                                        generateContactReport()
                                    }}
                                >
                                    EXPORT {contactData.totalparticipants} PARTICIPANTS TO EXCEL
                                </Button>
                            </Grid>
                            <Grid item xs={4} className={styles.selectcontrol}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    disabled={(contactData.unlinkparticipants - contactData.unlinkparticipantsnotauthorized) <= 0}
                                    onClick={() => {
                                        promptWithDialog((contactData.unlinkparticipants - contactData.unlinkparticipantsnotauthorized), true)
                                    }}
                                >
                                    UPLOAD {contactData.unlinkparticipants - contactData.unlinkparticipantsnotauthorized} PARTICIPANTS NOT LINKED
                                </Button>
                            </Grid>
                            <Grid item xs={4} className={styles.selectcontrol}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    disabled={(contactData.unlinkcheckedoutparticipants - contactData.unlinkpendingcartitemsparticipantsnotauthorized) <= 0}
                                    onClick={() => {
                                        promptWithDialog(contactData.unlinkcheckedoutparticipants - contactData.unlinkpendingcartitemsparticipantsnotauthorized
                                            , false)
                                    }}
                                    >
                                    UPLOAD  {contactData.unlinkcheckedoutparticipants - contactData.unlinkpendingcartitemsparticipantsnotauthorized
                                } PARTICIPANTS NOT LINKED THAT CHECKED OUT
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={styles.selectcontrol}>
                                <div style={{justifyContent: 'left', display: "flex"}}>{contactData.unlinkpendingcartitemsparticipantsnotauthorized} Participant(s) are not linked but have items in their cart</div>
                            </Grid>
                            <Grid item xs={12} className={styles.selectcontrol}>
                                <div style={{justifyContent: 'left', display: "flex"}}>{contactData.unlinkparticipantsnotauthorized} Participant(s) are not authorized and will not upload into DonorSnap</div>
                            </Grid>
                        </Grid>


                    )}
                    {value === 1 && (
                        <>
                            <DonationPage eventid={eventid} contactData={contactData} loading={loading} setLoading={setLoading}></DonationPage>
                        </>
                    )}
                </HookForm>
        </div>
    )
}

export default TransferPage
