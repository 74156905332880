import { useIsLoggedIn } from '../../slices/authSlice'
import LoadingBackdrop from './LoadingBackdrop'

export const AuthGuard = ({ children }) => {
  const isLoggedIn = useIsLoggedIn()

  if (!isLoggedIn) {
    return <LoadingBackdrop open={isLoggedIn} />
  }

  return children
}
