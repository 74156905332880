import axios from 'axios'
import { useQuery } from 'react-query'

export interface EventSettings {
  rownumber: number
  eventid: number
  name: string
  code: string
  active: boolean
  start: string
  end: string
  bidstart: string
  bidend: string
  timezone: string
  allowcustomlocations: boolean
  allowbillme: boolean
  allowbuyitnow: boolean
  allowbidanonymously: boolean
  allowautobid: boolean
  allowautobidincrement: boolean
  approvalofbidders: boolean
  allowcreditcards: boolean
  allowpaymentoffees: boolean
  ccfee: number
  startbiddernum: number
  receipttemplateid: number
  allowtexting: boolean
  allowguestentry: boolean
  eventbiddingopen: string
  eventbiddingclosed: string
  textprefix: string
  logourl: string,
  eventprivacy: string,
  logo: string
  eventshowbidderpaddlenumber: boolean
  eventusepaddlenumber: boolean
}

const defaultEventSettings = {
  rownumber: 0,
  eventid: 0,
  name: '',
  code: '',
  active: false,
  start: '',
  end: '',
  bidstart: '',
  bidend: '',
  timezone: '',
  allowcustomlocations: false,
  allowbillme: true,
  allowbuyitnow: true,
  allowbidanonymously: true,
  allowautobid: true,
  allowautobidincrement: true,
  approvalofbidders: false,
  allowcreditcards: true,
  allowpaymentoffees: true,
  allowtexting: true,
  allowguestentry: true,
  ccfee: 2.5,
  startbiddernum: 0,
  receipttemplateid: 1,
  eventbiddingopen: '',
  eventbiddingclosed: '',
  textprefix: '',
  logourl: '',
  eventprivacy: '',
  logo: '',
  eventshowbidderpaddlenumber: false,
  eventusepaddlenumber: false,
}

export const globalEventSettingsFetcher = async (eventIdvalue) => {
  const json = JSON.stringify({ eventid: eventIdvalue })
  const { data } = await axios.post('/eventsettings', json, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const parsedData = JSON.parse(data.jsonString)
  return parsedData as EventSettings
}

export const useFetchEventSettings = (eventid, enabled = true) => {

  return useQuery<EventSettings>(
    ['eventsettings', eventid],
    () => globalEventSettingsFetcher(eventid),
    {
      staleTime: 120000,
      placeholderData: defaultEventSettings,
      //keepPreviousData: true,
      enabled
    }
  )
}
