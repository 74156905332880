import React, { useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium'
import {
  useFetchparticipentLinking,
  usePutParticipantLink,
  getParticpentLinkList
} from '../../queries/useParticipantLinking'
import { useAutoEvent } from '../../queries/useAutoEvent'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { EventState, setEvent, useEventState } from '../../slices/eventSlice'
import dayjs from 'dayjs'
import { formatMoney } from '../../util/formatHelpers'
import { makeStyles, Button } from '@material-ui/core'
import SearchBar from '../../components/common/inputs/SearchBar'
interface Params {
  eventid: string
}

const useStyle = makeStyles({
  root: {
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'visible',
      lineHeight: '1.43rem',
      whiteSpace: 'normal'
    }
  }
})

const ParticipantsLinkingPage = () => {
  const [searchP, setsearchP] = useState({ipk:undefined, multi:undefined})

  
  const onClickMultiSearch = (searchString) => {
    console.log(searchString)
    setsearchP({ipk:null, multi:searchString})

  }
  const onClickIPKSearch = (ipk) => {
    console.log(ipk)

    setsearchP({ipk:ipk, multi:null})

    }
  const history = useHistory()
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)
  const event = useEventState()
  const putLink = usePutParticipantLink()
  console.log(event)
  var rows = []
  //todo: any decleration is not a great way to do this but going for speed
  const propPass: any = useLocation()
  console.log(propPass)
  const participantinfo = propPass.state?.data
  const { data, isLoading } = useFetchparticipentLinking(
    event?.eventId,
    participantinfo.participantid,
    searchP.multi == null ? undefined : searchP.multi,
    searchP.ipk == null ? undefined : searchP.ipk
  )
  if (data) {
    rows = data
  }
  const columns = [
    {
      field: 'buttons',
      headerName: 'Link',
      width: 150,
      disableExport: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log('before useputparticipant on linking page')

              putLink.mutate(
                {
                  eventid: event.eventId,
                  contactid: params.row.contactid,
                  participantid: participantinfo.participantid

                },
                {
                  onSuccess: (data) => {
                    console.log('on success useputparticipant on linking page')
                    console.log(data)
                    history.push(
                      '/auction/' + event.eventId + '/participantslinking'
                    )
                  }
                }
              )
            }}>
            Link
          </Button>
        )
      }
    },
    { field: 'rownumber', headerName: 'rownumber' },
    {
      field: 'contactid',
      type: 'string',
      headerName: 'Contact ID',
      width: 100,
      editable: false
    },
    {
      field: 'first',
      type: 'string',
      headerName: 'First Name',
      width: 200,
      editable: true
    },
    {
      field: 'last',
      type: 'string',
      headerName: 'Last Name',
      width: 200,
      editable: true
    },
    {
      field: 'email',
      type: 'string',
      headerName: 'Email',
      width: 200,
      editable: false
    },
    {
      field: 'first2',
      type: 'string',
      headerName: 'First Name 2',
      width: 200,
      editable: true
    },
    {
      field: 'last2',
      type: 'string',
      headerName: 'Last Name 2',
      width: 200,
      editable: true
    },
    {
      field: 'email2',
      type: 'string',
      headerName: 'Email 2',
      width: 200,
      editable: false
    },
    {
      field: 'phone',
      type: 'string',
      headerName: 'Phone',
      valueFormatter: (params) => {
        var cleaned = ('' + params.value).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        return match
          ? '(' + match[1] + ') ' + match[2] + '-' + match[3]
          : params.value
      },
      width: 200,
      editable: true
    },
    {
      field: 'cell',
      type: 'string',
      headerName: 'Cell',
      valueFormatter: (params) => {
        var cleaned = ('' + params.value).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        return match
          ? '(' + match[1] + ') ' + match[2] + '-' + match[3]
          : params.value
      },
      width: 200,
      editable: true
    },
    {
      field: 'company',
      type: 'string',
      headerName: 'Company',
      width: 200,
      editable: true
    },
    {
      field: 'address1',
      type: 'string',
      headerName: 'Address',
      width: 200,
      editable: true
    },
    {
      field: 'paddlenumbertext',
      type: 'string',
      headerName: 'Paddle Number',
      width: 200,
      editable: true
    }
  ]
  const classes = useStyle()

  return (
    <div>
      <Box m={1}
  display="flex"
  justifyContent="flex-end"
  alignItems="flex-end">
        <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <Typography align="center" variant="h6">{`Participant # ${participantinfo.participantid}`}</Typography>
              <Grid container>
                <Grid item md={4}>
               
                  <Typography align="center" variant="body1">{`${participantinfo.first} ${participantinfo.last}`}</Typography>
                  <Typography align="center" variant="body1">{participantinfo.address}</Typography>
                </Grid>
                <Grid item md={4} >
              
                  <Typography align="center" variant="body1">{`${participantinfo.email}`}</Typography>
                  <Typography align="center" variant="body1">{participantinfo.city}</Typography>
                </Grid>
                 <Grid item md={4}>
                 
                  <Typography align="center" variant="body1">{participantinfo.phone}</Typography>
                  <Typography align="center" variant="body1">{`${participantinfo.state} ${participantinfo.zip}`}</Typography>
                </Grid>
              </Grid>
            
            </Stack>
          </Paper>
          </Box>
          <Box>
          <Grid container>
                <Grid item md={7}>
                  <Box m ={1}>
                  <SearchBar placeHolder="Search name, email, phone, company, or address" onClick ={onClickMultiSearch} />
                  </Box>
                </Grid>
                <Grid item md={1} >
              
                 
              </Grid>
                <Grid item md={4} >
                <Box m ={1}>

                <SearchBar placeHolder="Search ContactIPK" onClick ={onClickIPKSearch} />
                </Box>

                </Grid>
                
              </Grid>
          </Box>
      <DataGridPremium
        getRowId={(row) => row.rownumber}
        className={classes.root}
        autoHeight
        columns={columns}
        rows={rows}
        loading={isLoading}
        rowThreshold={0}
        columnVisibilityModel={{
          // Hide column rownumber the other columns will remain visible
          rownumber: false
        }}
      />
    </div>
  )
}
export default ParticipantsLinkingPage
