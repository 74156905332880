import React, {useEffect, useState} from 'react'
import {DialogTitle, Grid, TextField, Tooltip, useTheme} from "@mui/material";

import styles from "./BroadcastMessagePage.module.css";
import SelectInput from '../../components/common/inputs/SelectInput'
import {useFetchParticpants} from "../ParticipantsPage/useFetchParticipants";
import {useParams} from "react-router";
import HookForm from "../../components/common/HookForm";
import TextInput from "../../components/common/inputs/TextInput";
import {useForm} from "react-hook-form";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    useMediaQuery
} from "@material-ui/core";
import {
    useCartEmailPresend,
    useBroadcastMessage,
    useCartEmailSend
} from "./useBroadcastMessage";
import {Message as MessageIcon, AddShoppingCartOutlined, Add, Edit, Delete, Message} from "@material-ui/icons";

import Paper from '@material-ui/core/Paper'
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import dayjs from "dayjs";
import axios from "axios";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import {DataGridPremium, GridCellParams, GridColDef, GridValueFormatterParams} from "@mui/x-data-grid-premium";
import {Sponsor, useFetchSponsors} from "../../queries/events/useFetchSponsors";
import {useFetchTextMessages, useUpdateBroadcastTextMessage} from "../../queries/useFetchBroadcastTextMessages";
import DateTimeInput from "../../components/common/inputs/DateTimeInput";
import {useQueryClient} from "react-query";

interface Params {
    eventid: string
}

type FormData = {
    broadcasttextid: number,
    broadcasttextphonetitle: string,
    broadcasttextmessage: string,
    broadcasttextstatus: string,
    broadcasttextsentdate: string
}

const BroadcastMessagePage = () => {
    const { eventid } = useParams<Params>()
    const formMethods = useForm<FormData>()
    const {watch, getValues, setValue, reset} = formMethods
    const broadcastMessage = useBroadcastMessage()
    const emailPresend = useCartEmailPresend()
    const queryClient = useQueryClient()
    const emailSend = useCartEmailSend()
    const { data: particpants, isLoading: dataLoading } = useFetchParticpants(eventid)
    const [distinctparticipantsauthorizedtexting, setDistinctparticipantsauthorizedtexting] = React.useState(1)
    const [messageList, setMessageList] = React.useState([{ value: '', label: '' }])
    const [messageTextList, setMessageTextList] = React.useState([{ value: '', label: '' }])
    const [sendNowMessageValueList, setSendNowMessageValueList] = React.useState([{ value: 'Sent', label: 'Send Now' }, { value: 'Scheduled', label: 'Send Later' }])
    const [emailPre, setEmailPre] = React.useState({ carts: 0, cartstotal: 0, lastdatesent: '' })
    const [dialogMsg, setDialogMsg] = React.useState("")
    const [reload, setReload] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)
    const [dataRows, setDataRows] = React.useState([])
    const [textParticipants, setTextParticipants] = React.useState([])
    const { data: textMessageData, isLoading: textMessagesLoading } = useFetchTextMessages(Number(eventid))
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const whenToSend = watch('broadcasttextstatus')

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            button: {
                margin: theme.spacing(1),
                paddingLeft: 10,
                marginLeft: 10,
                marginRight: 10,
            },
            paper: {
                padding: theme.spacing(2),
                spacing: theme.spacing(100),
                paddingLeft: 10,
                marginLeft: 10,
                marginRight: 10,
                textAlign: 'left',
                fontSize: 25,
                color: theme.palette.text.secondary
            }
        })
    )
    const classes = useStyles()

    useEffect(() => {
        preSend()
        getPhoneTextParticipants()
    }, [eventid, reload])

    useEffect(() => {
        console.log(textMessageData)
        if (textMessageData && textMessageData.length > 0) {
            const newDataRows = textMessageData.map((newdata: any) => {
                return {id: newdata.broadcasttextid, ...newdata}
            })
            setDataRows(newDataRows)
        }
    }, [textMessageData])

    useEffect(() => {
        let selectMessageList = []
        if (particpants && particpants.length > 0) {
            selectMessageList = particpants?.filter((item) => item.email && item.lastlogindate).map((obj) => {
                return { value: obj?.email, label: obj?.email }
            })
        }
        selectMessageList.unshift({ value: "ALL", label: "ALL" })
        setMessageList(selectMessageList)
    }, [particpants])

    useEffect(() => {
        let selectMessageList = []
        if (textParticipants && textParticipants.length > 0) {
            selectMessageList = textParticipants?.filter((item) => item.phone).map((obj) => {
                return { value: obj?.phone, label: obj?.participantinfo }
            })
        }
        var allLabel = 'ALL (' + distinctparticipantsauthorizedtexting + ' phone numbers currently opted in)'


        selectMessageList.unshift({ value: "ALL", label: allLabel })
        setMessageTextList(selectMessageList)
    }, [textParticipants])


    const getPhoneTextParticipants = async () => {
        const response = await axios.post(`/broadcasttextmessagelist`, {
           eventid: eventid
        })
        const parsedResponse = JSON.parse(response?.data?.jsonString)
        setDistinctparticipantsauthorizedtexting(parsedResponse.distinctparticipantsauthorizedtexting)
        setTextParticipants(parsedResponse.participantlist)
    }

    const preSend = async () => {
        let body = {
            eventid: eventid
        }

        await emailPresend.mutateAsync(body, {
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                const dateLastSent = dayjs(json.lastdatesent).format('MM-DD-YYYY')
                const timeLastSent = dayjs(json.lastdatesent).format('h:mm A')
                const dateFormat = dateLastSent + " at " +timeLastSent
                setEmailPre({carts: json.carts, cartstotal: json.cartstotal, lastdatesent: json.lastdatesent ? dateFormat : '' })
            }
        })
    }


    const onBroadcastTextSubmit = async (data: any) => {
        setIsLoading(true)
        let body = {
            eventid: eventid,
            broadcasttextmessage: data.broadcasttextmessage
        }

        if (data.broadcasttextid !== null) {
            body['broadcasttextid'] = data.broadcasttextid
        }

        if (data.broadcasttextphonetitle !== 'ALL') {
            body['broadcasttextphone'] = data.broadcasttextphonetitle
        }

        if (whenToSend === 'Scheduled') {
            body['broadcasttextscheduledate'] = dayjs(data.broadcasttextsentdate).format('YYYY-MM-DDTHH:mm:ss')
        }

        const { data: putMessageData } = await axios.put('/broadcasttextmessage', body)
        const json = JSON.parse(putMessageData.jsonString)
        if (json.status === "failure") {
            setDialogMsg(json.message)
        }
        else {
            queryClient.invalidateQueries(['textmessage', Number(eventid)])
            // setDialogMsg('Text Message was sent successfully.')
            setBroadcastTextDlg(false)
        }
        setIsLoading(false)
    }

    const onSubmit = async (data: any) => {
        let body = {
            eventid: eventid,
            message: data.message
        }

        if (data.email !== 'All') {
            body['email'] = data.email
        }

        await broadcastMessage.mutateAsync(body, {
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                // console.log(json)
                if (json.status === "failure") {
                    setDialogMsg(json.message)
                }
                else {
                    setDialogMsg('Message was sent successfully.')
                    setBroadcastDlg(false)
                }
            }
        })
    }

    const onSubmitCart = async (data: any) => {
        let body = {
            eventid: eventid,
            subjectline: data.subjectline,
        }

        await emailSend.mutateAsync(body, {
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                // console.log(json)
                if (json.status === "failure") {
                    setDialogMsg(json.message)
                }
                else {
                    setDialogMsg('Email sent successfully.')
                    setCartsDlg(false)
                }
            }
        })
    }


    const closeDialog = () => {
        setDialogObj({ msg: "", id: 0 })
        setDialogMsg("")
    }

    const [brodacastDlg, setBroadcastDlg] = React.useState(false);
    const [brodacastTextDlg, setBroadcastTextDlg] = React.useState(false);
    const [cartsDlg, setCartsDlg] = React.useState(false);

    const handleOpenBroadcast = () => {
        setBroadcastDlg(true);
    };

    const handleOpenBroadcastTextMessages = () => {
        reset({
            'broadcasttextid': null,
            'broadcasttextphonetitle': 'ALL',
            'broadcasttextmessage': '',
            'broadcasttextstatus': 'Sent',
            'broadcasttextsentdate': dayjs().add(30, 'minutes').toString()
        })
        setBroadcastTextDlg(true);
    };

    const handleCloseBroadcast = () => {
        setBroadcastDlg(false);
    };

    const handleCloseBroadcastText = () => {
        setBroadcastTextDlg(false);
    };

    const handleOpenCart = () => {
        setCartsDlg(true);
    };

    const handleCloseCart = () => {
        setCartsDlg(false);
    };

    const handleDelete = (params: GridCellParams) => {
        setDialogObj({
            id: Number(params.row.broadcasttextid),
            msg: "Are you sure you want to delete message - " + params.row.broadcasttextphonetitle + ' - ' + params.row.broadcasttextmessage,
        })
    }

    const handleEdit = (params: GridCellParams) => {
        reset({
            'broadcasttextid': params.row.broadcasttextid,
            'broadcasttextphonetitle': params.row.broadcasttextphonetitle,
            'broadcasttextmessage': params.row.broadcasttextmessage,
            'broadcasttextstatus': params.row.broadcasttextstatus,
            'broadcasttextsentdate': params.row.broadcasttextscheduledate
        })
        setBroadcastTextDlg(true)
    }

    const columns: GridColDef[] = [
        {
            field: 'broadcasttextid',
            headerName: '',
            disableExport: true,
            // renderHeader: () => {
            //     return (
            //         <IconButton
            //             className={styles.submitButton}
            //             // onClick={() => handleAdd()}
            //             size="small">
            //             <Add />
            //         </IconButton>
            //     )
            // },
            width: 80,
            sortable: false,
            renderCell: (params) => (
                    <IconButton
                        className={styles.button}
                        disabled={!params.row.canedit}
                        onClick={() => { handleEdit(params) }}
                    >
                        <Edit/>
                    </IconButton>
            )
        },
        {
            field: 'broadcasttextdate',
            headerName: 'Date',
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => {
                return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
            },
        },
        {
            field: 'broadcasttextphonetitle',
            headerName: 'To',
            width: 150,
            // valueFormatter: (params: GridValueFormatterParams) => {
            //     return params.value === 'ALL' ? 'ALL' : "Other"
            // },
        },
        {
            field: 'broadcasttextstatus',
            type: 'string',
            headerName: 'Status',
            minWidth: 150,
            sortable: false,
        },
        {
            field: 'broadcasttextmessage',
            type: 'string',
            headerName: 'Message',
            minWidth: 120,
            sortable: false,
            headerAlign: "center",
            align: 'center',
            renderCell: (params) => (
                <Tooltip disableFocusListener title={params.row.broadcasttextmessage} arrow>
                    <IconButton
                        title={params.row.broadcasttextmessage}
                    >
                        <Message />
                    </IconButton>
                </Tooltip>
            )
        },
        {
            field: 'broadcasttextcount',
            type: 'number',
            headerName: 'Text Count',
            minWidth: 120,
            sortable: false,
        },
        {
            field: 'action',
            headerName: 'Delete',
            disableExport: true,
            width: 120,
            sortable: false,
            renderCell: (params) => (
                <div title={params.row.candelete ? '' : "Only messages with a status of Scheduled can be deleted."}>
                    <div>
                        <IconButton disabled={!params.row.candelete} className={styles.button}
                            onClick={() => { handleDelete(params) }}
                        >
                            <Delete/>
                        </IconButton>
                    </div>
                </div>
            )
        }
    ]

    const handleDeleteConfirm = async (id: number) => {
        var finaldata = {
            "eventid": Number(eventid),
            "broadcasttextid": Number(id),
        }

        const { data } = await axios.delete('/broadcasttextmessage', {data: finaldata})
        const json = JSON.parse(data.jsonString)
        if (json.status === "failure") {
            setDialogObj({
                msg: '',
                id: 0
            })
            setDialogMsg(json.message)
        }
        else {
            queryClient.invalidateQueries(['textmessage', Number(eventid)])
            setDialogObj({
                msg: '',
                id: 0
            })
        }
    }


    const deleteDialog = async () => {
        await handleDeleteConfirm(dialogObj.id)
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogObj.msg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogObj.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={deleteDialog}>DELETE</Button>
                    </DialogActions>
                </Dialog>
            </>

            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>

            <>
                <Dialog
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // fullScreen={fullScreen}
                    open={cartsDlg}
                    onClose={handleCloseCart}
                    // fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle>Unpaid Items in Carts</DialogTitle>
                    <DialogContent>
                        <HookForm
                            methods={formMethods}
                            onSubmit={onSubmitCart}
                            // className={styles.tagsForm}
                            formProps={{ id: 'broadcast-form' }}>

                            <DialogContentText id="alert-dialog-description">
                                Send a reminder email to all participants with unpaid terms remaining in their cart. It will include a list of their items and a link to the checkout page.
                            </DialogContentText>

                            <DialogContentText>
                                Carts with items not checked out: {emailPre.carts}
                            </DialogContentText>

                            {emailPre?.lastdatesent.length > 0 &&
                                <DialogContentText style={{marginTop: 10}}>
                                    This email was last sent on {emailPre.lastdatesent}
                                </DialogContentText>
                            }

                            <Grid item xs={12} className={styles.selectcontrol} style={{ marginTop: 20, marginBottom: 20 }}>
                                <TextInput
                                    name="subjectline"
                                    label="Subject Line:"
                                    defaultValue="Don't forget to check out! You still have items waiting in your cart."
                                    tooltipText="The email subject line will always begin with your event name. The text in this field will show up after the event name."
                                    // textFieldProps={{ multiline: true, minRows: 4, maxRows:6 }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} className={styles.selectcontrol}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    xs={11.5} sm={11.5} md={11.5}
                                >
                                    <Button
                                        variant="contained"
                                        // className={styles.submitButton}
                                        //
                                        style={{marginRight: 10}}
                                        onClick={handleCloseCart}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={styles.submitButton}
                                        type="submit"
                                        disabled={emailPre.carts <= 0}
                                        form="broadcast-form">
                                        Send
                                    </Button>
                                </Grid>
                            </Grid>
                        </HookForm>
                    </DialogContent>
                </Dialog>
            </>

            <>
                <Dialog
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // fullScreen={fullScreen}
                    open={brodacastDlg}
                    onClose={handleCloseBroadcast}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <DialogTitle>Broadcast Message</DialogTitle>
                    <DialogContent>
                        <HookForm
                            methods={formMethods}
                            onSubmit={onSubmit}
                            // className={styles.tagsForm}
                            formProps={{ id: 'broadcast-form' }}>
                            <LoadingBackdrop open={isLoading} />
                        <Grid item xs={12} className={styles.selectcontrol} style={{ marginBottom: 20 }}>
                            <SelectInput
                                name={'email'}
                                label={'To'}
                                options={messageList}
                                includeNone={false}
                                defaultValue={'All'}
                                // required={fieldData.required}
                            />
                        </Grid>
                        <Grid item xs={12} className={styles.selectcontrol} style={{ marginBottom: 20 }}>
                            <TextInput
                                name="message"
                                label="Message:"
                                textFieldProps={{ multiline: true, minRows: 4, maxRows:6 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} className={styles.selectcontrol}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                xs={11.5} sm={11.5} md={11.5}
                            >
                                <Button
                                    variant="contained"
                                    // className={styles.submitButton}
                                    //
                                    style={{marginRight: 10}}
                                    onClick={handleCloseBroadcast}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    className={styles.submitButton}
                                    type="submit"
                                    form="broadcast-form">
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                        </HookForm>
                    </DialogContent>
                </Dialog>
            </>

            <>
                <Dialog
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // fullScreen={fullScreen}
                    open={brodacastTextDlg}
                    onClose={handleCloseBroadcastText}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <DialogTitle>Broadcast Text Message</DialogTitle>
                    <DialogContent>
                        <HookForm
                            methods={formMethods}
                            onSubmit={onBroadcastTextSubmit}
                            // className={styles.tagsForm}
                            formProps={{ id: 'broadcast-text-form' }}>
                            <LoadingBackdrop open={isLoading} />
                            <Grid item xs={12} className={styles.selectcontrol} style={{ marginBottom: 20 }}>
                                <SelectInput
                                    name={'broadcasttextphonetitle'}
                                    label={'To'}
                                    options={messageTextList}
                                    includeNone={false}
                                    defaultValue={'All'}
                                    // required={fieldData.required}
                                />
                            </Grid>
                            <Grid item xs={12} className={styles.selectcontrol} style={{ marginBottom: 20 }}>
                                <TextInput
                                    name="broadcasttextmessage"
                                    label="Message:"
                                    textFieldProps={{ multiline: true, minRows: 4, maxRows:6 }}
                                    required
                                />
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6} className={styles.selectcontrol} style={{ marginBottom: 20 }}>
                                    <SelectInput
                                        name={'broadcasttextstatus'}
                                        label={'When'}
                                        options={sendNowMessageValueList}
                                        includeNone={false}
                                        defaultValue={'Sent'}
                                        // required={fieldData.required}
                                    />
                                </Grid>
                                {whenToSend === 'Scheduled' &&
                                    <Grid item xs={6} className={styles.selectcontrol} style={{ marginBottom: 20 }}>
                                        <DateTimeInput
                                            name="broadcasttextsentdate"
                                            label="Date and Time"
                                            // defaultValue={gridParams.broadcasttextsentdate}
                                            required={true}
                                            // disabled={whenToSend === 'Sent'}
                                            tooltipText={'The date and time '}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} className={styles.selectcontrol}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    xs={11.5} sm={11.5} md={11.5}
                                >
                                    <Button
                                        variant="contained"
                                        // className={styles.submitButton}
                                        //
                                        style={{marginRight: 10}}
                                        onClick={handleCloseBroadcastText}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={styles.submitButton}
                                        type="submit"
                                        form="broadcast-text-form">
                                        Send
                                    </Button>
                                </Grid>
                            </Grid>
                        </HookForm>
                    </DialogContent>
                </Dialog>
            </>

            <h2>Communication Tools</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>Emails</Paper>
                        </Grid>
                        <Grid item xs={4} className={styles.selectcontrol}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                onClick={() => {
                                    handleOpenCart()
                                }}
                                startIcon={<AddShoppingCartOutlined />}>
                                UNPAID ITEMS IN CARTS
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>In-app messages to people currently viewing the page</Paper>
                        </Grid>
                        <Grid item xs={4} className={styles.selectcontrol}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                onClick={() => {
                                    handleOpenBroadcast()
                                }}
                                startIcon={<MessageIcon />}>
                                BROADCAST MESSAGE
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>Text Messages via cell phone</Paper>
                        </Grid>
                        <Grid item xs={4} className={styles.selectcontrol}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                onClick={() => {
                                    setReload(reload+1)
                                    handleOpenBroadcastTextMessages()
                                }}
                                startIcon={<MessageIcon />}>
                                BROADCAST TEXT MESSAGE
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={styles.selectcontrol}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                xs={11.5} sm={11.5} md={11.5}
                            >
                                <DataGridPremium
                                    autoHeight
                                    rows={dataRows}
                                    columns={columns}
                                    loading={textMessagesLoading}
                                    pagination={true}
                                    // sx={{ flex: 1 }}
                                    hideFooter
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                {/*</HookForm>*/}
        </div>
    )
}

export default BroadcastMessagePage
