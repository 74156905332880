import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useCreateEventItem = () => {
  const queryClient = useQueryClient()

  return useMutation((body: any) =>
      axios.put(`eventitems`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['eventitems'])
    }
  })
}

export const useUpdateEventItem = () => {
  const queryClient = useQueryClient()

  return useMutation((body: any) =>
      axios.post(`eventitems`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['eventitems'])
    }
  })
}
