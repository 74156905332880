import axios from 'axios'
import { useQuery } from 'react-query'

export type receiptTemplates = {
    rownumber: number
    receipttemplateid: number
    name: string
}

const receiptTemplatesDefaults = {
    rownumber: 0,
    receipttemplateid: 0,
    name: "Template 1"
}

export const globalrecieptTemplatesFetcher = async () => {
    const { data } = await axios.get('/receipttemplate')
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.receipttemplates as receiptTemplates[]
}

export const useFetchReceiptTemplates = () => {
    return useQuery<receiptTemplates[]>(
        ['receipttemplate'],
        () => globalrecieptTemplatesFetcher(),
        {
            staleTime: 120000,
            placeholderData: [receiptTemplatesDefaults],
            keepPreviousData: true
        }
    )
}
