import { useForm } from 'react-hook-form'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core'

import Alerts from '../../components/common/Alerts'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import styles from './LocationsTagsPage.module.css'
// import { useLocationsTagsMutation } from './useLocationsTagsMutation'
import {ExpandMore} from "@material-ui/icons";
import TagsTable from "../LocationsTagsPage/TagsTable";
import LocationsTable from "../LocationsTagsPage/LocationsTable";
import {useFetchLocationTags} from "../../queries/events/useFetchLocationTags";
import {useFetchUDTitles} from "../../queries/useParticipantTitles";
import {EventState, setEvent, useEventState} from '../../slices/eventSlice'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import DonationTable from "./DonationTable";
import React from "react";
import AccountingCode from "./AccountingCode";
import ParticipantUDTitlesTable from "./ParticipantUDTitles";

type FormData = {
  email: string
  password: string
  siteCode: string
}

interface Params {
    id: string
}

const LocationsTagsPage = () => {
    const { id } = useParams<Params>()
    useAutoEvent(id)
    const eventState = useEventState()

    const { data } = useFetchLocationTags(eventState.eventId)
    const  udTitles  = useFetchUDTitles(eventState.eventId)
    console.log(data)
    console.log(udTitles.data)
    const formMethods = useForm<FormData>({
        mode: 'onChange'
    })

    const onSubmit = ({ email, password, siteCode }: FormData) => {
      //locationsTagsMutation.mutate({ password, email, customerid: siteCode })
    }

    return (
        <div className={styles.root}>
          <h1>Dropdown Fields</h1>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <h2>Locations</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.inputsContainer}>
                        <h5>Specify a table number or another type of single select grouping for items.</h5>
                        <LocationsTable locations={data?.locations}/>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                  <h2>Tags</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.inputsContainer}>
                        <h5>Categorize items with tags so that guests can search for similar items. Multiple tags can be selected for each item.</h5>
                        <TagsTable tags={data?.tags}/>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                    <h2>Donation Types</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.inputsContainer}>
                        <h5>The Donation Type field is for the item’s donation record in DonorSnap or your donor management system.</h5>
                        <DonationTable donations={data?.donationtype}/>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                    <h2>Accounting Codes</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.inputsContainer}>
                        <h5>The Accounting code field is for the item’s record in DonorSnap or your donor management system.</h5>
                        <AccountingCode accountingcodes={data?.accountingcode}/>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
                    <h2>User Defined Fields</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={styles.inputsContainer}>
                        <h5>Rename the custom fields availible for use on the Participant record.</h5>
                        <ParticipantUDTitlesTable titles={udTitles?.data}/>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default LocationsTagsPage
