import React from 'react'
import { useController, useFormContext } from 'react-hook-form'

import {MenuItem, Switch as MuiSwitch, TextField} from '@material-ui/core'

import styles from './SelectInput.module.css'

type Props = {
  name: string
  label: string
  options: { value: any; label: string }[] | string[]
  defaultValue?: string
  rules?: Object
  required?: boolean
  disabled?: boolean
  includeNone?: boolean
  includeAll?: boolean
  textFieldProps?: Object
  onHandleChange?: Function
  onClick? : Function
  tooltipText?: string
}

const SelectInput = ({
  name,
  label,
  options,
  defaultValue = '',
  rules,
  required = false,
  disabled = false,
  includeNone = true,
  includeAll = false,
  textFieldProps,
  onHandleChange,
  onClick,
  tooltipText = ""
}: Props) => {
  const { control, formState } = useFormContext()

  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name as any,
    control,
    defaultValue: defaultValue,
    rules: { ...rules, required: required ? 'Field is required' : false }
  })

  const { errors } = formState
  
  return (
    <TextField
      select
      title={tooltipText}
      defaultValue={defaultValue}
      onChange={(event: any) => {
        console.log(event)
      }}
      onClick={() => {
        onClick && onClick()
      }}
      variant="outlined"
      size="small"
      label={label}
      disabled={disabled}
      // value={'None'}
      error={errors[name] ? true : false}
      FormHelperTextProps={{
        classes: {
          error: styles.error
        },
        error: true
      }}
      InputProps={{
        classes: {
          root: styles.input,
          error: styles.error,
          focused: styles.focus
        }
      }}
      InputLabelProps={{
        classes: {
          root: styles.label,
          error: styles.error,
          focused: styles.focus
        },
        shrink: true
      }}
      helperText={errors[name]?.message}
      required={required}
      {...textFieldProps}
      {...inputProps}>

      {includeNone &&
          <MenuItem
            key={"none"}
            value={"none"}
            className={styles.listItem}>
            None
          </MenuItem>
      }
      {includeAll &&
          <MenuItem
            key={"all"}
            value={"0"}
            className={styles.listItem}>
            ALL
          </MenuItem>
      }
      {options && typeof options?.[0] === 'object'
        ? (options as { value: any; label: string }[]).map(cat => (
            <MenuItem
              key={cat.value}
              value={cat.value}
              className={styles.listItem}>
              {cat.label}
            </MenuItem>
          ))
        : (options as string[]).map(cat => (
            <MenuItem key={cat} value={cat} className={styles.listItem}>
              {cat}
            </MenuItem>
          ))}
    </TextField>
  )
}

export default SelectInput
