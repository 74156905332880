import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, IconButton, TextField, Typography
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import SelectInput from '../../components/common/inputs/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
import styles from './EventUserDefinedTable.module.css'
import axios from "axios";
import {useCreateEvtUserDefined} from "../../queries/useEventUserDefinedFetch"
import {useCreateEvtItem} from "../../queries/useEventItemsFetch";
import {DataGridPremium, GridCellParams, GridColDef, GridValueFormatterParams} from "@mui/x-data-grid-premium";
import {Add, Delete, Edit} from "@material-ui/icons";
import {EventValueForm} from "./EventValueForm";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from "@mui/material";
import MaskedInput from "../../components/common/inputs/MaskedInput";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, {Dayjs} from "dayjs";

// 1 - boolean
// 2 - Lookup
// 3 - integer
// 4 - money (double)
// 5 - text
// 6 - date
// 7 - text ([1,3,5] multi lookup values
// 8 - Datetime
// 9 - Time

interface FormData {
  id: number
  control: number
  fieldname: string
  defaultcontrol1: boolean
  defaultcontrol2: number
  defaultcontrol3: number
  defaultcontrol4: string
  defaultcontrol5: string
  defaultcontrol6: string
  displayforallguest: boolean
  items: []
  active: boolean
  delete: boolean
}

export const EventUserDefinedForm = ({ isOpen, setIsOpen, gridParams, reloadForm, eventid, itemrows }) => {
  const formMethods = useForm<FormData>()
  const title = gridParams ? "Edit User Defined Item" : "Create User Defined Item"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [dialogMsg, setDialogMsg] = React.useState("")
  const controlWatch = watch('control')
  const [loading, setLoading] = React.useState(false);
  const [itemSelection, setItemSelection] = React.useState<string[]>([]);
  const [activeValue, setActiveValue] = useState<boolean>(true)
  const [displayForAllGuest, setDisplayForAllGuest] = useState<boolean>(true)
  const [isValueOpen, setIsValueOpen] = useState<boolean>(false)
  const [activeId, setActiveId] = useState<number>(1)
  const [currencyNumber, setCurrencyNumber] = useState<number>(0)
  const [dateTimeValue, setDateTimeValue] = useState<string>(null)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dropdownData, setDropdownData] = React.useState({
    fieldtypelist: [],
    itemlist: [],
  });

  const [controlDropdownValues, setControlDropdownValues] = useState([])

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  useEffect(() => {
    setControlDropdownValues([])
    getDropdowns()
  }, [])

  useEffect(() => {
    setControlDropdownValues([])
  }, [isOpen])

  const getDropdowns = async () => {
    setLoading(true)
    axios.post('/evtuserdefineddropdowns', {eventid: eventid}, {responseType: "json"}).then(response => {
      const parsedData = JSON.parse(response.data.jsonString)
      setDropdownData(parsedData)
      setLoading(false)
    })
  }

  const getEvtUserDefined = async (udid) => {
    axios.post('/evtuserdefined', {eventid: eventid, udid: udid}, {responseType: "json"}).then(response => {
      const parsedData = JSON.parse(response.data.jsonString)
      if (parsedData && parsedData?.dropdownlist) {
        console.log(parsedData?.dropdownlist)
        setControlDropdownValues(parsedData?.dropdownlist)
      }
    })
  }

  useEffect(() => {
    console.log(gridParams)
    if (gridParams?.fieldtype === 'Dropdown') {
      getEvtUserDefined(gridParams?.id).then(() => {
        setValue('defaultcontrol2', Number(gridParams.control2))
      })
    }
    // setControlDropdownValues(((gridParams && gridParams?.itemlist && gridParams?.itemlist?.length > 0) ? gridParams?.itemlist : []))
    setItemSelection((gridParams && gridParams?.items && gridParams?.items?.length > 0 ? gridParams.items : []))

    reset({
      id: (gridParams ? gridParams.id : null),
      control: (gridParams ? gridParams.control : 1),
      fieldname: (gridParams ? gridParams.fieldname : null),
      defaultcontrol1: (gridParams ? gridParams.displayvalue === "Yes" : null),
      defaultcontrol2: (gridParams ? gridParams.default2 : null),
      defaultcontrol3: (gridParams && gridParams.default3 ? gridParams.default3 : null),
      defaultcontrol4: (gridParams && gridParams.default4 ? gridParams.default4 : null),
      defaultcontrol5: (gridParams && gridParams.default5 ? gridParams.default5 : null),
      defaultcontrol6: (gridParams && gridParams.default6 ? gridParams.default6 : null),
      displayforallguest: (gridParams ? gridParams.displayforallguest : true),
      items: (gridParams ? gridParams.items : []),
      active: (gridParams ? gridParams.active : false),
      delete: (gridParams ? gridParams.delete : false),
    })
    setDisplayForAllGuest((gridParams ? gridParams.displayforallguest : true))

    // Setup Currency value
    if (gridParams?.control == 4) {
      setCurrencyNumber(gridParams?.default4)
    }
    else if (gridParams?.control == 6) {
      setDateTimeValue(gridParams?.default6)
    }
  }, [reloadForm])

  useEffect(() => {
    if (gridParams) {
      let resetObject = {
        id: (gridParams ? gridParams.id : null),
        fieldname: (gridParams ? gridParams.fieldname : null),
        defaultcontrol1: (gridParams ? gridParams.displayvalue === "Yes" : null),
        defaultcontrol2: (gridParams ? gridParams.default2 : null),
        defaultcontrol3: (gridParams && gridParams.default3 ? gridParams.default3 : null),
        defaultcontrol4: (gridParams && gridParams.default4 ? gridParams.default4 : null),
        defaultcontrol5: (gridParams && gridParams.default5 ? gridParams.default5 : null),
        defaultcontrol6: (gridParams && gridParams.default6 ? gridParams.default6 : null),
        items: (gridParams ? gridParams.items : []),
        active: (gridParams ? gridParams.active : false),
        delete: (gridParams ? gridParams.delete : false),
      }

      if (gridParams && gridParams.control) {
        resetObject['control'] = (gridParams ? gridParams.control : 1)
      }

      reset(resetObject)
      setDisplayForAllGuest((gridParams ? gridParams.displayforallguest : true))
    }
  }, [controlDropdownValues])


  const fieldTypeOptions = () => {
    const dtRows = dropdownData?.fieldtypelist?.filter((e) => e.active === true).map((types) => {
      return {value: types.control, label: types.name}
    })
    if (dtRows && dtRows.length > 0) {
      return [...dtRows]
    }
    else {
      return []
    }
  }

  const defaultValueOptions = () => {
    console.log(controlDropdownValues)
    const dtRows = controlDropdownValues.filter((e) => e.active === true).map((types) => {
      return {value: types.id, label: types.name}
    })
    if (dtRows && dtRows.length > 0) {
      return [{value: -9000, label: 'None'}, ...dtRows]
    }
    else {
      return []
    }
  }

  const handleItemChange = (event: SelectChangeEvent<typeof itemSelection>) => {
    const {
      target: { value },
    } = event;
    var values = []
    if (typeof value === 'string') {
      values = value.split(',')
    }
    else {
      value?.forEach(v => {
        if (v.length > 0) {
          values.push(v)
        }
      })
    }

    setItemSelection(values)
  }

  // const createMutation = useCreateEvtItem()
  const createMutation = useCreateEvtUserDefined()
  const onSubmit = async (data: FormData) => {
    console.log(data)
    // console.log(itemSelection)
    // console.log(gridParams)

    const itemsInSelection = itemSelection?.map(item => {
      return dropdownData?.itemlist?.find(realDataItem => realDataItem.itemname === item)
    })
    var itemidsString = ''

    itemsInSelection?.map(itm => {
      if (itm) {
        if (itemidsString.length > 0) {
          itemidsString += '|'
        }
        itemidsString += itm.itemid
      }
    })

    const defaultControl = 'defaultcontrol' + data.control
    const defaultControlkey = 'default' + data.control
    var finalData = {
      eventid: eventid,
      control: data.control,
      fieldname: data.fieldname,
      displayforallguest: displayForAllGuest,
      active: activeValue,
      itemids: itemidsString,
      // items: itemsInSelection
    }
    finalData[defaultControlkey] = data[defaultControl]
    if (data.control === 2) {
      const controlName = 'default' + data.control + 'text'
      const defaultControlValue = controlDropdownValues.find(control => control.id === data[defaultControl])
      finalData[controlName] = defaultControlValue?.name
      finalData['lookuplist'] = controlDropdownValues.map(cdv => {
        return {name: cdv.name, active: cdv.active}
      })
    }
    else if (data.control === 4) {
      finalData['default4'] = Number(currencyNumber)
    }
    else if (data.control === 6) {
      finalData['default6'] = dayjs(dateTimeValue).format('YYYY-MM-DDTHH:mm:ss')
    }
    if (gridParams?.id !== null && gridParams?.id > 0) {
      finalData['udid'] = gridParams.id
      finalData['lookuplist'] = controlDropdownValues
    }


    const { data: response } = await createMutation.mutateAsync(finalData)
    const parsedResponse = JSON.parse(response?.jsonString)
    // console.log(parsedResponse)
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse.message)
    }
    else {
      setIsOpen(false)
    }
  }

  const handleAdd = () => {
    setActiveId(null)
    setIsValueOpen(true)
  }

  const handleEdit = (params: GridCellParams) => {
    setActiveId(Number(params.row.id))
    setIsValueOpen(true)
  }

  const closeDialog = () => {
    setDialogObj({msg: '', id: 0})
    setDialogMsg("")
  }

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveValue(event.target.checked);
  }

  const handleDisplayForAllGuest = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setItemSelection([])
    }
    setDisplayForAllGuest(event.target.checked);
  }

  const handleDelete = (params: GridCellParams) => {
    console.log(params)
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.row.name + "\"?",
      id: Number(params.id)
    })
  }

  const deleteDialog = async () => {
    setControlDropdownValues(controlDropdownValues?.filter((i) => i.id !== dialogObj.id))
    setDialogObj({msg: '', id: 0})
  }



  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      renderHeader: () => {
        return (
            <IconButton
                className={styles.submitButton}
                onClick={() => handleAdd()}
                size="small">
              <Add />
            </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
            <Edit />
          </IconButton>
      )
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      minWidth: 350,
      sortable: false,
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? "Active" : "Inactive")
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      width: 70,
      sortable: false,
      renderCell: (params) => (
          // <div title={canDelete(params.row.active) ? '' : "Only items with a status of Inactive can be deleted."}>
            <div>
              <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                <Delete />
              </IconButton>
            </div>
          // </div>
          // </Tooltip>
      )
    }
  ]

  const handleAmountChange = (event) => {
    // setAmountChange(event.floatValue)
  }

  const handleCurrencyChange = (newValue: number | null) => {
    setCurrencyNumber(newValue)
  }

  const handleDateTimeChange = (newValue: Dayjs | null) => {
    setDateTimeValue(newValue.format('MM-DD-YYYY hh:mm A'))
  }

  const handleChecked = (item) => {
    return (itemSelection.indexOf(item.itemname) > -1)
  }


  return (
    <>
      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpen} maxWidth="xl" fullWidth={true} className={styles.dialog}>
        <DialogTitle>{title}</DialogTitle>
        <LoadingBackdrop open={createMutation.isLoading || loading} />
        <>
          <Dialog
              open={dialogMsg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <EventValueForm isValueOpen={isValueOpen} setIsValueOpen={setIsValueOpen} activeId={activeId} controlDropdownValues={controlDropdownValues} setControlDropdownValues={setControlDropdownValues} />
        <DialogContent>
          <HookForm
              methods={formMethods}
              onSubmit={onSubmit}
              className={styles.itemsForm}
              formProps={{ id: 'items-form' }}>

            <SelectInput
                name="control"
                label="Field Type"
                options={fieldTypeOptions()}
                includeNone={false}
                required={true}
            />
            <TextInput name="fieldname" label="Field Name" required />
            {controlWatch === 1 &&
                <SelectInput
                    name="defaultcontrol1"
                    label="Default Value"
                    options={[
                      {label: 'Yes', value: true},
                      {label: 'No', value: false}
                    ]}
                    includeNone={false}
                />
            }

            {controlWatch === 2 &&
                <>
                  <DataGridPremium
                      autoHeight
                      hideFooter={true}
                      rows={controlDropdownValues}
                      columns={columns}/>

                  <SelectInput
                      name="defaultcontrol2"
                      label="Default Value"

                      // disabled={controlDropdownValues?.length <= 0}
                      options={defaultValueOptions()}
                      // options={[
                      //   {label: 'Steak', value: 57},
                      //   {label: 'Chicken', value: 56}
                      // ]}
                      includeNone={false}
                  />
                </>
            }

            {controlWatch === 3 &&
              <MaskedInput
                  name="defaultcontrol3"
                  label="Default Value"
                  onValueChange={handleAmountChange}
                  thousandSeparator={false}
                  thousandsGroupStyle={""}
                  prefix={""}
              />
            }

            {controlWatch === 4 &&
                <CurrencyTextField
                    name="defaultcontrol4"
                    label="Default Value"
                    // variant="outline"
                    value={currencyNumber}
                    currencySymbol="$"
                    outputFormat="string"
                    textAlign={"left"}
                    onChange={(event, value) => handleCurrencyChange(value)}
                />
            }

            {controlWatch ===  5 &&
              <TextInput
                  name="defaultcontrol5"
                  label="Default Value"
                  defaultValue={""}
              />
            }

            {controlWatch ===  6 &&
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker key="defaultcontrol6"
                              label="Default Value"
                              value={dayjs(dateTimeValue)}
                              // disableCloseOnSelect={false}
                              onChange={(e: Dayjs | null) => { handleDateTimeChange(e)}}
                              // renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
            }
            <FormControlLabel control={<Checkbox name="displayforallguest" color="primary" checked={displayForAllGuest} onChange={handleDisplayForAllGuest}/>} label="Display for All Guest Registrations" />
            <FormControl style={{height: 45}}>
              <InputLabel>Display for Specific Item(s)</InputLabel>
              <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  name="items"
                  multiple
                  style={{height: 45}}
                  disabled={displayForAllGuest}
                  value={itemSelection}
                  onChange={handleItemChange}
                  input={<OutlinedInput label="Display for Specific Item(s)" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
              >
                {dropdownData?.itemlist?.map((item) => (
                    <MenuItem key={item.itemid} value={item.itemname}>
                      <Checkbox color="primary" checked={itemSelection.indexOf(item.itemname) > -1} />
                      <ListItemText primary={item.itemname} />
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel control={<Checkbox name="active" color="primary" checked={activeValue} onChange={handleActiveChange}/>} label="Active" />
          </HookForm>
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
              variant="contained"
              className={styles.submitButton}
              type="submit"
              form="items-form">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
