import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit'

import auth from '../slices/authSlice'
import event from '../slices/eventSlice'
import evt from '../slices/evtSlice'
import org from '../slices/orgSlice'


const rootReducer = combineReducers({
  auth,
  event,
  org,
  evt
})

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV === 'development'
})

export type AppState = ReturnType<typeof rootReducer>
