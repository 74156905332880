import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  AddShoppingCart as AddShoppingCartIcon,
  Message as MessageIcon,
  Refresh as RefreshIcon,
  Assessment as AssessmentIcon,
  BurstMode as BurstIcon,
  Gavel as GavelIcon,
  LocationOn as LocationIcon,
  People as PeopleIcon,
  RemoveShoppingCart as RemoveShoppingCartIcon,
  SettingsOutlined as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Web as WebIcon,
  Tune as TuneIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Group as GroupsIcon,
  AssignmentInd,
  PermContactCalendar
} from '@material-ui/icons'
import BadgeIcon from '@mui/icons-material/Badge'

import { useAutoEvent } from '../../queries/useAutoEvent'
import { EventState, setEvent, useEventState } from '../../slices/eventSlice'
import styles from './EventPage.module.css'
import { useAllowEventRefresh } from "../../slices/authSlice";

interface Params {
  id: string
}

const EventPage = () => {
  const history = useHistory()
  const { id } = useParams<Params>()
  useAutoEvent(id)
  const eventState = useEventState()
  const allowRefresh = useAllowEventRefresh()

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1)
      },
      paper: {
        padding: theme.spacing(2),
        spacing: theme.spacing(100),
        paddingLeft: 10,
        marginLeft: 10,
        marginRight: 10,
        textAlign: 'left',
        fontSize: 25,
        color: theme.palette.text.secondary
      }
    })
  )
  const classes = useStyles()

  return (
    <div className={styles.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>Event Setup</Paper>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={() => {
              history.push('/event/' + eventState.eventId + '/settings')
            }}
            startIcon={<SettingsIcon />}>
            Event Settings
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              history.push('/event/' + eventState.eventId + '/userdefined')
            }}
            startIcon={<TuneIcon />}>
            User Defined Fields
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<ConfirmationNumberIcon  />}
            onClick={() => {
              history.push('/event/' + eventState.eventId + '/eventitems')
            }}
          >
            Tickets and Items
          </Button>
        </Grid>
          <Grid item xs={4} className={styles.selectcontrol}>
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  onClick={() => {
                      history.push('/event/' + eventState.eventId + '/discountcodes')
                  }}
                  startIcon={<GroupsIcon />}>
                  Promo Codes
              </Button>
          </Grid>
          <Grid item xs={4} className={styles.selectcontrol}>
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  onClick={() => {
                      history.push('/event/' + eventState.eventId + '/EventEmailCustomization')
                  }}
                  startIcon={<GroupsIcon />}>
                  Email Customization
              </Button>
          </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>Event Activity</Paper>
      </Grid>
      <Grid container spacing={1}>
          <Grid item xs={4} className={styles.selectcontrol}>
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  onClick={() => {
                      history.push('/event/' + eventState.eventId + '/transactions')
                  }}
                  startIcon={<GroupsIcon />}>
                  EVENT TRANSACTIONS
              </Button>
          </Grid>
          <Grid item xs={4} className={styles.selectcontrol}>
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  onClick={() => {
                      history.push('/event/' + eventState.eventId + '/activitytickets')
                  }}
                  startIcon={<GroupsIcon />}>
                  TICKET MAINTENANCE
              </Button>
          </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              history.push('/event/' + eventState.eventId + '/eventguestmaintenance')
            }}
            startIcon={<GroupsIcon />}>
            Guest Maintenance
          </Button>
        </Grid>
      </Grid>
        <Grid container spacing={1}>
            <Grid item xs={4} className={styles.selectcontrol}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={() => {
                      history.push('/event/' + eventState.eventId + '/eventgroupmaintenance')
                    }}
                    startIcon={<AddShoppingCartIcon />}>
                    Group Maintenance
                </Button>
            </Grid>
            <Grid item xs={4} className={styles.selectcontrol}>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={() => {
                      history.push('/event/' + eventState.eventId + '/EventMassEmailMaintenanceTable')
                    }}
                    startIcon={<AddShoppingCartIcon />}>
                    Email
                </Button>
            </Grid>
        </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>Event Reports</Paper>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<PermContactCalendar />}
          // onClick={() => {
          //   history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
          // }}
          >
            Guest Information
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<BadgeIcon />}
          // onClick={() => {
          //   history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
          // }}
          >
            Name Tags
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<BadgeIcon />}
          // onClick={() => {
          //   history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
          // }}
          >
            Groupings
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<PermContactCalendar />}
          // onClick={() => {
          //   history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
          // }}
          >
            Event Summary
          </Button>
        </Grid>
       
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>Integrations</Paper>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<PermContactCalendar />}
          // onClick={() => {
          //   history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
          // }}
          >
            AuctionSnap
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<PermContactCalendar />}
          // onClick={() => {
          //   history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
          // }}
          >
            DonorSnap
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>Maintenance</Paper>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<PermContactCalendar />}
          // onClick={() => {
          //   history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
          // }}
          >
            Merge Participants
          </Button>
        </Grid>
       
      </Grid>
    </div>
  )
}

export default EventPage
