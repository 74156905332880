import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'


export type Participant = {
    participantid: number;
    authorizedbidder: boolean;
    first: string;
    last: string;
    company: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    email: string;
    source: string;
    bidnumber: number;
    active: boolean;
    contactid: number;
    paddlenumbertext: string;
    participantname: string;
}

const participantDefault = {
    participantid: 0,
    authorizedbidder: false,
    first: "",
    last: "",
    company: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    email: "",
    source: "",
    bidnumber: 0,
    active: false,
    contactid: 0,
    paddlenumbertext: "",
    participantname:""
}

export type ContactData = {
    rownumber: number,
    contactid: number,
    first: string,
    last: string,
    email: string,
    first2: string,
    last2: string,
    email2: string,
    phone: string,
    cell: string,
    company: string,
    address1: string,
    paddlenumbertext : string
  }
const contactDataDefault = {
    rownumber: 0,
    contactid: 0,
    first: '',
    last: '',
    email: '',
    first2: '',
    last2: '',
    email2: '',
    phone: '',
    cell: '',
    company: '',
    address1: '',
    paddlenumbertext: ''
}
interface ParticipantMerge {
    type: string;
    status: string;
    message: string;
    userdefinedtitle1: string;
    userdefinedtitle2: string;
    userdefinedtitle3: string;
    userdefinedtitle4: string;
    userdefinedtitle5: string;
    eventid: number;
    participantid: number;
    biddernumber: number | null;
    first: string;
    last: string;
    email: string;
    phone: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    userdefined1: string;
    userdefined2: string;
    userdefined3: string;
    userdefined4: string;
    userdefined5: string;
    lastlogindate: string;
    participantsource: string;
    participantcontactkey: number;
    currentwinningbids: number;
    numberofcheckouts: number;
    numberofintegrated: number;
    addressreadonly: boolean;
  }
  
  const defaultParticipantMerge: ParticipantMerge = {
    type: "",
    status: "",
    message: "",
    userdefinedtitle1: "",
    userdefinedtitle2: "",
    userdefinedtitle3: "",
    userdefinedtitle4: "",
    userdefinedtitle5: "",
    eventid: 0,
    participantid: 0,
    biddernumber: null,
    first: "",
    last: "",
    email: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    userdefined1: "",
    userdefined2: "",
    userdefined3: "",
    userdefined4: "",
    userdefined5: "",
    lastlogindate: "",
    participantsource: "",
    participantcontactkey: 0,
    currentwinningbids: 0,
    numberofcheckouts: 0,
    numberofintegrated: 0,
    addressreadonly: false,
  };

 const globalAuctionReportFetcher = async (body) => {
    console.log(" get participants global on query page")

    const { data } = await axios.post('/participant', body)
    const parsedData = JSON.parse(data.jsonString)
    const headers = parsedData.participants as Participant[]
   
    return headers
}

const partipantMergePost = async (body) => {

    const { data } = await axios.post('/participantmerge', body)
    const parsedData = JSON.parse(data.jsonString)
    const headers = parsedData as ParticipantMerge
   
    return headers
}

const partipantListingMergePost = async (body) => {

    const { data } = await axios.post('/participantmerge', body)
    const parsedData = JSON.parse(data.jsonString)
    const headers = parsedData.participants as Participant[]
   
    return headers
}

export const useFetchparticipentforMerge = (eventiDvalue, participantid) => {
    console.log(" get participants usefetch on query page")

    return useQuery<ParticipantMerge>(
        ['participantmerge', {eventiDvalue, participantid}],
        () => partipantMergePost({ "eventid": eventiDvalue, "participantid": participantid}),
        {
            staleTime: 100,
            placeholderData: defaultParticipantMerge     
        }
    )
}
export const useFetchparticipentsListing = (eventiDvalue) => {
    console.log(" get participants usefetch on query page")

    return useQuery<Participant[]>(
        ['linkingparticipant', eventiDvalue],
        () => globalAuctionReportFetcher({ "eventid": eventiDvalue}),
        {
            staleTime: 100,
            placeholderData: [participantDefault]        
        }
    )
}
export const useFetchMergeparticipentsListing = (eventiDvalue) => {
    console.log(" get participants usefetch on query page")

    return useQuery<Participant[]>(
        ['participantmerge', eventiDvalue],
        () => partipantListingMergePost({ "eventid": eventiDvalue}),
        {
            staleTime: 100,
            placeholderData: [participantDefault]        
        }
    )
}
 export const getParticpentLinkList = async (body) => {
    const { data } = await axios.post('/participantlinklookup', body)
    const parsedData = JSON.parse(data.jsonString)
    const headers = parsedData.contactdata as ContactData[]
   
    return headers
}

export const useFetchparticipentLinking = (eventiDvalue, participantid?, searchString?, contactid?) => {
    return useQuery<ContactData[]>(
        ['participantlinklookup', eventiDvalue , participantid, searchString, contactid],
        () => getParticpentLinkList({ "eventid": eventiDvalue, "participantid" : participantid, "search": searchString, "contactid": contactid}),
        {
            staleTime: 100,
            placeholderData: [contactDataDefault],

        }
    )
}


export const usePutParticipantLink = () => {
    const queryClient = useQueryClient()
    //console.log(" useputparticipant on query page")

    return useMutation((body: any) =>
        axios.put(`participantlink`, body), {
        onSuccess: () => {
            console.log(" success of useputparticipant on query page")
            queryClient.invalidateQueries(['participantlinklookup'])
            queryClient.invalidateQueries(['linkingparticipant'])
        }
    })
}
export const usePutParticipantMerge = () => {
    const queryClient = useQueryClient()
    //console.log(" useputparticipant on query page")

    return useMutation((body: any) =>
        axios.put(`participantmerge`, body), {
        onSuccess: () => {

           // queryClient.invalidateQueries(['linkingparticipant'])
        }
    })
}

export const usePutParticipantUNLink = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`participantunlink`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['linkingparticipant'])
        }
    })
}

export const usePutParticipantAutoLink = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.post(`participantlinkauto`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['linkingparticipant'])
        }
    })
}