import axios from 'axios'

export const passwordResetEmail = async (eventid, emailAddress) => {
    const json = JSON.stringify({ customerid: eventid, email: emailAddress })
    const { data } = await axios.post('/passwordreset', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.status === 'success'
}

export const passwordResetWithToken = async (token, password) => {
    const json = JSON.stringify({ token: token, password: password })
    const { data } = await axios.post('/passwordresetlink', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.status === 'success'
}

// export const useFetchEventMessages = (eventid) => {
//     return useQuery<EventMessages>(
//         ['eventmessages'],
//         () => globalEventMessagesFetcher(eventid),
//         {
//             staleTime: 120000,
//             placeholderData: defaultEventMessages,
//             keepPreviousData: true
//         }
//     )
// }
