import React, {useEffect, useState} from 'react'

import {Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef,  GridRowModel,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useCreateEventTickets, useFetchTickets, useUpdateTickets} from '../../queries/events/useEventTicketsFetch'
import { EventTicketsForm } from './EventTicketsForm'
import styles from './EventTicketsTable.module.css'
import {EventState, setEvent, useEventState} from "../../slices/eventSlice";
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import {useQueryClient} from "react-query";
import dayjs from "dayjs";
import {GenericForm} from "../GenericForm/GenericForm";

const eventItemsDefaults = {
  id: 0,
  control: 0,
  fieldname: '',
  fieldtype: '',
  displayvalue: '',
  active: true,
  uditem: '',
  items: '',
  itemids: [],
  displayforallguest: false,
}

interface Params {
  eventid: string
}

const EventTicketsTable = () => {
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)
  const queryClient = useQueryClient()
  const eventState = useEventState()
  // const eventUserDefineCreate = useCreateEvtUserDefined()
  const [gridParams, setGridParams] = useState(eventItemsDefaults)
  const [rowOrdering, setRowOrdering] = useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [reloadForm, setReloadForm] = useState<number>(0)
  const [ticketItem, setTicketItem] = useState("-1")
  const [ticketId, setTicketId] = React.useState(0)
  const [endpoint, setEndpoint] = useState("evtticket")

  const { data, isLoading } = useFetchTickets( Number(eventid), ticketItem )
  const [isFormShowing, setIsFormShowing] = useState(false)
  var rows = []

  if (data) {
    console.log(data)
    rows = data.tickets.map((item) => {
      return { id: item.ticketid, ...item}
    })
  }

  const handleAdd = () => {
    console.log(ticketItem)
    setEndpoint('evtticketcomplimentary')
    setTicketId(Number(ticketItem))
    setReloadForm(reloadForm+1)
    setGridParams(null)
    setIsFormShowing(true)
  }

  useEffect(() => {
    if (data && data.selectedticketitemid) {
      setTicketItem(data.selectedticketitemid)
    }
  }, [data])


  useEffect(() => {
    queryClient.invalidateQueries(['EventActivityTickets', Number(eventid), ticketItem])
  }, [ticketItem, isFormShowing])

  const handleEdit = (params: GridCellParams) => {
    setEndpoint('evtticket')
    setReloadForm(reloadForm+1)
    const purchaser = rows.filter((item) => item.id === Number(params.id))[0]

    setTicketId(params.row.ticketid)
    setGridParams(purchaser)
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.row.fieldname + "\"?",
      id: Number(params.id)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var finaldata = {
      "eventid": Number(eventid),
      "udid": id,
      "delete": true
    }
    // const { data: response } = await eventUserDefineCreate.mutateAsync({
    //   ...finaldata
    // },{
    //   onSuccess: (data) => {
    //     const json = JSON.parse(data.data.jsonString)
    //     if (json.status === "failure") {
    //       setDialogObj({
    //         msg: '',
    //         id: 0
    //       })
    //       setDialogMsg(json.message)
    //     }
    //     else {
    //       setDialogObj({
    //         msg: '',
    //         id: 0
    //       })
    //     }
    //   }
    // })
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      // renderHeader: () => {
      //   return (
      //       <IconButton
      //           className={styles.submitButton}
      //           onClick={() => handleAdd()}
      //           size="small">
      //         <Add />
      //       </IconButton>
      //   )
      // },
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
            <Edit />
          </IconButton>
      )
    },
    {
      field: 'id',
      hide: true,
      sortable: false,
    },
    {
      field: 'transactionname',
      type: 'string',
      headerName: 'Purchaser',
      minWidth: 320
    },
    {
      field: 'groupname',
      type: 'string',
      headerName: 'Group Name',
      minWidth: 320
    },
    {
      field: 'ticketname',
      type: 'string',
      headerName: 'Guest Name',
      minWidth: 320
    },
    // {
    //   field: 'action',
    //   headerName: 'Delete',
    //   headerAlign: 'center',
    //   align: 'center',
    //   disableExport: true,
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params) => (
    //       // <div title={canDelete(params.row.active) ? '' : "Only items with a status of Inactive can be deleted."}>
    //         <div>
    //           <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
    //             <Delete />
    //           </IconButton>
    //         </div>
    //       // </div>
    //       // </Tooltip>
    //   )
    // }
  ]
  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridSortedRowIdsSelector(apiRef);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
      options.allColumns = true;
      apiRef.current.exportDataAsCsv(options);
    }

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    const getTicketItemOptions = () => {
      return data?.ticketitemlist?.map(item => (
          <MenuItem key={item.ticketitemid} value={item.ticketitemid}>
            {item.ticketitemname}
          </MenuItem>
      ))
    }

    const handleSelectionChange = async (event: SelectChangeEvent) => {
      console.log(event.target.value)
      setTicketItem(event.target.value as string);
    }

    return (
        <>
          {/*<GridToolbarContainer>*/}
          {/*  <Grid*/}
          {/*      container*/}
          {/*      direction="row"*/}
          {/*      justifyContent="flex-begin"*/}
          {/*      alignItems="center"*/}
          {/*      // border={2}*/}
          {/*      xs={5} sm={4} md={2}*/}
          {/*  >*/}
          {/*    <Select*/}
          {/*        onChange={handleSelectionChange}*/}
          {/*        style={{ width: '100%', height:'30px' }}*/}
          {/*        disabled={params.workOrderObj === null}*/}
          {/*        value={templateSelect}>*/}
          {/*      {getTemplateOptions()}*/}
          {/*    </Select>*/}
          {/*  </Grid>*/}
          {/*  <Grid*/}
          {/*      container*/}
          {/*      direction="row"*/}
          {/*      justifyContent="flex-begin"*/}
          {/*      alignItems="flex-start"*/}
          {/*      // border={2}*/}
          {/*      xs={5} sm={4} md={2}*/}
          {/*  >*/}
          {/*    <Select*/}
          {/*        onChange={handleStatusChange}*/}
          {/*        style={{ width: '100%', height:'30px', marginLeft:'10px' }}*/}
          {/*        disabled={params.workOrderObj === null}*/}
          {/*        value={statusSelect}>*/}
          {/*      {getStatusOptions()}*/}
          {/*    </Select>*/}
          {/*  </Grid>*/}
          {/*  <Grid*/}
          {/*      container*/}
          {/*      direction="row"*/}
          {/*      justifyContent="flex-end"*/}
          {/*      alignItems="center"*/}
          {/*      xs={2.5} sm={4} md={8}*/}
          {/*  >*/}
          {/*    <Button*/}
          {/*        {...buttonBaseProps}*/}
          {/*        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}*/}
          {/*    >*/}
          {/*      EXPORT*/}
          {/*    </Button>*/}
          {/*    /!*<GridToolbarExport style={{color: '#00b2ff'}}/>*!/*/}
          {/*  </Grid>*/}
          {/*</GridToolbarContainer>*/}


          <GridToolbarContainer >
            <>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-begin"
                  alignItems="center"
                  // border={2}
                  xs={3} sm={4} md={4}
              >
                <Grid item xs={6}>
                  <InputLabel  style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-begin'}} >
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {handleAdd()}}
                        color="primary">
                      Add Complimentary Ticket
                    </Button>
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <Select
                      onChange={handleSelectionChange}
                      style={{ width: '100%', height:'30px' }}
                      value={ticketItem}>
                    {getTicketItemOptions()}
                  </Select>
                </Grid>
              </Grid>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={2} sm={6} md={8}
              >
                <Button
                    {...buttonBaseProps}
                    onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                >
                  EXPORT
                </Button>
                {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
              </Grid>
            </>
          </GridToolbarContainer>
        </>
    )
  }

  return (
      <div className={styles.root}>
        <h1>Ticket Maintenance</h1>
      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <GenericForm isGuestOpen={isFormShowing} setIsGuestOpen={setIsFormShowing} endpoint={endpoint} eventId={eventid} genericid={ticketId} />
      {/*<EventTicketsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} eventid={eventid} itemrows={rows}/>*/}
      <DataGridPremium
        autoHeight
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
      />
    </div>
  )
}

export default EventTicketsTable
