export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
export const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/

export const checkPasswordValidity = (value: string) => {
  return (
    [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
      pattern.test(value)
    ) || 'Must include a lower, upper, number, and special character'
  )
}

export const checkPasswordsMatch = (value: string, watch: Function) =>
  value === watch('password') || 'Passwords do not match'

export const checkEndDate = (value: string, watch: Function) => {
  return new Date(value) >= new Date(watch('start')) || 'Event end date should be after event start date.'
}

export const checkBidStartDate = (value: string, watch: Function) => {
  return new Date(value) >= new Date(watch('start')) || 'Bidding open date should be after event start date.'
}

export const checkBidEndDate = (value: string, watch: Function) => {
  return (new Date(value) >= new Date(watch('start')) && new Date(value) >= new Date(watch('bidstart'))) || 'Bidding close date should be after event start and bidding open dates.'
}

export const checkBidEndFinalDate = (value: string, watch: Function) => {
  return (new Date(value) >= new Date(watch('start')) && new Date(value) >= new Date(watch('bidstart'))) || 'Event Close must be at least 24 hours after Bidding Close to allow time for checkout.'
}

export const checkEventEndDate = (value: string, watch: Function) => {
  var datePlus = new Date(watch('bidend'));
  datePlus.setDate(datePlus.getDate() + 1);
  return (new Date(value) >= new Date(watch('start')) &&
      new Date(value) >= new Date(watch('bidstart')) &&
      new Date(value) >= datePlus &&
          new Date(value) >= new Date(watch('bidend'))) || 'Event Close must be at least 24 hours after Bidding Close to allow time for checkout.'
}

export const checkIsNumeric = (value: string) => {
  return !isNaN(Number(value)) || 'Must be a numeric value'
}

export const checkEndDateAfterStartDate = (
  startDate: string,
  endDate: string
) => {
  if (!startDate || !endDate) return true
  const start = new Date(startDate)
  const end = new Date(endDate)

  return start.getTime() >= end.getTime()
    ? 'End time must be after start time'
    : true
}

export const statesArray = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  { label: "ARMED FORCES IN EUROPE", value: "AE" },
  { label: "Armed Forces the Americas", value: "AA" },
  { label: "Armed Forces Pacific", value: "AP" },
  { label: "Virgin Islands", value: "VI" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Madhya Pradesh", value: "MP" },
  { label: "Guam", value: "GU" },
  { label: "American Samoa", value: "AS" },
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "Newfoundland and Labrador", value: "NL" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Nunavut", value: "NU" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
  { label: "Yukon", value: "YT" },
]
