import { useSelector } from 'react-redux'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AppState } from '../App/store'

export interface EvtState {
  eventId: string
  eventName: string
  eventCode?: string
  allowpaymentoffees?: boolean
  billme?: boolean
  allowattendedbytickettype?: boolean
  ccfee?: number
}

const initialState: EvtState = {
  eventId: '',
  eventName: '',
  eventCode: '',
  allowpaymentoffees: false,
  billme: false,
  ccfee: 0,
}

export const evtSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvt: (state, { payload }: PayloadAction<EvtState>) => {
      state.eventId = payload.eventId
      state.eventName = payload.eventName
      state.eventCode = (payload.eventCode ? payload.eventCode : state.eventCode)
      state.billme = payload.billme
      state.allowpaymentoffees = payload.allowpaymentoffees
      state.ccfee = payload.ccfee
    }
  }
})

/** ACTION EXPORTS */
export const {
  setEvt
} = evtSlice.actions

/** REDUCER EXPORT */
export default evtSlice.reducer

/** STATE HOOK EXPORTS - hooks for components to get state */
export const useEvtState = () => useSelector((state: AppState) => state.evt)
