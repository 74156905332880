import axios from 'axios'
import { useQuery } from 'react-query'

import { useAuthToken } from '../../slices/authSlice'

export interface OrgSettings {
  type: string
  status: string
  message: string
  isdonorsnapcustomer: boolean
  name: string
  contactname: string
  address: string
  address2: string
  city: string
  state: string
  zip: string
  country: string
  phone: string
  email: string
  goemerchant: GoEmerchant
  twilio: Twilio
  users: User[]
}

interface User {
  userid: number
  active: boolean
  email: string
  lastupdated: string
}

interface GoEmerchant {
  transactionid: string
  gatewayid: string
  ecommerceid: string
  lastupdated: string
  address: boolean
  postalcode: boolean
}

interface Twilio {
  phone: string
  accountsid: string
  token: string
}

export const orgSettingsFetcher = async (userid) => {
  if (userid) {
    const response = await axios.get(`/orgsettings`, {
      params: {
        customerid: userid
      }
    })
    const parsedResponse = JSON.parse(response?.data?.jsonString)
    const withNestedJSON = {
      ...parsedResponse,
      goemerchant: parsedResponse.goemerchant ? JSON.parse(parsedResponse.goemerchant) : {},
      twilio: parsedResponse.twilio ? JSON.parse(parsedResponse.twilio) : {}
    }

    return withNestedJSON
  }
  else {
    return null
  }
}

export const useFetchOrgSettings = ({
  userid,
  onSuccess = () => null,
  onError = () => null,
  enabled
}: {
  userid?: string
  onSuccess?: any
  onError?: any
  enabled?: boolean
} = {}) => {
  return useQuery<OrgSettings>(
    ['orgsettings', userid],
    () => orgSettingsFetcher(userid),
    {
      staleTime: 120000,
      enabled: enabled ? enabled : Boolean(userid),
      onSuccess,
      onError,
      retry: false
    }
  )
}
