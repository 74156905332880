import React, {useEffect, useState} from 'react';
import axios from "axios";
import {
    DataGridPremium,
    GridAlignment,
    GridCallbackDetails,
    GridCellParams,
    GridColDef,
    GridCsvExportOptions,
    GridCsvGetRowsToExportParams,
    GridEventListener, GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowEditStopReasons, GridRowModel, GridRowModes,
    GridRowParams,
    gridSortedRowIdsSelector,
    GridSortModel,
    GridToolbarContainer,
    GridToolbarExport, GridValueFormatterParams,
    MuiEvent,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-premium";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {Checkbox, Grid, TextField} from "@mui/material";
import dayjs from "dayjs";
import {GridSelectionModel} from "@mui/x-data-grid-pro";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import styles from "../../AuctionSettingsForm/AuctionSettingsForm.module.css";

const EmailRecipients = ({eventid, massemailid}) => {
    const [emailData, setEmailData] = useState({columnlist: [], emailcount: 0, massemailname: '', massemailtype: '', message: '', rowlist: [], totalcount: 0});
    const [checkboxSelection, setCheckboxSelection] = React.useState([])
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
    const [checked, setChecked] = React.useState(true);
    const [dialogMsg, setDialogMsg] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxSel(event.target.id, event.target.checked)
    };

    useEffect(() => {
        const body = {'eventid': eventid, 'massemailid': massemailid}

        const fetchData = async () => {
            const { data } = await axios.post('/evtmassemailrecipient', body)
            const parsedData = JSON.parse(data.jsonString)
            console.log(parsedData)
            setEmailData(parsedData)

            const selectedItems = parsedData.rowlist.map(listofstuff => {
                return {id: listofstuff.id, value: listofstuff.isselected}
            })
            if (shouldAllBeSelected(selectedItems)) {
                setCheckboxSelection([{id: 0, value: true}, ...selectedItems])
            }
            else {
                setCheckboxSelection(selectedItems)
            }
        }

        fetchData()
    }, [])

    const shouldAllBeSelected = (selections) => {
        var allSelected = true
        selections.forEach(listofstuff => {
            if (listofstuff.value == false) {
                allSelected = false
            }
        })
        return allSelected
    }

    const setCheckboxSel = (id, value) => {
        const selections = checkboxSelection.filter((op) => op.id != id)

        if (id == 0) {
            const selectedItems = emailData.rowlist.map(listofstuff => {
                return {id: listofstuff.id, value: value}
            })
            setCheckboxSelection([{id: 0, value: value}, ...selectedItems])
        }
        else {
            const selectionValues = [{id: Number(id), value: value}, ...selections].filter((op) => op.id != 0)
            const allValue = shouldAllBeSelected(selectionValues)

            selectionValues.push({id: 0, value: allValue})
            setCheckboxSelection(selectionValues)
        }
    }

    const getCheckboxSel = (id) => {
        const selection = checkboxSelection.find((op) => op.id === id)
        return selection ? selection.value : false
    }

    const valueGetter = (gridParams) => {
        var actualDay = dayjs(gridParams?.value?.toString(), "YYYY-MM-DD")
        const newDate =  dayjs().set('date', actualDay.date()).set('month', actualDay.month()).set('year', actualDay.year())

        return (gridParams.value ? newDate.format('MM/DD/YYYY') : '')
    }

    const lookupValue = (params: GridValueFormatterParams) => {
        if (params.value) {
            // console.log(params.value.toString())
            const paramsValue = params.value.toString()
            const tagDescription = processValue(paramsValue)
            return tagDescription
        }
    }
    const processValue = (valuesAsString: string) => {
        // var idsToLookup = [valuesAsString]
        // if (valuesAsString && typeof valuesAsString == "string" && valuesAsString.length > 0 && valuesAsString.includes(',')) {
        //     idsToLookup = valuesAsString.split(',')
        // }
        // const lookupStrings = idsToLookup.map(id => {
        //     const lookObj = mainData?.lookuplist.filter((item) => item.uniqueid === id)
        //     // console.log(lookObj)
        //     if (lookObj && lookObj.length > 0) {
        //         return lookObj[0].lookup
        //     }
        // })
        // const tagDescription = getTagDescription(lookupStrings)
        // return tagDescription
        return ''
    }

    // const valueSingleSelectGetter = (params) => {
    //     var check = ''
    //     if (params.value) {
    //         check = params.value.toString()
    //     }
    //     const lookup = lookupList.filter(list => list.uniqueid === check)
    //     if (lookup && lookup.length > 0) {
    //         return lookup[0].lookup
    //     }
    //     else {
    //         return params.value ? params.value : 'None'
    //     }
    // }

    function RenderCheckBox() {
        const [checked, setChecked] = React.useState(); // Initiated react binded value with param from `rows`

        // Handler for user clicks to set checkbox mark or unset it
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            // setChecked(event.target.checked);
        };
        //The bind for dynamic mark/unmark: checked={checked}
        //The handler for user clicks: onChange={handleChange}
        return (
            <Checkbox
                checked={checked}
                onChange={handleChange}
            />
        );
    }

    const buildDynamicColumnDefs = (columnList) => {
        var gridDefs = [];
        // var sortModel: GridSortModel = [gridSortItem]

        columnList?.forEach(field => {
            // 1 - boolean
            // 2 - Lookup
            // 3 - integer
            // 4 - money (double)
            // 5 - text
            // 6 - date
            // 7 - text ([1,3,5] multi lookup values
            // 8 - Datetime
            // 9 - Time

            let gridAlignmentHeader: GridAlignment = field.fieldheaderalign
            let gridAlignmentCell: GridAlignment = field.fieldalign
            let colWidth = field.fieldwidth

            var col: GridColDef = {
                "field": field.fieldfield.toString(),
                "headerName": field.fieldlabel,
                "type": 'string',
                "headerAlign": gridAlignmentHeader,
                "width": colWidth,
                "align": gridAlignmentCell,
            }

            if (field.fieldcontrol === 1) {
                // col.renderCell = RenderCheckBox
                // col.valueFormatter = boolValueLookup
                col = GRID_CHECKBOX_SELECTION_COL_DEF

                col.field = field.fieldfield.toString()
                col.headerName = field.fieldlabel
                col.width = colWidth
                col.align = gridAlignmentCell
                col.headerAlign = gridAlignmentHeader
                col.renderHeader = (params) => {
                    return (
                        <Checkbox
                            name={'0'}
                            id={'0'}
                            checked={getCheckboxSel(0)}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            size="medium"
                        />
                    )
                }
                col.renderCell = (params) => {
                    return (
                        <Checkbox
                            name={params.row.id}
                            id={params.row.id}
                            checked={getCheckboxSel(params.row.id)}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            size="medium"
                        />
                    )
                }
                // col.valueGetter = valueCheckboxGetter
                // col.valueSetter = valueCheckboxSetter
                // console.log(col)
            } else if (field.fieldcontrol === 2) {
                // col.groupingValueGetter = valueSingleSelectGetter
                // col.valueFormatter = valueLookup
                // col.renderCell = (params) => {
                //     const fieldid = field.schedulefieldid
                //     const fieldcolorId = 'schedulegridcolor' + field.schedulefieldid
                //     const testData = processValue(params.row[fieldid])
                //     return getCellText(testData, params.row[fieldcolorId])
            }
            else if (field.fieldcontrol === 3) {
                col.type = 'number'
            } else if (field.fieldcontrol === 4) {
                // col.renderEditCell = (params: any) =>  {
                //     return (
                //         <>
                //             <CurrencyTextField
                //                 label={field.schedulefieldlabel}
                //                 name={field.schedulefieldid.toString()}
                //                 value={getCurrencyValue(field.scheduleid, field.schedulefieldid)}
                //                 currencySymbol="$"
                //                 outputFormat="string"
                //                 textAlign={"left"}
                //                 onChange={(event, value) => handleCurrencyChange(value, field.scheduleid, field.schedulefieldid)}
                //             />
                //         </>
                //     )
                // }
            } else if (field.fieldcontrol === 5) {
                // col.renderEditCell = (params: any) => {
                //     return (
                //         <>
                //             <TextInput
                //                 name={state.schedulefieldid.toString()}
                //                 label={state.schedulefieldlabel}
                //                 defaultValue={""}
                //                 rules={{
                //                     required: ' is required'
                //                 }}
                //                 required={state.schedulefieldrequired}
                //             />
                //         </>
                //     )
                // }
            } else if (field.fieldcontrol === 6) {
                // col.valueFormatter = formatDate
                col.valueGetter = valueGetter
                col.groupingValueGetter = valueGetter
            } else if (field.fieldcontrol === 7) {
                col.valueFormatter = lookupValue
                col.renderEditCell = (params: any) => {
                    return (
                        <>
                        </>
                    )
                }
            }
            // const fielddata = getFieldData(field.fieldfield.toString())
            // console.log(fielddata)
            gridDefs.push({...col})
        })
        // console.log(GRID_CHECKBOX_SELECTION_COL_DEF)


        return [...gridDefs]
    }

    const save = async () => {
        const selections = checkboxSelection.filter(item => item.value == true)
        const ids = selections.map(sels => {
            return sels.id
        })

        const body = {
            "eventid": eventid,
            "massemailid": massemailid,
            "idlist": ids
        }

        const { data } = await axios.put('/evtmassemailrecipient', body)
        const parsedData = JSON.parse(data.jsonString)
        var message = "Successfully saved."
        if (parsedData.message) {
            parsedData.message = message
        }
        setDialogMsg(message)
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    return (
        <>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
            <div style={{ padding: 20 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <DataGridPremium
                            // checkboxSelection
                            autoHeight
                            rows={emailData?.rowlist}
                            columns={buildDynamicColumnDefs(emailData?.columnlist)}
                            // onSelectionModelChange={itm => console.log(itm)}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                            // onRowClick={handleRowClick}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" className={styles.button} onClick={save} fullWidth>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default EmailRecipients

