import React, { useState } from 'react'
import styles from './BidMaintenancePage.module.css'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {useEventState} from "../../slices/eventSlice";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {useFetchBidMaintenance, useUpdateBidMaintenance} from "../../queries/useFetchBidMaintenance";
import dayjs from "dayjs";
import {DataGridPremium, GridValueFormatterParams, GridCellParams} from "@mui/x-data-grid-premium";

interface Params {
    eventid: string
}

const BidMaintenancePage = () => {
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const eventState = useEventState()
    const { data, isLoading } = useFetchBidMaintenance(Number(eventid))
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0, title: "" })
    const [dialogMsg, setDialogMsg] = React.useState("")

    const updateBidMaintenanceMutation = useUpdateBidMaintenance()

    var bidmaintRows = []
    if (data) {
        bidmaintRows = data.map((bm, index) => {
            return { ...bm, id: bm.historyid }
        })
    }

    // scotty
    const handleDelete = (params: GridCellParams) => {

        setDialogObj({
            title: 'Are you sure you want to delete the following bid?',
            id: Number(params.id),
            msg: params.row.biddername + " | " + params.row.itemname  + " | $" + params.row.bidamount,
        })
    }

    const deleteDialog = async () => {
        await handleDeleteConfirm(dialogObj.id)
    }

    const closeDialog = () => {
        setDialogMsg('')
        setDialogObj({
            msg: '',
            id: 0,
            title: ""
        })
    }

    const handleDeleteConfirm = async (id: number) => {
        var finaldata = {
            "eventid": Number(eventState.eventId),
            "historyid": id,
            "delete": true
        }
        const { data: response } = await updateBidMaintenanceMutation.mutateAsync({
            ...finaldata
        },{
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                if (json.status === "failure") {
                    setDialogObj({
                        msg: '',
                        id: 0,
                        title: ""
                    })
                    setDialogMsg(json.message)
                }
                else {
                    setDialogObj({
                        msg: '',
                        id: 0,
                        title: ""
                    })
                }
            }
        })
    }

    const columns : any = [
        {
            field: 'action',
            headerName: 'Delete',
            width: 70,
            align: "right",
            headerAlign: "right",
            sortable: false,
            renderCell: (params) => (
                <>
                    {!params.row.checkoutcompleted &&
                        <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                            <Delete />
                        </IconButton>
                    }
                </>
            )
        },
        {
            field: 'rownumber',
            hide: true
        },
        {
            field: 'biddername',
            type: 'string',
            headerName: 'Bidder',
            width: 200
        },
        {
            field: 'itemnumber',
            type: 'string',
            headerName: 'Item #',
            width: 120
        },
        {
            field: 'itemname',
            type: 'string',
            headerName: 'Item Name',
            width: 200
        },
        {
            field: 'bidtype',
            headerName: 'Type',
            width: 150,
            sortable: false,
        },
        {
            field: 'bidtime',
            headerName: 'Bid Date',
            width: 200,
            sortable: true,
            valueFormatter: (params: GridValueFormatterParams) => {
                return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
            },
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 120,
            align: "right",
            headerAlign: "right",
            sortable: false,
        },
        {
            field: 'bidamount',
            headerName: 'Current Bid',
            width: 150,
            align: "right",
            headerAlign: "right",
            sortable: false,
            valueFormatter: (params: GridValueFormatterParams) => {
                return "$" + params.value
            },
        },
        {
            field: 'bidautoamount',
            headerName: 'Auto Bid',
            width: 120,
            align: "right",
            headerAlign: "right",
            sortable: false,
            valueFormatter: (params: GridValueFormatterParams) => {
                return "$" + params.value
            },
        }
    ]

    return (
        <div className={styles.root}>
          <h2>Bid Maintenance</h2>
            <>
            <Dialog
                open={dialogObj.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObj.title}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObj.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={deleteDialog}>DELETE</Button>
                </DialogActions>
            </Dialog>
            </>
            <DataGridPremium
                autoHeight
                rows={bidmaintRows}
                columns={columns}
                loading={isLoading}
                hideFooterSelectedRowCount={true}
                // density={'compact'}
                pagination={true}
            />
        </div>
    )
}

export default BidMaintenancePage
