import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useEditEventSettings = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (body: any) => {
      return axios.put(`/evtsettings`, body)
    }
    ,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['evtsettings'])
        queryClient.invalidateQueries(['evts'])
      }
    }
  )
}


export const useCreateEvent = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (body: any) => {
      return axios.put(`/evtsettings`, body)
    },
    {
      onSuccess: (data ) => {
        queryClient.invalidateQueries(['evtsettings'])
        queryClient.invalidateQueries(['evts'])
      }
    }
  )
}
