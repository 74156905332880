import cn from 'classnames'
import {
  bindMenu,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import {
  AppBar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core'
import {
  ChevronRight,
  ExitToApp as ExitIcon,
  HomeRounded as HomeIcon,
  Menu as MenuIcon,
  Person as PersonIcon,
  Settings,
  Web as WebIcon
} from '@material-ui/icons'

import { Logo } from '../../../../icons/Logo'
import { logout } from '../../../../slices/authSlice'
import styles from './Header.module.css'

// import { logout, useCurrentUser } from '@slices/authSlice'

const Header = ({ showMenu, isOpen, setIsOpen }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  const history = useHistory()
  const dispatch = useDispatch()

  const redirectToLogin = () => {
    dispatch(logout())
    history.push('/auth/login')
  }
  const redirectToHome = () => {
    history.push('/home')
  }
  return (
    <AppBar
      position="absolute"
      className={cn(styles.appBar, isOpen && styles.shift)}>
      <Toolbar className={styles.toolbar}>
        {showMenu && (
          <HomeIcon
              onClick={() => redirectToHome()}
              classes={{ root: cn(styles.menuButton) }} fontSize="large">
          </HomeIcon>
        )}

        <div className={styles.logoWrapper}>
          <Link to="/home">
            <Logo />
          </Link>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          {...bindTrigger(popupState)}>
          {/* <Typography>{user?.firstname}</Typography> */}
          <Avatar className={styles.avatar} />
          <IconButton color="inherit">
            <ChevronRight className={styles.arrow} />
          </IconButton>
        </div>

        <Menu {...bindMenu(popupState)} style={{ top: 40 }}>
          {/* <MenuItem onClick={onWebsiteClick}>
            <WebIcon style={{ marginRight: 10 }} />
            Website
          </MenuItem>
          <MenuItem onClick={onProfileClick}>
            <PersonIcon style={{ marginRight: 10 }} />
            My Profile
          </MenuItem> */}
          <MenuItem onClick={() => redirectToLogin()}>
            <ExitIcon style={{ marginRight: 10 }} />
            Sign Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header
