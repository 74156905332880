import styles from './FlexMonsterPage.module.css'
import * as FlexmonsterReact from 'react-flexmonster';

const FlexMonsterPage = () => {

  let jsonData = [
    {
      "Category": "Accessories",
      "Size": "277 oz",
      "Color": "red",
      "Destination": "United Kingdom",
      "Business Type": "Warehouse",
      "Country": "United Kingdom",
      "Price": 1000,
      "Quantity": 730,
      "Discount": 38
    },
    {
      "Category": "Accessories",
      "Size": "47 oz",
      "Color": "white",
      "Destination": "United States",
      "Business Type": "Warehouse",
      "Country": "United States",
      "Price": 7941,
      "Quantity": 73,
      "Discount": 53
    },
    {
      "Category": "Bikes",
      "Size": "264 oz",
      "Color": "white",
      "Destination": "Australia",
      "Business Type": "Specialty Bike Shop",
      "Country": "Australia",
      "Price": 6829,
      "Quantity": 19,
      "Discount": 56
    }
  ];

  // flexmonster.updateData({ data: jsonData });

  return (
    <div className={styles.root}>
      <h1>Auction Items</h1>
        <FlexmonsterReact.Pivot
            toolbar={true}
            componentFolder="https://cdn.flexmonster.com/"
            width="100%"
            report="https://cdn.flexmonster.com/reports/report.json"
        />
    </div>
  )
}

export default FlexMonsterPage
