import React, { useState, useMemo } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { DataGridPremium, DataGridPremiumProps,  GridToolbarContainer, GridToolbarExport, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import {useFetchReportCheckout} from "../../queries/useFetchReportCheckout";
import {useAutoEvent} from "../../queries/useAutoEvent";
import { useParams } from 'react-router'
import {EventState, setEvent, useEventState} from '../../slices/eventSlice'
import dayjs from "dayjs";
import {formatMoney} from "../../util/formatHelpers";
import { makeStyles } from "@material-ui/core";
import Button, {ButtonProps} from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import download from 'downloadjs';
import axios from "axios";
import styles from './AuctionSummaryReportPage.module.css'

interface Params {
  eventid: string
}

function CustomToolbar() {
  const eventState = useEventState()
  const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'small',
    startIcon: <FileDownloadOutlinedIcon />,
  };

  const generateAuctionSummaryClick = async () => {
    axios.post('/auctionsummaryexcel', {eventid: eventState.eventId}, {responseType: "blob"}).then(response => {
      var filename = eventState.eventName + '-auctionsummary.xlsx';
      var disposition = response.headers['content-disposition']
      var contentType = response.headers['content-type']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      if (response.data) {
        download(response.data, filename, contentType);
      }
    })
  }

  const generateAuctionDetailClick = async () => {
    axios.post('/auctionsummaryexceldetail ', {eventid: eventState.eventId}, {responseType: "blob"}).then(response => {
      var filename = eventState.eventName + '-auctiondetail.xlsx';
      var disposition = response.headers['content-disposition']
      var contentType = response.headers['content-type']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      if (response.data) {
        download(response.data, filename, contentType);
      }
    })
  }
  return (
    <GridToolbarContainer>
      <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
      >
        {/* <GridToolbarExport /> */}
        <Button
            {...buttonBaseProps}
            onClick={() => generateAuctionSummaryClick()}
        >
          EXPORT SUMMARY
        </Button>
        {/*<Button*/}
        {/*    {...uploadebuttonBaseProps}*/}
        {/*    onClick={() => { history.push(importPath) }}*/}
        {/*>*/}
        {/*  IMPORT PARTICIPANTS*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*    {...uploadebuttonBaseProps}*/}
        {/*    onClick={() => { history.push(importDMPPath) }}*/}
        {/*>*/}
        {/*  IMPORT DONORSNAP*/}
        {/*</Button>*/}
        <Button
            {...buttonBaseProps}
            onClick={() => generateAuctionDetailClick()}
        >
          EXPORT DETAIL
        </Button>
        {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
      </Grid>
    </GridToolbarContainer>
  );
}


const useStyle = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "visible",
      lineHeight: "1.43rem",
      whiteSpace: "normal"
    }
  }
});

  const AuctionSummaryReportPage = () => {
    const [isLoadingBackdrop, setisLoadingBackdrop] = React.useState(false)


    function DetailPanelContent({ row: rowProp }: { row: Header }) {
      return (
        <Stack
          sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
          direction="column"
        >
          <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
              <Typography variant="h6">{`Receipt #${rowProp.receiptnumber}`}</Typography>
              <Grid container>
                <Grid item md={6}>
                  <Typography variant="body2" color="textSecondary">
                    Customer information
                  </Typography>
                  <Typography variant="body1">{rowProp.biddername}</Typography>
                  <Typography variant="body1">{rowProp.biddernumber}</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body2" align="right" color="textSecondary">
                    Payment Details
                  </Typography>
                  <Typography variant="body1" align="right">
                  {`${rowProp.source}, ${rowProp.paymentmethod}`}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="right"
                  >{`Purchase Total ${formatMoney(Number(rowProp.subtotal))}, Total Checkout ${formatMoney(Number(rowProp.totalamount))}`}</Typography>
                </Grid>
              </Grid>
              <LoadingBackdrop open={isLoadingBackdrop} />
              <DataGridPremium
                density="compact"
                getRowId={(row) => row.rownumber}
                columns={[
                  { field: 'biddernumber', headerName: 'Bidder #' },
                  { field: 'biddername', headerName: 'Bidder Name' ,  minWidth: 150},
                  { field: 'itemnumber', headerName: 'Item #' },
                  { field: 'itemname', headerName: 'Item Name',  minWidth: 150},
                  { field: 'quantity', headerName: 'Quantity', type: 'number' },
                  { field: 'amount', headerName: 'Amount',
                  valueFormatter: (params: GridValueFormatterParams) => {
                    return formatMoney(Number(params.value))
                  }},
                ]}
                rows={rowProp.details}
                sx={{ flex: 1 }}
                hideFooter
              />
            </Stack>
          </Paper>
        </Stack>
      );
    }
    
    
    type Header = typeof rows[number];
  const columns = [
    { field: 'rownumber', headerName: 'Row Number' },
    { field: 'biddernumber', headerName: 'Bidder #',  minWidth: 100},
    { field: 'receiptnumber', headerName: 'Receipt #', minWidth: 100  },
    { field: 'biddername', headerName: 'Bidder Name',  minWidth: 200},
    { field: 'checkoutdate', headerName: 'Checkout Date',  minWidth: 200 ,
    valueFormatter: (params: GridValueFormatterParams) => {
        return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
    },},
    { field: 'subtotal', headerName: 'Subtotal',  minWidth: 100 ,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatMoney(Number(params.value))
        }},
    { field: 'additionaldonation', headerName: 'Additional Donation',  minWidth: 100 ,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatMoney(Number(params.value))
        }},
    { field: 'ccfees', headerName: 'Fees',  minWidth: 100 ,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatMoney(Number(params.value))
    }},
    { field: 'totalamount', headerName: 'Total',  minWidth: 100 ,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatMoney(Number(params.value))
        }},
    { field: 'source', headerName: 'Source',  minWidth: 100 },
    { field: 'paymentmethod', headerName: 'Payment Method',  minWidth: 100 },
  ];
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const event = useEventState()
    console.log(event)
    var rows = []

    const { data , isLoading } = useFetchReportCheckout(event?.eventId);
    if(data){
      rows = data;
    }
    const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} />, []);

  const getDetailPanelHeight = React.useCallback(() => 400, []);
  const classes = useStyle();

    return (
      <div className={styles.root}>
        <h2>Auction Summary Report</h2>
        <DataGridPremium
         localeText={{
          toolbarExport: "Export Summary"
        }}
        getRowId={(row) => row.rownumber}
        className={classes.root}
        autoHeight
          columns={columns}
          rows={rows}
          loading={isLoading}
          rowThreshold={0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          columnVisibilityModel={{
            // Hide column rownumber the other columns will remain visible
            rownumber: false,
          }}
          components={{
            Toolbar: CustomToolbar,
          }}

        
        />
        </div>
    );
  }
  export default AuctionSummaryReportPage
