import styles from './AuctionItemsPage.module.css'
import AuctionItemsTable from "./AuctionItemsTable";


const AuctionItemsPage = () => {
  return (
    <div className={styles.root}>
      <h1>Auction Items</h1>
        <AuctionItemsTable />
    </div>
  )
}

export default AuctionItemsPage
