import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useImportParticipants = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.put(`/importparticipants`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['importparticipants'])
        }
    })
}

export const useParticipantsImportFields = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.post(`importparticipants`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['importparticipants'])
        }
    })
}

export const useParticipantsImportDMPList = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.post(`importdmpparticipants`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['importdmpparticipants'])
        }
    })
}


export const useParticipantsImportDataMiner = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) => axios.put(`importdmpparticipants`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['importdmpparticipants'])
        }
    })
}



