import React, { FC } from 'react'

import { Divider, IconButton, List, ListItem } from '@material-ui/core'
import {Add, Launch} from '@material-ui/icons'
import QrCodeIcon from '@mui/icons-material/QrCode';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { saveAs } from "@progress/kendo-file-saver";
import { QRCode } from "@progress/kendo-react-barcodes";
import styles from './ViewList.module.css'

type Props = {
  values: { label: string; value: any, title?: string, hover?:string, islink?: boolean, aligntitle?: string, alignvalue?: string, widthtitle?: number, widthvalue?: number, eventname?: string,
    eventid?: number, active?: boolean}[]
  numOfCols?: number
  containerProps?: Object
  listProps?: Object
  listItemProps?: Object
}

const ViewList: FC<Props> = ({
  values,
  numOfCols = 3,
  listItemProps = {},
  listProps = {},
  containerProps = {}
}) => {
  const numOfRows = Math.ceil(values.length / numOfCols)

  const cols = Array.from({ length: numOfCols }, (v, i) => {
    return values.slice(numOfRows * i, numOfRows * (i + 1))
  })

    const exportQRCode = (eventname: string, eventLink: string, codeRef: any) => {
      console.log(codeRef)
        if (!codeRef || !codeRef.current) {
            return;
        }

        codeRef.current.exportImage().then((dataURI) => {
            saveAs(dataURI, eventname + ".png");
        });
    }

  return (
    <div className={styles.listContainer} {...containerProps}>
      {cols.map((col, i) => {
        // console.log(col)
        return (
          <div key={i}>
            <List className={styles.listContainerProps} {...listProps}>
              {col.map(({ label, value, title, hover, islink, aligntitle, alignvalue, widthtitle, widthvalue, eventname, eventid, active}, i) => {
                if (islink) {
                  const qrcode = React.createRef<QRCode>();
                  return (
                      <ListItem className={styles.listItem}>
                      <span style={{display: "none", height: 0, maxHeight: 0}}>
                            <QRCode
                                ref={qrcode}
                                value={value}
                            />
                      </span>
                        <strong>{title}:</strong>
                            <span>
                              <a href={value} target="_blank">
                                <IconButton
                                  className={styles.submitButton}
                                  onClick={ () => {} }
                                  size="small"
                                  title="Open link in new window"
                                >
                                  <Launch />
                                </IconButton>
                              </a>
                              <IconButton
                                  className={styles.submitButton}
                                  onClick={ () => {navigator.clipboard.writeText(value)} }
                                  size="small"
                                  title="Copy link to clipboard"
                              >
                                <FileCopyIcon />
                              </IconButton>
                              <IconButton
                                  className={styles.submitButton}
                                  onClick={ () => {exportQRCode(eventname, value, qrcode)} }
                                  size="small"
                                  title="Download QR Code"
                              >
                                <QrCodeIcon />
                              </IconButton>
                            </span>
                      </ListItem>
                      )
                }
                else {
                  return (
                      <ListItem
                          className={styles.listItem}
                          key={i}
                          {...listItemProps}>
                        <strong title={hover}>{label}</strong>
                        <span style={{marginLeft: '5px'}} title={hover}>
                          {Boolean(value) === false && value !== 0 ? 'N/A' : value}
                        </span>
                      </ListItem>
                  )
                }
              })}
            </List>
            {i !== cols.length - 1 && <Divider className={styles.divider} />}
          </div>
        )
      })}
    </div>
  )
}

export default ViewList
