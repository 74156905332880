import axios from 'axios'
import { useQuery } from 'react-query'


export type Locations = {
    rownumber: number
    id: number
    name: string
    closeddate: string
    active: boolean
}

export type Tags = {
    rownumber: number
    id: number
    name: string
    active: boolean
}

export type DonationType = {
    rownumber: number
    id: number
    name: string
    active: boolean
}

export type AccountingCode = {
    rownumber: number
    id: number
    name: string
    active: boolean
}

export type LocationTags = {
    locations: Locations[]
    tags: Tags[]
    donationtype: DonationType[]
    accountingcode: AccountingCode[]
}

export const globalLocationTagsFetcher = async (eventIdvalue) => {
    const json = JSON.stringify({ eventid: Number(eventIdvalue) });
    const { data } = await axios.post('/locationtags', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as LocationTags
}

export const useFetchLocationTags = (eventid) => {
    return useQuery<LocationTags>(
        ['locationtags', eventid],
        () => globalLocationTagsFetcher(eventid),
        {
            staleTime: 120000,
            placeholderData: { locations: [], tags: [], donationtype: [], accountingcode: [] },
            keepPreviousData: true
        }
    )
}
