import React, { useState } from 'react';
import { Dialog, Tabs, Tab, Box, Typography, TextField, Button, Grid  } from '@mui/material';
import { DataGrid, GridSelectionModel, GridColDef  } from '@mui/x-data-grid';
import {
  Editor,
  EditorTools,
  EditorUtils,
  ProseMirror
} from "@progress/kendo-react-editor";
import {InsertShortcodeTool} from '../../../components/common/inputs/RTEInsertTool/RTEInsertTool'
const EmailEditModal = ({ isOpen, handleClose, emailData  }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [testEmail, setTestEmail] = useState('');
 const handleSendTestEmail = () => {
  // Logic to send a test email to the provided address
};

// Function to handle the final send
const handleSendEmails = () => {
  // Logic to send the actual emails
};


const handleSelectAll = () => {
  const allIds = emailData.data?.map((row) => row.id);
  setSelectionModel(allIds);
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90, hide: true },
  { field: 'firstName', headerName: 'First Name', width: 150 },
  { field: 'lastName', headerName: 'Last Name', width: 150 },
  { field: 'email', headerName: 'Email', width: 200 },
  // Add more columns as needed
];
const handleDeselectAll = () => {
  setSelectionModel([]);
};

const handleResetFilters = () => {
  // Reset any applied filters on the DataGrid
};
  const { Bold, Italic, Underline, ViewHtml } = EditorTools;
  const { Schema, EditorView, EditorState } = ProseMirror;
  // This is the node configuration
const nonEditable = {
  name: "nonEditable",
  inline: true,
  group: "inline",
  content: "inline+",
  marks: "",
  attrs: {
    contenteditable: { default: null },
    class: { default: null },
    style: { default: null }
  },
  atom: true,
  parseDOM: [{ tag: "span.uneditable", priority: 51 }],
  // The styles can be added via the class as well
  toDOM: () => [
    "span",
    {
      contenteditable: false,
      class: "uneditable",
      style: "user-select: none; opacity: 0.5;"
    },
    0
  ]
};

  const onMount = event => {
    const { viewProps } = event;
    const { plugins, schema } = viewProps.state;

    // Append a new node.
    let nodes = schema.spec.nodes.addToEnd('nonEditable', nonEditable);

    // Create the new schema.
    const mySchema = new Schema({ nodes: nodes, marks: schema.spec.marks });

    // Create a new document using the modified schema.
    const doc = EditorUtils.createDocument(mySchema, '');

    // Return the custom EditorView object that will be used by Editor.
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc, plugins })
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <Tabs value={activeTab} onChange={handleChangeTab} centered>
        <Tab label="Edit Email Body" />
        <Tab label="Recipients" />
        <Tab label="Review & Send" />
        {/* <Tab label="Send History" /> */}
      </Tabs>

      <Box p={3}>
        {activeTab === 0 && (
          // Step 1: Edit Email Body
          <Box>
          <TextField name={"fromName"} label={"From Name:"} defaultValue=''/>
          <TextField name={"fromEmail"} label={"From Email:"} defaultValue=''/>
          <TextField name={"subject"} label={"Subject:"} defaultValue=''/>
            <Typography variant="h6">Header</Typography>
            <Editor
        tools={[[Bold, Italic, Underline, ViewHtml, InsertShortcodeTool]]}
        contentStyle={{ height: 300 }}
        onMount={onMount}
      />
                       <Box display="flex" justifyContent="space-between" mb={2}>

      <Button variant="contained" onClick={handleSelectAll} color='warning'>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleDeselectAll}>
          Save
        </Button>
        </Box>
          </Box>
        )}
        {activeTab === 1 && (
         <Box p={2}>
         <Grid container spacing={2} alignItems="center">
             <Grid item xs={6}>
               <Typography>Email Name: {emailData.emailName}</Typography>
             </Grid>
             <Grid item xs={6}>
               <Typography>Email Type: {emailData.emailType}</Typography>
             </Grid>
           </Grid>
           <Grid container spacing={2} alignItems="center">
             <Grid item xs={6}>
             <Typography mt={1}>
                Number of guests with emails: 0 {/* Display count here */}
              </Typography>
             </Grid>
             <Grid item xs={6}>
             <Typography mt={1}>
                Number of guests Selected: 0 {/* Display count here */}
              </Typography>
             </Grid>
           </Grid>
           <Box display="flex" justifyContent="space-between" mb={2}>
        <Button variant="contained" size="small"  onClick={handleSelectAll}>
          Select All
        </Button>
        <Button variant="contained"  size="small"  onClick={handleDeselectAll}>
          Deselect All
        </Button>
        <Button variant="contained" size="small"  onClick={handleResetFilters}>
          Reset Filters
        </Button>
      </Box>

      <DataGrid
        rows={emailData.data}
        columns={columns}
        pageSize={10}
        autoHeight
        checkboxSelection
        onSelectionModelChange={(newSelection) => setSelectionModel(newSelection)}
        selectionModel={selectionModel}
      />
                 <Box display="flex" justifyContent="space-between" mb={2}>

        <Button variant="contained" onClick={handleSelectAll} color='warning'>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleDeselectAll}>
          Save
        </Button>
        <Button variant="contained" onClick={handleResetFilters}>
          Go to Review/Send
        </Button>
        </Box>
           </Box>
        )}
        {activeTab === 2 && (
          // Step 3: Review and Send
          <Box p={2}>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Typography>Email Name: {emailData.emailName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Email Type: {emailData.emailType}</Typography>
              </Grid>
            </Grid>
          {/* Send Test Email Section */}
          <Grid container spacing={2} alignItems="center" mt={2}>
              <Grid item xs={8}>
                <TextField
                  label="Test Email Address"
                  value={testEmail}
                  onChange={(e) => setTestEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <Button onClick={handleSendTestEmail} variant="contained" color="primary" fullWidth>
                  Send Test
                </Button>
              </Grid>
            </Grid>

          <Grid container spacing={2} alignItems="center" mt={2}>
          <Grid item xs={4}>

          <Typography mt={1}>
          Preview First 10 Emails: {/* Display count here */}
            </Typography>
            </Grid>
            <Grid item xs={4}>

            <Button onClick={handleSendEmails} size='small' variant="contained" color="secondary">
              Send Previews
            </Button>
            </Grid>
            </Grid>
          {/* Send Emails Section */}
          <Grid container spacing={2} alignItems="center" mt={2}>
          <Grid item xs={4}>

          <Typography mt={1}>
              Number of emails to send: {/* Display count here */}
            </Typography>
            </Grid>
            <Grid item xs={4}>

            <Button onClick={handleSendEmails} variant="contained" size='small' color="secondary">
              Send Emails
            </Button>
            </Grid>
            </Grid>
        </Box>
        )}
        {activeTab === 3 && (
          // Step 4: Send History
          // <DataGrid
          //   // Configuration for send history grid
          // />
          <Box>
            {/* Summary of email and a Send button */}
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default EmailEditModal;
