import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export const globalEventGuestFetcher = async (body) => {
    const { data } = await axios.post('/evtactivityregistrations', body)
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData
}

export const useFetchGuestById = (eventID, guestid) => {
    var body = {'eventid': eventID, 'transactionid': guestid}
    return useQuery(
        ['EventGuest', eventID, guestid],
        () => globalEventGuestFetcher(body),
        {}
    )
}

export const useFetchGuests = (eventID) => {
    var body = {'eventid': eventID}
    return useQuery(
        ['EventGuest', eventID],
        () => globalEventGuestFetcher(body),
        {}
    )
}

export const useCreateEventGuest = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtactivityregistrations`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['EventGuest'])
        }
    })
}