import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Assuming you have set up the Telerik Kendo rich text editor
import {
  Editor,
  EditorTools,
  EditorUtils,
  ProseMirror,
  EditorChangeEvent
} from "@progress/kendo-react-editor";
import {InsertShortcodeTool} from '../../components/common/inputs/RTEInsertTool/RTEInsertTool'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import { makeStyles } from '@material-ui/core/styles';


interface Params {
  eventid: string
}
// Custom styles
const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    width: '100%', // Set the width to 100% of the parent
    maxWidth: '100%', // Ensure it doesn't expand past the parent
    display: 'block', // Ensures the content takes the full width
    boxSizing: 'border-box', // Includes padding and border in the element's total width and height
  },
  // ...other styles if needed
}));

const EmailCustomizationPage = () => {
  const classes = useStyles();
  const { Bold, Italic, Underline, ViewHtml } = EditorTools;
  const { Schema, EditorView, EditorState } = ProseMirror;
  const [transactionReceiptHeader, setTransactionReceiptHeader] = useState('');
  const [transactionReceiptFooter, setTransactionReceiptFooter] = useState('');
  const [purchaserHeader, setPurchaserHeader] = useState('');
  const [purchaserFooter, setPurchaserFooter] = useState('');
  const [guestHeader, setGuestHeader] = useState('');
  const [guestFooter, setGuestFooter] = useState('');
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)

  const handleTransactionReceiptHeaderChange = (event: EditorChangeEvent) => {
    setTransactionReceiptHeader(event.html);
  };

  const handleTransactionReceiptFooterChange = (event) => {
    setTransactionReceiptFooter(event.html);
  };
  const handlePurchaserHeaderChange = (event) => {
    setPurchaserHeader(event.html);
  };

  const handlePurchaserFooterChange = (event) => {
    setPurchaserFooter(event.html);
  };
  const handleGuestHeaderChange = (event) => {
    setGuestHeader(event.html);
  };

  const handleGuestFooterChange = (event) => {
    setGuestFooter(event.html);
  };
    // Fetch data functions
    const fetchData = async (url, setDataHeader, setDataFooter) => {
      try {
        const response = await axios.post(url, { eventid: eventid });
        const parsedData = JSON.parse(response.data.jsonString);
        if (parsedData && parsedData.header !== undefined && parsedData.footer !== undefined) {
          setDataHeader(parsedData.header);
        setDataFooter(parsedData.footer);
        } else {
          console.error('Data received is not in the expected format:', parsedData);
        }
       
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const saveTransactionReceiptData = async () => {
      const data = {
        eventid: eventid, 
        header: transactionReceiptHeader,
        footer: transactionReceiptFooter
      };
  
      try {
        const response = await axios.put('https://testapi.auctionsnap.com:8443/evtemailtransactionreceipt', data);
        alert('Transaction Receipt data saved successfully!');
      } catch (error) {
        console.error('Error saving Transaction Receipt data:', error);
        alert('Failed to save Transaction Receipt data.');
      }
    };
    const savePurchaserData = async () => {
      const data = {
        eventid:eventid, 
        header: purchaserHeader,
        footer: purchaserFooter
      };
  
      try {
        const response = await axios.put('https://testapi.auctionsnap.com:8443/evtemailpurchaser', data);
        alert('Purchaser data saved successfully!');
      } catch (error) {
        console.error('Error saving purchaser data:', error);
        alert('Failed to save purchaser data.');
      }
    };
    const saveGuestData = async () => {
      const data = {
        eventid: eventid, 
        header: guestHeader,
        footer: guestFooter
      };
  
      try {
        const response = await axios.put('https://testapi.auctionsnap.com:8443/evtemailguest', data);
        // Handle response here. For example, you can alert the user about successful save
        alert('guest data saved successfully!');
      } catch (error) {
        // Handle errors here. For example, you can alert the user about the error
        console.error('Error saving guest data:', error);
        alert('Failed to save guest data.');
      }
    };
  
    // Fetch data when the component mounts
    useEffect(() => {
      fetchData('https://testapi.auctionsnap.com:8443/evtemailtransactionreceipt', setTransactionReceiptHeader, setTransactionReceiptFooter);
      fetchData('https://testapi.auctionsnap.com:8443/evtemailpurchaser', setPurchaserHeader, setPurchaserFooter);
      fetchData('https://testapi.auctionsnap.com:8443/evtemailguest', setGuestHeader, setGuestFooter);
    }, []);
  
  // This is the node configuration
const nonEditable = {
  name: "nonEditable",
  inline: true,
  group: "inline",
  content: "inline+",
  marks: "",
  attrs: {
    contenteditable: { default: null },
    class: { default: null },
    style: { default: null }
  },
  atom: true,
  parseDOM: [{ tag: "span.uneditable", priority: 51 }],
  // The styles can be added via the class as well
  toDOM: () => [
    "span",
    {
      contenteditable: false,
      class: "uneditable",
      style: "user-select: none; opacity: 0.5;"
    },
    0
  ]
};

  const onMount = event => {
    const { viewProps } = event;
    const { plugins, schema } = viewProps.state;

    // Append a new node.
    let nodes = schema.spec.nodes.addToEnd('nonEditable', nonEditable);

    // Create the new schema.
    const mySchema = new Schema({ nodes: nodes, marks: schema.spec.marks });

    // Create a new document using the modified schema.
    const doc = EditorUtils.createDocument(mySchema, '');

    // Return the custom EditorView object that will be used by Editor.
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc, plugins })
      }
    );
  };
  

  return (
    <div className="email-customization-page">
      <h1>Email Customization</h1>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Transaction Receipt</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>
            <Typography variant="h6">Header</Typography>
            <Editor
             value={transactionReceiptHeader}
             onChange={(e) => handleTransactionReceiptHeaderChange(e)}
        tools={[[Bold, Italic, Underline, ViewHtml]]}
        contentStyle={{ height: 300 }}
      
      />

            <Typography variant="h6">Footer</Typography>
            <Editor
            value={transactionReceiptFooter}
            onChange={(e) => handleTransactionReceiptFooterChange(e)}
        tools={[[Bold, Italic, Underline, ViewHtml]]}
        contentStyle={{ height: 300 }}
       
      />                                <Button onClick={() => saveTransactionReceiptData()} variant="contained" color="primary">Save</Button>

      </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Email to Purchaser with Link to All Update Guest Data</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>        
            <Typography variant="h6">Header</Typography>
            <Editor
             value={purchaserHeader}
             onChange={(e) => handlePurchaserHeaderChange(e)}
        tools={[[Bold, Italic, Underline, ViewHtml, InsertShortcodeTool]]}
        contentStyle={{ height: 300 }}
        onMount={onMount}
      />
            <Typography variant="h6">Footer</Typography>
            <Editor
             value={purchaserFooter}
             onChange={(e) => handlePurchaserFooterChange(e)}
        tools={[[Bold, Italic, Underline, ViewHtml]]}
        contentStyle={{ height: 300 }}
        
      />                     <Button onClick={() => savePurchaserData()} variant="contained" color="primary">Save</Button>


          </div>

        </AccordionDetails>
      </Accordion>

    
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Email to Guest with Link to Update Guest Data</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>
            <Typography variant="h6">Header</Typography>
            <Editor
             value={guestHeader}
             onChange={(e) => handleGuestHeaderChange(e)}
        tools={[[Bold, Italic, Underline, ViewHtml, InsertShortcodeTool]]}
        contentStyle={{ height: 300 }}
        onMount={onMount}
      />
            <Typography variant="h6">Footer</Typography>
            <Editor
             value={guestFooter}
             onChange={(e) => handleGuestFooterChange(e)}
        tools={[[Bold, Italic, Underline, ViewHtml]]}
        contentStyle={{ height: 300 }}
        
      />                      <Button onClick={() => saveGuestData()} variant="contained" color="primary">Save</Button>


          </div>

        </AccordionDetails>
      </Accordion>

    </div>
  );
}

export default EmailCustomizationPage;
