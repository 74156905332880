import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  AuctionStatus {
    rownumber: number
    itemid: number
    biddername: string
    itemname: string
    itemnumber: string
    bidtime: string
    bidtype: string
    quantity: number
    bidamount: number
    bidautoamount: number
    checkoutcompleted: boolean
    itembiddingclosed: number
    active: boolean
    qtyunlimited: boolean
    butitnowonly: boolean
    open: string
    closing: string
    bidstatus: string
    buyitnowprice: number
}

const AuctionStatusDefaults = {
    rownumber: 0,
    itemid: 0,
    biddername: "",
    itemname: "",
    itemnumber: "",
    bidtime: "",
    bidtype: "",
    quantity: 0,
    bidamount: 0,
    bidautoamount: 0,
    checkoutcompleted: false,
    itembiddingclosed: 0,
    active: true,
    qtyunlimited: true,
    butitnowonly: true,
    open: "",
    closing: "",
    bidstatus: "",
    buyitnowprice: 0
}

export const useFetchAuctionStatus = (eventIdvalue) => {
    return useQuery<AuctionStatus[]>(
        ['auctionstatus', eventIdvalue],
        () => globalAuctionStatusFetcher(eventIdvalue),
        {
            staleTime: 120000,
            placeholderData: [AuctionStatusDefaults],
            keepPreviousData: true
        }
    )
}

export const useUpdateAuctionStatus = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`auctionstatus`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['auctionstatus'])
        }
    })
}

export const globalAuctionStatusFetcher = async (eventIdvalue) => {
    const { data } = await axios.post('/currentstatus', { eventid: eventIdvalue })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.statuslist as AuctionStatus[]
}
