import { useParams } from 'react-router'
import { Button } from '@mui/material'
import { useAutoEvent } from '../../../queries/useAutoEvent'
import { useEventState } from '../../../slices/eventSlice'
import {useFetchBidMaintenance} from "../../../queries/useFetchBidMaintenance";
import {CheckoutData, useFetchCheckout} from "../../../queries/useFetchCheckout";
import React, {useEffect} from "react";

import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef
} from "@mui/x-data-grid-premium";
import {formatMoney} from "../../../util/formatHelpers";
import {GridSelectionModel} from "@mui/x-data-grid-pro";
// import {DataGridPremium} from "@mui/x-data-grid-premium";

type Order = {
  id: number
  bidnumber: number
  name: string
  receiptnumber: number
  status: string
}

interface Params {
  id: string
}

const CheckoutOrdersGrid = ({ checkoutDataObject, setCheckoutDataObject, setCreditCardInfo}) => {
  const eventState = useEventState()
  const gridAPIRef = useGridApiRef()
  const [dataRows, setDataRows] = React.useState([]);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
  const { data, isLoading } = useFetchCheckout(Number(eventState.eventId))
  useEffect(() => {
    if (data?.checkoutlist && data?.checkoutlist?.length > 0) {
      const dataRows = getMappedRecords()
      console.log(dataRows)
      setDataRows(dataRows)
    }
    if (data) {
      setCreditCardInfo(
          { showccfees: data.showccfees,
            ccfeespercentage: data.ccfeespercentage,
            creditcardiframe: data.creditcardiframe,
            javascriptfile: data.javascriptfile,
            transcenterid: data.transcenterid,
            processorid: data.processorid,
            transactiontype: data.transactiontype
          }
      )
      console.log({ showccfees: data.showccfees,
        ccfeespercentage: data.ccfeespercentage,
        creditcardiframe: data.creditcardiframe,
        javascriptfile: data.javascriptfile,
        transcenterid: data.transcenterid,
        processorid: data.processorid,
        transactiontype: data.transactiontype
      })
    }

    // if (data?.checkoutlist &&  data?.checkoutlist.length > 0) {
    //   setTimeout(() => {
    //     gridAPIRef.current?.selectRows([data?.checkoutlist[0].rownumber])
    //     setCheckoutDataObject(data?.checkoutlist[0])
    //   })
    // }
  }, [data])

  useEffect(() => {
    if (dataRows && dataRows.length > 0) {
      setSelectionModel([dataRows[0].id])
      const checkoutObjData = data.checkoutlist.filter((checkoutlist) => { return checkoutlist.biddernumber === Number(dataRows[0].biddernumber) && checkoutlist.receiptnumber === Number(dataRows[0].receiptnumber)})
      if (checkoutObjData && checkoutObjData.length > 0) {
        setCheckoutDataObject(checkoutObjData[0])
      }
    }
  }, [dataRows])

  const getMappedRecords = () => {
    return data?.checkoutlist.filter((checkOut) => checkOut.checkoutstatus !== 'Open').map((checkoutData: CheckoutData, i) => {
      return {
        id: checkoutData.rownumber,
        rownumber: checkoutData.rownumber,
        biddernumber: checkoutData.biddernumber,
        paddlenumbertext: checkoutData.paddlenumbertext,
        receiptnumber: checkoutData.receiptnumber,
        participantname: checkoutData.participantname,
        checkoutstatus: checkoutData.checkoutstatus,
        amount: checkoutData.amount,
        isreadonly: checkoutData.isreadonly,
        key: checkoutData.key,
      }
    })
  }

  const handleRowClick = (param: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => {
    const checkoutObjData = data.checkoutlist.filter((checkoutlist) => { return checkoutlist.rownumber === Number(param.id)})
    if (checkoutObjData && checkoutObjData.length > 0) {
      setCheckoutDataObject(checkoutObjData[0])
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'biddernumber',
      type: 'number',
      headerName: 'Bid #',
      minWidth: 120
    },
    {
      field: 'paddlenumbertext',
      type: 'string',
      headerName: 'Paddle #',
      minWidth: 120
    },
    {
      field: 'participantname',
      type: 'string',
      headerName: 'Name',
      minWidth: 280
    },
    {
      field: 'receiptnumber',
      type: 'number',
      headerName: 'Receipt #',
      minWidth: 150
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      align: "right",
      headerAlign: "right",
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? formatMoney(Number(params.value)) : '$0.00')
      },
    },
    {
      field: 'checkoutstatus',
      type: 'string',
      headerName: 'Status',
      minWidth: 150
    }
  ]

  return (
    <>
        <DataGridPremium
          autoHeight
          rows={dataRows}
          pageSize={5}
          columns={columns}
          loading={false}
          apiRef={gridAPIRef}
          onRowClick={handleRowClick}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          pagination
          rowsPerPageOptions={[5]}
        />
    </>
  )
}

export default CheckoutOrdersGrid
