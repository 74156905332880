import React, { useState } from 'react'

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'

import { DonationForm } from './DonationForm'
import styles from './DonationTable.module.css'
import {useEventState} from "../../../slices/eventSlice";
import {useLocationTags} from "../../../mutations/useLocationTags";
import {DataGridPro, GridCellParams} from "@mui/x-data-grid-pro";

const DonationTable = (donations) => {
  const [gridParams, setGridParams] = useState({ id:0,  description: '', active: true})
  const data = donations?.donations
  const logationsTagsMutation = useLocationTags()
  const [isFormShowing, setIsFormShowing] = useState(false)
  const eventState = useEventState()
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")

  var donationRows = []
  if (data) {
    donationRows = data.map((v, i) => {
      return {description: v.name, active: v.active, tagid: v.id, id: v.id}
    })
  }

  const handleAdd = () => {
    setGridParams(null)
    setIsFormShowing(true)
  }

  const handleEdit = (params: GridCellParams) => {
    setGridParams({
      id:Number(params.id),
      description: params.getValue(params.id, 'description').toString(),
      active: Boolean(params.getValue(params.id, 'active'))})
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.getValue(params.id, 'description').toString() + "\"?",
      id: Number(params.id)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var finaldata = {
      "id": id,
      "eventid": Number(eventState.eventId),
      "delete": true,
      "type": "donationtype"
    }
    await logationsTagsMutation.mutateAsync({
      ...finaldata
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "failure") {
          setDialogObj({
            msg: '',
            id: 0
          })
          setDialogMsg(json.message)
        }
        else {
          setDialogObj({
            msg: '',
            id: 0
          })
        }
      }
    })
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns = [
    {
      field: 'Edit',
      renderHeader: () => {
        return (
          <IconButton
            className={styles.submitButton}
            onClick={ () => handleAdd() }
            size="small">
            <Add />
          </IconButton>
        )
      },
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
          <Edit />
        </IconButton>
      )
    },
    {
      field: 'description',
      type: 'string',
      headerName: 'Donation Type',
      width: 200
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        return params.value ? 'Active' : 'Inactive'
      }
    },
    {
      field: 'action',
      headerName: 'Delete',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
          <Delete />
        </IconButton>
      )
    }
  ]

  return (
    <>
      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <DonationForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} />
      <DataGridPro
        autoHeight
        rows={donationRows}
        columns={columns}
        loading={false}
        pagination={false as any}
      />
    </>
  )
}

export default DonationTable
