import React, { useState } from 'react'
import styles from './AuctionStatusPage.module.css'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {useEventState} from "../../slices/eventSlice";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {useFetchAuctionStatus, useUpdateAuctionStatus} from "../../queries/useFetchAuctionStatus";
import dayjs from "dayjs";
import {DataGridPremium, GridValueFormatterParams} from "@mui/x-data-grid-premium";


interface Params {
    eventid: string
}

const AuctionStatusPage = () => {
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const eventState = useEventState()
    const { data, isLoading } = useFetchAuctionStatus(Number(eventid))
    const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
    const [dialogMsg, setDialogMsg] = React.useState("")

    const updateAuctionStatusMutation = useUpdateAuctionStatus()

    var bidmaintRows = []
    if (data) {
        bidmaintRows = data.map((bm, index) => {
            return { ...bm, id: bm.itemid }
        })
    }

 

    
    const closeDialog = () => {
        setDialogMsg('')
        setDialogObj({
            msg: '',
            id: 0
        })
    }

  


    const columns: any = [
        {
            field: 'rownumber',
            hide: true
        },
        {
            field: 'biddername',
            type: 'string',
            headerName: 'Bidder',
            width: 200
        },
        {
            field: 'itemnumber',
            type: 'string',
            headerName: 'Item #',
            width: 120
        },
        {
            field: 'itemname',
            type: 'string',
            headerName: 'Item Name',
            width: 300
        },
        {
            field: 'bidtime',
            headerName: 'Bid Date',
            width: 200,
            valueFormatter: (params: GridValueFormatterParams) => {
                return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
            },
        },
        {
            field: 'bidtype',
            headerName: 'Type',
            width: 150,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 120,
            align: "right",
        },
        {
            field: 'bidamount',
            headerName: 'Current Bid',
            width: 150,
            align: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return "$" + params.value
            },
        },
        {
            field: 'buyitnowprice',
            headerName: 'Buy it Now',
            width: 150,
            align: "right",
            valueFormatter: (params: GridValueFormatterParams) => {
                return "$" + params.value
            },
        }
    ]

    return (
        <div className={styles.root}>
          <h2>Auction Status</h2>
            <>
            <Dialog
                open={dialogObj.msg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogObj.msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
            </>
            <DataGridPremium
            getRowId={(row) => row.rownumber} 
                autoHeight
                rows={bidmaintRows}
                columns={columns}
                loading={isLoading}
                hideFooterSelectedRowCount={true}
                // density={'compact'}
                pagination={true}
            />
        </div>
    )
}

export default AuctionStatusPage
