import { useDispatch } from 'react-redux'
import {globalEventSettingsFetcher} from "./events/useFetchEventSettings";
import {EventState, setEvent, useEventState} from "../slices/eventSlice";

export const useAutoEvent = (eventId: string) => {
  const eventState = useEventState()
  const dispatch = useDispatch()
  if (eventState.eventId != eventId) {
    globalEventSettingsFetcher(Number(eventId)).then((data) => {
      if (data) {
        console.log(data)
        const newState: EventState = {
          eventId: data?.eventid?.toString(),
          eventName: data.name,
          eventCode: data.code,
          eventStart: data.start,
          eventEnd: data.end,
          bidStart: data.bidstart,
          bidEnd: data.bidend,
          allowpaymentoffees: data?.allowpaymentoffees,
          ccfee: data?.ccfee
        }
        dispatch(setEvent(newState))
      }
    })
  }
}
