
import {
  useHistory,
} from 'react-router-dom'

import styles from './AuthPage.module.css'
import {useParams} from "react-router";
import axios from "axios";
import React, {useEffect} from "react";
import {AuthToken, loginUser} from "../../slices/authSlice";
import {parseJwt} from "../AuthPage/LoginForm/useLoginMutation";
import {useDispatch} from "react-redux";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";

export enum AuthPageSubPaths {
  login = '/login',
  forgotPassword = '/forgotPassword',
  resetPassword = '/resetPassword/:token'
}

interface Params {
  tokenid: string
}
const AdminAuthPage = () => {
  const { tokenid } = useParams<Params>()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const getTokenFromAdmin = async () => {
      const json = JSON.stringify({ token: tokenid })
      const data: any = await axios.post('/adminlogin', json, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      localStorage.setItem('tokenadmin', data.token)
      const parsedToken: AuthToken = parseJwt(data.token)
      // update redux
      dispatch(loginUser(parsedToken))

      history.push('/home')
    }
    getTokenFromAdmin();
  }, [tokenid])

  return (
    <div className={styles.root}>
      <div className={styles.backgroundContainer}>
        <LoadingBackdrop open={true} />
      </div>
    </div>
  )
}

export default AdminAuthPage

