import { useForm } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, Grid,
  TextField
} from '@material-ui/core'

import HookForm from '../../../components/common/HookForm'
import { useEventState } from '../../../slices/eventSlice'
import styles from './CheckoutForm.module.css'
import React, {useEffect} from "react";
import DateTimeInput from "../../../components/common/inputs/DateTimeInput";
import dayjs from "dayjs";
import MaskedInput from "../../../components/common/inputs/MaskedInput";
import AutocompleteSelect from "../../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {useUpdateManualBid} from "../../../queries/useManualBid";
import {useQueryClient} from "react-query";
import {manualBid} from "../../../queries/useFetchCheckoutDetail";
import axios from "axios";

type FormData = {
  id: string
  eventid: number
  itemid: number
  buyitnow: boolean
  buyitnowquantity: number
  price: string
  bidderid: number
}

export const AddItemForm = ({ addItemObjects, dialogObj, setDialogObj, eventid, participantid, selectedParticipant }) => {
  const formMethods = useForm<FormData>()
  const { reset, watch } = formMethods
  const eventState = useEventState()
  const title = "Add Checkout Item"
  const [dialogMsg, setDialogMsg] = React.useState({message: "", display: false})
  const [dialogStatus, setDialogStatus] = React.useState(true)
  const [quantityValues, setQuantityValues] = React.useState<number>()
  const queryClient = useQueryClient()

  const onSubmit = async (formData: FormData) => {
    const responseData = await manualBid(eventid, formData.itemid, true, quantityValues, participantid)
    const historyid = responseData.historyid
    if (responseData.status === "success") {
      queryClient.invalidateQueries(['checkoutdetailadd'])
      queryClient.invalidateQueries(['checkoutdetail'])

      const { data } = await axios.post('/checkoutdetail', { eventid: eventid, isreadonly: selectedParticipant.isreadonly, key: selectedParticipant.key })
      const parsedData = JSON.parse(data.jsonString)
      const item = parsedData?.checkoutitems?.find((item) => item.historyid === historyid)
      if (item) {
        setDialogObj({msg: '', show: false})
      }
      else {
        setDialogMsg({message: responseData.message2, display: true})
      }
    }
    else {
      setDialogMsg({message: responseData.message2, display: true})
    }
  }

  const closeDialog = () => {
    setDialogMsg({message: "", display: false})
    // if (dialogStatus) {
    //   // queryClient.invalidateQueries(['manualbid'])
    //   setDialogObj({msg: '', show: false})
    // }
  }

  const getItemOptions = () => {
    return addItemObjects?.itemlist?.map(item =>{
      return {value:item.itemid, label: item.itemnumber + ' - ' + item.name}
    })
  }

  const handleQuantityChange = (newValue: number) => {
    if (newValue > 0) {
      setQuantityValues(newValue)
    }
  }

  return (
    <Dialog open={dialogObj.show} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>

      <>
        <Dialog
            open={dialogMsg.display}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={onSubmit}
          className={styles.bidForm}
          formProps={{ id: 'bid-form' }}>

          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.selectcontrol}>
              {/*<div>Item: {gridParams.itemnumber} - {gridParams.name}</div>*/}
            </Grid>
            <Grid item xs={12} className={styles.selectcontrol}>
              <div style={{ marginBottom: '24px' }}>
                <AutocompleteSelect
                    name="itemid"
                    label="Item"
                    options={getItemOptions()}
                    required
                />
              </div>
            </Grid>
                <Grid item xs={12} className={styles.selectcontrol}>
                  <CurrencyTextField
                      label={"Quantity"}
                      name={"quantity"}
                      variant="outlined"
                      value={quantityValues}
                      fullWidth={true}
                      currencySymbol=""
                      decimalPlaces={0}
                      outputFormat="number"
                      textAlign={"left"}
                      required={true}
                      minimumValue="1"
                      // decimalCharacter="."
                      onChange={(event, value) => handleQuantityChange(value)}
                  />
                </Grid>
          </Grid>
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setDialogObj({msg: '', show: false})}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          form="bid-form">
          Add Item
        </Button>
      </DialogActions>
    </Dialog>
  )
}
