import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useForceRefresh = () => {
  const queryClient = useQueryClient()

  return useMutation((body: any) => axios.post(`/eventrefresh`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['forcerefresh'])
    }
  })
}

export const useForceLogout = () => {
  const queryClient = useQueryClient()

  return useMutation((body: any) => axios.post(`/forcelogout`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['forcelogout'])
    }
  })
}
