import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export const useEditEventSettings = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (body: any) => {
      return axios.put(`/eventsettings`, body)
    }
    ,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['eventsettings'])
        queryClient.invalidateQueries(['events'])
        queryClient.invalidateQueries(['eventdata'])
      }
    }
  )
}

export const useUpdateEventStatus = () => {
  const queryClient = useQueryClient()

  return useMutation(
      (body: any) => {
        return axios.put(`/eventstatus`, body)
      }
      ,
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(['eventsettings'])
          queryClient.invalidateQueries(['events'])
          queryClient.invalidateQueries(['eventdata'])
        }
      }
  )
}

export const useUpdateEventClone = () => {
  const queryClient = useQueryClient()

  return useMutation(
      (body: any) => {
        return axios.put(`/eventclone`, body)
      }
      ,
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries(['eventsettings'])
          queryClient.invalidateQueries(['events'])
          queryClient.invalidateQueries(['eventdata'])
        }
      }
  )
}

export const useCreateEvent = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (body: any) => {
      return axios.put(`/eventsettings`, body)
    },
    {
      onSuccess: (data ) => {
        queryClient.invalidateQueries(['eventsettings'])
        queryClient.invalidateQueries(['events'])
        queryClient.invalidateQueries(['eventdata'])
      }
    }
  )
}
