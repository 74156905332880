import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import "./iframe.css"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Switch} from '@material-ui/core'
import { FormLabel } from '@mui/material'
import download from 'downloadjs';
import Logger from '../../../util/logger';
import HookForm from '../../../components/common/HookForm'
import SelectInput from '../../../components/common/inputs/SelectInput/SelectInput'
// import Switch from '../../../components/common/inputs/Switch'
import TextInput from '../../../components/common/inputs/TextInput'
import styles from './CheckoutForm.module.css'
import {CheckoutData, useFetchCheckout} from "../../../queries/useFetchCheckout";
import {useEventState} from "../../../slices/eventSlice";
import {
  CheckoutHeaderData,
  CheckoutItemsData,
  useFetchCheckoutDetail,
  useAddCheckoutDetail,
  resendCheckout,
  generatePDF,
  checkout,
  checkoutDetailAdd,
  useFetchCheckoutDetailAdd
} from "../../../queries/useFetchCheckoutDetail";
import {DataGridPro, GridSelectionModel, GridValueFormatterParams} from "@mui/x-data-grid-pro";
import {formatMoney} from "../../../util/formatHelpers";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {CheckoutPageTab} from "../CheckoutPage";
import {ConfirmModal} from "../../../components/common/ConfirmModal";
import axios from "axios";
import CheckoutItemAddForm from "../CheckoutItemAddForm";
import {useQueryClient} from "react-query";
import LoadingBackdrop from '../../../components/common/LoadingBackdrop'
import {AddItemForm} from "./AddItemForm";
import {statesArray} from "../../../util/formValidation";

type FormData = {
    biddernumber: number
    company: string
    participantname: string
    first: string
    last: string
    address: string
    address2: string
    city: string
    state: string
    zip: string
    country: string
    phone: string
    email: string
    additionaldonation: number
    ccfeesexactamount: number
    ccfeesamount: number
    totalamount: number
    paymentmethod: string
    sendemail: boolean
}

type AddItemFormData = {
  item: string
  price: number
  quantity: number
  total: number
}

const CheckoutForm = ({ checkoutDataObject, creditCardInfo, setShowPayment }) => {
  console.log(checkoutDataObject)
  const queryClient = useQueryClient()
  const addItemDetail = useAddCheckoutDetail()
  const [cryptogram, setCryptogram] = useState('');
  const [cryptogramTimeout, setCryptogramTimeout] = useState(0);
  const [cryptogramCode, setCryptogramCode] = useState('');
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([1]);
  const [subtotal, setSubtotal] = useState(0);
  const [ccfees, setCCFees] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [additionalDonation, setAdditionalDonation] = React.useState(0)
  const [totalAmount, setTotalAmount] = React.useState(0)
  const [showResendDialog, setShowResendDialog] = React.useState(false)
  const [showCheckoutDialog, setShowCheckoutDialog] = React.useState(false)
  const [isLoadingBackdrop, setisLoadingBackdrop] = React.useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", show: false })
  const [dialogAddItemObj, setAddItemDialogObj] = React.useState({ msg: "", show: false })
  const [invalidateIframe, setInvalidateIframe] = React.useState(1)

  useEffect(() => {
    // console.log(creditCardInfo)
    if (creditCardInfo && creditCardInfo.javascriptfile) {
      const script = document.createElement("script")
      script.src = creditCardInfo?.javascriptfile
      script.id =  "firstpay-script-cryptogram"
      script.type = "text/javascript"
      script.setAttribute("data-transcenter", creditCardInfo?.transcenterid)
      script.setAttribute("data-processor", creditCardInfo?.processorid)

      document.body.appendChild(script)
    }
  }, [creditCardInfo])

  useEffect(() => {
    window.addEventListener("message", processPayment, false)
    return () => {
      window.removeEventListener("message", processPayment)
    }
  }, [])

  const processPayment = (e: any) => {
    if (e.data.firstpay) {
      const ccData = e.data
      setCryptogramCode(ccData.code)
      if (ccData.type === 'newCryptogram') {
        setCryptogram(ccData.cryptogram)
        setCryptogramTimeout(Date.now())
      }
      if (ccData.type === 'cryptogramFailed') {
        setCryptogram('')
        console.log(ccData)
        var logData = {
          eventid: Number(eventState.eventId),
    category: 'Checkout',
    location: 'Admin Checkout',
    message: ccData.message,
    iserror: true
        }
        Logger.logEvent(logData)
        //setCryptogramTimeout(Date.now())
      }
    }
  }

  const checkoutFormMethods = useForm<FormData>()
  const {reset, watch, getValues} = checkoutFormMethods
  const addItemMethods = useForm<AddItemFormData>()
  const [isAddItemsFormShowing, setIsAddItemsFormShowing] = useState(false)
  const [includeCCFees, setIncludeCCFees] = React.useState<boolean>(false);
  const handleCCFeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeCCFees(event.target.checked);
  };

  const [headerDataRows, setHeaderDataRows] = React.useState<CheckoutHeaderData>();
  const [itemDataRows, setItemDataRows] = React.useState([]);
  const eventState = useEventState()
  const paymentMethodWatch = watch('paymentmethod')

  const { data: dataDetailAdd, isLoading: isDataDetailLoading } = useFetchCheckoutDetailAdd(Number(eventState.eventId))

  const { data, isLoading } = useFetchCheckoutDetail(Number(eventState.eventId), checkoutDataObject?.key, checkoutDataObject?.isreadonly)
  useEffect(() => {
    console.log(data)
    var additionalDonations = 0
    if (data?.checkoutheader && data?.checkoutheader?.length > 0) {
      const headerRecords = getHeaderRecords()
      var headRecs = headerRecords[0]

      reset(headerRecords[0])
      additionalDonations = headerRecords[0].additionaldonation
      setHeaderDataRows(headerRecords[0])
      console.log(headerRecords[0])
    }
    if (data?.checkoutitems && data?.checkoutitems?.length > 0) {
      const dataRows = getItemRecords()
      setItemDataRows(dataRows)
      
      const amount = data?.checkoutitems?.reduce((acc: any, curr: any) => {
        if (checkoutDataObject?.checkoutstatus === 'Checked Out') {
          return acc + curr.amount * curr.quantity
        }
        else {
          return 0
        }
      }, 0)

      setSubtotal(amount)

      var ccAmount = 0
      if (amount > 0) {
        ccAmount += amount
      }
      if (additionalDonation > 0) {
        ccAmount += additionalDonations
      }
      setCCFees(ccAmount * (creditCardInfo?.ccfeespercentage / 100))
      setAdditionalDonation(additionalDonations)

      const total = (creditCardInfo?.showccfees && includeCCFees) ?
          ccAmount + (ccAmount * (creditCardInfo?.ccfeespercentage / 100)) :
          ccAmount
      setTotalAmount(total)

      setQuantity(data?.checkoutitems?.reduce((acc: any, curr: any) => {
        if (checkoutDataObject?.checkoutstatus === 'Checked Out') {
          return acc + curr.quantity
        }
        else {
          return 0
        }
      }, 0))
    }
    else {
      setItemDataRows([])
    }
  }, [data])

  useEffect(() => {
      const amount = selectedItems?.reduce((acc: any, curr: any) => {
        return acc + curr.amount * curr.quantity
      }, 0)
      setSubtotal(amount)

      var ccAmount = 0
      if (amount > 0) {
        ccAmount += amount
      }
      if (additionalDonation > 0) {
        ccAmount += additionalDonation
      }
      setCCFees(((ccAmount) * (creditCardInfo?.ccfeespercentage / 100)))

      const total = (creditCardInfo?.showccfees && includeCCFees && creditCardInfo?.ccfeespercentage > 0.000000001) ?
          ccAmount + (ccAmount * (creditCardInfo?.ccfeespercentage / 100)) :
          ccAmount
      setTotalAmount(total)

      setQuantity(selectedItems?.reduce((acc: any, curr: any) => {
        return acc + curr.quantity
      }, 0))
  }, [includeCCFees, selectedItems, additionalDonation])

  const getHeaderRecords = () => {
    return data?.checkoutheader.map((checkoutHeader: CheckoutHeaderData, i) => {
      return {
        biddernumber: checkoutHeader.biddernumber,
        company: checkoutHeader.company,
        participantid: checkoutHeader.participantid,
        participantname: checkoutHeader.participantname,
        first: checkoutHeader.first,
        last: checkoutHeader.last,
        address: checkoutHeader.address,
        address2: checkoutHeader.address2,
        city: checkoutHeader.city,
        state: checkoutHeader.state,
        zip: checkoutHeader.zip,
        country: checkoutHeader.country,
        phone: checkoutHeader.phone,
        email: checkoutHeader.email,
        additionaldonation: checkoutHeader.additionaldonation,
        ccfeesexactamount: ccfees,
        totalamount: checkoutHeader.totalamount,
        paymentmethod: checkoutHeader.paymentmethod,
        ccfeesamount: checkoutHeader.ccfeesamount,
        sendemail: checkoutHeader.sendemail,

      }
    })
  }

  const getItemRecords = () => {
    return data?.checkoutitems.map((checkoutItems: CheckoutItemsData, i) => {
      return {
        id: checkoutItems.historyid,
        itemid: checkoutItems.itemid,
        historyid: checkoutItems.historyid,
        itemnumber: checkoutItems.itemnumber,
        itemname: checkoutItems.itemname,
        quantity: checkoutItems.quantity,
        fairmarketvalue: checkoutItems.fairmarketvalue,
        amount: checkoutItems.amount,
      }
    })
  }

  const onSubmit = (formData: FormData) => {
    if (paymentMethodWatch === "CC" && cryptogram.length <= 0) {
      const errorMessage = "Please enter a valid credit card."
      setDialogObj({msg: errorMessage, show: true})
    }
    else {
      setShowCheckoutDialog(true)
    }
  }

  const columns = [
    {
      field: 'id',
      type: 'string',
      headerName: 'id',
      //minWidth: 120,
      hide: true
    },
    {
      field: 'itemnumber',
      type: 'string',
      headerName: 'Item #',
      minWidth: 120
    },
    {
      field: 'itemname',
      type: 'string',
      headerName: 'Name',
      minWidth: 200
    },
    {
      field: 'quantity',
      type: 'number',
      headerName: 'QTY',
      minWidth: 100
    },
    // {
    //   field: 'fairmarketvalue',
    //   type: 'number',
    //   headerName: 'Fair Market Value',
    //   minWidth: 250,
    //   valueFormatter: (params: GridValueFormatterParams) => {
    //     return formatMoney(Number(params.value))
    //   },
    // },
    {
      field: 'amount',
      type: 'number',
      headerName: 'Amount',
      minWidth: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
      },
    }
  ]

  const handleAdditionalDonationChange = (newValue: number | null) => {
    if (newValue > 0) {
      setAdditionalDonation(newValue)
    }
    else {
      setAdditionalDonation(0)
    }
  }

  const handleCheckout = async () => {
    setisLoadingBackdrop(true)
    setShowCheckoutDialog(false)
    const company = getValues("company")
    const first = getValues("first")
    const last = getValues("last")
    const address = getValues("address")
    const address2 = getValues("address2")
    const city = getValues("city")
    const state = getValues("state")
    const zip = getValues("zip")
    const email = getValues("email")
    const sendemail = getValues("sendemail")

    var errorMessage = ""
    if (first.length <= 0) {
      errorMessage = "First name is required."
    }
    else if (last.length <= 0) {
      errorMessage = "Last name is required."
    }
    // else if (address.length <= 0) {
    //   errorMessage = "Address is required."
    // }
    // else if (city.length <= 0) {
    //   errorMessage = "City is required."
    // }
    // else if (state.length <= 0) {
    //   errorMessage = "State is required."
    // }
    else if (zip.length <= 0) {
      errorMessage = "Zip is required."
    }
    // else if (email.length <= 0) {
    //   errorMessage = "Email is required."
    // }
    else if (selectedItems === null || selectedItems?.length <= 0) {
      errorMessage = "Please select at least 1 item."
    }
    else if (paymentMethodWatch === "CC" && cryptogram.length <= 0) {
      errorMessage = "Please enter a valid credit card."
    }

    if (errorMessage.length > 0) {
      setDialogObj({msg: errorMessage, show: true})
    }
    else {
      const checkoutData = await checkout({
        eventid: Number(eventState.eventId),
        key: checkoutDataObject?.key,
        participantid: headerDataRows?.participantid,
        company: company,
        first: first,
        last: last,
        address: address,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
        email: email,
        additionaldonation: additionalDonation,
        ccfeesexactamount: creditCardInfo?.ccfeespercentage, // Number(ccfees.toFixed(2)),
        totalamount: Number(totalAmount.toFixed(2)),
        paymentmethod: paymentMethodWatch,
        sendemail: sendemail,
        emerchtoken: cryptogram,
        subtotal: subtotal,
        itemid: selectedItems?.map((item) => {
          return item.itemid
        }),
        historyid: selectedItems?.map((item) => {
          return item.historyid
        })
      })

      if (checkoutData.status === 'success') {
        setDialogObj({msg: "Checkout was successfull.", show: true})
        setInvalidateIframe(invalidateIframe+1)
        setCryptogram('')
      }
      else {
        setDialogObj({msg: checkoutData.message, show: true})
      }
      queryClient.invalidateQueries(['checkout'])
      queryClient.invalidateQueries(['checkoutdetail'])
    }

    setisLoadingBackdrop(false)
  }

  const buildCheckoutConfirm = () => {
    const itemMessage = selectedItems?.map(item => {
      return (
          <>
            #{item.itemnumber} - {item.itemname} - Qty: {item.quantity} - ${item.amount}<br/>
          </>
      )
    })

    const itemMessageFinal = <>
      {(selectedItems && selectedItems?.length > 0) &&
          <>{itemMessage}<br/></>
      }
      {/*{additionalDonation &&*/}
      <>
        <b>Additional Donation:</b> ${additionalDonation ? additionalDonation : 0}<br/>
      </>
      {/*}*/}
      <b>Processing Fees:</b> ${ccfees}<br/>
      <b>Total Amount:</b> ${Number(totalAmount.toFixed(2))}
    </>

    var message =
        <>
          <DialogContentText>{itemMessageFinal}</DialogContentText>
          <DialogContentText>Are you sure you want to purchase these items?</DialogContentText>
        </>
    return message
  }

  const handleResend = async () => {
    setisLoadingBackdrop(true)
    setShowResendDialog(false)
    const email = getValues("email")
    const resendData = await resendCheckout(Number(eventState.eventId), checkoutDataObject?.key, email)

    if (resendData.status === 'success') {
      setDialogObj({msg: "Receipt was successfully sent to " + email + ".", show: true})
    }
    else {
      setDialogObj({msg: resendData.message, show: true})
    }
    setisLoadingBackdrop(false)
  }

  const generatePDFClick = async () => {
    setisLoadingBackdrop(true)
    setShowResendDialog(false)
    const resendData = await generatePDF(Number(eventState.eventId), checkoutDataObject?.key)
    if (resendData) {
      download(resendData, eventState.eventName + '-' + checkoutDataObject?.key +  '-receipt.pdf', 'application/pdf');
    }
    setisLoadingBackdrop(false)
  }

  return (
      <>
        <div className={styles.root} >
          <LoadingBackdrop open={isLoadingBackdrop} />

          <AddItemForm addItemObjects={dataDetailAdd} dialogObj={dialogAddItemObj}  setDialogObj={setAddItemDialogObj} eventid={Number(eventState.eventId)} participantid={headerDataRows?.participantid}  selectedParticipant={null}/>
          {/*<CheckoutItemAddForm addItemObjects={dataDetailAdd} dialogObj={dialogAddItemObj}  setDialogObj={setAddItemDialogObj} eventid={Number(eventState.eventId)} participantid={headerDataRows?.participantid} key={checkoutDataObject?.key} isreadonly={checkoutDataObject?.isreadonly}/>*/}
          <ConfirmModal
              text="Are you sure you want to resend the receipt via email?"
              isOpen={showResendDialog}
              onClose={() => setShowResendDialog(false)}
              onConfirm={() => handleResend()}
              confirmText="Resend Receipt"
          />
          <ConfirmModal
              title={'You have selected the following items for checkout:'}
              text={buildCheckoutConfirm()}
              isOpen={showCheckoutDialog}
              onClose={() => setShowCheckoutDialog(false)}
              onConfirm={() => handleCheckout()}
              confirmText="Checkout"
          />
          <>
            <Dialog
                open={dialogObj.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dialogObj.msg}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { setDialogObj({msg: '', show: false})}}>Ok</Button>
              </DialogActions>
            </Dialog>
          </>
          <div className={styles.rootitems}>
          <HookForm
              methods={checkoutFormMethods}
              className={styles.form}
              formProps={{ id: 'checkout-form' }}
              onSubmit={onSubmit}>

            <div className={styles.subrootitems} >
              <div className={styles.subrootcard} >
                <TextInput name="company" label="Company" disabled={true} />
                <TextInput name="first" label="First Name" required={true} disabled={true} />
                <TextInput name="last" label="Last Name" required={true} disabled={true} />
                <TextInput name="address" label="Address" required={false} disabled={true} />
                <TextInput name="address2" label="Address 2" disabled={true} />
                <TextInput name="city" label="City" required={false} disabled={true} />
                <SelectInput
                    name="state"
                    label="State"
                    includeNone={false}
                    disabled={true}
                    required={false}
                    options={statesArray.map((state) => {
                      return {label: state.value, value: state.value}
                    })}
                />
                <TextInput name="zip" label="Zip" required={true} disabled={true} />
                <TextInput name="email" label="Email" required={true} disabled={true} />
                <div>
                  <Switch
                      color="primary"
                      name="sendemail"
                      defaultChecked
                      disabled={true} />
                  <FormLabel disabled={true}>Send Email</FormLabel>
                </div>


                <p>Subtotal: {formatMoney(subtotal | 0)} ({quantity | 0} items)</p>
                {(Number(headerDataRows?.ccfeesamount) > 0.0001) &&
                    <div>
                      <FormLabel>CC Fees: {formatMoney(Number(headerDataRows?.ccfeesamount))}</FormLabel>
                    </div>
                }
                <CurrencyTextField
                    label={"Additional Donation"}
                    name={"additionaldonation"}
                    variant="outlined"
                    allowNegativeValue={false}
                    default={Number(headerDataRows?.additionaldonation)}
                    value={headerDataRows?.additionaldonation}
                    fullWidth={true}
                    currencySymbol="$"
                    outputFormat="number"
                    minimumValue={0}
                    decimalPlaces={0}
                    disabled={true}
                    textAlign={"left"}
                    required={true}
                    decimalCharacter="."
                    onChange={(event, value) => handleAdditionalDonationChange(value)}
                />
                <CurrencyTextField
                    label={"Total"}
                    name={"total"}
                    variant="outlined"
                    // default={totalAMount}
                    value={headerDataRows?.totalamount}
                    fullWidth={true}
                    currencySymbol="$"
                    outputFormat="number"
                    textAlign={"left"}
                    disabled={true}
                    decimalCharacter="."
                    // onChange={(event, value) => handleAdditionalDonationChange(value)}
                />
                <SelectInput
                    name="paymentmethod"
                    label="Payment Method"
                    includeNone={false}
                    disabled={true}
                    defaultValue={'check'}
                    required={true}
                    options={[
                      { value: 'billme', label: 'Bill Me Later' },
                      { value: 'check', label: 'Check' },
                      { value: 'cash', label: 'Cash' },
                      { value: 'CC', label: 'Credit Card' }
                    ]}
                />
              </div>
              <div className={styles.subrootgrid} >
                <div className={styles.gridContainer}>
                  <div className={styles.grid}>
                    <DataGridPro
                        rows={itemDataRows}
                        columns={columns}
                        loading={false}
                        pagination={false as any}
                        // disableSelectionOnClick={checkoutDataObject?.isreadonly}
                        onSelectionModelChange={(newSelectionModel) => {
                          setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                    />
                  </div>
                  {/*{!checkoutDataObject?.isreadonly &&*/}
                  {/*    <Button*/}
                  {/*        variant="contained"*/}
                  {/*        onClick={() => setAddItemDialogObj({msg: '', show: true})}>*/}
                  {/*      Add Item*/}
                  {/*    </Button>*/}
                  {/*}*/}
                </div>
              </div>
            </div>
            {/*<div className={styles.subrootcard}>*/}
            {/*  <iframe*/}
            {/*      title="1stpayment-iframe"*/}
            {/*      id="firstpay-iframe"*/}
            {/*      key={checkoutDataObject?.key + invalidateIframe}*/}
            {/*      src={ creditCardInfo?.creditcardiframe }*/}
            {/*      data-transcenter-id={ creditCardInfo?.transcenterid }*/}
            {/*      data-processor-id={ creditCardInfo?.processorid }*/}
            {/*      data-transaction-type={ creditCardInfo?.transactiontype }*/}
            {/*      text-card-number={0}*/}
            {/*      data-manual-submit="false"*/}
            {/*      style={{display: (paymentMethodWatch === 'CC' ? 'inline' : 'none')}}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*{!checkoutDataObject?.isreadonly &&*/}
            {/*    <>*/}
            {/*      <Button*/}
            {/*          variant="contained"*/}
            {/*          style={{ background: 'var(--success)' }}*/}
            {/*          onClick={() => {setShowCheckoutDialog(true)}}*/}
            {/*      >*/}
            {/*        Complete Checkout*/}
            {/*      </Button>*/}
            {/*    </>*/}
            {/*}*/}
            {checkoutDataObject?.isreadonly &&
                <>
                  <Button
                      variant="contained"
                      style={{ background: 'var(--success)' }}
                      onClick={() => {setShowResendDialog(true)}}
                  >
                    Resend Receipt
                  </Button>
                  <Button
                      variant="contained"
                      style={{ background: 'var(--success)' }}
                      onClick={() => generatePDFClick()}
                  >
                    PDF
                  </Button>
                </>
            }
          </HookForm>
          </div>
        </div>
      </>

  )
}

export default CheckoutForm
