import React from 'react'
import { Control, Controller, useFormContext } from 'react-hook-form'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel, Grid
} from '@material-ui/core'
import styles from "../../../../pages/AuctionSettingsForm/EventSettingsForm.module.css";
import TextInput from "../TextInput";

type Props = {
  options: { value: string; label: string; tooltipText?: string; textoptions?: { name: string; label: string; tooltipText?: string; defaultValue?: string, disabled?: boolean, maxLength?: number, endAdornment?: string, required?: boolean} }[] | string[]
  name: string
  label?: string
  rules?: Object
  formLabelProps?: Object
  formGroupProps?: Object
  formControlProps?: Object
  formControlLabelProps?: Object
  checkboxProps?: Object
  onChange?: Function
  defaultValue?: { [key: string]: boolean }
}

const CheckboxGroup = ({
  options,
  label,
  rules,
  formControlProps,
  formLabelProps,
  formGroupProps,
  checkboxProps,
  name,
  formControlLabelProps,
  onChange,
  defaultValue = null
}: Props) => {
  const { control } = useFormContext()

  return (
    <FormControl component="fieldset" {...formControlProps}>
      {label && (
        <FormLabel
          component="legend"
          style={{ marginBottom: '1rem' }}
          {...formLabelProps}>
          {label}
        </FormLabel>
      )}

      <FormGroup {...formGroupProps}>
        {(options as {value: string; label: string; tooltipText?: string; }[] | string[]).map((option) => {
          return (
              <>
                <FormControlLabel
                    key={option.value || option}
                    title={option?.tooltipText}
                    control={
                      <Controller
                          defaultValue={defaultValue?.[option?.value] || false}
                          control={control}
                          rules={rules}
                          name={`${name}.${option.value || option}` as any}
                          render={({ field }) => (
                              <Checkbox
                                  color="primary"
                                  size="small"
                                  title={option?.tooltipText}
                                  onChange={(e) => {
                                    // onChange && onChange(e)
                                    onChange && onChange(e, field)
                                    return field.onChange(e.target.checked)
                                  }}
                                  checked={Boolean(field.value)}
                                  {...checkboxProps}
                              />
                          )}
                      />
                    }
                    label={option.label || option}
                    {...formControlLabelProps}
                />
                {(option?.textoptions && !option.textoptions.disabled) &&
                    <div style={{padding: '5px 0 5px 20px'}}>
                      <TextInput
                          name={option.textoptions.name}
                          label={option.textoptions.label}
                          defaultValue={option.textoptions.defaultValue}
                          tooltipText={option.textoptions.tooltipText}
                          disabled={option.textoptions.disabled}
                          maxLength={option.textoptions.maxLength}
                          endAdornment={option.textoptions.endAdornment}
                          required = {option.textoptions.required}
                      />
                    </div>
                }
              </>
          )
        })}

      </FormGroup>
    </FormControl>
  )
}

export default CheckboxGroup
