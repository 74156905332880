import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { IconButton } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import { ConfirmModal } from '../../components/common/ConfirmModal'
import {
  Sponsor,
  useFetchSponsors
} from '../../queries/events/useFetchSponsors'
import {EventState, setEvent, useEventState} from '../../slices/eventSlice'
import SponsorsForm from '../SponsorsForm'
import { useSponsorsMutation } from '../SponsorsForm/useSponsorsMutation'
import styles from './EventSponsors.module.css'
import {useParams} from "react-router";
import {useDispatch} from "react-redux";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {DataGridPremium} from "@mui/x-data-grid-premium";

interface Params {
    id: string
}

const EventSponsors = () => {
    const { id } = useParams<Params>()
    useAutoEvent(id)
    const eventState = useEventState()

  const { data, isLoading } = useFetchSponsors(Number(eventState.eventId))
  const [isFormShowing, setIsFormShowing] = useState(false)
  const [reloadForm, setReloadForm] = useState<number>(0)
  const [sponsorToEdit, setSponsorToEdit] = useState<Sponsor>(null)
  const [sponsorToDelete, setSponsorToDelete] = useState<Sponsor>(null)
  const queryClient = useQueryClient()

  const editMutation = useSponsorsMutation()

  const onDelete = async () => {
    try {
      await editMutation.mutateAsync({
        sponsorid: sponsorToDelete.sponsorid,
        eventid: Number(eventState.eventId),
        delete: true
      })
      setSponsorToDelete(null)
      queryClient.invalidateQueries(['participant'])
    } catch {}
  }

  const rows = data
    ? data.map((v) => {
        return {
          ...v,
          id: v.sponsorid
        }
      })
    : []

  const columns: any = [
    {
      field: 'Edit',
      renderHeader: () => {
        return (
          <IconButton
            className={styles.submitButton}
            size="small"
            onClick={() => {
              setReloadForm(reloadForm+1)
              setSponsorToEdit(null)
              setIsFormShowing(true)
            }}>
            <Add />
          </IconButton>
        )
      },
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          className={styles.button}
          onClick={() => {
            setReloadForm(reloadForm+1)
            setSponsorToEdit(params.row as Sponsor)
            setIsFormShowing(true)
          }}>
          <Edit />
        </IconButton>
      )
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Sponsor Name',
      width: 280
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
          return params.value ? 'Active' : 'Inactive'
      }
    },
    {
      field: 'action',
      headerName: 'Delete',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          className={styles.button}
          onClick={() => setSponsorToDelete(params.row as Sponsor)}>
          <Delete />
        </IconButton>
      )
    }
  ]

  return (
    <div className={styles.root}>
      <h2>Event Sponsors</h2>
        <h5>Upload the logos of event sponsors to be displayed in a rotating slideshow at the bottom of the auction website.</h5>
      <SponsorsForm
        isOpen={isFormShowing}
        setIsOpen={setIsFormShowing}
        sponsor={sponsorToEdit}
        reloadForm={reloadForm}
      />
      <ConfirmModal
        text="Are you sure you want to delete this sponsor? This cannot be undone?"
        confirmText="Delete Sponsor"
        onConfirm={onDelete}
        isOpen={Boolean(sponsorToDelete)}
        onClose={() => setSponsorToDelete(null)}
      />
      <DataGridPremium
        autoHeight
        rows={rows}
        columns={columns}
        loading={isLoading}
        pagination={true}
      />
    </div>
  )
}

export default EventSponsors
