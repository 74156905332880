import { useState, useEffect } from 'react'
import styles from './LiveAuctionPage.module.css'
import { useParams } from 'react-router'
import { useAutoEvent } from '../../queries/useAutoEvent'
import { useEventState } from '../../slices/eventSlice'
import {
  useFetchLiveAuctionItems,
  useUpdateLiveBid,
  useClearLiveBid,
  useConfirmLiveAuction
} from '../../queries/useActionItemsFetch'
import {
  DataGridPro,
  GridCellParams,
  useGridApiRef,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import { formatMoney } from '../../util/formatHelpers'
import { Autocomplete, TextField } from '@mui/material'
import clsx from 'clsx';

import {
  GridRenderEditCellParams,
  useGridApiContext
} from '@mui/x-data-grid-pro'
import { useCallback } from 'react'
interface Params {
  eventid: string
}

const LiveAuctionPage = () => {
  const { eventid } = useParams<Params>()

  useAutoEvent(eventid)
  const eventState = useEventState()
  const { data, isLoading } = useFetchLiveAuctionItems(Number(eventid))
  const manualBidMutation = useUpdateLiveBid()
  const clearBidMutation = useClearLiveBid()
  const [liveBidError, setLiveBidError] = useState('')
  const [rows, setRows] = useState([])
  const [participantList, setParticipantList] = useState([])
  const apiRef = useGridApiRef()
  const [clearedRowIds, setClearedRowIds] = useState([])
  const confirmmutation = useConfirmLiveAuction()
  const [dialogMsg, setDialogMsg] = useState('')
  const editableFields = ['bidderparticipantid', 'lastbid'] // Add here all your editable fields
  const [rowsToSave, setRowsToSave] =  useState({});
  const [isLoadingSpinner, setIsLoading] = useState(false);


  useEffect(() => {
    if (data && data.LiveAuctionitemlist) {
      setRows(data.LiveAuctionitemlist)
      setParticipantList(data.bidderlist)

      // Initialize clearedRowIds with ids of blank rows
      const blankRowIds = data.LiveAuctionitemlist.filter(
        (row) => row.bidderparticipantid <= 0
      ).map((row) => row.itemid)
      setClearedRowIds(blankRowIds)
    }
  }, [data])

  const getBidderOptions = () => {
    //console.log(participantList)
    return participantList?.map((item) => {
      return { value: item.bidderparticipantid, label: item.biddername }
    })
  }
  const handleRowEditCommit = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false }  
    console.log(rowsToSave)
    if(rowsToSave && rowsToSave[0] && rowsToSave[0].itemid === newRow.itemid ){
      if (updatedRow.lastbid > 0 && updatedRow.bidderparticipantid) {

      handleSave(updatedRow)
      return updatedRow
      }
    }
   return oldRow

  }
  const handleRowUpdateError = (error) => {
    console.log(error)
  }
    const handleSave = (row) => {
    setClearedRowIds((prevIds) => prevIds.filter((id) => id !== row.itemid))
      try {
        console.log('mutate')
        var part = participantList.find(
          (p) => p.bidderparticipantid === row.bidderparticipantid
        )
        manualBidMutation.mutate(
          {
            eventid: eventState.eventId,
            itemid: row.itemid,
            amount: row.lastbid,
            bidderparticipantid: part.bidderparticipantid
          },
          {
            onSuccess: (response) => {
              const json = JSON.parse(response.data.jsonString)
              console.log(json)
              if (json.status === 'failed') {
                setLiveBidError(json.message)
              } else {
                setLiveBidError('')
              }
              setRowsToSave({});
              setIsLoading(false);
            }
          }
        )
      } catch {
        setRowsToSave({});
        setIsLoading(false);
      }
    
  }
  // If a row is cleared, add it to clearedRowIds
  const handleClear = (row) => {
    setClearedRowIds((prevIds) => [...prevIds, row.itemid])
    // Clear the row here...

    clearBidMutation.mutate(
      {
        eventid: eventState.eventId,
        historyid: row.historyid
      },
      {
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          console.log(json)
          if (json.status === 'failed') {
            setLiveBidError(json.message)
          } else {
            setLiveBidError('')
          }
        }
      }
    )
  }
  
  const onButtonClick = (e, cellParams) => {
    //e.stopPropagation()
   
    const isSaveButton = clearedRowIds.includes(cellParams.row.itemid)
    if (isSaveButton) {
      setRowsToSave([cellParams.row]);
      apiRef.current.stopRowEditMode({ id: cellParams.row.itemid });

    } else {
      handleClear(cellParams.row)
    }
  }

  const sendWinningBid = () => {
    confirmmutation.mutate(
      {
        eventid: eventState.eventId
      },
      {
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          if (json.status === 'failure') {
            setDialogMsg(json.message)
          } else {
            setDialogMsg(
              'Successfully notified winning bidders and added the items to their carts. '
            )
          }
        }
      }
    )
  }
 
  const columns = [
    {
      field: 'itemnumber',
      type: 'string',
      headerName: 'Item #',
      width: 150
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      sortable: false
    },
    {
      field: 'bidderparticipantid',
      headerName: 'Bidder',
      width: 300,
      sortable: false,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          fullWidth
          options={getBidderOptions() ?? []}
          autoHighlight
          open = {true}
          isOptionEqualToValue={(op, val) => op.value == val.value}
          onChange={(event, newValue) => {
            //event.stopPropagation();
            if (typeof newValue === 'object' && newValue !== null) {
              apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: newValue.value
              })
            }
          }}
          renderInput={(inputParams) => (
            <TextField {...inputParams} error={params.error} />
          )}
        />
      ),
      valueFormatter: (params: GridValueFormatterParams) => {
        var bidderOptions = getBidderOptions();
        if (bidderOptions && bidderOptions.length > 0) {
          var part = bidderOptions.find((p) => p.value === params.value);
          return part ? part.label : '(Double click to add bidder)';
        } else {
          return '';
        }
      } ,
      cellClassName: (params: GridCellParams<any>) => {
        console.log(params)
        if (params.value == null) {
          return '';
        }
  
        return clsx('super-app', {
          negative: params.value < 0,
        });
      } 
    },
    {
      field: 'lastbid',
      //lastbid
      headerName: 'Amount',
      width: 150,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value ? params.value : 0))
      },
      editable: true
    },
    {
      field: 'actions',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        if (params.row.livebidcheckedout == 'Yes') {
          return null
        }
        const buttonText = clearedRowIds.includes(params.row.itemid)
          ? 'Save Bid'
          : 'Clear Bid'
        return (
          <Button onClick={(e) => onButtonClick(e, params)} variant="contained">
            {buttonText}
          </Button>
        )
      }
    },
    {
      field: 'livebidconfirmed',
      headerName: 'Added to Cart',
      width: 150,
      sortable: false
    },
    {
      field: 'livebidcheckedout',
      headerName: 'Checked Out',
      width: 150,
      sortable: false
    },
    {
      field: 'livebidhasemail',
      headerName: 'Has Email',
      width: 150,
      sortable: false
    },
  ]

 

  const closeDialog = () => {
    setDialogMsg('')
  }
  return (
    <Box
    sx={{
      
      '& .super-app.negative': {
        color: '#a9a9a9',
        fontWeight: '600',
      }
    }}
  >
      <>
        <Dialog
          open={dialogMsg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <h2>Live Auction Winning Bid Entry</h2>
      <h4>Double click to enter winning bidder and amount.</h4>
      {liveBidError.length > 0 && <p>An error occurred: {liveBidError}</p>}
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: '20px' }}
        onClick={() => sendWinningBid()}>
        Send Winning Bid Emails and Add Items to Cart
      </Button>
      <DataGridPro
        apiRef={apiRef}
        editMode="row"
        autoHeight
        getRowId={(row) => row.itemid}
        columns={columns}
        rows={rows}
        loading={isLoading}
        rowThreshold={0}
        processRowUpdate={handleRowEditCommit}
        onProcessRowUpdateError={handleRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  )
}

export default LiveAuctionPage
