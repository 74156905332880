import axios from 'axios'
import { useQuery } from 'react-query'

export type EventMessages = {
  preeventtext: string
  contactustext: string
  emailreceipttextfooter: string
  winningbidtextheader: string
  winningbidtextfooter: string
  emailreceipttextheader: string
  mobilelogintext: string
  mobilewelcometext: string
  message: string
}

var defaultEventMessages = {
  preeventtext: '',
  contactustext: '',
  emailreceipttextfooter: '',
  winningbidtextheader: '',
  winningbidtextfooter: '',
  emailreceipttextheader: '',
  mobilelogintext: '',
  mobilewelcometext: '',
  message: ''
}

export const globalEventMessagesFetcher = async (eventIdvalue) => {
  const json = JSON.stringify({ eventid: eventIdvalue })
  const { data } = await axios.post('/eventmessages', json, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const parsedData = JSON.parse(data.jsonString)
  return parsedData as EventMessages
}

export const useFetchEventMessages = (eventid) => {
  return useQuery<EventMessages>(
    ['eventmessages', eventid],
    () => globalEventMessagesFetcher(eventid),
    {
      staleTime: 120000,
      placeholderData: defaultEventMessages,
      keepPreviousData: true
    }
  )
}
