import axios from 'axios'
import { useQuery } from 'react-query'

export type Events = {
    rownumber: number
    eventid: number
    eventname: string
    eventcode: string
    startdate: string
    enddate: string
    eventlink: string
    numofregisteredbidders: number
    numofpendingbidders: number
    raised: number
    openbids: number
    openbidsamount: number
    pendingamount: number
    collectedamount: number
    active: boolean
}

export  type EventDetail = {
    value: string
    title: string
    hover: string
    islink: boolean
    aligntitle: string
    alignvalue: string
    widthtitle: number
    widthvalue: number
}

export type EventAuctions = {
    active: boolean
    eventid: number
    eventname: string
    eventdetail: EventDetail[]
}

export type EventsData = {
    validauction: boolean
    validevent: boolean
    newbuttontext: string
    events: Events[]
    eventauctions: EventAuctions[]
}

export const globalEventsFetcher = async () => {
    const { data } = await axios.get('/events')
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData.events as Events[]
}

export const globalFetcher = async () => {
    const { data } = await axios.get('/events')
    const parsedData = JSON.parse(data.jsonString)
    // console.log(parsedData)
    return parsedData as EventsData
}

export const useFetchEvents = () => {
    return useQuery<Events[]>(
        ['events'],
        () => globalEventsFetcher(),
        {
            staleTime: 120000,
            placeholderData: [],
            keepPreviousData: true
        }
    )
}

export const useFetchEventData = () => {
    return useQuery<EventsData>(
        ['eventdata'],
        () => globalFetcher(),
        {
            staleTime: 120000,
            // placeholderData: {},
            keepPreviousData: true
        }
    )
}
