import React, {useEffect, useMemo, useState} from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import {Link, useHistory} from 'react-router-dom'
import Button from '@mui/material/Button';
import download from 'downloadjs';

import {  IconButton } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

import {Box, Grid} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import { PagePathTypes } from '../../App'
import { ConfirmModal } from '../../components/common/ConfirmModal'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import {EventState, setEvent, useEventState} from '../../slices/eventSlice'
import ParticipantsForm from './ParticipantsForm'
import { useCreateParticipant } from './ParticipantsForm/useCreateParticipant'
import styles from './ParticipantsPage.module.css'
import { Participant, useFetchParticpantswithUDTitles } from './useFetchParticipants'
import {useAutoEvent} from "../../queries/useAutoEvent";
import {OrgState, setOrg, useOrgState} from "../../slices/orgSlice";
import {useFetchOrgSettings} from "../OrgSettingsPage/useFetchOrgSettings";
import {useAuthState} from "../../slices/authSlice";
import {useDispatch} from "react-redux";
import dayjs from "dayjs";
import axios from "axios";

interface Params {
  eventid: string
}

const ParticipantsPage = () => {
    const orgState = useOrgState()
    const history = useHistory()
    const { eventid } = useParams<Params>()
    useAutoEvent(eventid)
    const event = useEventState()
    const auth = useAuthState()
    const dispatch = useDispatch()
    const { data: settings } = useFetchOrgSettings({ userid: auth?.token?.userid })
    const [dialogMsg, setDialogMsg] = useState("")
    const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (settings) {
      const orgState: OrgState = {
        name: settings?.name, message: settings?.message, isdonorsnapcustomer: settings?.isdonorsnapcustomer, twilio: settings?.twilio
      }
      dispatch(setOrg(orgState))
    }
  }, [settings])

  const importPath = '/auction/' + eventid + '/participants/import'
  const importDMPPath = '/auction/' + eventid + '/participants/dmp/import'

  const [isFormShowing, setIsFormShowing] = useState(false)
  const [particpantToEdit, setParticpantToEdit] = useState<Participant>(null)
  const [particpantToDelete, setParticpantToDelete] =
    useState<Participant>(null)
  const [reloadForm, setReloadForm] = useState<number>(0)

  const { data: particpantswithTitles, isLoading } = useFetchParticpantswithUDTitles(event?.eventId)
  const rows = useMemo(
    () =>
      particpantswithTitles?.participants?.map((p) => {
        return { ...p, id: p.participantid }
      }) || [],
    [particpantswithTitles]
  )

  const getNextBidderNumber = () => {
    return Math.max.apply(
      Math,
      particpantswithTitles?.participants?.map(function (o) {
        return o.bidnumber + 1
      })
    )
  }
  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridSortedRowIdsSelector(apiRef);
  const closeDialog = () => {
    setDialogMsg("")
  }

    const generateContactReport = async () => {
        // setLoading(true)
        axios.post('/integrationcontactreport', {eventid: eventid}, {responseType: "blob"}).then(response => {
            var filename = 'integrationcontactreport.xlsx';
            var disposition = response.headers['content-disposition']
            var contentType = response.headers['content-type']
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            if (response.data) {
                download(response.data, filename, contentType);
            }
            // setLoading(false)
        })
    }
  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
        generateContactReport()
      // options.allColumns = true;
      // apiRef.current.exportDataAsCsv(options);
    }


    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    const uploadebuttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <ImportExportIcon />,
    };

    return (
        <>
          <GridToolbarContainer >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                  {/*<Button*/}
                  {/*    {...uploadebuttonBaseProps}*/}
                  {/*    onClick={() => { history.push(importPath) }}*/}
                  {/*>*/}
                  {/*  IMPORT PARTICIPANTS*/}
                  {/*</Button>*/}
                  {/*<Button*/}
                  {/*    {...uploadebuttonBaseProps}*/}
                  {/*    onClick={() => { history.push(importDMPPath) }}*/}
                  {/*>*/}
                  {/*  IMPORT DONORSNAP*/}
                  {/*</Button>*/}
                  <Button
                      {...buttonBaseProps}
                      onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                  >
                    EXPORT
                  </Button>
                  {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
          </GridToolbarContainer>
        </>
    )
  }
  const editMutation = useCreateParticipant()
  const queryClient = useQueryClient()

  const onDelete = async () => {
    try {
      
       await editMutation.mutateAsync({
        participantid: particpantToDelete.participantid,
        eventid: Number(event.eventId),
        delete: true
      }, {
        onSuccess: (data) => {
            const json = JSON.parse(data.data.jsonString)
            // console.log(json)
            if (json.status === "failure") {
                setDialogMsg(json.message)
            }
            else {
                //setDialogMsg('Message was sent successfully.')
            }
        }
    })
      setParticpantToDelete(null)
      queryClient.invalidateQueries(['participant'])
    } catch {}
  }

  const columns = [
    {
      field: 'Edit',
      renderHeader: () => {
        return (
          <IconButton
            className={styles.submitButton}
            onClick={() => {
              setReloadForm(reloadForm + 1)
              setParticpantToEdit(null)
              setIsFormShowing(true)
            }}
            size="small">
            <Add />
          </IconButton>
        )
      },
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          className={styles.button}
          onClick={() => {
            setReloadForm(reloadForm + 1)
            setParticpantToEdit(params.row as Participant)
            setIsFormShowing(true)
          }}>
          <Edit />
        </IconButton>
      )
    },
    {
      field: 'authorizedbidder',
      type: 'boolean',
      headerName: 'Authorized',
      width: 150,
      editable: true
    },
    {
      field: 'bidnumber',
      type: 'string',
      headerName: 'Bidder #',
      width: 150,
      editable: false
    },
    {
      field: 'paddlenumbertext',
      type: 'string',
      headerName: 'Paddle #',
      width: 150,
      editable: false
    },
    {
      field: 'first',
      type: 'string',
      headerName: 'First name',
      width: 200,
      editable: true
    },
    {
      field: 'last',
      type: 'string',
      headerName: 'Name',
      width: 200,
      editable: true
    },
    {
      field: 'email',
      type: 'string',
      headerName: 'Email',
      width: 200,
      editable: false
    },
    {
      field: 'phone',
      type: 'string',
      headerName: 'Phone',
      valueFormatter: (params) => {
        var cleaned = ('' + params.value).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        return match
          ? '(' + match[1] + ') ' + match[2] + '-' + match[3]
          : params.value
      },
      width: 200,
      editable: true
    },
    {
      field: 'numberofbids',
      type: 'number',
      headerName: '# Bids',
      width: 200,
      editable: true
    },
    {
      field: 'numberwinningbids',
      type: 'number',
      headerName: '# Winning Items',
      width: 200,
      editable: true
    },
    {
      field: 'source',
      type: 'string',
      headerName: 'Source',
      width: 200,
      editable: true
    },
    {
      field: 'lastlogindate',
      headerName: 'Last Login',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
        }
        else {
          return 'Never'
        }
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      width: 150,
      disableExport: true,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          className={styles.button}
          onClick={() => setParticpantToDelete(params.row as Participant)}>
          <Delete />
        </IconButton>
      )
    }
  ]

  return (
    <div className={styles.root}>
      <>
          <LoadingBackdrop open={loading} />
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                        <DialogTitle>Error Can't Delete</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>
      <Grid alignContent={'center'} justifyContent={'center'} container spacing={2}>
        <h2>Participants</h2>
      </Grid>
      <div className={styles.titleRow}>
        <Grid alignContent={'flex-end'} justifyContent={'flex-end'} container spacing={2} style={{paddingBottom: 10}}>
          {orgState.isdonorsnapcustomer &&
              <Grid item>
                <Button
                    component={Link}
                    to={importDMPPath}
                    variant="contained">
                  Import Donorsnap
                </Button>
              </Grid>
          }
          <Grid item>
            <Button
                component={Link}
                to={importPath}
                variant="contained">
              Import Spreadsheet
            </Button>
          </Grid>

        </Grid>
      </div>
      <ParticipantsForm
        isOpen={isFormShowing}
        setIsOpen={setIsFormShowing}
        particpant={particpantToEdit}
        reloadForm={reloadForm}
        nextBidNumber={getNextBidderNumber()}
        udTitles = {particpantswithTitles?.udTitles}
      />
      <LoadingBackdrop open={editMutation.isLoading} />
      <ConfirmModal
        text="Are you sure you want to delete this participant? This cannot be undone?"
        confirmText="Delete Participant"
        onConfirm={onDelete}
        isOpen={Boolean(particpantToDelete)}
        onClose={() => setParticpantToDelete(null)}
      />
      <DataGridPro
        autoHeight
        onCellEditCommit={(params: any, e, details) => {
          const row = details.api?.getRow(params.id)
          editMutation.mutateAsync({
            ...(row || {}),
            eventid: Number(event.eventId),
            [params.field]: params.value
          })
        }}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pagination={true}
      />
    </div>
  )
}

export default ParticipantsPage
