import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  BidMaintenance {
    rownumber: number
    historyid: number
    biddername: string
    itemnumber: string
    bidtime: string
    bidtype: string
    quantity: number
    bidamount: number
    bidautoamount: number
    checkoutcompleted: boolean
    itembiddingclosed: number
    active: boolean
}

const BidMaintenanceDefaults = {
    rownumber: 0,
    historyid: 0,
    biddername: "",
    itemnumber: "",
    bidtime: "",
    bidtype: "",
    quantity: 0,
    bidamount: 0,
    bidautoamount: 0,
    checkoutcompleted: false,
    itembiddingclosed: 0,
    active: true
}

export const useFetchBidMaintenance = (eventIdvalue) => {
    return useQuery<BidMaintenance[]>(
        ['bidmaintenance', eventIdvalue],
        () => globalBidMaintenanceFetcher(eventIdvalue),
        {
            staleTime: 120000,
            placeholderData: [BidMaintenanceDefaults],
            keepPreviousData: true
        }
    )
}

export const useUpdateBidMaintenance = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`bidmaintenance`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['bidmaintenance'])
        }
    })
}

export const globalBidMaintenanceFetcher = async (eventIdvalue) => {
    const { data } = await axios.post('/bidmaintenance', { eventid: eventIdvalue })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.bids as BidMaintenance[]
}
