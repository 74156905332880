import { useForm } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, Grid,
  TextField
} from '@material-ui/core'

import HookForm from '../../components/common/HookForm'
import SelectInput from '../../components/common/inputs/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
import { useEventState } from '../../slices/eventSlice'
import styles from './ManualBidEntryPage.module.css'
import React, {useEffect} from "react";
import DateTimeInput from "../../components/common/inputs/DateTimeInput";
import dayjs from "dayjs";
import MaskedInput from "../../components/common/inputs/MaskedInput";
import AutocompleteSelect from "../../components/common/inputs/AutoCompleteSelectInput/AutocompleteSelect"
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {useUpdateManualBid} from "../../queries/useManualBid";
import {useQueryClient} from "react-query";

type FormData = {
  id: string
  eventid: number
  itemid: number
  buyitnow: boolean
  buyitnowquantity: number
  price: string
  bidderid: number
}
export const BidForm = ({ isOpen, setIsOpen, gridParams, bidderList, buyItNow }) => {
  const formMethods = useForm<FormData>()
  const { reset, watch, setError} = formMethods
  const manualBidMutation = useUpdateManualBid()
  const eventState = useEventState()
  const title = (buyItNow ? "Buy It Now" : "Manual Bid")
  const [dialogMsg, setDialogMsg] = React.useState({message: "", display: false})
  const [dialogStatus, setDialogStatus] = React.useState(true)
  const [currencyValues, setCurrencyValues] = React.useState(gridParams.lastbid)
  const [quantityValues, setQuantityValues] = React.useState(gridParams.buyitnowquantity)
  const [isValid, setIsValid] = React.useState(true)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (gridParams) {
      console.log(gridParams)
      console.log(eventState)
      reset({
        id: gridParams.id,
        eventid: Number(eventState.eventId),
        itemid: gridParams.itemid,
        buyitnow: buyItNow,
        buyitnowquantity: gridParams.buyitnowquantity,
        price: gridParams.price,
        bidderid: gridParams.bidderid
      })
      var initialAmount = gridParams.startingbid
      if (gridParams.startingbid === gridParams.lastbid) {
        initialAmount = gridParams.lastbid
      }
      else if (gridParams.lastbid > 0) {
        initialAmount = gridParams.lastbid + gridParams.bidincrement
      }

      setCurrencyValues(initialAmount)
      setQuantityValues(1)
      setIsValid(true)
    }
  }, [gridParams])

  useEffect(() => {
    setIsValid(true)
  }, [isOpen])

  const onSubmit = async (formData: FormData) => {

    try {
      await manualBidMutation.mutateAsync({
        eventid: eventState.eventId,
        itemid: gridParams.itemid,
        buyitnow: buyItNow,
        quantity: quantityValues,
        amount: Number(currencyValues),
        bidderparticipantid: formData.bidderid
      },{
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          console.log(json)
          if (json.status === "failed") {
            setDialogStatus(false)
          }
          setDialogMsg({message: json.message2, display: true})
        }
      })
    } catch {}
  }

  const closeDialog = () => {
    setDialogMsg({message: "", display: false})
    if (dialogStatus) {
      queryClient.invalidateQueries(['manualbid'])
      setIsOpen(false)
    }
  }

  const getBidderOptions = () => {
    return bidderList?.map(item =>{
      return {value:item.bidderparticipantid, label:item.biddername}
    })
  }

  const handleCurrencyChange = (newValue: number | null) => {
    // setError('bidderid', { type: 'required', message: 'Location is required' })

    if (newValue > gridParams.lastbid) {
      setIsValid(true)
      setCurrencyValues(Number(newValue.toFixed(1)))
    }
    else {
      setIsValid(false)
    }
  }

  const handleQuantityChange = (newValue: number) => {
    if (newValue > 0) {
      setQuantityValues(newValue)
    }
  }

  return (
    <Dialog open={isOpen} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>

      <>
        <Dialog
            open={dialogMsg.display}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <DialogContent>
        <HookForm
          methods={formMethods}
          onSubmit={onSubmit}
          className={styles.bidForm}
          formProps={{ id: 'bid-form' }}>

          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.selectcontrol}>
              <div>Item: {gridParams.itemnumber} - {gridParams.name}</div>
            </Grid>
            <Grid item xs={12} className={styles.selectcontrol}>
              {buyItNow &&
                  <div style={{ marginTop: '24px' }} >Buy It Now Price: ${gridParams.buyitnowprice}</div>
              }
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} className={styles.selectcontrol}>
              <div>
                <AutocompleteSelect
                    name="bidderid"
                    label="Bidder"
                    options={getBidderOptions()}
                    required
                />
              </div>
            </Grid>
          </Grid>

            {!buyItNow &&
                <Grid container spacing={1} >
                  <Grid item xs={12} className={styles.selectcontrol}>
                    <div>
                      <CurrencyTextField
                          label={"Amount"}
                          name={"price"}
                          variant="outlined"
                          default={gridParams.lastbid}
                          value={currencyValues}
                          fullWidth={true}
                          currencySymbol="$"
                          outputFormat="number"
                          textAlign={"left"}
                          required={!buyItNow}
                          decimalPlaces={0}
                          decimalCharacter="."
                          error={!isValid}
                          helperText={!isValid && "Minimum bid amount is $" + gridParams.lastbid}
                          onChange={(event, value) => handleCurrencyChange(value)}
                      />
                    </div>
                  </Grid>
                </Grid>
            }
          <Grid container spacing={1} justifyContent={"flex-end"}>
          {buyItNow && (
                <Grid item xs={12} className={styles.selectcontrol}>
                  <CurrencyTextField
                      label={"Quantity"}
                      name={"buyitnowquantity"}
                      variant="outlined"
                      value={quantityValues}
                      fullWidth={true}
                      currencySymbol=""
                      decimalPlaces={0}
                      outputFormat="number"
                      textAlign={"left"}
                      required={buyItNow}
                      minimumValue="1"
                      // decimalCharacter="."
                      onChange={(event, value) => handleQuantityChange(value)}
                  />
                </Grid>
          )}
          </Grid>
        </HookForm>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={styles.submitButton}
          type="submit"
          disabled={!buyItNow && !isValid}
          form="bid-form">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
