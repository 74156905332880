import React, { useState } from 'react'

import { IconButton } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import { useFetchUsers, User } from '../../../queries/useUsers'
import { useUser } from "../../../mutations/useUser";
import { OrgUserForm } from './UserForm'
import styles from './UsersTable.module.css'
import {DataGridPremium, GridCellParams} from "@mui/x-data-grid-premium";
import {GridColumns} from "@mui/x-data-grid-pro";

const Users = {
  userid: 0,
  email: '',
  password: '',
  active: true,
  globalsetup: false,
  auctionsettings: false,
  sponsors: false,
  customusermessage: false,
  customemail:  false,
  location: false,
  auctionitems: false,
  participants: false,
  manualbidentry: false,
  checkout: false,
  participantlisting: false,
  accountingreport: false,
  transfertransactionsto: false,
  transfertransactionsfrom: false,
  transfernewcontactsto:  false,
  createinteractionrecords:  false,
  lastupdateddate: '',
  lastupdatedby: ''
}

const UsersTable = () => {
  const [userToEdit, setUserToEdit] = useState<User>(null)
  const [reloadForm, setReloadForm] = useState<number>(0)
  const putUser = useUser()

  var { data } = useFetchUsers()
  var rows = []
  if (data) {
    rows = data.map((user) => {
      return { id: Number(user.userid), email: user.email, password:'', active: user.active,
        globalsetup: user.globalsetup,
        auctionsettings: user.auctionsettings,
        sponsors: user.sponsors,
        customusermessage: user.customusermessage,
        customemail:  user.customemail,
        location: user.location,
        auctionitems: user.auctionitems,
        participants: user.participants,
        manualbidentry: user.manualbidentry,
        checkout: user.checkout,
        participantlisting: user.participantlisting,
        accountingreport: user.accountingreport,
        transfertransactionsto: user.transfertransactionsto,
        transfertransactionsfrom: user.transfertransactionsfrom,
        transfernewcontactsto:  user.transfernewcontactsto,
        createinteractionrecords:  user.createinteractionrecords,
        lastupdateddate: user.lastupdateddate,
        lastupdatedby: user.lastupdatedby
      }
    })
  }

  const handleAdd = () => {
    setReloadForm(reloadForm+1)
    setUserToEdit(null)
    setIsFormShowing(true)
  }

  const handleEdit = (params: GridCellParams) => {
    setReloadForm(reloadForm+1)
    const data = rows.filter((e) => Number(e.id) === Number(params.id))[0]
    setUserToEdit({
      userid: Number(params.id),
      email: data.email,
      password: data.password,
      active: data.active,
      globalsetup: data.globalsetup,
      auctionsettings: data.auctionsettings,
      sponsors: data.sponsors,
      customusermessage: data.customusermessage,
      customemail:  data.customemail,
      location: data.location,
      auctionitems: data.auctionitems,
      participants: data.participants,
      manualbidentry: data.manualbidentry,
      checkout: data.checkout,
      participantlisting: data.participantlisting,
      accountingreport: data.accountingreport,
      transfertransactionsto: data.transfertransactionsto,
      transfertransactionsfrom: data.transfertransactionsfrom,
      transfernewcontactsto:  data.transfernewcontactsto,
      createinteractionrecords:  data.createinteractionrecords,
      lastupdateddate: data.lastupdateddate,
      lastupdatedby: data.lastupdatedby
    })
    setIsFormShowing(true)
  }

  const handleDeleteConfirm = async (params: GridCellParams) => {
    var finaldata = {
      "userid": params.id,
      "email": params.getValue(params.id, 'email').toString(),
      "delete": true
    }
    await putUser.mutateAsync({
      ...finaldata
    })
  }

  const [isFormShowing, setIsFormShowing] = useState(false)

  const columns: any = [
    {
      field: 'Edit',
      renderHeader: () => {
        return (
          <IconButton
            className={styles.submitButton}
            onClick={() => handleAdd()}
            size="small">
            <Add />
          </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
          <Edit />
        </IconButton>
      )
    },
    {
      field: 'id',
      hide: true
    },
    {
      field: 'email',
      type: 'string',
      headerName: 'Email',
      width: 200
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        return params.value ? 'Active' : 'Inactive'
      }
    },
    {
      field: 'lastupdateddate',
      type: 'string',
      headerName: 'Last Updated Date',
      width: 200,
      valueFormatter: (params) =>
        params.value ? new Date(params.value as string).toLocaleString() : 'N/A'
    },
    {
      field: 'lastupdatedby',
      type: 'date',
      headerName: 'Last Updated By',
      width: 200
    },
    {
      field: 'action',
      headerName: 'Delete',
      width: 100,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleDeleteConfirm(params) }}>
            <Delete />
          </IconButton>
      )
    }
  ]

  return (
    <>
      <DataGridPremium
        autoHeight
        rows={rows}
        columns={columns}
        loading={false}
        pagination={false}
      />
      <OrgUserForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} user={userToEdit} reloadForm={reloadForm}/>
    </>
  )
}

export default UsersTable
