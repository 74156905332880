import React, {useEffect, useState} from 'react'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  useOrgSettings,
  useOrgSettingsTwilio,
  useOrgSettingsGEM,
  useResetTwilio,
  useResetGEM
} from '../../mutations/useOrgSettings'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import HookForm from '../../components/common/HookForm'
import TextInput from '../../components/common/inputs/TextInput'
import { useAuthToken } from '../../slices/authSlice'
import styles from './OrgSettingsPage.module.css'
import UsersTable from './UsersTable'
import { useFetchOrgSettings } from './useFetchOrgSettings'
import { useFetchOrgSettingsTwilio } from './useFetchOrgSettingsTwilio'
import { useFetchOrgSettingsGEM } from './useFetchOrgSettingsGEM'
import MaskedInput from "../../components/common/inputs/MaskedInput";
import {emailRegex, phoneRegex, zipCodeRegex} from "../../util/formValidation";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {EventState, setEvent} from "../../slices/eventSlice";
import LoadingBackdrop from "../../components/common/LoadingBackdrop";
import {OrgState, setOrg} from "../../slices/orgSlice";
import DateTimeInput from "../../components/common/inputs/DateTimeInput";
import {Stack} from "@mui/material";
import {useQueryClient} from "react-query";
import axios from "axios";
import RecieptTemplatesTable from "./RecieptTemplates";

type FormData = {
  email: string
  phone: string
  postalcode: string
  orgInfo: {
    name: string
    contactname: string
    address1: string
    address2: string
    city: string
    state: string
    postalcode: string
    email: string
    phone: string
  }
}

type formDataTwilio = {
  twiliophone: string
  twilioaccountsid: string
  twilioauthtoken: string
  valid: boolean
}

type formDataGEM = {
  transactionid: string
  gatewayid: string
  ecommerceid: string
  retailid: string
  lastupdated: string
  validationdate: string
}

interface Params {
  id: string,
  name: string
}

const OrgSettingsPage = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { id, name } = useParams<Params>()
  const eventState: EventState = {
    eventId: id,
    eventName: name
  }
  dispatch(setEvent(eventState))

  const putOrgSetting = useOrgSettings()
  const putOrgSettingsTwilio = useOrgSettingsTwilio();
  const putOrgSettingsGEM = useOrgSettingsGEM();
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [dialogObj, setDialogObj] = React.useState({ msg: "", show: false, twilio: false })
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const formMethods = useForm<FormData>()
  const { reset } = formMethods

  const twilioFormMethods = useForm<formDataTwilio>()
  const { reset : twilloReset } = twilioFormMethods

  const gemFormMethods = useForm<formDataGEM>()
  const { reset : gemReset } = gemFormMethods

  const authToken = useAuthToken()

  const ontwilioSubmit = async (formData: formDataTwilio) => {
    setIsLoading(true)
    var finaldata = {
      "twiliophone": formData.twiliophone,
      "twilioaccountsid": formData.twilioaccountsid,
      "twilioauthtoken": formData.twilioauthtoken,
    }

    const { data: response } = await putOrgSettingsTwilio.mutateAsync({
      ...finaldata
    })
    const parsedResponse = JSON.parse(response?.jsonString)
    const twilio = JSON.parse(parsedResponse?.twilio)

    if (parsedResponse?.status === 'success' && twilio.valid) {
      const orgState: OrgState = {
        name: settings?.name, message: settings?.message, isdonorsnapcustomer: settings?.isdonorsnapcustomer, twilio: twilio
      }
      dispatch(setOrg(orgState))
      queryClient.invalidateQueries(['orgsettings', authToken?.userid])
      setDialogMsg('Twilio settings successfully saved.')
    }
    if (!twilio.valid) {
      setDialogMsg(twilio.errormessage)
    }
    else if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse?.message)
    }

    setIsLoading(false)
  }

  const onGEMSubmit = async (formData: formDataGEM) => {
    setIsLoading(true)
    var finaldata = {
      "goetransactionid": formData.transactionid,
      "goegatewayid": formData.gatewayid
    }

    const { data: response } = await putOrgSettingsGEM.mutateAsync({
      ...finaldata
    })
    const parsedResponse = JSON.parse(response?.jsonString)
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse?.message)
    }
    if (parsedResponse?.goemerchant) {
      const goemerchant = JSON.parse(parsedResponse?.goemerchant)
      if (!goemerchant.valid) {
        setDialogMsg(goemerchant.errormessage)
      }
      else if (parsedResponse?.status === 'success' && goemerchant.valid) {
        setDialogMsg('GoEmerchant settings successfully saved.')
      }
    }

    setIsLoading(false)
  }

  const onSubmit = async (formData: FormData) => {
    setIsLoading(true)
    var finaldata = {
      "name": formData.orgInfo.name,
      "contactname": formData.orgInfo.contactname,
      "address" : formData.orgInfo.address1,
      "address2": formData.orgInfo.address2,
      "city": formData.orgInfo.city,
      "state": formData.orgInfo.state,
      "zip": formData.postalcode,
      "email": formData.email,
      "phone": formData.phone
    }

    const { data: response } = await putOrgSetting.mutateAsync({
      ...finaldata
    })
    const parsedResponse = JSON.parse(response?.jsonString)
    if (parsedResponse?.status === 'success') {
      // const orgState: OrgState = {
      //   name: formData.orgInfo.name, message: settings?.message
      // }
      // dispatch(setOrg(orgState))
      setDialogMsg('Organization settings successfully saved.')
    }
    if (parsedResponse?.status === 'failure') {
      setDialogMsg(parsedResponse?.message)
    }
    setIsLoading(false)
  }

  const { data: settings } = useFetchOrgSettings({ userid: authToken?.userid })
  const { data: twilioSettings } = useFetchOrgSettingsTwilio({})
  const { data: GEMSettings } = useFetchOrgSettingsGEM({})

  useEffect(() => {
    if (settings) {
      const orgState: OrgState = {
        name: settings?.name, message: settings?.message, isdonorsnapcustomer: settings?.isdonorsnapcustomer, twilio: settings?.twilio
      }
      dispatch(setOrg(orgState))
    }
  }, [settings])

  const hasInitiatedForm = useRef(false)
  useEffect(() => {
    if (settings && !hasInitiatedForm.current) {
      reset({
        email: settings.email,
        phone: settings.phone,
        postalcode: settings.zip,
        orgInfo: {
          name: settings.name,
          contactname: settings.contactname,
          address1: settings.address,
          address2: settings.address2,
          city: settings.city,
          state: settings.state,
          postalcode: settings.zip,
          email: settings.email,
          phone: settings.phone
        }
      })
      hasInitiatedForm.current = true
    }
  }, [settings])

  const hasInitiatedTwilioForm = useRef(false)
  useEffect(() => {
    if (twilioSettings && !hasInitiatedTwilioForm.current) {
      twilloReset({
          twiliophone: twilioSettings.twiliophone.replace("+1", ""),
          twilioaccountsid: twilioSettings.twilioaccountsid,
          twilioauthtoken: twilioSettings.twilioauthtoken,
      })
      hasInitiatedTwilioForm.current = true
    }
  }, [twilioSettings])

  const hasInitiatedGEMForm = useRef(false)
  useEffect(() => {
    if (GEMSettings && !hasInitiatedGEMForm.current) {
      gemReset({
        transactionid: GEMSettings.transactionid,
        gatewayid: GEMSettings.gatewayid,
        ecommerceid: GEMSettings.ecommerceid,
        retailid: GEMSettings.retailid,
        lastupdated: GEMSettings.lastupdated,
        validationdate: GEMSettings.validationdate
      })
      hasInitiatedGEMForm.current = true
    }
  }, [GEMSettings])

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      show: false,
      twilio: false
    })
  }

  const checkPhoneNumber = (value: string) =>
    value?.replace(/\D/g,'').length === 10 || 'Please enter a valid phone number.'

  const onResetGEM = () => {
    setDialogObj({
      msg: 'Are you sure you want to reset the GoEmerchant settings?',
      show: true,
      twilio: false
    })
  }

  const onResetTWILIO = () => {
    setDialogObj({
      msg: 'Are you sure you want to reset the Twilio settings?',
      show: true,
      twilio: true
    })
  }

  const resetConfirm = async () => {
    if (dialogObj.twilio) {
      const { data } = await axios.get('/resettwilio')
      const parsedData = JSON.parse(data.jsonString)
      queryClient.invalidateQueries(['orgsettingstwilio'])
      queryClient.invalidateQueries(['orgsettings', authToken?.userid])
      if (parsedData.status === 'success') {
        const orgState: OrgState = {
          name: settings?.name, message: settings?.message, isdonorsnapcustomer: settings?.isdonorsnapcustomer,
          twilio: { twiliophone:"", twilioaccountsid:"", twilioauthtoken:"",
            valid:false, errormessage:"", validationdate:null}
        }
        dispatch(setOrg(orgState))
        twilloReset({
          twiliophone: "",
          twilioaccountsid: "",
          twilioauthtoken: "",
        })
        setDialogObj({
          msg: "",
          show: false,
          twilio: true
        })
      }
      else {
        setDialogObj({
          msg: parsedData.message,
          show: true,
          twilio: true
        })
      }
    }
    else {
      const { data } = await axios.get('/resetgem')
      const parsedData = JSON.parse(data.jsonString)
      queryClient.invalidateQueries(['orgsettingsgem'])
      if (parsedData.status === 'success') {
        gemReset({
          transactionid: "",
          gatewayid: "",
          ecommerceid: "",
          retailid: "",
          lastupdated: null,
          validationdate: null
        })
        setDialogObj({
          msg: "",
          show: false,
          twilio: true
        })
      }
      else {
        setDialogObj({
          msg: parsedData.message,
          show: true,
          twilio: true
        })
      }
    }
  }

  return (
    <div className={styles.root}>
      <h1>Organization Settings</h1>
        <LoadingBackdrop open={isLoading} />
        <>
          <Dialog
              open={dialogObj.msg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogObj.msg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button onClick={resetConfirm}>RESET</Button>
            </DialogActions>
          </Dialog>
        </>
        <>
          <Dialog
              open={dialogMsg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Organization Info</h2>
          </AccordionSummary>
          <AccordionDetails>
            <HookForm methods={formMethods}
                      onSubmit={onSubmit}
                      key={9}
                      className={styles.inputsContainer}
                      formProps={{ id: 'org-form-9', name: "9" }}>
            <div className={styles.inputsContainer}>
              <TextInput name="orgInfo.name" label="Organization Name" required/>
              <TextInput name="orgInfo.contactname" label="Contact Name" required/>
              <TextInput name="orgInfo.address1" label="Address Line 1" required/>
              <TextInput name="orgInfo.address2" label="Address Line 2" />
              <TextInput name="orgInfo.city" label="City" required/>
              <TextInput name="orgInfo.state" label="State" required/>
              <TextInput
                  name="postalcode"
                  label="Postal Code"
                  required
                  rules={{
                    pattern: {
                      value: zipCodeRegex,
                      message: 'Please enter a valid postal code.'
                    }
                  }}
              />
              <TextInput
                  name="email"
                  label="Email"
                  required
                  rules={{
                    pattern: {
                      value: emailRegex,
                      message: 'Please enter a valid email.'
                    }
                  }}
              />
              <MaskedInput
                  name="phone"
                  label="Phone"
                  defaultValue=""
                  // onValueChange={handlePhone}
                  thousandSeparator={false}
                  thousandsGroupStyle={""}
                  prefix={""}
                  format={"(###) ###-####"}
                  mask={"_"}
                  rules={{
                    pattern: {
                      value: phoneRegex,
                      message: 'Please enter a valid phone number.'
                    }
                  }}
              />
              <Button type="submit" form="org-form-9" className={styles.bottomSaveButton}>
                Save
              </Button>
            </div>
            </HookForm>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <h2>goEmerchant - Payment Processing Integration </h2>
              {GEMSettings?.valid ?
                  <>
                    <LinkIcon style={{marginLeft: "10px", color: "green"}}/>
                    <h2 style={{marginLeft: "10px", color: "green"}}>Connected</h2>
                  </>
                  :
                  <>
                    <LinkOffIcon style={{marginLeft: "10px", color: "red"}}/>
                    <h2 style={{marginLeft: "10px", color: "red"}}>Not Connected</h2>
                  </>
              }
            </div>
          </AccordionSummary>
          <HookForm methods={gemFormMethods}
                    onSubmit={onGEMSubmit}
                    className={styles.inputsContainer}
                    formProps={{ id: 'org-form-gem' }}>
          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <TextInput
                name="transactionid"
                required
                label="Transaction Center Id"
              />
              <TextInput name="gatewayid" required label="Gateway Id" />
              <TextInput name="ecommerceid" disabled={true} label="eCommerce Id" />
              {/*<TextInput name="retailid" disabled={true} label="Retail Id" />*/}
              <DateTimeInput name="validationdate" defaultValue={null} disabled={true} label="Validation Date" />
              <DateTimeInput name="lastupdated" disabled={true} label="Last Updated" />
              <Stack direction="row" spacing={2}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    form="org-form-gem"
                    className={styles.bottomSaveButton}>
                  Validate and Save
                </Button>
                <Button variant="contained" className={styles.resetButton} onClick={onResetGEM}>
                  Reset
                </Button>
              </Stack>
            </div>
          </AccordionDetails>
          </HookForm>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <h2>Twilio - Text Messaging Integration</h2>
              {twilioSettings?.valid ?
                  <>
                    <LinkIcon style={{marginLeft: "10px", color: "green"}}/>
                    <h2 style={{marginLeft: "10px", color: "green"}}>Connected</h2>
                  </>
                  :
                  <>
                    <LinkOffIcon style={{marginLeft: "10px", color: "red"}}/>
                    <h2 style={{marginLeft: "10px", color: "red"}}>Not Connected</h2>
                  </>
              }
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <HookForm methods={twilioFormMethods}
                        onSubmit={ontwilioSubmit}
                        className={styles.inputsContainer}
                        formProps={{ id: 'org-form-twilio'}} >
              <MaskedInput
                  name="twiliophone"
                  label="Phone Number"
                  defaultValue=""
                  thousandSeparator={false}
                  thousandsGroupStyle={""}
                  prefix={""}
                  format={"(###) ###-####"}
                  required
                  mask={"_"}
                  rules={{
                    validate: (val) => checkPhoneNumber(val)
                  }}
              />
              <TextInput name="twilioaccountsid" required label="Account SID" />
              <div className={styles.inputButtonContainer}>
                <TextInput
                    name="twilioauthtoken"
                    required
                    label="Auth Token"
                />
              </div>
                <Stack direction="row" spacing={2}>
                  <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      form="org-form-twilio"
                      className={styles.bottomSaveButton}>
                    Validate and Save
                  </Button>
                  <Button variant="contained" className={styles.resetButton} onClick={onResetTWILIO}>
                    Reset
                  </Button>
                </Stack>
              </HookForm>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Users</h2>
          </AccordionSummary>
          <AccordionDetails>
            <UsersTable />
          </AccordionDetails>
        </Accordion>
        {/*<Accordion>*/}
        {/*  <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>*/}
        {/*    <h2>Receipt Templates</h2>*/}
        {/*  </AccordionSummary>*/}
        {/*  <AccordionDetails>*/}
        {/*    <RecieptTemplatesTable />*/}
        {/*  </AccordionDetails>*/}
        {/*</Accordion>*/}
    </div>
  )
}

export default OrgSettingsPage
