import React, { useState } from 'react'

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'
import { UDTitlesForm } from './ParticipantUDTitle'
import styles from './ParticipantUDTitle.module.css'
import {useEventState} from "../../../slices/eventSlice";
import {useUpdateTitles} from "./useUpdateUDTitles";
import {DataGridPro, GridCellParams} from "@mui/x-data-grid-pro";

const UDTitlesTable = (titles) => {
  const [gridParams, setGridParams] = useState({ id:0,  description: '', active: true})
  const data = titles?.titles
  //const udTitlesMutation = useUpdateTitles()
  const [isFormShowing, setIsFormShowing] = useState(false)
  const eventState = useEventState()
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")

  var tagRows = []
  if (data) {
    tagRows = [{id: 1, name: data.userdefinedtitle1},
      {id: 2, name: data.userdefinedtitle2},
      {id: 3, name: data.userdefinedtitle3},
      {id: 4, name: data.userdefinedtitle4},
      {id: 5, name: data.userdefinedtitle5}]
  }

  const handleAdd = () => {
    setGridParams(null)
    setIsFormShowing(true)
  }




 

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns = [
    {
      field: 'Edit',
      renderHeader: () => {
        return (
          <IconButton
            className={styles.submitButton}
            onClick={ () => handleAdd() }
            size="small">
            <Edit />
          </IconButton>
        )
      },
      width: 150,
      sortable: false,
      renderCell: (params) => (
        ''
      )
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      width: 200
    }
  ]

  return (
    <>
      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <UDTitlesForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} titles={titles} />
      <DataGridPro
        autoHeight
        rows={tagRows}
        columns={columns}
        loading={false}
        pagination={false as any}
      />
    </>
  )
}

export default UDTitlesTable
