import { QueryClient, QueryClientProvider } from 'react-query'
import {Provider, useDispatch} from 'react-redux'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom'


import { AuthGuard } from '../components/common/AuthGuard'
import Layout from '../components/common/Layout'
import AuctionItemsPage from '../pages/AuctionItemsPage'
import EventItemsPage from '../pages/EventItemsPage'
import FlexMonsterPage from "../pages/FlexMonsterPage";
import AuthPage from '../pages/AuthPage'
import CustomMessagesPage from '../pages/CustomMessagesPage'
import AuctionPage from '../pages/AuctionPage/AuctionPage'
import EventPage from '../pages/EventPage/EventPage'
import AuctionSettingsForm from '../pages/AuctionSettingsForm'
import EvtSettingsForm from '../pages/EventSettingsForm'
import EventEmailCustomization from '../pages/EventEmailCustomization'
import EventMassEmail from '../pages/EventMassEmail'

import EventSponsors from '../pages/EventSponsors'
import HomePage from '../pages/HomePage'
import TermsPage from '../pages/TermsPage'
import LocationsTagsPage from '../pages/LocationsTagsPage'
import ManualBidEntryPage from '../pages/ManualBidEntryPage'
import OrgSettingsPage from '../pages/OrgSettingsPage'
import ParticipantsPage from '../pages/ParticipantsPage'
import ParticipantsListingPage from '../pages/ParticipantsListingPage'
import ParticipantMergePage from '../pages/ParticipantMergePage'
import ParticipantLinkingPage from '../pages/ParticipantLinkingPage'
import ImportPage from '../pages/ParticipantsPage/ImportPage'
import { useAutoAuth } from '../queries/useAutoAuth'
import HttpInterceptor from '../util/HttpInterceptor'
import styles from './App.module.css'
import store from './store'
import BidMaintenancePage from "../pages/BidMaintenancePage";
import AuctionStatusPage from "../pages/AuctionStatusPage";
import LiveAuctionPage from "../pages/LiveAuctionPage";
import CheckoutMainPage from "../pages/CheckoutPage";
import AuctionSummaryReportPage from "../pages/AuctionSummaryReportPage";
import { LicenseInfo } from '@mui/x-data-grid-pro'
import '@progress/kendo-theme-default/dist/all.css';
import AdminAuthPage from '../pages/AdminAuthPage'
import BroadcastMessagePage from "../pages/BroadcastMessagePage";
import ForceRefreshPage from "../pages/ForceRefreshPage";
import TransferPage from "../pages/TransferPage";
import EventUserDefinedPage from '../pages/EventUserDefinedPage'
import EventTransactionsPage from "../pages/EventTransactionsPage";
import EventTicketsPage from "../pages/EventTicketsPage";
import EventDiscountCodesPage from "../pages/EventDiscountCodesPage";
import EventGuestMaintenanceTable from "../pages/EventGuestMaintenancePage";
import { AuctionCarouselSetup } from '../pages/AuctionCarouselSetup'
import EventGroupMaintenance from "../pages/EventGroupMaintenancePage";

LicenseInfo.setLicenseKey(
  'f7086eca5ec0b9128713cd1ec7c772f3Tz03NzkxNSxFPTE3MzA1NjM4NjAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

export enum PagePathTypes {
  auth = '/auth',
  adminlogin = '/adminlogin/:tokenid',
  settings = '/settings',
  auctionsettings = '/auction/:id/settings',
  evtsettings = '/event/:id/settings',
  eventtransactions = '/event/:eventid/transactions',
  eventdiscountcodes = '/event/:eventid/discountcodes',
  eventactivitytickets = '/event/:eventid/activitytickets',
  eventguestmaintenance = '/event/:eventid/eventguestmaintenance',
  eventgroupmaintenance = '/event/:eventid/eventgroupmaintenance',
  eventemailcustomization = '/event/:eventid/EventEmailCustomization',
  emassemailmaintenancetable = '/event/:eventid/EventMassEmailMaintenanceTable',
  newevent = '/eventnew',
  newevt = '/evtnew',
  home = '/home',
  terms = '/terms',
  eventsponsors = '/auction/:id/sponsors',
  custommessages = '/auction/:id/custommessages',
  locationstags = '/auction/:id/locationstags',
  auctionitems = '/auction/:id/auctionitems',
  eventitems = '/event/:eventid/eventitems',
  eventuserdefined = '/event/:eventid/userdefined',
  participants = '/auction/:eventid/participants',
  ParticipantsLinking = '/auction/:eventid/participantsLinking',
  ParticipantLinking = '/auction/:eventid/ParticipantLinking',
  importParticpants = '/auction/:eventid/participants/import',
  importTypeParticpants = '/auction/:eventid/participants/:importtype/import',
  manualbidentry = '/auction/:eventid/manualbidentry',
  bidmaintenance = '/auction/:eventid/bidmaintenance',
  AuctionSummaryReportPage = '/auction/:eventid/AuctionSummaryReportPage',
  TransferPage = '/auction/:eventid/TransferPage',
  //AuctionSummaryReportDetailPage = '/event/:eventid/AuctionSummaryReportDetailPage',
  AuctionStatus = '/auction/:eventid/AuctionStatus',
  liveauction = '/auction/:eventid/liveauction',
  checkout = '/auction/:eventid/checkout',
  broadcastmessage = '/auction/:eventid/broadcastmessage',
  forcerefresh = '/auction/:eventid/forcerefresh',
  auction = '/auction/:id',
  event = '/event/:id',
  auctioncarouselsetup = '/auction/:id/carouselsetup',
  participantmerge = '/auction/:id/participantmerge'
}
const queryClient = new QueryClient()

const AutoAuth = ({ children }) => {
  useAutoAuth()
  return children
}


export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HttpInterceptor />
      <Provider store={store}>

        <div className={styles.app}>
          <Router>
              <Switch>
                <Route path={PagePathTypes.auth}>
                  <AuthPage />
                </Route>
                <Route path={PagePathTypes.adminlogin}>
                  <AdminAuthPage />
                </Route>
                <AutoAuth>
                <AuthGuard>
                  <Route exact path="/">
                    <Layout showOrgTitle={true}>
                      <Redirect to={PagePathTypes.home} />
                    </Layout>
                  </Route>
                  <Route exact path="/flexmonster">
                    <Layout showOrgTitle={false}>
                      <FlexMonsterPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.settings}>
                    <Layout showArrow={true} showSettings={false} showOrgTitle={true}>
                      <OrgSettingsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.home}>
                    <Layout showSettings={true} showOrgTitle={true}>
                      <HomePage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.terms}>
                    <Layout showSettings={false} showOrgTitle={false}>
                      <TermsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.auctionsettings}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      backArrowToAuction={false}
                      showAuctionUrl={true}
                    >
                      <AuctionSettingsForm isEdit={true} />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.newevent}>
                    <Layout
                        showHome={false}
                        showArrow={false}
                        showBackArrow={true}
                        showOrgTitle={true}
                    >
                      <AuctionSettingsForm />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.evtsettings}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showEventUrl={true}
                    >
                      <EvtSettingsForm isEdit={true} />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.newevt}>
                    <Layout
                        showHome={false}
                        showArrow={false}
                        showBackArrow={true}
                        showOrgTitle={true}
                    >
                      <EvtSettingsForm />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventsponsors}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}>
                      <EventSponsors />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventemailcustomization}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showEventUrl={true}>
                      <EventEmailCustomization />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.emassemailmaintenancetable}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventMassEmail />
                    </Layout>
                  </Route>
                  
                  <Route exact path={PagePathTypes.custommessages}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}>
                      <CustomMessagesPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.locationstags}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        backArrowToAuction={true}
                        showAuctionUrl={true}
                    >
                      <LocationsTagsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.auctionitems}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}
                    >
                      <AuctionItemsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventitems}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventItemsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventuserdefined}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventUserDefinedPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventtransactions}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventTransactionsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventdiscountcodes}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventDiscountCodesPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventactivitytickets}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventTicketsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventgroupmaintenance}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventGroupMaintenance />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.eventguestmaintenance}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showEventUrl={true}
                    >
                      <EventGuestMaintenanceTable />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.participants}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}
                    >
                      <ParticipantsPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.ParticipantsLinking}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      backArrowToAuction={true}
                      showAuctionUrl={true}
                    >
                      <ParticipantsListingPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.ParticipantLinking}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      backArrowToAuction={false}
                      showAuctionUrl={true}
                    >
                      <ParticipantLinkingPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.importParticpants}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}
                    >
                      <ImportPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.importTypeParticpants}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <ImportPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.manualbidentry}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}
                    >
                      <ManualBidEntryPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.bidmaintenance}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <BidMaintenancePage />
                    </Layout>
                  </Route>                  
                  <Route exact path={PagePathTypes.AuctionStatus}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <AuctionStatusPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.AuctionSummaryReportPage}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <AuctionSummaryReportPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.TransferPage}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <TransferPage />
                    </Layout>
                  </Route>
                  {/* <Route exact path={PagePathTypes.AuctionSummaryReportDetailPage}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}>
                      <AuctionSummaryReportDetailPage />
                    </Layout>
                  </Route> */}
                  <Route exact path={PagePathTypes.liveauction}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <LiveAuctionPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.checkout}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <CheckoutMainPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.broadcastmessage}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <BroadcastMessagePage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.forcerefresh}>
                    <Layout
                        showArrow={false}
                        showHome={true}
                        showSettings={false}
                        showMenu={true}
                        showBackArrow={true}
                        showOrgTitle={false}
                        showAuctionUrl={true}
                    >
                      <ForceRefreshPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.auction}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={false}
                      showOrgTitle={false}
                      showAuctionUrl={true}
                    >
                      <AuctionPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.event}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={false}
                      showOrgTitle={false}
                      showEventUrl={true}>
                      <EventPage />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.auctioncarouselsetup}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}
                    >
                      <AuctionCarouselSetup  />
                    </Layout>
                  </Route>
                  <Route exact path={PagePathTypes.participantmerge}>
                    <Layout
                      showArrow={false}
                      showHome={true}
                      showSettings={false}
                      showMenu={true}
                      showBackArrow={true}
                      showOrgTitle={false}
                      showAuctionUrl={true}
                    >
                      <ParticipantMergePage  />
                    </Layout>
                  </Route>
                </AuthGuard>
                </AutoAuth>
              </Switch>
          </Router>
        </div>
      </Provider>
    </QueryClientProvider>
  )
}
