import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, IconButton, TextField, Typography
} from '@material-ui/core'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import SelectInput from '../../components/common/inputs/SelectInput'
import TextInput from '../../components/common/inputs/TextInput'
import styles from './EventDiscountCodesTable.module.css'
import axios from "axios";
import {
  Grid,
} from "@mui/material";
import {formatMoney} from "../../util/formatHelpers";
import DateTimeInput from "../../components/common/inputs/DateTimeInput";
import {useQueryClient} from "react-query";


interface FormData {
  id: number
  discountactive: boolean
  discountamount: string
  discountcandelete: boolean
  discountcode: string
  discountdescription: string
  discountdateend: string
  discountid: number
  discountitemids: string
  discountitems: string
  discountmethod: string
  discountmethodid: number
  discountminimumpurchaseneeded: string
  discountminimumquantityneeded: number
  discountdatestart: string
  discounttype: string
  discounttypeid: number
}

export const EventDiscountCodesForm = ({ isOpen, setIsOpen, reloadForm, eventid, discountid }) => {
  const formMethods = useForm<FormData>()
  const queryClient = useQueryClient()
  const title = discountid > 0 ? "Edit Promo Code" : "Add Promo Code"
  const { setValue, watch, getValues, setError, clearErrors, setFocus } = formMethods
  const { reset } = formMethods
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [displayItemDiscount, setDisplayItemDiscount] = React.useState(true)
  const [displayPaymentMethodAmount, setDisplayPaymentMethodAmount] = React.useState(true)
  const discounttypeidWatch = watch('discounttypeid')
  const discountmethodidWatch = watch('discountmethodid')
  const discountamountWatch = watch('discountamount')

  const [dropdownData, setDropdownData] = React.useState({
    discounttypelist: [],
    itemlist: [],
    discountmethodlist: [],
  })
  const [discountData, setDiscountData] = React.useState({
    discountactive: true,
    discountamount: '',
    discountcandelete: true,
    discountcode: '',
    discountdescription: '',
    discountdateend: '',
    discountid: 0,
    discountitemids: "",
    discountitems: "",
    discountmethod: "",
    discountmethodid: 0,
    discountminimumpurchaseneeded: "",
    discountminimumquantityneeded: 1,
    discountdatestart: "",
    discounttype: '',
    discounttypeid: 0
  })

  useEffect(() => {
    console.log(discountid)
  }, [discountid])


  // useEffect(() => {
  //   var newData = {...discountData}
  //   console.log('amount watch')
  //   const discountObject = dropdownData?.discountmethodlist?.find(item => item.id === discountmethodidWatch)
  //   console.log(discountObject)
  //   if (discountObject?.code === 'M') {
  //     newData['discountamount'] = formatMoney(Number(discountamountWatch.replace('$', '')))
  //   }
  //   else {
  //     newData['discountamount'] = discountamountWatch + '%'
  //   }
  //   reset(newData)
  // }, [discountamountWatch])


  useEffect(() => {
    const discountObject = dropdownData?.discounttypelist?.find(item => item.id === discounttypeidWatch)
    if (discountObject?.code === 'I') {
      setDisplayItemDiscount(true)
    }
    else {
      setDisplayItemDiscount(false)
    }
  }, [discounttypeidWatch])

  useEffect(() => {
    const discountObject = dropdownData?.discountmethodlist?.find(item => item.id === discountmethodidWatch)
    // console.log(discountData)
    var newData = {}
    if (discountObject?.code === 'M') {
      setDisplayPaymentMethodAmount(true)
    }
    else {
      setDisplayPaymentMethodAmount(false)
    }
    // newData['discountamount'] = "0"
    // newData['discountmethodid'] = discountmethodidWatch
    // reset(newData)
  }, [discountmethodidWatch])

  useEffect(() => {
    getDropdownData()
    if (discountid > 0) {
      getDiscountData()
    }
    else {
      reset ({
          discountactive: true,
          discountamount: '',
          discountcandelete: true,
          discountcode: '',
          discountdescription: '',
          discountdateend: '',
          discountid: 0,
          discountitemids: "",
          discountitems: "",
          discountmethod: "",
          discountmethodid: 0,
          discountminimumpurchaseneeded: "",
          discountminimumquantityneeded: 1,
          discountdatestart: "",
          discounttype: '',
          discounttypeid: 0
      })
    }
  }, [discountid, isOpen])

  const getDropdownData = async () => {
    setLoading(true)
    const { data } = await axios.post('/evtdiscountcodesdropdowns', {eventid: eventid})
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    setDropdownData(parsedData)
    setLoading(false)
  }

  const getDiscountData = async () => {
    setLoading(true)
    const { data } = await axios.post('/evtdiscountcodes', {eventid: eventid, discountid: discountid})
    const parsedData = JSON.parse(data.jsonString)
    var newData = {...parsedData}
    console.log(newData)
    setDiscountData(newData)
    reset(newData)

    const discountObject = dropdownData?.discounttypelist?.find(item => item.id === newData.discounttypeid)
    if (discountObject?.code === 'I') {
      setDisplayItemDiscount(true)
    }
    else {
      setDisplayItemDiscount(false)
    }

    setLoading(false)
  }

  const onSubmit = async (formdata: FormData) => {
    console.log(formdata)
    const discountObject = dropdownData?.discountmethodlist?.find(item => item.id === discountmethodidWatch)
    var amount = formdata['discountamount']
    // if (discountObject?.code === 'M') {
    //   amount = amount.replace('$', '')
    // }


    var body = {
      eventid: eventid,
      delete: false,
      ...formdata,
      discountamount: amount,
      discountminimumpurchaseneeded: formdata['discountminimumpurchaseneeded'],
      discountminimumquantityneeded: Number(formdata['discountminimumquantityneeded']),
      discountitemids: formdata['discountitemids']?.toString(),
      discounttype: formdata['discounttype']
    }
    console.log(body)

    const { data } = await axios.put('/evtdiscountcodes', body)
    const parsedData = JSON.parse(data.jsonString)

    if (parsedData?.status === 'failure') {
      setDialogMsg(parsedData.message)
    }
    else {
      queryClient.invalidateQueries(['evtdiscountcodes'])
      setIsOpen(false)
    }
  }

  const closeDialog = () => {
    setDialogMsg("")
  }

  const getItemListOptions = () => {
    const dtRows = dropdownData.itemlist.map((item) => {
      return {value: item.itemid, label: item.itemname}
    })
    return dtRows
  }

  const getDiscountMethodOptions = () => {
    const dtRows = dropdownData?.discountmethodlist?.map((item) => {
      return {value: item.id, label: item.name}
    })
    return dtRows
  }

  const getDiscountTypeListOptions = () => {
    const dtRows = dropdownData?.discounttypelist?.map((item) => {
      return {value: item.id, label: item.name}
    })
    return dtRows
  }

  return (
    <>
      <Dialog open={isOpen} maxWidth="xl" fullWidth={true} className={styles.dialog}>
        <DialogTitle>{title}</DialogTitle>
        <LoadingBackdrop open={loading} />
        <>
          <Dialog
              open={dialogMsg.length > 0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
        <DialogContent>
          <HookForm
              methods={formMethods}
              onSubmit={onSubmit}
              className={styles.itemsForm}
              formProps={{ id: 'items-form' }}
          >

            <Grid container spacing={1}>
              <Grid item xs={6} className={styles.selectcontrol}>
                <TextInput
                    name="discountcode"
                    label="Promo Code"
                    textFieldProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol}>
                <TextInput
                    name="discountdescription"
                    label="Description"
                    textFieldProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <SelectInput
                    name="discounttypeid"
                    label="Item Discounted"
                    options={getDiscountTypeListOptions()}
                    textFieldProps={{ fullWidth: true }}
                    required={true}
                    includeNone={false}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <SelectInput
                    name="discountitemids"
                    label="Item Discounted"
                    options={getItemListOptions()}
                    textFieldProps={{ fullWidth: true }}
                    // required={!displayItemDiscount}
                    includeNone={false}
                    disabled={!displayItemDiscount}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <SelectInput
                    name="discountmethodid"
                    label="Promo Code Method"
                    options={getDiscountMethodOptions()}
                    textFieldProps={{ fullWidth: true }}
                    required={true}
                    includeNone={false}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <TextInput
                    name="discountamount"
                    label={displayPaymentMethodAmount ? "Amount" : "Percentage"}
                    endAdornment={displayPaymentMethodAmount ? "" : "%"}
                    startAdornment={displayPaymentMethodAmount ? "$" : ""}
                    required
                    textFieldProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <TextInput
                    name="discountminimumquantityneeded"
                    label="Min Quantity Needed"
                    required
                    textFieldProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <TextInput
                    name="discountminimumpurchaseneeded"
                    label="Minimum Purchase Amount Needed"
                    startAdornment={"$"}
                    required
                    textFieldProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <DateTimeInput
                    name="discountdatestart"
                    label="Start Date"
                    dateTimeProps={{ fullWidth: true }}
                    textFieldProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <DateTimeInput
                    name="discountdateend"
                    label="End Date"
                    dateTimeProps={{ fullWidth: true }}
                    textFieldProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={6} className={styles.selectcontrol} style={{marginTop: '10px'}}>
                <SelectInput
                    name="discountactive"
                    label="Status"
                    options={[{value: true, label: 'Active'}, {value: false, label: 'Inactive'}]}
                    textFieldProps={{ fullWidth: true }}
                    required={true}
                    includeNone={false}
                />
              </Grid>
            </Grid>
          </HookForm>
        </DialogContent>
        <DialogActions>
          <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
              variant="contained"
              className={styles.submitButton}
              type="submit"
              form="items-form">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
