import React, {useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import "./iframe.css"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Switch} from '@material-ui/core'
import { FormLabel } from '@mui/material'
import download from 'downloadjs';
import Logger from '../../../util/logger';

import HookForm from '../../../components/common/HookForm'
import SelectInput from '../../../components/common/inputs/SelectInput/SelectInput'
import TextInput from '../../../components/common/inputs/TextInput'
import styles from './CheckoutAllForm.module.css'
import {useEventState} from "../../../slices/eventSlice";
import {
  CheckoutHeaderData,
  CheckoutItemsData,
  useFetchCheckoutDetail,
  useAddCheckoutDetail,
  resendCheckout,
  generatePDF,
  checkout,
  checkoutDetailAdd,
  useFetchCheckoutDetailAdd, CheckoutDetailObject
} from "../../../queries/useFetchCheckoutDetail";
import {DataGridPro, GridValueFormatterParams} from "@mui/x-data-grid-pro";
import {formatMoney} from "../../../util/formatHelpers";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {ConfirmModal} from "../../../components/common/ConfirmModal";
import {useQueryClient} from "react-query";
import LoadingBackdrop from '../../../components/common/LoadingBackdrop'
import {AddItemForm} from "./AddItemForm";
import {statesArray} from "../../../util/formValidation";
import axios from "axios";

type FormData = {
    biddernumber: number
    company: string
    participantname: string
    first: string
    last: string
    address: string
    address2: string
    city: string
    state: string
    zip: string
    country: string
    phone: string
    email: string
    additionaldonation: number
    ccfeesexactamount: number
    totalamount: number
    paymentmethod: string
    sendemail: boolean
    participant: string
}

type AddItemFormData = {
  item: string
  price: number
  quantity: number
  total: number
}

const CheckoutAllForm = ({ dataRows, creditCardInfo, participantsData }) => {
  const queryClient = useQueryClient()
  const addItemDetail = useAddCheckoutDetail()
  const [cryptogram, setCryptogram] = useState('');
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedParticipant, setSelectedParticipants] = useState(null);

  const [subtotal, setSubtotal] = useState(0);
  const [ccfees, setCCFees] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [additionalDonation, setAdditionalDonation] = React.useState(0)
  const [totalAmount, setTotalAmount] = React.useState(0)
  const [showResendDialog, setShowResendDialog] = React.useState(false)
  const [showCheckoutDialog, setShowCheckoutDialog] = React.useState(false)
  const [isLoadingBackdrop, setisLoadingBackdrop] = React.useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", show: false })
  const [dialogAddItemObj, setAddItemDialogObj] = React.useState({ msg: "", show: false })
  const [invalidateIframe, setInvalidateIframe] = React.useState(1)

  useEffect(() => {
    // console.log(creditCardInfo)
    if (creditCardInfo && creditCardInfo.javascriptfile) {
      const script = document.createElement("script")
      script.src = creditCardInfo?.javascriptfile
      script.id =  "firstpay-script-cryptogram"
      script.type = "text/javascript"
      script.setAttribute("data-transcenter", creditCardInfo?.transcenterid)
      script.setAttribute("data-processor", creditCardInfo?.processorid)

      document.body.appendChild(script)
    }
  }, [creditCardInfo])

  useEffect(() => {
    window.addEventListener("message", processPayment, false)
    setCryptogram('')
    return () => {
      setCryptogram('')
      window.removeEventListener("message", processPayment)
    }
  }, [])

  useEffect(() => {
    // console.log(dataRows[0])

    const dataRowId = Number(dataRows[0]?.id)
    const participantId = Number(selectedParticipant?.id)
    const isEqual = dataRowId == participantId
    if (isEqual === false) {
      setSelectedParticipants(dataRows[0])
    }
  }, [dataRows])

  
  const processPayment = (e: any) => {
    if (e.data.firstpay) {
      const ccData = e.data
      if (ccData.type === 'newCryptogram') {
        setCryptogram(ccData.cryptogram)
      }
    }
  }

  const checkoutFormMethods = useForm<FormData>()
  const {reset, watch, getValues, setValue} = checkoutFormMethods
  const addItemMethods = useForm<AddItemFormData>()
  const [isAddItemsFormShowing, setIsAddItemsFormShowing] = useState(false)
  // const [checkoutDetailData, setCheckoutDetailData] = useState({
  //   status: '', message: '', checkoutheader: [], checkoutitems: []
  // })
  const [includeCCFees, setIncludeCCFees] = React.useState<boolean>(false);
  const handleCCFeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeCCFees(event.target.checked);
  };

  const [headerDataRows, setHeaderDataRows] = React.useState<CheckoutHeaderData>();
  const [itemDataRows, setItemDataRows] = React.useState([]);
  const eventState = useEventState()
  const paymentMethodWatch = watch('paymentmethod')
  const participantWatch = watch('participant')

  const { data: dataDetailAdd, isLoading: isDataDetailLoading } = useFetchCheckoutDetailAdd(Number(eventState.eventId))

  useEffect(() => {
    if (participantWatch) {
      const participantSelected = dataRows?.filter(item => item.key === participantWatch)
      if (participantSelected && participantSelected.length > 0) {
        if (participantSelected[0].id !== selectedParticipant.id) {
          console.log('participant watch --- WATCH THIS')
          console.log(participantSelected)
          setSelectedParticipants(participantSelected[0])
        }
      }
    }

    setInvalidateIframe(invalidateIframe+1)
    setCryptogram('')
    setValue('additionaldonation', 0)
    setAdditionalDonation(0)
    setSelectedItems([])
    setIncludeCCFees(false)
    setValue('paymentmethod', 'check')
  }, [participantWatch])

  const { data: checkoutDetailData, isLoading } = useFetchCheckoutDetail(Number(eventState.eventId), selectedParticipant?.key, selectedParticipant?.isreadonly)
  useEffect(() => {
    var additionalDonations = 0
    if (checkoutDetailData && checkoutDetailData?.checkoutheader && checkoutDetailData?.checkoutheader?.length > 0) {
      const headerRecords = getHeaderRecords()
      var headRecs = headerRecords[0]
      headRecs['participant'] = selectedParticipant?.key
      headRecs['paymentmethod'] = 'check'
      reset(headRecs)
      additionalDonations = dataRows[0].additionaldonation
      setHeaderDataRows(headerRecords[0])
    }

    if (checkoutDetailData && checkoutDetailData?.checkoutitems && checkoutDetailData?.checkoutitems?.length > 0) {
      const dataTempRows = getItemRecords()
      if (dataTempRows && dataTempRows?.length > 0) {
        setItemDataRows(dataTempRows)
      }

      const amount = checkoutDetailData?.checkoutitems?.reduce((acc: any, curr: any) => {
        if (dataRows?.checkoutstatus === 'Checked Out') {
          return acc + curr.amount * curr.quantity
        }
        else {
          return 0
        }
      }, 0)
      setSubtotal(amount)

      var ccAmount = 0
      if (amount > 0) {
        ccAmount += amount
      }
      if (additionalDonation > 0) {
        ccAmount += additionalDonations
      }
      setCCFees(ccAmount * (eventState?.ccfee / 100))
      setAdditionalDonation(additionalDonations)

      const total = (eventState?.allowpaymentoffees && includeCCFees) ?
          ccAmount + (ccAmount * (eventState?.ccfee / 100)) :
          ccAmount
      setTotalAmount(total)

      setQuantity(dataRows?.checkoutitems?.reduce((acc: any, curr: any) => {
        if (dataRows?.checkoutstatus === 'Checked Out') {
          return acc + curr.quantity
        }
        else {
          return 0
        }
      }, 0))
    }
    else {
      setItemDataRows([])
    }
  }, [checkoutDetailData])

  useEffect(() => {
      const amount = selectedItems?.reduce((acc: any, curr: any) => {
        return acc + curr.amount * curr.quantity
      }, 0)
      setSubtotal(amount)

      var ccAmount = 0
      if (amount > 0) {
        ccAmount += amount
      }
      if (additionalDonation > 0) {
        ccAmount += additionalDonation
      }
      setCCFees(((ccAmount) * (eventState?.ccfee / 100)))

      const total = (eventState?.allowpaymentoffees && includeCCFees && eventState?.ccfee > 0.000000001) ?
          ccAmount + (ccAmount * (eventState?.ccfee / 100)) :
          ccAmount
      setTotalAmount(total)

      setQuantity(selectedItems?.reduce((acc: any, curr: any) => {
        return acc + curr.quantity
      }, 0))
  }, [includeCCFees, selectedItems, additionalDonation])

  const getHeaderRecords = () => {
    return checkoutDetailData?.checkoutheader?.map((checkoutHeader: CheckoutHeaderData, i) => {
      return {
        biddernumber: checkoutHeader.biddernumber,
        company: checkoutHeader.company,
        participantid: checkoutHeader.participantid,
        participantname: checkoutHeader.participantname,
        first: checkoutHeader.first,
        last: checkoutHeader.last,
        address: checkoutHeader.address,
        address2: checkoutHeader.address2,
        city: checkoutHeader.city,
        state: checkoutHeader.state,
        zip: checkoutHeader.zip,
        country: checkoutHeader.country,
        phone: checkoutHeader.phone,
        email: checkoutHeader.email,
        additionaldonation: checkoutHeader.additionaldonation,
        ccfeesexactamount: ccfees,
        totalamount: checkoutHeader.totalamount,
        paymentmethod: checkoutHeader.paymentmethod,
        sendemail: checkoutHeader.sendemail,
        participant: participantWatch
      }
    })
  }

  const getItemRecords = () => {
    return checkoutDetailData?.checkoutitems?.map((checkoutItems, i) => {
      return {
        id: checkoutItems.historyid,
        itemid: checkoutItems.itemid,
        historyid: checkoutItems.historyid,
        itemnumber: checkoutItems.itemnumber,
        itemname: checkoutItems.itemname,
        quantity: checkoutItems.quantity,
        fairmarketvalue: checkoutItems.fairmarketvalue,
        amount: checkoutItems.amount,
      }
    })
  }

  const onSubmit = (formData: FormData) => {
    if (paymentMethodWatch === "CC" && cryptogram.length <= 0) {
      const errorMessage = "Please enter a valid credit card."
      setDialogObj({msg: errorMessage, show: true})
    }
    else {
      setShowCheckoutDialog(true)
    }
  }

  const columns = [
    {
      field: 'itemnumber',
      type: 'string',
      headerName: 'Item #',
      minWidth: 120
    },
    {
      field: 'itemname',
      type: 'string',
      headerName: 'Name',
      minWidth: 200
    },
    {
      field: 'quantity',
      type: 'number',
      headerName: 'QTY',
      minWidth: 100
    },
    // {
    //   field: 'fairmarketvalue',
    //   type: 'number',
    //   headerName: 'Fair Market Value',
    //   minWidth: 250,
    //   valueFormatter: (params: GridValueFormatterParams) => {
    //     return formatMoney(Number(params.value))
    //   },
    // },
    {
      field: 'amount',
      type: 'number',
      headerName: 'Amount',
      minWidth: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatMoney(Number(params.value))
      },
    }
  ]

  const handleAdditionalDonationChange = (newValue: number | null) => {
    if (newValue > 0) {
      setAdditionalDonation(newValue)
    }
    else {
      setAdditionalDonation(0)
    }
  }

  const handleCheckout = async () => {
    setisLoadingBackdrop(true)
    setShowCheckoutDialog(false)
    const company = getValues("company")
    const first = getValues("first")
    const last = getValues("last")
    const address = getValues("address")
    const address2 = getValues("address2")
    const city = getValues("city")
    const state = getValues("state")
    const zip = getValues("zip")
    const email = getValues("email")
    const sendemail = getValues("sendemail")

    var errorMessage = ""
    if (first.length <= 0) {
      errorMessage = "First name is required."
    }
    else if (last.length <= 0) {
      errorMessage = "Last name is required."
    }
    // else if (address.length <= 0) {
    //   errorMessage = "Address is required."
    // }
    // else if (city.length <= 0) {
    //   errorMessage = "City is required."
    // }
    // else if (state.length <= 0) {
    //   errorMessage = "State is required."
    // }
    else if (zip.length <= 0) {
      errorMessage = "Zip is required."
    }
    // else if (email.length <= 0) {
    //   errorMessage = "Email is required."
    // }
    // else if (selectedItems?.length <= 0) {
    //   errorMessage = "Please select at least 1 item."
    // }
    else if (paymentMethodWatch === "CC" && cryptogram.length <= 0) {
      errorMessage = "Please enter a valid credit card."
    }
    else if (Number(totalAmount.toFixed(2)) <= 0) {
      errorMessage = "Please select a checkout item or additional donation to proceed."
    }

    if (errorMessage.length > 0) {
      setDialogObj({msg: errorMessage, show: true})
    }
    else {
      const checkoutData = await checkout({
        eventid: Number(eventState.eventId),
        key: dataRows?.key,
        participantid: headerDataRows?.participantid,
        company: company,
        first: first,
        last: last,
        address: address,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
        email: email,
        additionaldonation: additionalDonation,
        ccfeesexactamount: includeCCFees ? eventState?.ccfee : 0, // Number(ccfees.toFixed(2)),
        totalamount: Number(totalAmount.toFixed(2)),
        paymentmethod: paymentMethodWatch,
        sendemail: sendemail,
        emerchtoken: cryptogram,
        subtotal: subtotal,
        itemid: selectedItems?.map((item) => {
          return item.itemid
        }),
        historyid: selectedItems?.map((item) => {
          return item.historyid
        })
      })

      if (checkoutData.status === 'success') {
        setAdditionalDonation(0)
        setDialogObj({msg: "Checkout was successfull.", show: true})
        setInvalidateIframe(invalidateIframe+1)
        setCryptogram('')
        queryClient.invalidateQueries(['checkout'])
        queryClient.invalidateQueries(['checkoutdetail'])
      }
      else {
        setDialogObj({msg: checkoutData.message, show: true})
      }
    }

    setisLoadingBackdrop(false)
  }

  const buildCheckoutConfirm = () => {
    var message
    const itemMessage = selectedItems?.map(item => {
      return (
          <>
            #{item.itemnumber} - {item.itemname} - Qty: {item.quantity} - ${item.amount}<br/>
          </>
      )
    })


    const first = getValues("first")
    const last = getValues("last")
    const name = first + ' ' + last

    const itemMessageFinal = <>
      <><b>{name}</b><br/><br/></>
      {(selectedItems && selectedItems?.length > 0) &&
          <>{itemMessage}<br/></>
      }
      {/*{additionalDonation &&*/}
      <>
        <b>Additional Donation:</b> {formatMoney(Number(additionalDonation ? additionalDonation : 0))}<br/>
      </>
      {/*}*/}
      {includeCCFees &&
          <>
            <b>Processing Fees:</b> {formatMoney(Number(ccfees))}<br/>
          </>
      }
      <b>Total Amount:</b> {formatMoney(Number(totalAmount.toFixed(2)))}
    </>

    message =
        <>
          <DialogContentText>{itemMessageFinal}</DialogContentText>
          <DialogContentText>Are you sure you want to purchase these items?</DialogContentText>
        </>

    return message
  }

  const handleResend = async () => {
    setisLoadingBackdrop(true)
    setShowResendDialog(false)
    const email = getValues("email")
    const resendData = await resendCheckout(Number(eventState.eventId), dataRows?.key, email)

    if (resendData.status === 'success') {
      setDialogObj({msg: "Receipt was successfully sent to " + email + ".", show: true})
    }
    else {
      setDialogObj({msg: resendData.message, show: true})
    }
    setisLoadingBackdrop(false)
  }

  const generatePDFClick = async () => {
    setisLoadingBackdrop(true)
    setShowResendDialog(false)
    const resendData = await generatePDF(Number(eventState.eventId), dataRows?.key)
    if (resendData) {
      download(resendData, eventState.eventName + '-' + dataRows?.key +  '-receipt.pdf', 'application/pdf');
    }
    setisLoadingBackdrop(false)
  }

  const getParticipantOptions = participantsData?.map(item => {
    return {value:item.key, label: item.participantinfo}
  }) || []

  const showCheckoutDialogMessage = (e) => {
    e.stopPropagation()
    if (Number(totalAmount.toFixed(2)) <= 0) {
      const errorMessage = "Please select a checkout item or additional donation to proceed."
      setDialogObj({msg: errorMessage, show: true})
    }
    else if (paymentMethodWatch === "CC" && cryptogram.length <= 0) {
      const errorMessage = "Please enter a valid credit card."
      setDialogObj({msg: errorMessage, show: true})
    }
    else {
      setShowCheckoutDialog(true)
    }
  }

  return (
      <>
        <div className={styles.root}>
          <LoadingBackdrop open={isLoadingBackdrop} />
          <AddItemForm addItemObjects={dataDetailAdd} dialogObj={dialogAddItemObj}  setDialogObj={setAddItemDialogObj} eventid={Number(eventState.eventId)} participantid={headerDataRows?.participantid} selectedParticipant={selectedParticipant} />
          {/*<CheckoutItemAddForm addItemObjects={dataDetailAdd} dialogObj={dialogAddItemObj}  setDialogObj={setAddItemDialogObj} eventid={Number(eventState.eventId)} participantid={headerDataRows?.participantid} key={checkoutDataObject?.key} isreadonly={checkoutDataObject?.isreadonly}/>*/}
          <ConfirmModal
              text="Are you sure you want to resend the receipt via email?"
              isOpen={showResendDialog}
              onClose={() => setShowResendDialog(false)}
              onConfirm={() => handleResend()}
              confirmText="Resend Receipt"
          />
          <ConfirmModal
              title={'You have selected the following items for checkout:'}
              text={buildCheckoutConfirm()}
              isOpen={showCheckoutDialog}
              onClose={() => setShowCheckoutDialog(false)}
              onConfirm={() => handleCheckout()}
              confirmText="Checkout"
          />
          <>
            <Dialog
                open={dialogObj.show}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dialogObj.msg}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { setDialogObj({msg: '', show: false})}}>Ok</Button>
              </DialogActions>
            </Dialog>
          </>
          <div className={styles.rootitems}>
          <HookForm
              methods={checkoutFormMethods}
              className={styles.form}
              formProps={{ id: 'checkout-form' }}
              onSubmit={onSubmit}>
              <SelectInput
                  name="participant"
                  label="Participant"
                  includeNone={false}
                  options={getParticipantOptions}
                  // rules={{
                  //     validate: (val) => (val?.length === 0 || val === 'none' || val === 0) ?  "Location is required." : clearErrors('location'),
                  // }}
                  // tooltipText={'The table number or other type of location grouping for the item.'}
              />

            <div className={styles.subrootitems} >
              <div className={styles.subrootcard} >
                <TextInput name="company" label="Company" disabled={dataRows?.isreadonly} />
                <TextInput name="first" label="First Name" required={true} disabled={dataRows?.isreadonly} />
                <TextInput name="last" label="Last Name" required={true} disabled={dataRows?.isreadonly} />
                <TextInput name="address" label="Address" required={false} disabled={dataRows?.isreadonly} />
                <TextInput name="address2" label="Address 2" disabled={dataRows?.isreadonly} />
                <TextInput name="city" label="City" required={false} disabled={dataRows?.isreadonly} />
                <SelectInput
                    name="state"
                    label="State"
                    includeNone={false}
                    disabled={dataRows?.isreadonly}
                    required={false}
                    options={statesArray.map((state) => {
                      return {label: state.value, value: state.value}
                    })}
                />
                <TextInput name="zip" label="Zip" required={true} disabled={dataRows?.isreadonly} />
                <TextInput name="email" label="Email" required={true}/>
                <div>
                  <Switch
                      color="primary"
                      name="sendemail"
                      defaultChecked
                      disabled={dataRows?.isreadonly} />
                  <FormLabel>Send Email</FormLabel>
                </div>


                <p>Subtotal: {formatMoney(subtotal | 0)} ({quantity | 0} items)</p>
                {(eventState.allowpaymentoffees) &&
                    <div>
                      <Switch
                          color="primary"
                          name="includefees"
                          onChange={handleCCFeesChange}
                          checked={includeCCFees}
                          disabled={dataRows?.isreadonly}
                      />
                      <FormLabel>Include CC Fees: {formatMoney(ccfees)}</FormLabel>
                    </div>
                }
                <CurrencyTextField
                    label={"Additional Donation"}
                    name={"additionaldonation"}
                    variant="outlined"
                    allowNegativeValue={false}
                    default={Number(dataRows?.additionaldonation)}
                    value={additionalDonation}
                    fullWidth={true}
                    currencySymbol="$"
                    outputFormat="number"
                    minimumValue={0}
                    decimalPlaces={0}
                    textAlign={"left"}
                    disabled={dataRows?.isreadonly}
                    required={false}
                    decimalCharacter="."
                    onChange={(event, value) => handleAdditionalDonationChange(value)}
                />
                <CurrencyTextField
                    label={"Total"}
                    name={"total"}
                    variant="outlined"
                    // default={totalAMount}
                    value={totalAmount}
                    fullWidth={true}
                    currencySymbol="$"
                    outputFormat="number"
                    textAlign={"left"}
                    disabled={true}
                    decimalCharacter="."
                    // onChange={(event, value) => handleAdditionalDonationChange(value)}
                />
                <SelectInput
                    name="paymentmethod"
                    label="Payment Method"
                    includeNone={false}
                    disabled={dataRows?.isreadonly}
                    defaultValue={'check'}
                    required={true}
                    options={[
                      { value: 'billme', label: 'Bill Me Later' },
                      { value: 'check', label: 'Check' },
                      { value: 'cash', label: 'Cash' },
                      { value: 'CC', label: 'Credit Card' }
                    ]}
                />
              </div>
              <div className={styles.subrootgrid} >
                <div className={styles.gridContainer}>
                  <div className={styles.grid}>
                    <DataGridPro
                        rows={itemDataRows}
                        columns={columns}
                        loading={false}
                        pagination={false as any}
                        disableSelectionOnClick={dataRows?.isreadonly}
                        checkboxSelection={!dataRows?.isreadonly}
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRowData = checkoutDetailData?.checkoutitems?.filter((item) =>
                              selectedIDs.has(item.historyid)
                          )
                          setSelectedItems(selectedRowData);
                        }}
                    />
                  </div>
                  {!dataRows?.isreadonly &&
                      <Button
                          variant="contained"
                          onClick={() => setAddItemDialogObj({msg: '', show: true})}>
                        Add Item
                      </Button>
                  }
                </div>
              </div>
            </div>
            <div className={styles.subrootcard}>
              <iframe
                    title="1stpayment-iframe"
                    id="firstpay-iframe"
                    key={invalidateIframe}
                    src={ creditCardInfo?.creditcardiframe }
                    data-transcenter-id={ creditCardInfo?.transcenterid }
                    data-processor-id={ creditCardInfo?.processorid }
                    data-transaction-type={ creditCardInfo?.transactiontype }
                    text-card-number={0}
                    data-manual-submit="false"
                    style={{display: (paymentMethodWatch === 'CC' ? 'inline' : 'none')}}
                />
            </div>
            {!dataRows?.isreadonly &&
                <>
                  <Button
                      variant="contained"
                      style={{ background: 'var(--success)' }}
                      onClick={(e) => {showCheckoutDialogMessage(e)}}
                  >
                    Complete Checkout
                  </Button>
                </>
            }
            {dataRows?.isreadonly &&
                <>
                  <Button
                      variant="contained"
                      style={{ background: 'var(--success)' }}
                      onClick={() => {setShowResendDialog(true)}}
                  >
                    Resend Receipt
                  </Button>
                  <Button
                      variant="contained"
                      style={{ background: 'var(--success)' }}
                      onClick={() => generatePDFClick()}
                  >
                    PDF
                  </Button>
                </>
            }
          </HookForm>
          </div>
        </div>
      </>
  )
}

export default CheckoutAllForm
