import cn from 'classnames'
import { FC, useEffect, useState } from 'react'

import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab/'

import styles from './ChipsList.module.css'

type Props = {
  className?: string
  list: { value: any; label: string }[]
  setList: Function
  defaultValues?: string[]
  options: { value: any; label: string }[]
  label: string
  tooltipText?: string
}

const ChipsList: FC<Props> = ({
  className,
  list,
  setList,
  defaultValues,
  options,
  label,
  tooltipText
}) => {
  const [value, setValue] = useState<{ value: any; label: string }>()
  const [inputValue, setInputValue] = useState('')

  // useEffect(() => {
  //   setList(defaultValues)
  // }, [defaultValues?.length])

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && value) {
      e.preventDefault()
      const foundItem = list.find((o) => o.value === value.value)
      if (!foundItem) {
        setList((c) => [...c, value])
      }
      setInputValue('')
      setValue({ value: '', label: '' })
    }
  }

  const onSelChange = (e) => {
    if (value) {
      e.preventDefault()
      const foundItem = list.find((o) => o.value === value.value)
      if (!foundItem) {
        setList((c) => [...c, value])
      }
    }
  }

  const onDelete = (index) => {
    setList(list.filter((v, i) => i !== index))
  }

  return (
    <div className={cn(styles.root, className)}>
      <Autocomplete
        clearOnBlur
        options={options}
        getOptionLabel={(option) => option.label}
        renderOption={(option) => option.label}
        value={value}
        onChange={(event: any, option: any) => {
          setValue(option)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            title={tooltipText}
            variant="outlined"
            onKeyPress={onKeyPress}
            onSelect={onSelChange}
            size="small"
          />
        )}
      />
      <div className={styles.chipContainer}>
        {list?.map((option, i) => {
          return (
            <Chip
              key={option.value}
              label={option.label}
              onDelete={() => onDelete(i)}
              style={{ marginRight: '0.25rem', marginTop: '0.25rem' }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ChipsList
