/* eslint-disable react-hooks/exhaustive-deps */
import { sanitize } from 'dompurify'


import { Editor, EditorTools } from "@progress/kendo-react-editor";
import React, {useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
   ButtonProps } from '@material-ui/core'
  import { styled } from '@mui/material/styles';
import { ExpandMore } from '@material-ui/icons'
import HookForm from '../../components/common/HookForm'
import LoadingBackdrop from '../../components/common/LoadingBackdrop'
import { useCustomMessagesMutation } from '../../mutations/useCustomMessagesMutation'
import { useFetchEventMessages } from '../../queries/events/useFetchEventMessages'
import { useAutoEvent } from '../../queries/useAutoEvent'
import { EventState, setEvent, useEventState } from '../../slices/eventSlice'
import styles from './CustomMessagesPage.module.css'

type FormData = {
  email: string
  password: string
  siteCode: string
}

interface Params {
  id: string
}

const CustomMessagesPage = () => {
  const richTextTools = [
    [EditorTools.Bold, EditorTools.Italic, EditorTools.Underline, EditorTools.Strikethrough],
    // [EditorTools.Subscript, EditorTools.Superscript],
    EditorTools.ForeColor,
    EditorTools.BackColor,
    [EditorTools.CleanFormatting],
    [EditorTools.AlignLeft, EditorTools.AlignCenter, EditorTools.AlignRight, EditorTools.AlignJustify],
    [EditorTools.Indent, EditorTools.Outdent],
    [EditorTools.OrderedList, EditorTools.UnorderedList],
    // [EditorTools.NumberedList, EditorTools.BulletedList],
    EditorTools.FontSize,
    EditorTools.FontName,
    EditorTools.FormatBlock,
    [EditorTools.SelectAll],
    [EditorTools.Undo, EditorTools.Redo],
    [EditorTools.Link, EditorTools.Unlink, EditorTools.InsertImage, EditorTools.ViewHtml],
    [EditorTools.InsertTable, EditorTools.InsertFile],
    // [EditorTools.Pdf, EditorTools.Print],
    [EditorTools.AddRowBefore, EditorTools.AddRowAfter, EditorTools.AddColumnBefore, EditorTools.AddColumnAfter],
    [EditorTools.DeleteRow, EditorTools.DeleteColumn, EditorTools.DeleteTable],
    [EditorTools.MergeCells, EditorTools.SplitCell],
  ]

  const { id } = useParams<Params>()
  const [dialogMsg, setDialogMsg] = React.useState('')
  useAutoEvent(id)
  const eventState = useEventState()

  const { data, isLoading } = useFetchEventMessages(Number(eventState.eventId))
  console.log(
    '🚀 ~ file: CustomMessagesPage.tsx ~ line 54 ~ CustomMessagesPage ~ data',
    data
  )

  const formMethods = useForm<FormData>({
    mode: 'onChange'
  })

  const [preEventValue, setPreEventValue] = useState('')
  const [loginSummaryValue, setLoginSummaryValue] = useState('')
  const [welcomeValue, setWelcomeValue] = useState('')
  const [receiptHeaderValue, setReceiptHeaderValue] = useState('')
  const [receiptFooterValue, setReceiptFooterValue] = useState('')
  const [winningBidHeaderValue, setWinningBidHeaderValue] = useState('')
  const [winningBidFooterValue, setWinningBidFooterValue] = useState('')
  const [contactUsText, setContactUsText] = useState('')

  useEffect(() => {
    setPreEventValue(data?.preeventtext)
    setLoginSummaryValue(data?.mobilelogintext)
    setWelcomeValue(data?.mobilewelcometext)
    setReceiptHeaderValue(data?.emailreceipttextheader)
    setReceiptFooterValue(data?.emailreceipttextfooter)
    setWinningBidHeaderValue(data?.winningbidtextheader)
    setWinningBidFooterValue(data?.winningbidtextfooter)
    setContactUsText(data?.contactustext)
  }, [data])
  const FloatingButton = styled(Button)<ButtonProps>(({ theme }) => ({
    margin:'auto',
    top: 'auto',
    //  right: '40%',
    bottom: 20,
     left: '25%',
    position: 'fixed',
    width: '50%'
  }))
  const messagesMutation = useCustomMessagesMutation()
  const onSubmit = () => {
    messagesMutation.mutate(
      {
        eventid: Number(eventState.eventId),
        preeventtext: preEventValue,
        mobilelogintext: loginSummaryValue,
        mobilewelcometext: welcomeValue,
        emailreceipttextheader: receiptHeaderValue,
        emailreceipttextfooter: receiptFooterValue,
        winningbidtextheader: winningBidHeaderValue,
        winningbidtextfooter: winningBidFooterValue,
        contactustext: contactUsText
      },
      {
        onSuccess: (data) => {
          const json = JSON.parse(data.data.jsonString)
          if (json.status === 'failure') {
            setDialogMsg(json.message)
          } else {
            setDialogMsg('Customization settings successfully saved.')
          }
        }
      }
    )
  }

  const closeDialog = () => {
    setDialogMsg('')
  }

  return (
    <div className={styles.root}>
      <h1>Page/Email Customization</h1>
      <>
        <Dialog
          open={dialogMsg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <HookForm
        methods={formMethods}
        onSubmit={onSubmit}
        className={styles.form}>
        <LoadingBackdrop open={isLoading || messagesMutation.isLoading} />

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Pre-Event Banner Page Header</h2>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <h5>This content will show in place of the login screen before the Event Open date.</h5>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 200 }}
                  value={preEventValue}
                  onChange={(eventState) => {
                    setPreEventValue(eventState.html)
                  }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Event Login Page Header</h2>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <h5>This content will show above the login fields while the event is open.</h5>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 200 }}
                  value={loginSummaryValue}
                  onChange={(eventState) => {
                    setLoginSummaryValue(eventState.html)
                  }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Event Welcome Page Body</h2>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <h5>This content will show in the middle of the page below the countdown timer and buttons after a user logs in.</h5>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 330 }}
                  value={welcomeValue}
                  onChange={(eventState) => {
                    setWelcomeValue(eventState.html)
                  }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Email Receipt</h2>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <h3>Header</h3>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 330 }}
                  value={receiptHeaderValue}
                  onChange={(eventState) => {
                    setReceiptHeaderValue(eventState.html)
                  }}
              />
              <h3>Footer</h3>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 200 }}
                  value={receiptFooterValue}
                  onChange={(eventState) => {
                    setReceiptFooterValue(eventState.html)
                  }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Winning Bid Email</h2>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <h3>Header</h3>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 200 }}
                  value={winningBidHeaderValue}
                  onChange={(eventState) => {
                    setWinningBidHeaderValue(eventState.html)
                  }}
              />
              <h3>Footer</h3>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 200 }}
                  value={winningBidFooterValue}
                  onChange={(eventState) => {
                    setWinningBidFooterValue(eventState.html)
                  }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
            <h2>Contact Us Page</h2>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.inputsContainer}>
              <Editor
                  tools={richTextTools}
                  contentStyle={{ height: 200 }}
                  value={contactUsText}
                  onChange={(eventState) => {
                    setContactUsText(eventState.html)
                  }}
              />
            </div>
          </AccordionDetails>
        </Accordion>

        <FloatingButton type="submit" variant="contained" className={styles.button}>
          Save
        </FloatingButton>
      </HookForm>
    </div>
  )
}

export default CustomMessagesPage
