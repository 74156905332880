import React, {useEffect} from 'react'
import {Grid} from "@mui/material";

import styles from "./ForceRefreshPage.module.css";
import SelectInput from '../../components/common/inputs/SelectInput'
import {useFetchParticpants} from "../ParticipantsPage/useFetchParticipants";
import {useParams} from "react-router";
import HookForm from "../../components/common/HookForm";
import TextInput from "../../components/common/inputs/TextInput";
import {useForm} from "react-hook-form";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import {useForceRefresh, useForceLogout} from "./useForceRefresh";
import Paper from "@material-ui/core/Paper";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";

interface Params {
    eventid: string
}

const ForceRefreshPage = () => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            button: {
                margin: theme.spacing(1)
            },
            paper: {
                padding: theme.spacing(2),
                spacing: theme.spacing(100),
                paddingLeft: 10,
                marginLeft: 10,
                marginRight: 10,
                textAlign: 'left',
                fontSize: 25,
                color: theme.palette.text.secondary
            }
        })
    )

    const classes = useStyles()
    const { eventid } = useParams<Params>()
    const formMethods = useForm<FormData>()
    const forceRefresh = useForceRefresh()
    const forceLogout = useForceLogout()
    const { data: particpants, isLoading } = useFetchParticpants(eventid)
    const [particpantsList, setParticpantsList] = React.useState([{ value: '', label: '' }])
    const [dialogMsg, setDialogMsg] = React.useState("")

    useEffect(() => {
        let selectList = []
        if (particpants && particpants.length > 0) {
            selectList = particpants?.filter((item) => item.email && item.lastlogindate).map((obj) => {
                return { value: obj?.email, label: obj?.email }
            })
        }
        selectList.unshift({ value: "All", label: "All" })
        setParticpantsList(selectList)
    }, [particpants])

    const onSubmit = async (data: any) => {
        let body = {
            eventid: eventid
        }

        if (data.email !== 'All') {
            body['email'] = data.email
        }

        await forceRefresh.mutateAsync(body, {
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                if (json.status === "failure") {
                    setDialogMsg(json.message)
                }
                else {
                    setDialogMsg('Force Refresh was sent successfully.')
                }
            }
        })
    }

    const onSubmitForceLogout = async (data: any) => {
        let body = {
            eventid: eventid
        }

        if (data.email !== 'All') {
            body['email'] = data.email
        }

        await forceLogout.mutateAsync(body, {
            onSuccess: (data) => {
                const json = JSON.parse(data.data.jsonString)
                if (json.status === "failure") {
                    setDialogMsg(json.message)
                }
                else {
                    setDialogMsg('Force Logout was sent successfully.')
                }
            }
        })
    }

    const closeDialog = () => {
        setDialogMsg("")
    }

    return (
        <div className={styles.root}>
            <>
                <Dialog
                    open={dialogMsg.length > 0}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </>

            <h2>Force Refresh</h2>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmit}
                    className={styles.tagsForm}
                    formProps={{ id: 'force-refresh-form' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={styles.selectcontrol}>
                            <SelectInput
                                name={'email'}
                                label={'To'}
                                options={particpantsList}
                                includeNone={false}
                                defaultValue={'All'}
                                // required={fieldData.required}
                            />
                        </Grid>
                        <Grid item xs={12} className={styles.selectcontrol}>
                            <Button
                                variant="contained"
                                className={styles.submitButton}
                                type="submit"
                                form="force-refresh-form">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </HookForm>
                <HookForm
                    methods={formMethods}
                    onSubmit={onSubmitForceLogout}
                    className={styles.tagsForm}
                    formProps={{ id: 'force-logout-form' }}>

                <Grid container spacing={2}>
                        <Grid item xs={12} className={styles.selectcontrol}>
                            <h2>Force Logout</h2>
                        </Grid>
                        <Grid item xs={12} className={styles.selectcontrol}>
                            <SelectInput
                                name={'email'}
                                label={'To'}
                                options={particpantsList}
                                includeNone={false}
                                defaultValue={'All'}
                                // required={fieldData.required}
                            />
                        </Grid>
                        <Grid item xs={12} className={styles.selectcontrol}>
                            <Button
                                variant="contained"
                                className={styles.submitButton}
                                type="submit"
                                form="force-logout-form">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </HookForm>
        </div>
    )
}

export default ForceRefreshPage
