import React, { useState } from 'react'

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton} from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import { LocationsForm } from './LocationsForm'
import styles from './LocationsTable.module.css'
import { useEventState } from "../../../slices/eventSlice";
import {useLocationTags} from "../../../mutations/useLocationTags";
import dayjs from "dayjs";
import {DataGridPro, GridValueFormatterParams, GridCellParams} from "@mui/x-data-grid-pro";

const LocationsTable = (locations) => {
  const [gridParams, setGridParams] = useState({ id:0,  location: '', opendate: '', closeddate: '', active: true})
  const logationsTagsMutation = useLocationTags()
  const eventState = useEventState()
  const data = locations?.locations
  const [isFormShowing, setIsFormShowing] = useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")

  var rows = []
  if (data) {
    rows = data.map((v, i) => {
      return {
        location: v.name,
        opendate: v.opendate,
        closeddate: v.closeddate,
        active: v.active,
        id: Number(v.id)
      }
    })
  }

  const handleAdd = () => {
    setGridParams(null)
    setIsFormShowing(true)
  }

  const handleEdit = (params: GridCellParams) => {
    setGridParams({
      id:Number(params.id),
      location: params.getValue(params.id, 'location').toString(),
      opendate: params.getValue(params.id, 'opendate').toString(),
      closeddate: params.getValue(params.id, 'closeddate').toString(),
      active: Boolean(params.getValue(params.id, 'active'))})
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.getValue(params.id, 'location').toString() + "\"?",
      id: Number(params.id)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var finaldata = {
      "id": id,
      "eventid": Number(eventState.eventId),
      "delete": true,
      "type": "location"
    }
    await logationsTagsMutation.mutateAsync({
      ...finaldata
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "failure") {
          setDialogObj({
            msg: '',
            id: 0
          })
          setDialogMsg(json.message)
        }
        else {
          setDialogObj({
            msg: '',
            id: 0
          })
        }
      }
    })
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns = [
    {
      field: 'Edit',
      renderHeader: () => {
        return (
          <IconButton
            className={styles.submitButton}
            onClick={ () => handleAdd() }
            size="small">
            <Add />
          </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
          <Edit />
        </IconButton>
      )
    },
    {
      field: 'location',
      type: 'string',
      headerName: 'Location',
      width: 200
    },
    {
      field: 'opendate',
      type: 'dateTime',
      headerName: 'Open Date',
      valueFormatter: (params: GridValueFormatterParams) => {
        return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
      },
      width: 200
    },
    {
      field: 'closeddate',
      type: 'dateTime',
      headerName: 'Close Date',
      valueFormatter: (params: GridValueFormatterParams) => {
        return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
      },
      width: 200
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        return params.value ? 'Active' : 'Inactive'
      }
    },
    {
      field: 'action',
      headerName: 'Delete',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
          <Delete />
        </IconButton>
      )
    }
  ]

  return (
    <>
      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <LocationsForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} />
      <DataGridPro
        autoHeight
        rows={rows}
        columns={columns}
        loading={false}
        pagination={false as any}
      />
    </>
  )
}

export default LocationsTable
