import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { parseJwt } from '../pages/AuthPage/LoginForm/useLoginMutation'
import { useFetchOrgSettings } from '../pages/OrgSettingsPage/useFetchOrgSettings'
import {AuthToken, loginUser, updateUser} from '../slices/authSlice'
import axios from "axios";
import {User} from "./useUsers";

export const useAutoAuth = () => {
  const dispatch = useDispatch()
  const token = localStorage.getItem('tokenadmin')

  const parsedToken: AuthToken = ((token && token !== 'undefined') ? parseJwt(token) : null)
  const history = useHistory()

  return useFetchOrgSettings({
    userid: parsedToken?.userid,
    onSuccess: () => {
      if (parsedToken && parsedToken.userid) {
        dispatch(loginUser(parsedToken))

        axios.post('/usersetup', {userid: parsedToken.userid}).then((response) => {
          const parsedData = JSON.parse(response?.data?.jsonString)
          if (parsedData && parsedData?.users && parsedData?.users.length > 0) {
            const user = parsedData.users[0]
            dispatch(updateUser(user))
          }
        })

      } else {
        history.push('/auth/login')
      }
    },
    onError: () => {
      history.push('/auth/login')
    },
    enabled: Boolean(history)
  })
}
