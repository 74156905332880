import React, {useEffect, useState} from 'react'

import {Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridToolbarExport, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import styles from './EventGuestMaintenance.module.css'
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {Grid, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import {useQueryClient} from "react-query";
import {useFetchGuests} from "../../queries/events/useEventGuestFetch";
import dayjs from "dayjs";
import axios from "axios";
import {GenericForm} from "../GenericForm/GenericForm";
import {GuestForm} from "./GuestForm";
import {useFetchTickets} from "../../queries/events/useEventTicketsFetch";

const eventItemsDefaults = {
  id: 0,
  control: 0,
  fieldname: '',
  fieldtype: '',
  displayvalue: '',
  active: true,
  uditem: '',
  items: '',
  itemids: [],
  displayforallguest: false,
}

interface Params {
  eventid: string
}

const EventGuestMaintenanceTable = () => {
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)
  const queryClient = useQueryClient()
  const [gridParams, setGridParams] = useState(eventItemsDefaults)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [reloadForm, setReloadForm] = useState<number>(0)
  const [ticketItem, setTicketItem] = useState("-1")
  const [rows, setRows] = useState([])
  const [isFormShowing, setIsFormShowing] = useState(false)
  const { data: guestsData, isLoading } = useFetchGuests( Number(eventid) )

  const { data: ticketsData, isLoading: isLoadingTickets } = useFetchTickets( Number(eventid), ticketItem )


  useEffect(() => {
    console.log(guestsData)
    if (guestsData) {
      setRows(guestsData.registrations.map((item) => {
        return { id: item.registrationid, ...item}
      }))
      if (guestsData.selectedticketitemid) {
        setTicketItem(guestsData.selectedticketitemid)
      }
    }
  }, [guestsData])

  useEffect(() => {
    // queryClient.invalidateQueries(['EventGuest', Number(eventid), ticketItem])
    getGuestTicketData()
  }, [ticketItem])

  const getGuestTicketData = async () => {
    axios.post('/evtactivityregistrations', {eventid: Number(eventid), ticketid: ticketItem}, {responseType: "json"}).then(response => {
      const parsedData = JSON.parse(response.data.jsonString)
      console.log(parsedData)
      // setTicketData(parsedData)
    })
  }

  const handleEdit = (params: GridCellParams) => {
    setReloadForm(reloadForm+1)
    const purchaser = rows?.filter((item) => item.id === Number(params.id))[0]

    console.log(purchaser)

    setGridParams(purchaser)
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.row.registrationname + "\"?",
      id: Number(params.id)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var finaldata = {
      "eventid": Number(eventid),
      "registrationid": id,
      "delete": true
    }

    const { data } = await axios.delete('/evtactivityregistrations', {data: finaldata})
    const json = JSON.parse(data.jsonString)

    if (json.status === "failure") {
      setDialogObj({
        msg: '',
        id: 0
      })
      setDialogMsg(json.message)
    }
    else {
      queryClient.invalidateQueries(['EventGuest', eventid])
    }
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
            <Edit />
          </IconButton>
      )
    },
    {
      field: 'id',
      hide: true,
      sortable: false,
    },
    {
      field: 'purchasernames',
      type: 'string',
      headerName: 'Purchaser',
      minWidth: 280
    },
    {
      field: 'registrationname',
      type: 'string',
      headerName: 'Name',
      minWidth: 320
    },
    {
      field: 'registrationemail',
      type: 'string',
      headerName: 'Email',
      minWidth: 320
    },
    {
      field: 'ticketnames',
      type: 'string',
      headerName: 'Ticket',
      minWidth: 180
    },
    {
      field: 'registrationphone',
      type: 'string',
      headerName: 'Phone',
      minWidth: 180
    },
    {
      field: 'registrationsource',
      type: 'string',
      headerName: 'Source',
      minWidth: 120
    },
    {
      field: 'registrationadded',
      type: 'string',
      headerName: 'Added',
      minWidth: 220,
      valueFormatter: (params: GridValueFormatterParams) => {
        return dayjs(params.value as string).format('MM-DD-YYYY hh:mm A')
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      width: 70,
      sortable: false,
      renderCell: (params) => {
        return (
            <div>
              {params.row.candelete &&
                  <IconButton
                      className={styles.button}
                      onClick={() => {
                        handleDelete(params)
                      }}
                  >
                    <Delete/>
                  </IconButton>
              }
            </div>
        )
      }
    }
  ]
  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridSortedRowIdsSelector(apiRef);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
      options.allColumns = true;
      apiRef.current.exportDataAsCsv(options);
    }

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    const getTicketItemOptions = () => {
      console.log(ticketsData)
      return ticketsData?.ticketitemlist?.map(item => (
          <MenuItem key={item.ticketitemid} value={item.ticketitemid}>
            {item.ticketitemname}
          </MenuItem>
      ))
    }

    const handleSelectionChange = async (event: SelectChangeEvent) => {
      setTicketItem(event.target.value as string);
    }

    return (
        <GridToolbarContainer >
          <>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-begin"
                  alignItems="center"
                  // border={2}
                  xs={3} sm={4} md={4}
              >
                <Grid item xs={12}>
                  <Select
                      onChange={handleSelectionChange}
                      style={{ width: '100%', height:'30px' }}
                      value={ticketItem}>
                    {getTicketItemOptions()}
                  </Select>
                </Grid>
              </Grid>
              <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={2} sm={6} md={8}
              >
                <Button
                    {...buttonBaseProps}
                    onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                >
                  EXPORT
                </Button>
              </Grid>
            </>
          </GridToolbarContainer>
    )
  }

  return (
      <div className={styles.root}>
        <h1>Guest Maintenance</h1>
      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>
      <GuestForm isGuestOpen={isFormShowing} setIsGuestOpen={setIsFormShowing} endpoint={'evtactivityregistrations'} eventId={eventid} genericid={gridParams.id} />
      <DataGridPremium
        autoHeight
        rows={rows }
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
      />
    </div>
  )
}

export default EventGuestMaintenanceTable
