import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'

export interface  ManualBidDataObject {
    status: string
    message: string
    manualbiditemlist: ManualBidData[]
    bidderlist: BidderListData[]
}

export interface BidderListData {
    rownumber: number
    bidderparticipantid: number
    biddername: string
}

export interface  ManualBidData {
    rownumber: number
    itemid: number
    itemnumber: string
    name: string
    tags: string
    itemtype: string
    numberofbids: number
    startingbid: number
    bidincrement: number
    lastbid: number
    open: string
    closing: string
    opencd: string
    closingcd: string
    buyitnowavailable: boolean
    buyitnowonly: boolean
    allowautobid: boolean
    buyitnowprice: number
    bidstatus: string
    quantityavailable: number
    location: string
    unlimitedquantity: boolean
    maxpurchasequantity: number
    biddername: string
    biddernumber: number
    biddersource: string
    imageurl: string
}

export const ManualBidDataDefaults = {
    rownumber: 0,
    itemid: 0,
    itemnumber: null,
    name: null,
    tags: null,
    itemtype: null,
    numberofbids: 0,
    startingbid: 0,
    bidincrement: 0,
    lastbid: 0,
    open: null,
    closing: null,
    opencd: null,
    closingcd: null,
    buyitnowavailable: false,
    buyitnowonly: false,
    allowautobid: false,
    buyitnowprice: 0,
    bidstatus: null,
    quantityavailable: 0,
    location: null,
    unlimitedquantity: false,
    maxpurchasequantity: 0,
    biddername: null,
    biddernumber: 0,
    biddersource: null,
    imageurl: null,
}

export const useFetchManualBid = (eventId: number) => {
    return useQuery<ManualBidDataObject>(
        ['manualbid', eventId],
        () => globalFetcher(eventId),
        {
            staleTime: 100,
            keepPreviousData: false
        }
    )
}

export const useUpdateManualBid = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`manualbid`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['manualbid'])
        }
    })
}

export const useDeleteManualBid = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.delete(`manualbid`, {data: body}), {
        onSuccess: () => {
            queryClient.invalidateQueries(['manualbid'])
        }
    })
}

export const globalFetcher = async (eventId: number) => {
    const { data } = await axios.post('/manualbid', {
        eventid: eventId
    })
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as ManualBidDataObject
}
