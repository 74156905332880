import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import download from 'downloadjs';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  AddShoppingCart as AddShoppingCartIcon,
  Message as MessageIcon,
  Refresh as RefreshIcon,
  Assessment as AssessmentIcon,
  BurstMode as BurstIcon,
  Gavel as GavelIcon,
  LocationOn as LocationIcon,
  People as PeopleIcon,
  RemoveShoppingCart as RemoveShoppingCartIcon,
  SettingsOutlined as SettingsIcon,
  ShoppingCart as ShoppingCartIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Web as WebIcon
} from '@material-ui/icons'

import { useAutoEvent } from '../../queries/useAutoEvent'
import { EventState, setEvent, useEventState } from '../../slices/eventSlice'
import styles from './AuctionPage.module.css'
import {useAllowEventRefresh} from "../../slices/authSlice";
import {setAuctionURL, setEventURL} from "../../slices/eventSlice"
import {useAuctionInfo, useEventInfo} from '../../queries/useFetchAuctionInfo'
import dayjs from "dayjs";
import axios from "axios";
import {User} from "../../queries/useUsers";
import React from "react";
import {useQueryClient} from "react-query";
import {useDispatch} from "react-redux";

interface Params {
  id: string
}

const AuctionPage = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = useParams<Params>()
    const queryClient = useQueryClient()
    useAutoEvent(id)
    const eventState = useEventState()
    const allowRefresh = useAllowEventRefresh()
    const {data} = useAuctionInfo(id)
    const {data: eventData} = useEventInfo(id)
    const [dialogMsg, setDialogMsg] = React.useState("");
    console.log(data)
    console.log(eventData)
    dispatch(setEventURL(eventData.eventlink))
    dispatch(setAuctionURL(data.auctionlink))
    const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1)
      },
      paper: {
        padding: theme.spacing(2),
        spacing: theme.spacing(100),
        paddingLeft: 10,
        marginLeft: 10,
        marginRight: 10,
        textAlign: 'left',
        fontSize: 25,
        color: theme.palette.text.secondary
      },
      info: {
        padding: theme.spacing(2),
        spacing: theme.spacing(100),
        paddingLeft: 10,
        marginLeft: 10,
        marginRight: 10,
        textAlign: 'left',
        flexGrow: 1
      }
    })
    )
    const generateWinningBidsClick = async () => {
      axios.post('/auctionitemstatusreport', {eventid: eventState.eventId}, {responseType: "blob"}).then(response => {
        var filename = eventState.eventName + '-winningBids.xlsx';
        var disposition = response.headers['content-disposition']
        var contentType = response.headers['content-type']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        if (response.data) {
          download(response.data, filename, contentType);
        }
      })
    }
  
    const clickReset = async () => {
        const { data } = await axios.post('/auctionresetnotifications', {eventid: eventState.eventId})
        const parsedData = JSON.parse(data.jsonString)
        if (parsedData.status === 'failed') {
            setDialogMsg(parsedData.message)
        }
        else {
            queryClient.invalidateQueries(['auctioninformation', eventState.eventId])
            queryClient.invalidateQueries(['eventinformation', eventState.eventId])
        }
    }

    const handleClose = () => {
        setDialogMsg("")
    };

    const classes = useStyles()

  return (
    <div className={styles.root}>
        <>
            <Dialog
                open={dialogMsg.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        <Grid container spacing={1}>
          <Grid item xs={4}  style={{ display: 'flex', flexDirection: 'column' }}>
            {data && (
              <>
                <Paper className={classes.info}> <p><b>Bidding Opens:</b> {dayjs(data.biddingopens as string).format('MMMM DD, YYYY hh:mm A')}</p>
                <p><b>Bidding Closes:</b> {dayjs(data.biddingcloses as string).format('MMMM DD, YYYY hh:mm A')}</p>
                <p></p></Paper>
              </>
            )}
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
            {data && (
              <>
                <Paper className={classes.info}> <p><b>Authorized Bidders:</b> {data.authorizedbidders}</p> <p><b>Pending Registrations:</b> {data.pendingregistrations}</p> <p></p></Paper>
              </>
            )}
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
            {data && (
              <>
                <Paper className={classes.info}><p><b>Open Bids:</b> ${data.openbidsamount}</p> <p><b>Pending Checkout:</b> ${data.pendingcheckoutamount}</p> <p><b>Collected:</b> ${data.collectedamount} </p></Paper>
              </>
            )}
          </Grid>
        </Grid>
        {data.showresetbutton &&
            <Grid xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={3} >
                            <Button
                                variant="contained"
                                size="large"
                                style={{ background: '#FF0000', color: '#ffffff', width: '100%'}}
                                className={classes.button}
                                onClick={() => {
                                    clickReset()
                                }}>
                                Reset Notification Status
                            </Button>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography component="div">
                                <Box sx={{ fontWeight: 'bold', m: 1, fontStyle: 'bold' }}>
                                    {data.resetmessage}
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        }

      <Grid item xs={12}>
        <Paper className={classes.paper}>Auction Setup</Paper>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/settings')
            }}
            startIcon={<SettingsIcon />}>
            Settings
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/sponsors')
            }}
            startIcon={<BurstIcon />}>
            Sponsors
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<WebIcon />}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/custommessages')
            }}>
            Page/Email Customization
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<LocationIcon />}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/locationstags')
              history.push('/auction/' + eventState.eventId + '/locationstags')
            }}>
            Dropdown Fields
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<GavelIcon />}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/auctionitems/')
            }}>
            Auction Items
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<PeopleIcon />}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/participants')
            }}>
            Participants
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<WebIcon />}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/carouselsetup')
            }}>
            Item Carousel
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>Auction Activity</Paper>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/manualbidentry')
            }}
            startIcon={<AddShoppingCartIcon />}>
            Manual Bid Entry
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/bidmaintenance')
            }}
            startIcon={<RemoveShoppingCartIcon />}>
            Bid Maintenance
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/AuctionStatus')
            }}
            startIcon={<RemoveShoppingCartIcon />}>
            Auction Status
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/liveauction')
            }}
            startIcon={<AddShoppingCartIcon />}>
            Live Auction Winning Bid Entry
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/checkout')
            }}
            className={classes.button}
            startIcon={<ShoppingCartIcon />}>
            Checkout
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                  history.push('/auction/' + eventState.eventId + '/broadcastmessage')
              }}
              className={classes.button}
              startIcon={<MessageIcon />}>
              Communication Tools
          </Button>
        </Grid>
        {allowRefresh &&
          <Grid item xs={4} className={styles.selectcontrol}>
              <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                      history.push('/auction/' + eventState.eventId + '/forcerefresh')
                  }}
                  className={classes.button}
                  startIcon={<RefreshIcon />}>
                  Force Refresh
              </Button>
          </Grid>
        }
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>Reports</Paper>
      </Grid>
      <Grid container spacing={1}>
        {/*<Grid item xs={4} className={styles.selectcontrol}>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    size="large"*/}
        {/*    className={classes.button}*/}
        {/*    startIcon={<SupervisedUserCircleIcon />}*/}
        {/*    onClick={() => {*/}
        {/*      history.push(*/}
        {/*        '/event/' + eventState.eventId + '/participantlisting'*/}
        {/*      )*/}
        {/*    }}>*/}
        {/*    Participation Listing*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={4} className={styles.selectcontrol}>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    size="large"*/}
        {/*    className={classes.button}*/}
        {/*    startIcon={<AssessmentIcon />}*/}
        {/*    onClick={() => {*/}
        {/*      history.push('/event/' + eventState.eventId + '/accountingreport')*/}
        {/*    }}>*/}
        {/*    Accounting Report*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AssessmentIcon />}
            onClick={() => {
              history.push('/auction/' + eventState.eventId + '/AuctionSummaryReportPage')
            }}>
            Auction Summary
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AssessmentIcon />}
            onClick={() => generateWinningBidsClick()
            }>
            Winning Bids
          </Button>
        </Grid>


        {/* <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<AssessmentIcon />}
            onClick={() => {
              history.push('/event/' + eventState.eventId + '/AuctionSummaryReportDetailPage')
            }}>
            Auction Summary Detail
          </Button>
        </Grid> */}
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>DonorSnap Integration</Paper>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SupervisedUserCircleIcon />}
            onClick={() => {
              history.push(
                '/auction/' + eventState.eventId + '/participantslinking'
              )
            }}>
            Participant Linking
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<AssessmentIcon />}
              onClick={() => {
                  history.push('/auction/' + eventState.eventId + '/TransferPage')
              }}>
              Transfer/Export
          </Button>
        </Grid>
        <Grid item xs={4} className={styles.selectcontrol}>
          <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<PeopleIcon />}
              onClick={() => {
                  history.push('/auction/' + eventState.eventId + '/participantmerge')
              }}>
              Participant Merge
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default AuctionPage
