import axios from 'axios'
import { useQuery } from 'react-query'
import { udTitles } from '../pages/ParticipantsPage/useFetchParticipants'

export type UDTitles = {
    userdefinedtitle1: string
    userdefinedtitle2: string
    userdefinedtitle3: string
    userdefinedtitle4: string
    userdefinedtitle5: string
}

const UDTitlesDefault = {
    userdefinedtitle1: "userdefinedtitle1",
    userdefinedtitle2: "userdefinedtitle2",
    userdefinedtitle3: "userdefinedtitle3",
    userdefinedtitle4: "userdefinedtitle4",
    userdefinedtitle5: "userdefinedtitle5"
}

export const globalLocationTitlesFetcher = async (eventIdvalue) => {
    const json = JSON.stringify({ eventid: Number(eventIdvalue) });
    const { data } = await axios.post('/participanttitles', json, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const parsedData = JSON.parse(data.jsonString)
    return parsedData as udTitles
}

export const useFetchUDTitles = (eventid) => {
    return useQuery<udTitles>(
        ['participanttitles', eventid],
        () => globalLocationTitlesFetcher(eventid),
        {
            staleTime: 120000,
            placeholderData: UDTitlesDefault,
            keepPreviousData: false
        }
    )
}

