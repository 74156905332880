import React, {useEffect, useState} from 'react'

import {Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip} from '@material-ui/core'
import { Add, Add as AddIcon, Delete, Edit } from '@material-ui/icons'
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef, GridCsvExportOptions, GridCsvGetRowsToExportParams,
  GridRowParams, gridSortedRowIdsSelector,
  GridSortModel, GridToolbarContainer, GridValueFormatterParams,
  MuiEvent, useGridApiContext,
  useGridApiRef,  GridRowModel,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-premium";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useCreateEvtUserDefined, useFetchUserDefined, useUpdateUserDefined} from '../../queries/useEventUserDefinedFetch'
import { EventUserDefinedForm } from './EventUserDefinedForm'
import styles from './EventUserDefinedTable.module.css'
import {EventState, setEvent, useEventState} from "../../slices/eventSlice";
import {useParams} from "react-router";
import {useAutoEvent} from "../../queries/useAutoEvent";
import {Grid} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Button from '@mui/material/Button';
import {useQueryClient} from "react-query";

const eventItemsDefaults = {
  id: 0,
  control: 0,
  fieldname: '',
  fieldtype: '',
  displayvalue: '',
  active: true,
  uditem: '',
  items: '',
  itemids: [],
  displayforallguest: false,
}

interface Params {
  eventid: string
}

const EventUserDefinedTable = () => {
  const { eventid } = useParams<Params>()
  useAutoEvent(eventid)
  const eventUserDefineCreate = useCreateEvtUserDefined()
  const [gridParams, setGridParams] = useState(eventItemsDefaults)
  const [rowOrdering, setRowOrdering] = useState(false)
  const [dialogObj, setDialogObj] = React.useState({ msg: "", id: 0 })
  const [dialogMsg, setDialogMsg] = React.useState("")
  const [reloadForm, setReloadForm] = useState<number>(0)

  const { data, isLoading } = useFetchUserDefined( Number(eventid) )
  const [isFormShowing, setIsFormShowing] = useState(false)
  var rows = []

  if (data) {
    console.log(data)
    rows = data.map((item) => {
      return { id: item.itemid, ...item}
    })
  }

  const handleAdd = () => {
    setReloadForm(reloadForm+1)
    setGridParams(null)
    setIsFormShowing(true)
  }

  const handleEdit = (params: GridCellParams) => {
    setReloadForm(reloadForm+1)
    const ai = rows.filter((item) => item.id === Number(params.id))[0]
    console.log(ai)
    const itemsTemp = ai.items.split(',').map(strs => { return strs.trim() })
    const itemidsTemp = ai.itemids.split('|')

    var values = {
      id: ai.id,
      control: ai.control,
      fieldname: ai.fieldname,
      fieldtype: ai.fieldtype,
      displayvalue: ai.displayvalue,
      active: ai.active,
      uditem: ai.uditem,
      items: itemsTemp,
      itemids: itemidsTemp,
      displayforallguest: ai.displayforallguest,
    }
    const defaultValue = 'default' + ai.control
    values[defaultValue] = ai[defaultValue]

    setGridParams(values)
    setIsFormShowing(true)
  }

  const handleDelete = (params: GridCellParams) => {
    setDialogObj({
      msg: 'Are you sure you want to delete \"' + params.row.fieldname + "\"?",
      id: Number(params.id)
    })
  }

  const handleDeleteConfirm = async (id: number) => {
    var finaldata = {
      "eventid": Number(eventid),
      "udid": id,
      "delete": true
    }
    const { data: response } = await eventUserDefineCreate.mutateAsync({
      ...finaldata
    },{
      onSuccess: (data) => {
        const json = JSON.parse(data.data.jsonString)
        if (json.status === "failure") {
          setDialogObj({
            msg: '',
            id: 0
          })
          setDialogMsg(json.message)
        }
        else {
          setDialogObj({
            msg: '',
            id: 0
          })
        }
      }
    })
  }

  const deleteDialog = async () => {
    await handleDeleteConfirm(dialogObj.id)
  }

  const closeDialog = () => {
    setDialogMsg('')
    setDialogObj({
      msg: '',
      id: 0
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'Edit',
      disableExport: true,
      renderHeader: () => {
        return (
            <IconButton
                className={styles.submitButton}
                onClick={() => handleAdd()}
                size="small">
              <Add />
            </IconButton>
        )
      },
      width: 80,
      sortable: false,
      renderCell: (params) => (
          <IconButton className={styles.button} onClick={() => { handleEdit(params) }}>
            <Edit />
          </IconButton>
      )
    },
    {
      field: 'id',
      hide: true,
      sortable: false,
    },
    {
      field: 'fieldname',
      type: 'string',
      headerName: 'Field Name',
      minWidth: 350,
      sortable: true,
    },
    {
      field: 'fieldtype',
      type: 'string',
      headerName: 'Field Type',
      minWidth: 200,
      sortable: true,
      hide: false
    },
    {
      field: 'displayvalue',
      type: 'string',
      headerAlign: 'left',
      align: 'right',
      headerName: 'Default Value',
      minWidth: 150,
      sortable: true,
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Status',
      width: 120,
      sortable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (params.value ? "Active" : "Inactive")
      },
    },
    {
      field: 'action',
      headerName: 'Delete',
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      width: 70,
      sortable: false,
      renderCell: (params) => (
          // <div title={canDelete(params.row.active) ? '' : "Only items with a status of Inactive can be deleted."}>
            <div>
              <IconButton className={styles.button} onClick={() => { handleDelete(params) }}>
                <Delete />
              </IconButton>
            </div>
          // </div>
          // </Tooltip>
      )
    }
  ]
  const getUnfilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
      gridSortedRowIdsSelector(apiRef);

  function CustomGridToolbar() {
    const apiRef = useGridApiContext();

    const handleExport = (options: GridCsvExportOptions) => {
      options.allColumns = true;
      apiRef.current.exportDataAsCsv(options);
    }


    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadOutlinedIcon />,
    };

    return (
        <>
          <GridToolbarContainer >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                  <Button
                      {...buttonBaseProps}
                      onClick={() => handleExport({getRowsToExport: getUnfilteredRows})}
                  >
                    EXPORT
                  </Button>
                  {/*<GridToolbarExport style={{color: '#00b2ff'}}/>*/}
                </Grid>
          </GridToolbarContainer>
        </>
    )
  }

  return (
      <div className={styles.root}>
        <h1>User Defined Fields</h1>

      <Dialog
          open={dialogObj.msg.length > 0}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogObj.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={deleteDialog}>DELETE</Button>
        </DialogActions>
      </Dialog>

      <>
        <Dialog
            open={dialogMsg.length > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      </>

      <EventUserDefinedForm isOpen={isFormShowing} setIsOpen={setIsFormShowing} gridParams={gridParams} reloadForm={reloadForm} eventid={eventid} itemrows={rows}/>
      <DataGridPremium
        autoHeight
        rows={rows}
        columns={columns}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        componentsProps={{ toolbar: { csvOptions: { allColumns: true }}}}
      />
    </div>
  )
}

export default EventUserDefinedTable
