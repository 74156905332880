import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import DayJSUtils from '@date-io/dayjs'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

type Props = {
  name: string
  label: string
  defaultValue?: string
  required?: boolean
  disabled?: boolean
  rules?: Object
  textFieldProps?: Object
  dateTimeProps?: Object
  tooltipText?: string
  onClose?: any
}

const DateTimeInput = ({
  name,
  label,
  defaultValue = '',
  rules = {},
  required = false,
  disabled = false,
  textFieldProps,
  dateTimeProps,
  tooltipText = "",
  onClose = null,
}: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name as any,
    control,
    rules: { ...rules, required: required ? 'Field is required' : false },
    defaultValue: defaultValue
  })

  return (
    <MuiPickersUtilsProvider utils={DayJSUtils}>
      <DateTimePicker
        size="small"
        title={tooltipText}
        disabled={disabled}
        InputLabelProps={{ shrink: true, required }}
        variant="dialog"
        inputProps={{ error: Boolean(errors[name]), ...textFieldProps }}
        inputVariant="outlined"
        name={name}
        defaultValue={defaultValue}
        label={label}
        error={Boolean(errors[name])}
        format="MM/DD/YYYY h:mm a"
        helperText={errors[name]?.message}
        required={required}
        onClose={() => {
          onClose && onClose()
        }}
        {...dateTimeProps}
        {...inputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DateTimeInput
