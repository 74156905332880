import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Button } from '@material-ui/core'
import { passwordResetEmail } from "../../../queries/useResetPassword";
import { PagePathTypes } from '../../../App'
import HookForm from '../../../components/common/HookForm'
import TextInput from '../../../components/common/inputs/TextInput'
import { emailRegex } from '../../../util/formValidation'
import { AuthPageSubPaths } from '../AuthPage'
import styles from './ForgotPasswordForm.module.css'
import Alerts from "../../../components/common/Alerts";
import {useState} from "react";

type FormData = {
  email: string
  siteCode: string
}

const ForgotPasswordForm = () => {
  const [successMsg, setSuccessMsg] = useState<string>(null)
  const formMethods = useForm<FormData>({
    mode: 'onChange'
  })
  const { formState, control } = formMethods
  // const dispatch = useDispatch()
  //const router = useRouter()
  //const router = useRouter()

  // useEffect(() => {
  //   if (isLoading && authStatus !== 'pending') {
  //     setIsloading(false)
  //   }

  //   if (authStatus === 'loggedIn') {
  //     router.push('/')
  //   }
  // }, [authStatus, isLoading, router])

  const onSubmit = async (formData: FormData) => {
      console.log('send onSubmit')
      const emailSent = await passwordResetEmail(formData.siteCode, formData.email)
      console.log(emailSent)
      setSuccessMsg('An email was sent to ' + formData.email + ". Please click the link within the email to reset your password.")
  }
  return (
    <div className={styles.root}>
      <h2>Forgot Password</h2>
      <Alerts successMsg={successMsg} />
      <HookForm
        methods={formMethods}
        onSubmit={onSubmit}
        className={styles.form}>
        {/* {isLoading && (
    <CircularProgress className={styles.loadingWheel} size="5rem" />
  )} */}

        <TextInput name="siteCode" label="Site Code" required />
        <TextInput
          name="email"
          label="Email"
          required
          rules={{
            pattern: {
              value: emailRegex,
              message: 'Enter a valid email'
            }
          }}
        />
        <Button type="submit" variant="contained" className={styles.button}>
          Send Password Reset Link
        </Button>
      </HookForm>
      <Link to={`${PagePathTypes.auth}${AuthPageSubPaths.login}`}>
        Back to Login
      </Link>
    </div>
  )
}

export default ForgotPasswordForm
