import { useSelector } from 'react-redux'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AppState } from '../App/store'

export interface OrgState {
  name: string
  message: string
  isdonorsnapcustomer: boolean
  twilio: any
}

const initialState: OrgState = {
  name: '',
  message: '',
  isdonorsnapcustomer: false,
  twilio: {}
}

export const orgSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrg: (state, { payload }: PayloadAction<OrgState>) => {
      state.name = payload.name
      state.message = payload.message
      state.isdonorsnapcustomer = payload.isdonorsnapcustomer
      state.twilio = payload.twilio
    }
  }
})

/** ACTION EXPORTS */
export const {
  setOrg
} = orgSlice.actions

/** REDUCER EXPORT */
export default orgSlice.reducer

/** STATE HOOK EXPORTS - hooks for components to get state */
export const useOrgState = () => useSelector((state: AppState) => state.org)
