import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import NumberFormat, {NumberFormatValues} from 'react-number-format'
import {InputAdornment, TextField} from '@material-ui/core'
import styles from './MaskedInput.module.css'
import {StyledMUIInput} from "../TextInput/TextInput";

type Props = {
  name: string
  format?: string
  mask?: string
  disabled?: boolean
  label: string
  required?: boolean
  rules?: Object
  textFieldProps?: Object
  startAdornment?: string
  endAdornment?: string
  defaultValue?: string
  placeholder?: string
  prefix?: string
  onValueChange?: (values: NumberFormatValues) => void,
  thousandsGroupStyle?: string,
  thousandSeparator?: boolean
  tooltipText?: string
}

const MaskedInput = ({
  name,
  label,
  format,
  mask,
  disabled,
  rules = {},
  required = false,
  textFieldProps,
  startAdornment = "",
  endAdornment = "",
  defaultValue = null,
  placeholder = '',
  prefix = "$",
  onValueChange,
  thousandsGroupStyle = 'thousand',
  thousandSeparator = true,
  tooltipText = ''
}: Props) => {
  const { control, formState } = useFormContext()

  const {
    field: { ref, ...inputProps }
  } = useController({
    name: name as any,
    control,
    rules: { ...rules, required: required ? 'Field is required' : false },
    defaultValue: defaultValue
  })

  const { errors } = formState

  return (
    <NumberFormat
      customInput={TextField}
      title={tooltipText}
      format={format}
      mask={mask}
      disabled={disabled}
      thousandSeparator={thousandSeparator}
      allowNegative={false}
      onValueChange={onValueChange}
      placeholder={placeholder}
      classes={{ root: styles.root }}
      FormHelperTextProps={{
        classes: {
          error: styles.error
        }
      }}
      InputProps={{
        classes: {
          root: styles.input,
          error: styles.error,
          focused: styles.focus
        },
        startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>
      }}
      InputLabelProps={{
        classes: {
          root: styles.label,
          error: styles.error,
          focused: styles.focus
        },
        shrink: true
      }}
      size={'small' as any}
      variant="outlined"
      label={label}
      error={errors[name] ? true : false}
      helperText={errors[name]?.message}
      required={required}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      defaultValue={defaultValue}
      prefix={prefix}
      thousandGroupStyle={thousandsGroupStyle}
      {...textFieldProps}
      {...inputProps}
    />
  )
}

export default MaskedInput
