import axios from 'axios'
import { useQuery } from 'react-query'

export interface User {
    userid: number
    email: string
    password: string
    active: boolean
    globalsetup: boolean
    auctionsettings: boolean
    sponsors: boolean
    customusermessage: boolean
    customemail: boolean
    location: boolean
    auctionitems: boolean
    participants: boolean
    manualbidentry: boolean
    checkout: boolean
    participantlisting: boolean
    accountingreport: boolean
    transfertransactionsto: boolean
    transfertransactionsfrom: boolean
    transfernewcontactsto: boolean
    createinteractionrecords: boolean
    lastupdateddate: string
    lastupdatedby: string
}


export const globalUserFetcher = async () => {
    const { data } = await axios.post('/usersetup', {})
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.users as User[]
}

export const useFetchUsers = ({
                                userid
                            }: {
    userid?: string
} = {}) => {
    return useQuery<User[]>(
        ['users', userid],
        () => globalUserFetcher(),
        {
            staleTime: 0,
            placeholderData: [],
            keepPreviousData: false
        }
    )
}
