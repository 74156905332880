import { useSelector } from 'react-redux'

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AppState } from '../App/store'
import {EventState} from "./eventSlice";

export type CurrentUser = any

export type AuthToken = {
  aud: string
  email: string
  exp: number
  iss: string
  jti: string
  sitecode: string
  siteid: string
  sub: string
  userid: string
}
export interface AuthState {
  currentUser: CurrentUser
  token: AuthToken
  isLoggedIn: boolean
  resetPasswordKey: string
  alloweventrefresh: boolean
  terms: string
}

const initialState: AuthState = {
  currentUser: '',
  token: null,
  isLoggedIn: false,
  resetPasswordKey: '',
  alloweventrefresh: false,
  terms: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser: (state, { payload }: PayloadAction<AuthState>) => {
      state.alloweventrefresh = (payload.alloweventrefresh === true ? true : false)
      state.currentUser = (payload.currentUser ? payload.currentUser : state.currentUser)
    },
    setCurrentUser: (
      state,
      { payload }: PayloadAction<AuthState['currentUser']>
    ) => {
      state.currentUser = Object.assign(state.currentUser, payload)
    },
    loginUser: (state, { payload }: PayloadAction<AuthToken>) => {
      state.token = payload
      state.isLoggedIn = true
    },
    logout: (state) => {
      localStorage.removeItem('tokenadmin')
      state.currentUser = initialState.currentUser
      state.isLoggedIn = false
      state.token = null
      state.alloweventrefresh = false
    },
    setResetPasswordKey: (state, { payload }: PayloadAction<string>) => {
      state.resetPasswordKey = payload
    },
    setAllowEventRefresh: (state, { payload }: PayloadAction<boolean>) => {
      state.alloweventrefresh = payload
    },
    setTerms: (state, { payload }: PayloadAction<string>) => {
      state.terms = payload
    },
    setToken: (state, { payload }: PayloadAction<AuthToken>) => {
      state.token = payload
    }
  }
})

/** ACTION EXPORTS */
export const {
  updateUser,
  setCurrentUser,
  loginUser,
  logout,
  setResetPasswordKey,
  setAllowEventRefresh,
  setToken, setTerms
} = authSlice.actions

/** REDUCER EXPORT */
export default authSlice.reducer

/** STATE HOOK EXPORTS - hooks for components to get state */
export const useAuthState = () => useSelector((state: AppState) => state.auth)

export const useCurrentUser = () =>
  useSelector(({ auth }: AppState) => auth.currentUser)

export const useAuthToken = () =>
  useSelector(({ auth }: AppState) => auth.token)

export const useIsLoggedIn = () =>
  useSelector(({ auth }: AppState) => auth.isLoggedIn)

export const useResetPasswordKey = () =>
  useSelector(({ auth }: AppState) => auth.resetPasswordKey)

export const useAllowEventRefresh = () =>
  useSelector(({ auth }: AppState) => auth.alloweventrefresh)

/** SAGA SELECTORS - selects state for sagas */
export const selectResetPasswordKey = ({ auth }: AppState) =>
  auth.resetPasswordKey
