import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export const globalEventTransactionFetcher = async (body) => {
    const { data } = await axios.post('/evtactivitytransactions', body)
    const parsedData = JSON.parse(data.jsonString)
    console.log(parsedData)
    return parsedData.transactions
}

export const useFetchTransaction = (eventIDvalue) => {
    var body = {"eventid": eventIDvalue}
    return useQuery(
        ['EventTransaction', eventIDvalue],
        () => globalEventTransactionFetcher(body),
        {
            staleTime: 120000,
            //keepPreviousData: true
        }
    )
}

export const useCreateEventTransaction = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined'])
        }
    })
}

export const useUpdateTransaction = (eventIDvalue) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtuserdefined`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['UserDefined', eventIDvalue])
        }
    })
}