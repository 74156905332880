import axios from 'axios'
import { useQuery, useQueryClient, useMutation } from 'react-query'

export const globalEventItemsFetcher = async (body) => {
    const { data } = await axios.post('/evtitems', body)
    const parsedData = JSON.parse(data.jsonString)
    return parsedData.eventitems
}

export const useFetchEventItems = (eventiDvalue) => {
    return useQuery(
        ['evtitems', eventiDvalue],
        () => globalEventItemsFetcher({ "eventid": eventiDvalue }),
        {
            staleTime: 120000,
            //keepPreviousData: true
        }
    )
}

export const useCreateEvtItem = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtitems`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['evtitems'])
        }
    })
}

export const useUpdateEvtItem = () => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.post(`evtitems`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['evtitems'])
        }
    })
}

export const useUpdateItemOrder = (eventiDvalue) => {
    const queryClient = useQueryClient()

    return useMutation((body: any) =>
        axios.put(`evtitemsorder`, body), {
        onSuccess: () => {
            queryClient.invalidateQueries(['evtitems', eventiDvalue])
        }
    })
}