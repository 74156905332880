import { FC, PropsWithChildren } from 'react'
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form'

type Props<FormData> = {
  onSubmit: SubmitHandler<FormData>
  methods: UseFormReturn<FormData>
  className?: string
  formProps?: Object
}

function HookForm<FormData>({
  children,
  methods,
  onSubmit,
  className = '',
  formProps = {}
}: PropsWithChildren<Props<FormData>>) {
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={className}
        {...formProps}>
        {children}
      </form>
    </FormProvider>
  )
}

export default HookForm
